import { useMemo } from 'react';

import Image from '../../../components/image';
import { BlockType } from '../../../types/page-generator';
import { getContentValueFromProps } from '../../generate-page/utils';

type Props = {
  block: BlockType | undefined;
};

export const MainBanner = ({ block }: Props) => {
  const getFieldValue = getContentValueFromProps(block);

  const logoImage = useMemo(() => getFieldValue('logo'), [block]);
  const enableLogo = useMemo(() => getFieldValue('settings.enableLogo'), [block]);

  return <>{enableLogo && <Image src={logoImage} />}</>;
};
