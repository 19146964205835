import { FC, Key } from 'react';

import { Box, Grid, Stack, Pagination, Typography } from '@mui/material';

import Iconify from '../../../../components/iconify';
import { ComponentTypeProps } from '../../../../types/page-generator';

const VouchersV2: FC<ComponentTypeProps> = ({ block }) => {
  const vouchers = [
    {
      id: 1,
      url: '/assets/images/xtra/banners/people/1.png',
      text: 'שוברי חופשה',
    },
    {
      id: 2,
      url: '/assets/images/xtra/banners/people/2.png',
      text: 'אופנה',
    },
    {
      id: 3,
      url: '/assets/images/xtra/banners/people/3.png',
      text: 'מסעדות',
    },
    {
      id: 4,
      url: '/assets/images/xtra/banners/people/4.png',
      text: 'אטרקציות ואקסטרים',
    },
    {
      id: 5,
      url: '/assets/images/xtra/banners/people/5.png',
      text: 'רשתות מזון',
    },
    {
      id: 6,
      url: '/assets/images/xtra/banners/people/6.png',
      text: 'מוצרים',
    },
    {
      id: 7,
      url: '/assets/images/xtra/banners/people/7.png',
      text: 'טיפוח',
    },
    {
      id: 8,
      url: '/assets/images/xtra/banners/people/8.png',
      text: 'סרטים פנאי ותרבות',
    },
    {
      id: 9,
      url: '/assets/images/xtra/banners/people/9.png',
      text: 'מתנות עד הבית',
    },
    {
      id: 10,
      url: '/assets/images/xtra/banners/people/10.png',
      text: 'ספא',
    },
    {
      id: 11,
      url: '/assets/images/xtra/banners/people/11.png',
      text: 'מוצרים עם שווי החלפה',
    },
    {
      id: 12,
      url: '/assets/images/xtra/banners/people/12.png',
      text: 'מתנות עד הבית',
    },
  ];
  return (
    <>
      <Grid container spacing={2}>
        {vouchers.map((voucher: any, id: Key | null | undefined) => (
          <Grid item xs={3} key={id}>
            <Box
              sx={{
                backgroundSize: 'cover',
                backgroundImage: `url(${voucher.url})`,
                backgroundRepeat: 'no-repeat',
                height: '140px',
                borderRadius: '10px',
              }}
            />
            <Stack direction="row" justifyContent="space-between" mt={1}>
              <Typography>{voucher.text}</Typography>
              <Iconify icon="mingcute:arrow-left-line" />
            </Stack>
          </Grid>
        ))}
      </Grid>
      <Stack alignItems="center">
        <Pagination count={10} color="primary" sx={{ margin: '0 auto' }} />
      </Stack>
    </>
  );
};

export default VouchersV2;
