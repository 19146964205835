import { useRouter } from 'next/navigation';
import { FC, useMemo, useState } from 'react';

import { Box, Grid, Container, Typography } from '@mui/material';

import { useGetMerchants } from 'src/api/shop';
import { BASIC_URL_FOR_IMAGES } from 'src/constants/common';
import { DEFAULT_MERCHANT_IMAGE } from 'src/_mock/_companies';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';

import { IMerchant } from 'src/types/merchant';
import { ComponentTypeProps } from 'src/types/page-generator';

import { getContentValueFromProps } from '../../utils';

const LIMIT = 1000;

export const CompanyListCityPeople: FC<ComponentTypeProps> = ({ block }) => {
  const [page, setPage] = useState(0);
  const router = useRouter();
  const { dispatch } = useAppContext();

  const { merchants, totalCount } = useGetMerchants({
    limit: LIMIT,
    offset: page * LIMIT,
    relations: ['categories'],
  });
  const getFieldValue = getContentValueFromProps(block);

  // === Content Values ===
  const link: string = useMemo(() => getFieldValue('link'), [block?.link]);
  const isExistLink = useMemo(() => link && link !== '#', [link]);
  const getPreparedLink = () => {
    if (link && isExistLink) return link[0] === '/' ? link : `/${link}`;
    return '#';
  };
  const getImageLink = (imgLink: string) =>
    imgLink ? `${BASIC_URL_FOR_IMAGES}${imgLink}` : DEFAULT_MERCHANT_IMAGE;

  const handleClick = (id: string) => () => {
    dispatch({
      type: ActionTypes.SET_SELECTED_MERCHANT,
      payload: merchants.find((m: IMerchant) => m.id === id),
    });
    if (link && isExistLink) router.push(getPreparedLink());
  };

  return (
    <Container maxWidth="sm">
      <Grid
        container
        spacing={1}
        mt={2}
        sx={{
          width: { xs: '100%', sm: '340px' },
          margin: '0 auto',
        }}
      >
        {!totalCount && (
          <Typography
            sx={{ margin: '0 auto' }}
            textAlign="center"
            variant="h6"
            color="secondaryContrastText"
          >
            NO MERCHANTS
          </Typography>
        )}
        {!!totalCount &&
          merchants.map((merchant: IMerchant) => (
            <Grid key={merchant.id} item width="100%" xs={4}>
              <Box
                width={95}
                height={95}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  margin: '0 auto',
                  borderRadius: 2,
                  background: '#FFFFFF',
                  backgroundImage: `url('${getImageLink(merchant.mainImageURL) || ''}')`,
                  backgroundPosition: '50%',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                }}
                onClick={handleClick(merchant.id)}
              />
            </Grid>
          ))}
      </Grid>
    </Container>
  );
};
