import { useMemo, useState, useEffect } from 'react';

// @mui
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Button, InputLabel, FormControl, OutlinedInput, InputAdornment } from '@mui/material';

// constants
import { BASIC_URL_FOR_IMAGES } from 'src/constants/common';

// types
import { IVirtualCard } from 'src/types/club-cards';

import Iconify from '../../components/iconify';
import { UsedVirtualCard } from '../../types/checkout';
import { ActionTypes, useAppContext } from '../../contexts/AppContext';

// ----------------------------------------------------------------------

type ClubCardProps = {
  card: IVirtualCard;
  handleToggleCard: () => void;
  // openModal: () => void;
  isChecked: boolean;
  isMix: boolean;
  isMixMustClub: boolean;
  isEnoughMoney: boolean;
  totalSum: number;
  showError: (text: string) => void;
};

const ClubCard = ({
  card,
  handleToggleCard,
  isEnoughMoney,
  isChecked,
  isMix,
  isMixMustClub,
  showError,
  totalSum,
}: ClubCardProps) => {
  const [cost, setCost] = useState<number | null>(null);
  const { dispatch, state } = useAppContext();
  const isUsedCard = state.checkout.usedVirtualCards
    .map((usedCard: UsedVirtualCard) => usedCard.id)
    .includes(card.id);
  const cartCost = useMemo(
    () => state.checkout.usedVirtualCards.find((c: UsedVirtualCard) => c.id === card.id)?.cost || 0,
    [state.checkout.usedVirtualCards]
  );
  const isEqualCartCost = isUsedCard ? cartCost === cost : null;
  const isLimitCardsCount = useMemo(
    () => state.checkout.usedVirtualCards.length > 1,
    [state.checkout.usedVirtualCards]
  );
  const isLimitCardMoney = useMemo(() => Number(cost) > Number(card.balance), [cost, card]);
  const isDisableButton =
    cost === null ||
    isEqualCartCost ||
    (!isUsedCard && isLimitCardsCount) ||
    (!isUsedCard && cost === 0);

  const defaultImage =
    process.env.NEXT_PUBLIC_PROJECT_NAME === 'xtra'
      ? '/assets/images/xtra/default.png'
      : '/assets/images/happy-gift/banners/blue-card.png';

  const imageURL = card.cardType?.mainImageURL
    ? `${BASIC_URL_FOR_IMAGES}${card.cardType.mainImageURL}`
    : defaultImage;

  useEffect(() => setCost(cartCost || null), []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setCost(+event.target.value);
  const handleUseCard = useMemo(() => {
    if (typeof showError === 'function' && isLimitCardMoney)
      return () => showError('You entered an amount exceeding the card balance');
    if (isDisableButton) return () => {};

    return () => {
      let correctCost = cost <= totalSum ? cost : totalSum;

      if (isUsedCard) {
        correctCost = cost <= totalSum + cartCost ? cost : totalSum + cartCost;
      }

      dispatch({
        type: ActionTypes.USE_VIRTUAL_CARD,
        payload: { id: card.id, cost: correctCost, name: card.cardType?.name || 'card' },
      });

      setCost(correctCost || null);
    };
  }, [dispatch, isLimitCardMoney, isUsedCard, isDisableButton, cost, totalSum]);

  if (!card.cardType) return null;
  return (
    <Card
      sx={{
        width: '100%',
        height: '48px',
        borderColor: isChecked ? 'primary.main' : 'transparent',
        ...(isMix && { border: 0 }),
        ...(!isUsedCard && isLimitCardsCount && { opacity: '0.4' }),
        ...(!isMix && !isEnoughMoney && { opacity: '0.4' }),
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: isMix ? 'space-between' : 'space-around',
        gap: '8px',
        borderRadius: 0.5,
        padding: '36px 8px',
      }}
      onClick={isEnoughMoney ? handleToggleCard : () => {}}
      variant="outlined"
    >
      {isMix && (
        <Box
          sx={{
            width: '104px',
            height: '36px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            size="small"
            sx={{
              height: '100%',
              width: '58px',
              backgroundColor: '#212B36',
              color: 'white',
              borderRadius: '8px',
              ...(isUsedCard &&
                isEqualCartCost && {
                  backgroundColor: '#22C55E',
                  width: '100%',
                }),
              ...(isLimitCardMoney && {
                backgroundColor: '#FF5630',
              }),
              '&:hover': {
                backgroundColor: '#0d0f11',
                ...(isUsedCard &&
                  isEqualCartCost && {
                    backgroundColor: '#398556',
                  }),
                ...(isLimitCardMoney && {
                  backgroundColor: '#b65946',
                }),
              },
            }}
            onClick={handleUseCard}
          >
            {isUsedCard && isEqualCartCost && !isLimitCardMoney ? (
              <Stack display="flex" flexDirection="row" gap={2} alignItems="center">
                <Typography variant="button">לתשלום</Typography>
                <Iconify icon="el:ok" width={14} />
              </Stack>
            ) : (
              <Typography variant="button">שמור</Typography>
            )}
          </Button>
        </Box>
      )}

      {(isMix || isMixMustClub) && (
        <FormControl sx={{ m: 0, width: '125px' }} variant="outlined">
          <InputLabel size="small" htmlFor={`cost_virtual_card_${card.id}`}>
            סכום להורדה
          </InputLabel>
          <OutlinedInput
            size="small"
            id={`cost_virtual_card_${card.id}`}
            type="text"
            value={cost || ''}
            endAdornment={
              <InputAdornment position="end">
                <Iconify
                  icon="fa:shekel"
                  width={10}
                  color={isEqualCartCost ? '#EB157B' : 'inherit'}
                />
              </InputAdornment>
            }
            label="סכום להורדה"
            onChange={handleChange}
          />
        </FormControl>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', gap: '4px', minWidth: '22%' }}>
        <Typography sx={{ color: '#EB157B', fontSize: '14px' }}>
          <bdi>₪{card.balance || 0}</bdi>
        </Typography>
        <Typography sx={{ fontSize: '12px' }}>
          <bdi>יתרת הכרטיס:</bdi>
        </Typography>
      </Box>

      <Typography
        variant="body2"
        sx={{
          minWidth: '23%',
          fontSize: '14px',
          textAlign: 'end',
          fontWeight: 400,
        }}
      >
        {card.cardType?.name || ''}
      </Typography>

      <CardMedia
        sx={{ height: 40, width: 70, borderRadius: 1, objectFit: 'contain' }}
        image={imageURL}
      />
    </Card>
  );
};

export default ClubCard;
