import * as Yup from 'yup';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
// eslint-disable-next-line
import ReactCodeInput from 'react-verification-code-input';

import { Box, Stack, Button, TextField, Typography } from '@mui/material';

import Image from '../../../../../../components/image';
import { ComponentTypeProps } from '../../../../../../types/page-generator';
import FormProvider, { RHFRadioGroup } from '../../../../../../components/hook-form';
import { useAuthContext } from '../../../../../../auth/hooks';

interface Props {
  onClose: () => void;
  closingForbidden?: boolean;
}

export const PasportNumberAndCode = ({ block }: ComponentTypeProps) => {
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [isCodeView, setIsCodeView] = useState(false);
  const { t } = useTranslation();
  const ref = useRef<any>();
  const RegisterSchema = Yup.object().shape({});
  const defaultValues = {};
  const isMaybeNext = !(phone.length > 9);
  const isMaybeNextEmail = !(email.length > 9);

  const { login, checkCode, user } = useAuthContext();
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });
  const mainImage: string = block?.mainImage as string;

  const getCode = async () => {
    try {
      await login(phone);
      setIsCodeView(true);
    } catch (e) {
      console.error(e);
    }
  };

  const sendCode = async (code: string) => {
    try {
      setIsCodeView(true);
      await checkCode(phone, code);
      setIsCodeView(false);
    } catch (e) {
      console.error(e);
      setIsCodeView(false);
    }
  };

  return (
    <Box>
      <Stack width={1} alignItems="center">
        <Image src={mainImage || ''} sx={{ width: '20%' }} />
      </Stack>
      {!isCodeView && (
        <Stack p={3} gap={2}>
          <Typography
            color="primary"
            sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'start' }}
          >{`${t('Enter passport code')}`}</Typography>
          <TextField fullWidth label={t('Pasport code')} />
          <TextField fullWidth label={t('Code')} />
        </Stack>
      )}
      <Box>
        {block?.settings?.enablePhone && (
          <Box>
            {!isCodeView && (
              <Stack p={3} gap={2}>
                <Typography
                  color="primary"
                  sx={{
                    fontSize: '18px',
                    fontWeight: 600,
                    textAlign: 'start',
                  }}
                >
                  אימות חשבון
                </Typography>
                <TextField
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  id="outlined-basic"
                  label="מספר הטפון"
                  variant="outlined"
                  helperText="הקלד 10 ספרות"
                />
              </Stack>
            )}
          </Box>
        )}
        {block?.settings?.enableEmail && (
          <Typography sx={{ mx: 4, display: !block?.settings?.enablePhone ? 'none' : '' }}>
            או
          </Typography>
        )}
        {block?.settings?.enableEmail && (
          <Box>
            {!isCodeView && (
              <Stack p={3} gap={2}>
                {/* <Typography */}
                {/*  color="primary" */}
                {/*  sx={{ */}
                {/*    fontSize: '18px', */}
                {/*    fontWeight: 600, */}
                {/*    textAlign: 'start', */}
                {/*  }} */}
                {/* > */}
                {/*  אימות חשבון */}
                {/* </Typography> */}
                <TextField
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="outlined-basic"
                  label="אימייל "
                  variant="outlined"
                />
                <Stack direction="row" gap={2}>
                  <Button
                    sx={{ height: '40px', padding: 0 }}
                    variant="contained"
                    color="primary"
                    disabled={isMaybeNext}
                    onClick={getCode}
                  >
                    כניסה
                  </Button>
                  <FormProvider methods={methods} onSubmit={() => {}}>
                    <RHFRadioGroup
                      row
                      name="type"
                      options={[
                        {
                          label: `${t('קבלת קוד: s.m.s')}`,
                          value: 'קבלת קוד: s.m.s',
                        },
                        { label: `${t('הודעה קולית')}`, value: 'הודעה קולית' },
                      ]}
                    />
                  </FormProvider>
                </Stack>
              </Stack>
            )}
          </Box>
        )}
        {isCodeView && (
          <Stack alignItems="center">
            <Typography mb={5}>Enter code from sms</Typography>
            <Box mb={5} sx={{ direction: 'rtl' }}>
              <ReactCodeInput
                ref={ref}
                autoFocus
                fields={6}
                onComplete={(code) => sendCode(code)}
              />
            </Box>
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default PasportNumberAndCode;
