import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import Image from '../../components/image';
import Iconify from '../../components/iconify';
import { LOGO_PATH } from '../../config-global';
import { useResponsive } from '../../hooks/use-responsive';

type Props = { onClose: () => void; open: boolean };

const MixPaymentModal = ({ onClose, open }: Props) => {
  const smUp = useResponsive('up', 'sm');

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: smUp ? '480px' : '100%',
          minHeight: 250,
          bgcolor: 'background.paper',
          borderRadius: '20px',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Iconify
            icon="iconamoon:close"
            onClick={onClose}
            sx={{ cursor: 'pointer', color: 'grey.600', position: 'absolute', right: 0, top: 0 }}
          />
          <Stack alignItems="center">
            <Image src={LOGO_PATH.full} width="255px" />
            <Stack direction="column" alignItems="center">
              <Typography
                sx={{ fontSize: '24px', fontWeight: 'bold', lineHeight: '28px' }}
                color="secondary.main"
              >
                לא ניתן לממש בסכום זה. הסכום
              </Typography>
              <Typography
                sx={{ fontSize: '24px', fontWeight: 'bold', lineHeight: '28px' }}
                color="secondary.main"
              >
                בכרטיס ברשותך הנו
              </Typography>
              <Typography
                sx={{ fontSize: '24px', fontWeight: 'bold', lineHeight: '28px' }}
                color="secondary.main"
              >
                {100.0}
                <Iconify icon="fa:shekel" width={14} color="secondary.main" />
              </Typography>
            </Stack>

            <Divider
              orientation="horizontal"
              sx={{ borderStyle: 'dashed', my: 2, borderColor: 'grey.600', width: '100%' }}
            />

            <Stack direction="column" alignItems="center" sx={{ mb: 2 }}>
              <Typography
                sx={{ fontSize: '20px', fontWeight: '100', textDecoration: 'underline' }}
                color="#212B36"
              >
                ניתן להשלים את הסכום באמצאות כרטיס אשראי.
              </Typography>
              <Typography
                sx={{ fontSize: '20px', fontWeight: '100', textDecoration: 'underline' }}
                color="#212B36"
              >
                האם רוצים להשלים באמצאות כרטיס אשראי?
              </Typography>
            </Stack>

            <Stack direction="row-reverse" gap={2}>
              <Button variant="outlined" sx={{ height: '36px' }} onClick={onClose}>
                לא, תודה
              </Button>
              <Button
                variant="outlined"
                onClick={() => {}}
                sx={{
                  height: '36px',
                  backgroundColor: '#212B36',
                  color: '#fff',
                  '&:hover': { backgroundColor: '#0d0f11' },
                }}
              >
                כן, בבקשה
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default MixPaymentModal;
