import { FC, Key } from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Image from '../../../../components/image';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';

const AllHappyHoldersBanners: FC<ComponentTypeProps> = ({ block }) => {
  const { t } = useTranslation();
  const smDown = useResponsive('down', 'sm');
  const cards = [
    {
      title:
        'Gift Card מפנק הניתן למימוש ישיר במגוון רחב של רשתות אופנה, מסעדות בתי קפה ועוד מגוון הטבות המחכות לכם באתר ההטבות של HappyGift, שם תוכלו למצוא הטבות נוספות במגוון תחומים הניתנות למימוש באמצעות התחברות לאתר לאחר הקשת קוד הכרטיס שתקבלו ',
      subTitle: '*הכרטיס ניתן לטעינה חוזרת',
      linkText: 'רשימת בתי עסק מכבדים',
      logo: '/assets/images/happy-gift/logo.png',
      image: '/assets/images/happy-gift/banners/inclined/white-pink.png',
    },
    {
      title:
        'Gift Card מפנק הניתן למימוש ישיר במגוון רחב של רשתות אופנה, מסעדות בתי קפה ועוד מגוון הטבות המחכות לכם באתר ההטבות של HappyGift, שם תוכלו למצוא הטבות נוספות במגוון תחומים הניתנות למימוש באמצעות התחברות לאתר לאחר הקשת קוד הכרטיס שתקבלו ',
      subTitle:
        '*הכרטיס ניתן למימוש גם ברשתות נוספות: קסטרו, קסטרו קידס ואיב רושה (כולל כפל מבצעים, סוף עונה ומבצעי מועדון), רשת צומת ספרים, מגה ספורט, מיננה.',
      linkText: 'רשימת בתי עסק מכבדים',
      logo: '/assets/images/happy-gift/logo.png',
      image: '/assets/images/happy-gift/banners/inclined/black-orange.png',
    },
    {
      title:
        'Gift Card מפנק הניתן למימוש ישיר במגוון רחב של רשתות אופנה, מסעדות בתי קפה ועוד מגוון הטבות המחכות לכם באתר ההטבות של HappyGift, שם תוכלו למצוא הטבות נוספות במגוון תחומים הניתנות למימוש באמצעות התחברות לאתר לאחר הקשת קוד הכרטיס שתקבלו ',
      subTitle:
        '*הכרטיס ניתן למימוש ברשתות נוספות: קסטרו, קסטרו קידס ואיב רושה (כולל כפל מבצעים, סוף עונה ומבצעי מועדון), מותגי בריל: גלי, Nine West, לי קופר, סולוג (כולל מבצעי מועדון ועודפים), רשת צומת ספרים, מגה ספורט, טרקלין חשמל, H&O , מיננה',
      linkText: 'רשימת בתי עסק מכבדים',
      logo: '/assets/images/happy-gift/logo.png',
      image: '/assets/images/happy-gift/banners/inclined/blue-pink.png',
    },
    {
      title:
        'Gift Card מפנק הניתן למימוש ישיר במסעדות וברשתות : Frame, פיצה עגבניה, GUESS , H&O , צומת ספרים, סולתם, ורדינון, נעמן, אינטימה, כיתן, פולגת, טימברלנד, אימפוריום, אופטיקנה, נאוטיקה, golf, golf kids, golf&co, DKNY, Concept store , שגב sam edelman ועוד',
      linkText: 'רשימת בתי עסק מכבדים',
      logo: '/assets/images/happy-gift/logo.png',
      image: '/assets/images/happy-gift/banners/inclined/red-orange.png',
    },
  ];

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Box
      sx={{
        '& .slick-slide': {
          height: smDown ? '600px' : '310px',
          direction: 'rtl',
          mb: '120px',
          display: 'grid',
          alignItems: 'end',
        },
        '& .slick-slider': {
          '& .slick-dots': {
            position: 'absolute',
            bottom: smDown ? '50px' : '80px',
            right: smDown ? '20%' : '50%',
            width: '150px',
            '& .slick-active': {
              '& button': {
                '&::before': {
                  color: '#EB157B !important',
                },
              },
            },
            '& li': {
              margin: 0,
              '& button': {
                width: '20%',
                '&::before': {
                  color: '#EB157B !important',
                },
              },
            },
          },
        },
      }}
    >
      <Slider {...settings}>
        {cards.map((item: any, index: Key) => (
          <Card
            key={index}
            sx={{
              width: '100%',
              borderRadius: '20px',
              backgroundSize: 'cover',
              padding: smDown ? '25px!important' : 5,
            }}
          >
            <Grid
              container
              spacing={2}
              flexDirection={smDown ? 'column-reverse' : 'row-reverse'}
              alignItems=""
            >
              <Grid
                item
                md={7}
                xs={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  width: smDown ? '100%' : '50%',
                  height: '100%',
                }}
              >
                <Stack direction="column" alignItems="flex-end" gap={2}>
                  <Image src={item.logo} />
                  <Typography
                    sx={{
                      fontWeight: '',
                      fontStyle: '',
                      textDecoration: '',
                      color: 'primary.main',
                      fontSize: '',
                      direction: 'rtl',
                      textAlign: 'end',
                      width: smDown ? '100%' : '70%',
                      lineHeight: 1,
                      // mb: 2,
                    }}
                  >
                    {item.title}
                  </Typography>

                  <Typography
                    sx={{
                      fontWeight: '',
                      fontStyle: '',
                      textDecoration: '',
                      color: 'primary.main',
                      fontSize: '',
                      direction: 'ltr',
                      textAlign: 'start',
                      width: smDown ? '100%' : '70%',
                      lineHeight: 1,
                      // mb: 2,
                    }}
                  >
                    {item.subTitle}
                  </Typography>

                  <Typography
                    sx={{
                      direction: 'rtl',
                      color: 'primary.main',
                      fontSize: '',
                      fontStyle: '',
                      fontWeight: 600,
                      textDecoration: 'underline',
                      mb: 1,
                    }}
                  >
                    {item.linkText}
                  </Typography>
                  <Stack direction="row" spacing={2} py={1} mt="-25px">
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        borderRadius: 10,
                        fontSize: '12px',
                        fontWeight: 400,
                        bgcolor: '#EB157B',
                        '&:hover': { bgcolor: '#EB157B' },
                      }}
                    >
                      Happy store
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item md={5} xs={12}>
                <Box sx={{ position: 'relative' }}>
                  <Image src={item.image} sx={{ width: '100%' }} />
                </Box>
              </Grid>
            </Grid>
          </Card>
        ))}
      </Slider>
    </Box>
  );
};

export default AllHappyHoldersBanners;
