import { FC } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Image from 'src/components/image';

import SanitizeHTML from '../../../../utils/sanitize';
import SvgColor from '../../../../components/svg-color';
import { useResponsive } from '../../../../hooks/use-responsive';
import { BlockType } from '../../../../types/page-generator';

type ShopBanner2BlockType = {
  SB2_cardImage?: string;
  SB2_logo?: string;
  SB2_titleTextStart?: string;
  SB2_titleTextButton?: string;
  SB2_titleTextEnd?: string;
  SB2_contentText?: string;
  SB2_arrowLinkText?: string;
};
type ShopBanner2Props = { block?: BlockType & ShopBanner2BlockType };

const ShopBanner2: FC<ShopBanner2Props> = ({ block }) => {
  const smDown = useResponsive('down', 'sm');
  const theme = useTheme();
  const backgroundImage = '';
  const alignItems = 'center';
  const {
    SB2_cardImage,
    SB2_logo,
    SB2_titleTextStart,
    SB2_titleTextButton,
    SB2_titleTextEnd,
    SB2_contentText,
    SB2_arrowLinkText,
  } = block || {};

  const cardImage = SB2_cardImage ?? '/assets/images/happy-gift/banners/cardholder.png';
  const happyGiftLogoImage = SB2_logo ?? '/assets/images/happy-gift/happy-gift-global-banner.png';
  const titleText = {
    start: SB2_titleTextStart ?? 'לראשונה בישראל',
    button: SB2_titleTextButton ?? 'הכרטיס',
    end: SB2_titleTextEnd ?? 'שמקנה הכל!',
  };
  const contentText =
    SB2_contentText ??
    'הגיפט קארד היחיד שמאפשר מימוש בכל מקום בארץ ובעולם, אפשרות לגיפט קארד לאיביי, איקאה, זארה, Next, amazon ועודמותגים בארץ ובעולם כולל רשתות מזון ובנוסף הטבות למחזיקי הכרטיס דרך אתר מיקרודיל כולל הנחות אטרקטביות.';
  const arrowLinkText = SB2_arrowLinkText ?? 'קח אותי לחנות מיוחדת למחזיקי כרטי HappyGift Global';

  const title = (
    <Typography
      sx={{
        fontWeight: 600,
        color: '#27097A',
        fontSize: '24px',
        textAlign: 'start',
        // mb: 2,
      }}
    >
      {titleText.start}
      <Button
        variant="contained"
        sx={{
          mx: 1,
          height: '45px',
          py: 0,
          borderRadius: '10px',
          fontWeight: 600,
          fontSize: '24px',
          bgcolor: '#27097A',
          '&:hover': { bgcolor: '#27097A', color: 'secondary.contrastText' },
        }}
      >
        {titleText.button}
      </Button>
      {titleText.end}
    </Typography>
  );

  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '20px',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        padding: 5,
      }}
    >
      <Grid container spacing={2} flexDirection="row-reverse" alignItems={alignItems}>
        <Grid item md={5} xs={12}>
          <Box sx={{ position: 'relative' }}>
            <Image src={cardImage} sx={{ width: smDown ? '50%' : '80%' }} />
          </Box>
        </Grid>
        <Grid
          item
          md={7}
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            width: smDown ? '100%' : '50%',
          }}
        >
          <Stack direction="column" alignItems="start" gap={1}>
            <Stack gap={1} alignItems="start">
              <Image src={happyGiftLogoImage} sx={{ width: '20%', mb: '-15px' }} />
              {title}
              <Typography
                sx={{
                  color: 'black',
                  fontSize: '15px',
                  width: '85%',
                }}
              >
                <SanitizeHTML html={contentText} />
              </Typography>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="text"
                  sx={{
                    flex: 1,
                    color: '#27097A',
                    textDecoration: 'underline',
                    width: '100%',
                    px: 0,
                    '&:hover': {
                      bgcolor: 'transparent',
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {arrowLinkText}
                  <SvgColor
                    src="/assets/icons/happy-gift/arrow_1.svg"
                    sx={{ width: 35, mx: 1 }}
                    color={theme.palette.primary.main}
                  />
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShopBanner2;
