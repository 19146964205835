import { FC, useMemo } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useResponsive } from 'src/hooks/use-responsive';

import { getContentValueFromProps } from '../../utils';
import { ComponentTypeProps } from '../../../../types/page-generator';

const TitleAndZipLine: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  // const smDown = useResponsive('down', 'sm');
  const isMobile = useResponsive('down', 'sm');

  const contentTextColor = useMemo(() => getFieldValue('settings.contentColor'), [getFieldValue]);
  const contentFontSize = useMemo(() => getFieldValue('settings.contentFontSize'), [getFieldValue]);
  const contentFontWeight = useMemo(
    () => getFieldValue('settings.contentFontWeight'),
    [getFieldValue]
  );
  const contentFontStyle = useMemo(
    () => getFieldValue('settings.contentFontStyle'),
    [getFieldValue]
  );
  const contentTextDecoration = useMemo(
    () => getFieldValue('settings.contentTextDecoration'),
    [getFieldValue]
  );

  const contentClasses = useMemo(() => getFieldValue('settings.contentClasses'), [getFieldValue]);

  const titleText = useMemo(() => {
    if (block?.titleText) {
      return block?.titleText;
    }
    return getFieldValue('titleText');
  }, [block?.titleText, getFieldValue]);

  const smDown = useResponsive('down', 'sm');

  return (
    <Stack alignItems="center">
      {/* TODO: Временное решение с классом, сделать постоянное */}
      <Typography
        className={contentClasses}
        sx={{
          lineHeight: isMobile ? 'normal' : '',
          color: contentTextColor,
          fontSize: contentFontSize,
          fontWeight: contentFontWeight,
          fontStyle: contentFontStyle,
          textDecoration: contentTextDecoration,
          textAlign: smDown ? 'center' : '',
        }}
      >
        {titleText}
      </Typography>
    </Stack>
  );
};
export default TitleAndZipLine;
