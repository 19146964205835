// import Link from 'next/link';
import NextLink from 'next/link';
import { useParams } from 'next/navigation';
import { useMemo, useState, useEffect, ChangeEvent } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import SanitizeHTML from 'src/utils/sanitize';

import { useTranslate } from 'src/locales';
import { addToBasket } from 'src/api/basket';
import { useAuthContext } from 'src/auth/hooks';
import { extractPrices } from 'src/helpers/extractPrices';
import { BASIC_URL_FOR_IMAGES } from 'src/constants/common';
import Scrollbar from 'src/extra-components/bazaar/Scrollbar';
import { generateOptions } from 'src/helpers/generateOptions';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import { checkIfProductInCart } from 'src/helpers/checkIfProductInCart';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import BasicModal from 'src/components/modals/basic-modal';

import { IMerchant } from 'src/types/merchant';
import { IAsterisk } from 'src/types/asterisk';
import { ButtonField } from 'src/types/generator';
import { BlockType } from 'src/types/page-generator';
import { Ib2bProduct, ICategoryItem, ProvidersEnum } from 'src/types/b2b';

import { getContentValueFromProps } from '../generate-page/utils';
import { useContentContext } from '../../components/content';

type Props = {
  // TODO: Very error-prone mix of types, should be refactored and separated to different components
  product: Ib2bProduct | IMerchant | any;
  imageLink: string;
  block?: BlockType;
  topPriceFontSize?: string;
  bottomPriceFontSize?: string;
};

type openPrice = {
  price: number;
  title: string;
  value: string;
};

export const ProductDetailsSummary = ({
  product,
  imageLink,
  block,
  topPriceFontSize,
  bottomPriceFontSize,
}: Props) => {
  const [modal, setModal] = useState(false);
  const [count, setCount] = useState<string>('');
  const [elements, setElements] = useState<string[]>([]);
  const [attributes, setAttributes] = useState(false);
  const { user } = useAuthContext();
  const { openSite } = useContentContext();
  const defaultLimit = 1;
  const isStock = product.provider === ProvidersEnum.STOCK;
  const limit =
    isStock && product.productCodeStockCount ? +product.productCodeStockCount : defaultLimit;
  const OPTIONS = generateOptions(1, limit);
  const smUp = useResponsive('up', 'sm');
  const { title, id } = useParams();
  const getFieldValue = getContentValueFromProps(block);
  const { t } = useTranslate();
  const { dispatch, state } = useAppContext();
  const isMobile = useResponsive('down', 'sm');
  const { cart } = state;
  const enablePrice = useMemo(() => getFieldValue('settings.enablePrice'), [block]);
  const enableShare = useMemo(() => getFieldValue('settings.enableShare'), [block]);
  const enableQuantity = useMemo(() => getFieldValue('settings.enableQuantity'), [block]);
  const enableBalance = useMemo(() => getFieldValue('settings.enableBalance'), [block]);
  const enableButtons = useMemo(() => getFieldValue('settings.enableButtons'), [block]);
  const enableButtonAdd = useMemo(() => getFieldValue('settings.enableButtonAdd'), [block]);
  const topPriceColor = useMemo(() => getFieldValue('settings.topPriceColor'), [block]);
  const bottomPriceColor = useMemo(() => getFieldValue('settings.bottomPriceColor'), [block]);
  const productSettingsString: any = localStorage.getItem('product');
  const settingsProductString: any = localStorage.getItem('settings');
  const productSettings: any = JSON.parse(productSettingsString);
  const settingsProduct: any = JSON.parse(settingsProductString);
  const branches = useBoolean();
  const details = useBoolean();
  const actionButtons = useMemo(
    () => (getFieldValue('actionButtons') as ButtonField[]) || [],
    [getFieldValue]
  );

  useEffect(() => {
    const currentPageUrl = window.location.href;
    const queryString = currentPageUrl?.split('?')[1];
    if (queryString?.length) setAttributes(true);
    const el = queryString?.split(',');
    setElements(el);
  }, []);

  const imageSrc = product?.mainImageURL ? `${BASIC_URL_FOR_IMAGES}${product?.mainImageURL}` : '';
  const cleanDescription = product?.description?.replace(/<\/?[^>]+(>|$)/g, '') || '';
  const change = localStorage.getItem('change');
  const isInCart = checkIfProductInCart(cart, product.id);

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };
  const merchants = product?.merchants || [];

  const {
    discount: discountPrice,
    basePrice,
    price,
    maxPrice,
    minPrice,
  } = extractPrices(product?.prices);

  const available = attributes
    ? elements.includes('av')
    : settingsProduct?.enableAvailable || settingsProduct?.settings?.enableAvailable;

  const balance = attributes
    ? elements.includes('ba')
    : settingsProduct?.enableAllBalance || settingsProduct?.settings?.enableBalance;

  const availableLimit = attributes
    ? elements.includes('avl')
    : settingsProduct?.enableAvailableLimit || settingsProduct?.settings?.enableAvailableLimit;

  const discount = attributes
    ? elements.includes('ds')
    : settingsProduct?.enableDiscount || settingsProduct?.settings?.enableDiscount;

  const coincidence = state?.openPricesProducts?.find(
    (item: openPrice) => item.value === product?.id
  );
  const coincidenceFromBuilder = state?.openPricesProductsFromBuilder?.find(
    (item: openPrice) => item.value === product?.id
  );
  const localDataString: string | null = localStorage.getItem('openPrice');
  const localData: any = localDataString ? JSON.parse(localDataString) : [];
  const value = coincidenceFromBuilder;

  let currentPriceView: number = value?.price ?? 0;

  if (value?.price) {
    const _price = Number(value.price);
    if (maxPrice && _price > maxPrice) currentPriceView = maxPrice;
    else if (minPrice && _price < minPrice) currentPriceView = minPrice;
  }

  const asterisks = useMemo((): IAsterisk | null => {
    if (!product) return null;

    if (Array.isArray(product.asterisks) && product.asterisks.length) {
      return product.asterisks[0];
    }

    if (Array.isArray(product.categories) && product.categories.length) {
      const categoryWithAsterisks = product.categories.find(
        (cat: ICategoryItem) => !!(Array.isArray(cat.asterisks) && cat.asterisks.length)
      );

      if (categoryWithAsterisks && categoryWithAsterisks.asterisks) {
        return categoryWithAsterisks.asterisks[0];
      }
    }

    if (Array.isArray(merchants) && merchants.length) {
      const merchantAsterisks = merchants[0].asterisks;

      if (Array.isArray(merchantAsterisks) && merchantAsterisks.length) {
        return merchantAsterisks[0];
      }

      const merchantCategories = merchants[0].categories;

      if (Array.isArray(merchantCategories) && merchantCategories.length) {
        const merchantCategoryAsterisks = merchantCategories[0].asterisks;

        if (Array.isArray(merchantCategoryAsterisks) && merchantCategoryAsterisks.length) {
          return merchantCategoryAsterisks[0];
        }
      }
    }

    if (Array.isArray(state.networks) && state.networks.length) {
      const networkAsterisks = state.networks[0].asterisks;

      if (Array.isArray(networkAsterisks) && networkAsterisks.length) {
        return networkAsterisks[0];
      }
    }

    return null;
  }, [product, state.networks]);

  const handleOpenPriceChange = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | any,
    max?: number,
    min?: number
  ) => {
    const target = event.target.value;
    let currentCount;
    currentCount = target;
    if (max && Number(target) > max) {
      currentCount = max;
    }
    // if (Number(target) < min) {
    //   currentCount = min;
    // }
    const newPrice: openPrice = {
      value: product?.id,
      title: product?.title,
      price: parseInt(target, 10),
    };
    setCount(currentCount);
    if (localDataString) {
      const updated: openPrice[] = [
        ...localData.filter((priceItem: openPrice) => priceItem.value !== product?.id),
        newPrice,
      ];
      localStorage.setItem('openPrice', JSON.stringify(updated));
    } else {
      localStorage.setItem('openPrice', JSON.stringify([newPrice]));
    }
  };

  useEffect(() => {
    const current = localData?.find((item: openPrice) => item.value === id);
    setCount(current?.price);
  }, []);

  const handleClick = () => {
    const currentPageUrl = window.location.href;
    let params = '?';
    if (title === 'product') {
      if (discount) params += params === '?' ? 'ds' : ',ds';
      if (enableBalance) params += params === '?' ? 'ba' : ',ba';
      if (available) params += params === '?' ? 'av' : ',av';
      if (availableLimit) params += params === '?' ? 'av' : ',avl';
    }
    const urlProduct = `${currentPageUrl}${params}`;
    const urlCard = `${currentPageUrl}`;
    const url = title === 'product' ? urlProduct : urlCard;
    if (navigator.share) {
      navigator
        ?.share({
          url,
        })
        .then(() => console.log(''))
        .catch((error) => console.error('Error', error));
    } else {
      navigator.clipboard
        ?.writeText(url)
        ?.then(() => {
          alert('Copied');
        })
        .catch((error) => {
          console.error('Failed to copy: ', error);
        });
    }
  };

  return (
    <Stack spacing={3} sx={{ pt: isMobile ? 0 : 3 }}>
      <Stack spacing={2} sx={{ direction: 'rtl' }}>
        {product?.brand && <Image src={product?.brand} />}
        <Typography
          sx={{ fontWeight: 800, fontSize: '25px', color: 'primary.main', direction: 'ltr' }}
        >
          <SanitizeHTML html={product?.title || product?.name} />
        </Typography>
        {product?.content && (
          <Typography
            sx={{ fontWeight: 400, fontSize: '216x', color: 'primary.main', direction: 'ltr' }}
          >
            <SanitizeHTML html={product?.content} />
          </Typography>
        )}
        {enablePrice && (
          <Box sx={{ display: change === 'virtualCard' ? 'none' : '' }}>
            {title !== 'merchant' && (
              <>
                {discount ? (
                  <Stack direction="row" justifyContent="flex-end">
                    <Typography
                      sx={{
                        fontWeight: 800,
                        fontSize: bottomPriceFontSize || '20px',
                        color: bottomPriceColor || 'primary.main',
                        direction: 'ltr',
                      }}
                    >
                      {discountPrice ? `₪${discountPrice}` : basePrice && `₪${basePrice}`}
                    </Typography>
                    <Typography
                      sx={{
                        textDecoration: 'line-through',
                        color: topPriceColor || 'secondary.main',
                        fontSize: topPriceFontSize || '16px',
                      }}
                    >
                      {price && `₪${price}`}
                    </Typography>
                  </Stack>
                ) : (
                  <>
                    {currentPriceView || price ? (
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Typography
                          sx={{
                            color: bottomPriceColor || 'primary.main',
                            fontSize: bottomPriceFontSize || '20px',
                          }}
                        >
                          ₪{currentPriceView || (price && currentPriceView) || price}
                        </Typography>
                      </Stack>
                    ) : (
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Typography
                          sx={{
                            color: 'text.secondary',
                            fontSize: 16,
                          }}
                        >
                          ₪
                        </Typography>
                        <TextField
                          name="openPrice"
                          placeholder={`${t('Sum')}`}
                          sx={{ width: 60, '& input': { p: 1 } }}
                          value={count}
                          onChange={(e) => {
                            handleOpenPriceChange(e, maxPrice, minPrice);
                            // setCount(e.target.value);
                          }}
                        />
                      </Stack>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
        )}

        <Typography
          sx={{ fontWeight: 400, fontSize: '18px', color: 'primary.main', direction: 'ltr' }}
        >
          <SanitizeHTML html={product?.description || ''} />
        </Typography>
        {product?.phone && (
          <Stack direction="row" sx={{ color: 'secondary.main' }} justifyContent="flex-end">
            <Iconify icon="fluent:call-28-filled" color="secondary.main" />
            <Typography>{product?.phone}</Typography>
          </Stack>
        )}
        {product?.link && (
          <Stack direction="row" sx={{ color: 'primary.main' }}>
            <Iconify icon="fluent:globe-32-filled" color="primary.main" width={25} sx={{ ml: 2 }} />
            <Typography>{product?.link}</Typography>
          </Stack>
        )}
        {title === 'merchant' ? (
          <>
            <Stack direction="row-reverse" alignItems="baseline" width={1} gap={1}>
              {!!product?.networkId && (
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    mt: 3,
                    borderRadius: '20px',
                    color: 'primary.main',
                    px: { xs: 1, md: 1 },
                  }}
                  onClick={branches.onTrue}
                >
                  {t('Branches')}
                </Button>
              )}
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  mt: 3,
                  borderRadius: '20px',
                  color: 'primary.main',
                  px: { xs: 1, md: 1 },
                }}
                onClick={details.onTrue}
              >
                {t('Details')}
              </Button>
            </Stack>
          </>
        ) : (
          <>
            {title !== 'card' && (
              <>
                {balance && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    width="100%"
                    gap={2}
                  >
                    <Stack spacing={1}>
                      <Typography
                        variant="caption"
                        component="div"
                        sx={{ textAlign: 'right', fontSize: '18px' }}
                      >
                        199
                      </Typography>
                    </Stack>
                    <Typography sx={{ fontSize: '18px', color: '#27097A' }}>:נשאר</Typography>
                  </Stack>
                )}
                {availableLimit && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    width="100%"
                    gap={2}
                  >
                    <Stack spacing={1}>
                      <Typography
                        variant="caption"
                        component="div"
                        sx={{ textAlign: 'right', fontSize: '18px' }}
                      >
                        {limit}
                      </Typography>
                    </Stack>
                    <Typography sx={{ fontSize: '18px', color: '#27097A' }}>
                      :ניתן לקנייה
                    </Typography>
                  </Stack>
                )}
              </>
            )}
          </>
        )}
        {title !== 'merchant' && (
          <Stack
            direction="row"
            alignItems="baseline"
            gap={2}
            justifyContent="flex-end"
            // sx={{ display: change === 'virtualCard' ? 'none' : '' }}
          >
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              sx={{ height: '60px' }}
              flexWrap="wrap"
            >
              {enableButtons && (
                <>
                  {actionButtons.map((actionButton: ButtonField, idx: number) => (
                    <Button
                      component={actionButton?.link.includes('modal') ? Button : NextLink}
                      variant={actionButton?.variant || 'contained'}
                      color={actionButton?.color || 'primary'}
                      size={actionButton?.size || 'small'}
                      key={`menuButton_${idx}`}
                      sx={{ borderRadius: 10, fontSize: '10px' }}
                      href={
                        (!actionButton?.link.includes('modal') &&
                          !actionButton.isDialogToggler &&
                          actionButton?.link) ||
                        ''
                      }
                      onClick={() => {
                        if (actionButton.isDialogToggler && actionButton.modalId) {
                          setActiveDialog(actionButton.modalId);
                        }
                      }}
                    >
                      {actionButton.label || `[${t('no label')}]`}
                    </Button>
                  ))}
                </>
              )}

              {enableButtonAdd && (
                <>
                  {isInCart ? (
                    <Button
                      variant="contained"
                      color="success"
                      sx={{
                        height: '40px',
                        borderRadius: '25px',
                        fontWeight: 400,
                      }}
                      startIcon={<Iconify icon="ep:success-filled" />}
                    >
                      &nbsp; בעגלה&nbsp;
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size={actionButtons[0]?.size || 'medium'}
                      // onClick={() => openModal(true)}
                      color="secondary"
                      sx={{
                        borderRadius: 10,
                        fontSize: '10px',
                        // bgcolor: '#E2205C',
                        // color: 'primary.contrastText',
                        // '&:hover': {
                        //   bgcolor: '#E2205C',
                        //   color: 'primary.contrastText',
                        // },
                      }}
                      onClick={() => {
                        if (!user) {
                          dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: 'login' });
                        } else {
                          const priceToCart = currentPriceView || +count;
                          addToBasket(
                            state.smbAccount.id,
                            product?.id,
                            discountPrice || basePrice || priceToCart,
                            imageLink,
                            dispatch,
                            cart
                          );
                        }
                      }}
                    >
                      {`${t('Add to Cart')}`}
                    </Button>
                  )}
                </>
              )}
            </Stack>
            {title !== 'card' && (
              <>
                {available && (
                  <>
                    <TextField
                      select
                      SelectProps={{ native: true }}
                      sx={{
                        '& .MuiNativeSelect-select': {
                          py: 1,
                          px: 2,
                        },
                      }}
                    >
                      {OPTIONS.map((option, ind) => (
                        <option key={ind} value={option}>
                          {option}
                        </option>
                      ))}
                    </TextField>
                    <Typography
                      sx={{
                        color: 'primary.main',
                        fontSize: '18px',
                        fontWeight: 600,
                      }}
                    >
                      לרכישה
                    </Typography>
                  </>
                )}
              </>
            )}
          </Stack>
        )}
        {enableShare && (
          <Stack width={1} alignItems="end">
            <Iconify
              icon="solar:share-bold"
              width={16}
              sx={{ mr: 0.5, cursor: 'pointer' }}
              onClick={handleClick}
            />
          </Stack>
        )}
        {asterisks && (
          <Typography sx={{ color: 'red', textAlign: 'left' }}>
            {/* @ts-ignore */}* {asterisks.description}
          </Typography>
        )}
      </Stack>
      <Modal
        open={modal}
        onClose={() => setModal(false)}
        sx={{
          '& .MuiBackdrop-root': {
            height: '100vh',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 700,
            bgcolor: 'background.paper',
            borderRadius: '20px',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack direction="row" justifyContent="flex-end">
            <Iconify
              icon="iconamoon:close-thin"
              onClick={() => setModal(false)}
              sx={{ cursor: 'pointer' }}
            />
          </Stack>
          {merchants.map((merchant: any, index: number) => (
            <Stack key={index} alignItems="center">
              <Typography id="modal-modal-title" variant="h6" component="h2" color="secondary.main">
                {merchant.title} סניפים
              </Typography>
              <Grid>
                <Grid sx={{ mt: 2 }} key={index}>
                  <Image src={`${BASIC_URL_FOR_IMAGES}${merchant.mainImageURL}`} />
                </Grid>
              </Grid>
            </Stack>
          ))}
        </Box>
      </Modal>
      <BasicModal open={details.value} onClose={() => details.onFalse()}>
        <Stack alignItems="flex-start">
          <Typography id="modal-modal-title" variant="h6" component="h2" color="secondary.main">
            {product?.title || ''}
          </Typography>
          <Stack
            direction="row-reverse"
            justifyContent="space-between"
            width={1}
            alignItems="center"
          >
            {product?.url && (
              <Stack direction="row-reverse" gap={2} sx={{ color: 'secondary.main' }}>
                <a href={product?.url}>
                  <Typography sx={{ textDecoration: 'underline' }}>
                    לאתר בית העסק לחץ כאן
                  </Typography>
                  <Iconify icon="fa-solid:globe" />
                </a>
              </Stack>
            )}
            <Image src={imageSrc} />
          </Stack>
          <Typography>
            <SanitizeHTML html={product?.description} />
          </Typography>
        </Stack>
      </BasicModal>
      <BasicModal open={branches.value} onClose={() => branches.onFalse()}>
        <Stack alignItems="flex-start">
          <Typography id="modal-modal-title" variant="h6" component="h2" color="secondary.main">
            סניפים
          </Typography>
          <TextField
            sx={{ py: 2 }}
            fullWidth
            placeholder="חיפוש..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
          <Scrollbar sx={{ height: smUp ? '400px' : '150px', width: '100%' }}>
            <Stack gap={2} height="450px">
              {/* TO-DO: add real branches */}
              {/* {branchItems.map((branch, id) => (
                  <Stack key={id}>
                    <Typography>{branch.title}</Typography>
                    <Typography sx={{ color: 'grey.600' }}>{branch.subTitle}</Typography>
                    <Typography sx={{ color: 'grey.600' }}>{branch.phone}</Typography>
                  </Stack>
                ))} */}
              <Typography>{product?.network?.name}</Typography>
              <Typography sx={{ color: 'grey.600' }}>{product?.network?.subTitle}</Typography>
              <Typography sx={{ color: 'grey.600' }}>{product?.network?.phone}</Typography>
            </Stack>
          </Scrollbar>
        </Stack>
      </BasicModal>
    </Stack>
  );
};
