import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useTranslate } from 'src/locales';

import { MotionViewport } from 'src/components/animate';

import { BlockType } from 'src/types/page-generator';

// import CardList from './components/card-list';
// import Rate from './components/rate-component';
// import MoreItems from './components/more-items';
// import Categories from './components/categories';
// import FlashDeals from './components/flash-deals';
// import BannerSlide from './components/test-banner';
// import ServiceList from './components/service-list';
// import CategoryList from './components/category-list';
// import ProductCarousel from './components/product-carousel';
// import BigDiscountList from './components/big-discount-list';
// import NewArrivalsList from './components/new-arrivals-list';
// import SalesBanner from './components/fashion-3/sales-banner';
// import Testimonials from './components/grocery-2/testimonials';
// import ServiceItems from './components/fashion-1/service-items';
// import OfferBanners from './components/fashion-2/offer-banners';
// import BlogListArea from './components/fashion-2/blog-list-area';
// import ProductWithFilter from './components/products-with-filter';
// import TopCategories from './components/fashion-3/top-categories';
// import TrendingItems from './components/fashion-1/trending-items';
// import CategoryBanner from './components/fashion-3/category-banner';
// import MultiRowBanner from './components/fashion-1/multi-row-banner';
// import FullWidthBanner from './components/fashion-2/full-width-banner';
// import HotDealsCarousel from './components/fashion-1/hot-deals-carousel';
// import ProductListColumn from './components/fashion-2/product-list-column';
// import BigBannersCarousel from './components/fashion-3/big-banners-carousel';
// import MultilineCategories from './components/grocery-2/multiline-categories';
// import BestSellingProducts from './components/fashion-2/best-selling-products';
// import SubscribeNewsLetter from './components/fashion-1/subscribe-news-letter';
// import MultiBannersCarousel from './components/fashion-1/multi-banners-carousel';
// import BestSellingCategories from './components/fashion-2/best-selling-categories';
// import InstagramPhotoGallery from './components/fashion-3/instagram-photo-gallery';
// import BrandListCarouselArea from './components/fashion-2/brand-list-carousel-area';
// import SearchBarWithBackground from './components/grocery-1/search-bar-with-background';
// import ProductsCarouselHoverEffect from './components/grocery-3/products-carousel-hover-effect';
// import PopularProductsWithMultiLevelMenu from './components/grocery-1/popular-products-with-multi-level-menu';

import { BlockTypes } from 'src/types/generator';

import Popup from './components/popups';
import Filter from './components/filter';
import Footer from './components/footer';
import Blog from './components/xtra/blog';
import Filterv2 from '../products/filterv2';
import Blogs from './components/xtra/blogs';
import NewHeader from '../../layouts/main/header';
import CouponsV2 from './components/xtra/coupons';
import Search from './components/boom-buy/search';
import VouchersV1 from './components/xtra/vouchers';
import SearchPageSection from './components/search';
import VouchersV2 from './components/xtra/vouchers2';
import VouchersV3 from './components/xtra/vouchers3';
import Banner1 from './components/boom-buy/banner-1';
// eslint-disable-next-line import/no-cycle
import HGWalletView from '../wallet/view/wallet-view';
import { AccountView } from './components/account/view';
import Products from './components/happy-gift/products';
import Providers from './components/boom-buy/Providers';
import CustomAbout from './components/happy-gift/about';
import CheckoutView from '../checkout/view/checkout-view';
import CatalogBoomBuy from './components/boom-buy/catalog';
import HeadBanner from './components/boom-buy/head-banner';
import TopBanners from './components/happy-gift/TopBanners';
import { Merchant } from './components/happy-gift/merchant';
import { Networks } from './components/happy-gift/networks';
import Newsletter from './components/happy-gift/newsletter';
import ShopBanner from './components/happy-gift/shop-banner';
import StepBanner from './components/happy-gift/step-banner';
import { Merchants } from './components/happy-gift/merchents';
import VideoBanner from './components/happy-gift/video-banner';
import CardsBanner from './components/happy-gift/cards-banner';
import ClubCardsView from './components/happy-gift/club-cards';
import MainXtraMainBanner from './components/xtra/main-banner';
import ShopBanner2 from './components/happy-gift/shop-banner-2';
import ShopBanner3 from './components/happy-gift/shop-banner-3';
import ShopBanner4 from './components/happy-gift/shop-banner-4';
import ShopBanner5 from './components/happy-gift/shop-banner-5';
import ShopBanner6 from './components/happy-gift/shop-banner-6';
import BlogsTextAfter from './components/xtra/blogs-text-after';
import { GreyBanner } from './components/happy-gift/grey-banner';
import { Apartments } from './components/city-people/apartments';
import MainXtraMainBanner2 from './components/xtra/main-banner-2';
import MainXtraMainBanner3 from './components/xtra/main-banner-3';
import BrandsCarousel2 from './components/xtra/brands-carousel-2';
import { NetworkInfo } from './components/happy-gift/network-info';
import HottestOffers from './components/happy-gift/hottest-offers';
import ProductPagination from './components/happy-gift/pagination';
import VoucherBanner from './components/happy-gift/voucher-banner';
import LogoComponent from './components/happy-gift/logo-component';
import { BlackBanner } from './components/happy-gift/black-banner';
import PastimeBanner from './components/happy-gift/pastime-banner';
import OneBigButton from './components/city-people/one-big-button';
import BannerProducts from './components/boom-buy/banner-products';
import { ReverseBlock } from './components/happy-gift/reverseBlock';
import { ServiceList } from './components/city-people/service-list';
import MainMainBanner from './components/boom-buy/main-main-banner';
import BrandsCarousel from './components/happy-gift/brands-carousel';
import FAQCityPeople from './components/city-people/faq-city-people';
import AllShopBanners from './components/happy-gift/all-shop-banners';
import SixHoverBlocks from './components/happy-gift/sic-hover-blocks';
import BigBannerElements from './components/xtra/big-banner-elements';
import ProductDetailsView from '../product/view/product-details-view';
import FooterCityPeople from './components/footer/footer-city-people';
import CouponDetailsView from './components/coupon/coupon-details-view';
import CategoriesSwiper from './components/happy-gift/CategoriesSwiper';
import { SingleService } from './components/city-people/single-service';
import CategoriesBoomBuy from './components/boom-buy/categoriesBoomBuy';
import AllShopBanners2 from './components/happy-gift/all-shop-banners-2';
import TitleAndZipLine from './components/happy-gift/title-and-zip-line';
import ProductDetailsView2 from '../product/view/product-details-view-2';
import OrderCityPeople from './components/city-people/order-city-people';
import PhotoDescription from './components/happy-gift/photo-description';
import ThreeColumnsInfo from './components/happy-gift/three-columns-info';
import SearchByCategory from './components/happy-gift/search-by-category';
import TitleAndSubtitle from './components/happy-gift/title-and-subtitle';
import CategoriesSlider from './components/city-people/categories-slider';
import PopularCategories from './components/happy-gift/popular-categories';
import HeaderCityPeople from './components/city-people/header-city-people';
import { BannersCarousel } from './components/happy-gift/banners-carousel';
import MainBannerHolders from './components/happy-gift/main-banner-holders';
import CustomTestimonials from './components/happy-gift/custom-testimonials';
import AllHappyHrBanners from './components/happy-gift/all-happy-hr-banners';
import AttractionsCoupons from './components/happy-gift/attractions-coupons';
import BalanceCityPeople from './components/city-people/balance-city-people';
import HistoryCityPeople from './components/city-people/history-city-people';
import { SixTableElements } from './components/happy-gift/six-table-elements';
import FourColumnElements from './components/happy-gift/four-column-elements';
import CompanyDescription from './components/city-people/company-description';
import CombinedStepBanner from './components/happy-gift/combined-step-banner';
import { HeadActiveBanner } from './components/happy-gift/head-active-banner';
import BigBannerHappyGift from './components/happy-gift/big-banner-happy-gift';
import { ProductDescription } from './components/happy-gift/product-description';
import TotalInfoCityPeople from './components/city-people/total-info-city-people';
import ButtonsBannerHappyHr from './components/happy-gift/buttons-banner-happy-hr';
import CardsBannerWithButton from './components/happy-gift/cards-banner-with-button';
import StandardVerticalBanner from './components/happy-gift/standard-vertical-banner';
import AllHappyHoldersBanners from './components/happy-gift/all-happy-holders-banners';
import { CompanyListCityPeople } from './components/city-people/company-list-city-people';
import HistorySearchCityPeople from './components/city-people/history-search-city-people';
import BannerWithBackgroundPic from './components/city-people/banner-with-background-pic';
import BrandListCarouselArea from './components/bazaar/fashion-2/brand-list-carousel-area';
import ProductBoomBuyDetailsView from './components/boom-buy/product-boom-buy-details-view';
import HappyGiftActivitiesBanner from './components/happy-gift/happy-gift-activities-banner';
import ThreeColumnsInfoWithIcons from './components/happy-gift/three-column-info-with-icons';
import SupplierHistoryCityPeople from './components/city-people/supplier-history-city-people';
import StandartHorizontalBannerV1 from './components/happy-gift/standart-horizontal-banner-v1';
import { SpecialCategoriesHappyGift } from './components/happy-gift/special-categories-happy-gift';
import BannerWithBalanceCityPeople from './components/city-people/banner-with-balance-city-people';
import ThreeColumnsCardInfoWithIcons from './components/happy-gift/three-columns-card-info-with-icons';
import { SpecialCategoriesHappyHolders } from './components/happy-gift/special-categories-happy-holders';
import CarouselStandardHorizontalBanner from './components/happy-gift/carousel-standard-horizontal-banner';
import { IndentationBlock } from './components/happy-gift/indentation-block';
import { ExchangeOption } from './components/happy-gift/exchange-option';
import { PaymentMethods } from './components/happy-gift/payment-methods';
import { Iframe } from './components/happy-gift/iframe';
import { Banner1Carousel } from './components/boom-buy/banner1-carousel';
import Provider from './components/boom-buy/provider';
import AllProviders from './components/boom-buy/all-providers';
import ContactCityPeople from './components/city-people/contact-city-people';
import { ButtonInfoBanner } from './components/happy-gift/button-info-banner';
import { DividerBlock } from './components/happy-gift/deviderBlock';
import BusinessAccountView from './components/business-account/view/business-account-view';

// ----------------------------------------------------------------------

interface GeneratePageSectionProps {
  block: BlockType;
}

// ----------------------------------------------------------------------

export default function GeneratePageBlock({ block }: GeneratePageSectionProps) {
  if (block.type === 'reverseBlock') block.settings.fullSize = true;
  const { fullSize } = block?.settings || { fullSize: false };
  const { customPadding } = block?.settings || { customPadding: false };
  const { customPaddingValue } = block?.settings || { customPaddingValue: 0 };
  const isCityPeopleProj = process.env.NEXT_PUBLIC_PROJECT_NAME === 'citypeople';
  const isKsharim = process.env.NEXT_PUBLIC_PROJECT_NAME === 'ksharim';
  const isIndentationBlock = block.type === 'indentationBlock' || 'dividerBlock' ? 0 : 5;
  const isPadding = customPadding ? customPaddingValue : isIndentationBlock;
  const padding = isKsharim ? 2 : isPadding;
  return fullSize ? (
    <PageSectionContent block={block} />
  ) : (
    <Container
      component={MotionViewport}
      sx={{
        py: isCityPeopleProj
          ? { xs: 0.5 }
          : { xs: block?.settings?.disableOnMobile ? 0 : 1, md: padding },
      }}
    >
      {/* <PageWrapper> */}
      <PageSectionContent block={block} />
      {/* </PageWrapper> */}
    </Container>
  );
}

// function PageWrapper({ children }: { children: React.ReactNode }) {
//   return <ThemeProvider>{children}</ThemeProvider>;
// }

function PageSectionContent({ block }: GeneratePageSectionProps) {
  const { t } = useTranslate();
  switch (block.type) {
    // header и footer генерятся в layout
    case BlockTypes.header:
      return <NewHeader block={block} />;
    case BlockTypes.videobanner:
      return <VideoBanner />;
    case BlockTypes.cardholderbanner:
      return <SixTableElements />;
    case BlockTypes.brandlistcarouselarea:
      return <BrandListCarouselArea brands={[]} divider={false} direction="" />;
    case BlockTypes.titleandsubtitle:
      return <TitleAndSubtitle block={block} />;
    case BlockTypes.threecolumnscardinfowithicons:
      return <ThreeColumnsCardInfoWithIcons block={block} />;
    case BlockTypes.customtestimonials:
      return <CustomTestimonials block={block} />;
    case BlockTypes.filterv2:
      return <Filterv2 filters={[]} />;
    case BlockTypes.attractionscoupons:
      return <AttractionsCoupons block={block} />;
    case BlockTypes.pastimebanner:
      return <PastimeBanner block={block} />;
    case BlockTypes.banner1:
      return <Banner1 block={block} />;
    case BlockTypes.banner1Carousel:
      return <Banner1Carousel block={block} />;
    case BlockTypes.bannerproducts:
      return <BannerProducts block={block} />;
    case BlockTypes.search:
      return <Search block={block} />;
    case BlockTypes.catalogboombuy:
      return <CatalogBoomBuy block={block} />;
    case BlockTypes.categoriesboomBuy:
      return <CategoriesBoomBuy block={block} />;
    case BlockTypes.mainmainbanner:
      return <MainMainBanner block={block} />;
    case BlockTypes.headbanner:
      return <HeadBanner block={block} />;
    case BlockTypes.productboombuydatailsview:
      return <ProductBoomBuyDetailsView block={block} />;
    case BlockTypes.provider:
      return <Provider block={block} />;
    case BlockTypes.providers:
      return <Providers block={block} />;
    case BlockTypes.allProviders:
      return <AllProviders block={block} />;
    case BlockTypes.carouselStandardHorizontalBanner:
      return <CarouselStandardHorizontalBanner block={block} />;
    // case BlockTypes.banner:
    //   return <BannerSlide />;
    // case BlockTypes.textblock:
    //   return <Typography>{`${t('Text')}`}</Typography>;
    // case BlockTypes.filter:
    //   return <Typography>{`${t('Filter')}`}</Typography>;
    // case BlockTypes.carousel:
    //   return <FlashDeals flashDeals={[]} title="Test deals" />;
    // case BlockTypes.bigdiscountlist:
    //   return <BigDiscountList bigDiscountList={[]} headTitle="Test Discount" />;
    // case BlockTypes.cardlist:
    //   return <CardList carList={[]} carBrands={[]} />;
    // case BlockTypes.categories:
    //   return <Categories categories={[]} />;
    // case BlockTypes.categorylist:
    //   return <CategoryList categoryList={[]} />;
    // case BlockTypes.productwithfilter:
    //   return <ProductWithFilter shops={[]} title="Test title" brands={[]} productList={[]} />;
    // case BlockTypes.moreitems:
    //   return <MoreItems moreItems={[]} />;
    // case BlockTypes.newarrivalslist:
    //   return <NewArrivalsList newArrivalsList={[]} />;
    // case BlockTypes.productcarousel:
    //   return <ProductCarousel carouselData={[]} />;
    // case BlockTypes.ratecomponent:
    //   return <Rate topRatedBrands={[]} topRatedList={[]} />;
    // case BlockTypes.servicelist:
    //   return <ServiceList serviceList={[]} />;
    // case BlockTypes.hotdealscarousel:
    //   return <HotDealsCarousel hotDealList={[]} />;
    // case BlockTypes.multibannerscarousel:
    //   return <MultiBannersCarousel dealOfTheWeek={[]} />;
    // case BlockTypes.multirowbanner:
    //   return <MultiRowBanner />;
    // case BlockTypes.serviceitems:
    //   return <ServiceItems serviceList={[]} />;
    // case BlockTypes.trendingitems:
    //   return <TrendingItems products={[]} />;
    // case BlockTypes.subscribenewsletter:
    //   return <SubscribeNewsLetter />;
    // case BlockTypes.bigbannerscarousel:
    //   return <BigBannersCarousel carouselData={[]} />;
    // case BlockTypes.categorybanner:
    //   return <CategoryBanner />;
    // case BlockTypes.topcategories:
    //   return <TopCategories />;
    // case BlockTypes.salesbanner:
    //   return <SalesBanner />;
    // case BlockTypes.instagramphotogallery:
    //   return <InstagramPhotoGallery blogs={[]} />;
    // case BlockTypes.searchbarwithbackground:
    //   return <SearchBarWithBackground />;
    // case BlockTypes.popularproductswithmultilevelmenu:
    //   return (
    //     <PopularProductsWithMultiLevelMenu
    //       products={[]}
    //       popularProducts={[]}
    //       trendingProducts={[]}
    //       grocery1NavList={[]}
    //     />
    //   );
    // case BlockTypes.multilinecategories:
    //   return <MultilineCategories categories={[]} />;
    // case BlockTypes.testimonials:
    //   return <Testimonials testimonials={[]} />;
    // case BlockTypes.productscarouselhovereffect:
    //   return <ProductsCarouselHoverEffect products={[]} />;
    // case BlockTypes.bloglistarea:
    //   return <BlogListArea blogs={[]} />;
    // case BlockTypes.fullwidthbanner:
    //   return <FullWidthBanner />;
    // case BlockTypes.offerbanner:
    //   return <OfferBanners />;
    // case BlockTypes.productlistcolumn:
    //   return (
    //     <ProductListColumn
    //       saleProducts={[]}
    //       latestProducts={[]}
    //       popularProducts={[]}
    //       bestWeekProducts={[]}
    //     />
    //   );
    // case BlockTypes.bestsellingcategories:
    //   return <BestSellingCategories categories={[]} />;
    // case BlockTypes.bestsellingproducts:
    //   return <BestSellingProducts products={[]} />;
    case BlockTypes.wallet:
      return <HGWalletView block={block} />;
    case BlockTypes.cart:
      return <CheckoutView block={block} />;
    case BlockTypes.account:
      return <AccountView block={block} />;
    case BlockTypes.businessAccount:
      return <BusinessAccountView block={block} />;
    case BlockTypes.StandartHorizontalBannerV1:
      return <StandartHorizontalBannerV1 block={block} />;
    case BlockTypes.whitecardbanner:
    case BlockTypes.threecolumnsinfo:
      return <ThreeColumnsInfo block={block} />;
    // case BlockTypes.b2cbanner:
    // case BlockTypes.titleandsubtitle:
    //   return <TitleAndSubtitle block={block} />;
    // case BlockTypes.customtestimonials:
    //   return <CustomTestimonials block={block} />;
    case BlockTypes.searchbycategory:
      return <SearchByCategory block={block} />;
    case BlockTypes.threecolumncardinfowithicons:
      return <ThreeColumnsCardInfoWithIcons block={block} />;
    case BlockTypes.threecolumnsinfowithIcons:
      return <ThreeColumnsInfoWithIcons block={block} />;
    case BlockTypes.standardverticalbanner:
      return <StandardVerticalBanner block={block} />;
    case BlockTypes.forcolumnelements:
      return <FourColumnElements block={block} />;
    case BlockTypes.titleandzipline:
      return <TitleAndZipLine block={block} />;
    case BlockTypes.brandscarousel:
      return <BrandsCarousel block={block} />;
    case BlockTypes.shopbanner:
      return <ShopBanner block={block} />;
    case BlockTypes.shopbanner2:
      return <ShopBanner2 block={block} />;
    case BlockTypes.shopbanner3:
      return <ShopBanner3 block={block} />;
    case BlockTypes.shopbanner4:
      return <ShopBanner4 block={block} />;
    case BlockTypes.shopbanner5:
      return <ShopBanner5 block={block} />;
    case BlockTypes.shopbanner6:
      return <ShopBanner6 block={block} />;
    case BlockTypes.buttonsbannerhappyhr:
      return <ButtonsBannerHappyHr block={block} />;
    case BlockTypes.allhappyhrbanner:
      return <AllHappyHrBanners block={block} />;
    case BlockTypes.hottestoffers:
      return <HottestOffers block={block} />;
    case BlockTypes.popularcategories:
      return <PopularCategories block={block} />;
    case BlockTypes.products:
      return <Products block={block} />;
    case BlockTypes.photoDescription:
      return <PhotoDescription block={block} />;
    case BlockTypes.reverseBlock:
      return <ReverseBlock block={block} />;
    case BlockTypes.productDescription:
      return <ProductDescription block={block} />;
    case BlockTypes.about:
      return <CustomAbout block={block} />;
    case BlockTypes.productpagination:
      return <ProductPagination block={block} />;

    case BlockTypes.coupondetailsview:
      return <CouponDetailsView block={block} />;
    case BlockTypes.voucherbanner:
      return <VoucherBanner block={block} />;
    case BlockTypes.bigbannerhappygift:
      return <BigBannerHappyGift block={block} />;
    case BlockTypes.allshopbanners:
      return <AllShopBanners block={block} />;
    case BlockTypes.allshopbanners2:
      return <AllShopBanners2 block={block} />;
    case BlockTypes.specialcategories:
      return <SpecialCategoriesHappyGift block={block} />;
    case BlockTypes.clubcards:
      return <ClubCardsView block={block} />;
    case BlockTypes.headActiveBanner:
      return <HeadActiveBanner block={block} />;
    case BlockTypes.bannersCarousel:
      return <BannersCarousel block={block} />;
    case BlockTypes.footer:
      return <Footer block={block} />;
    case BlockTypes.banner:
      return <TopBanners block={block} />;
    case BlockTypes.textblock:
      return <TextareaSiteBuilder />;
    case BlockTypes.mainxtramainbanner:
      return <MainXtraMainBanner block={block} />;
    case BlockTypes.mainxtramainbanner2:
      return <MainXtraMainBanner2 block={block} />;
    case BlockTypes.mainxtramainbanner3:
      return <MainXtraMainBanner3 block={block} />;
    case BlockTypes.vouchersv1:
      return <VouchersV1 block={block} />;
    case BlockTypes.vouchersv2:
      return <VouchersV2 block={block} />;
    case BlockTypes.vouchersv3:
      return <VouchersV3 block={block} />;
    case BlockTypes.brandscarousel2:
      return <BrandsCarousel2 block={block} />;
    case BlockTypes.couponsv2:
      return <CouponsV2 block={block} />;
    case BlockTypes.bigbannerelements:
      return <BigBannerElements block={block} />;
    // case BlockTypes.cards:
    //   return <CardsWrapper />;
    case BlockTypes.filter:
      return <Filter block={block} />;
    case BlockTypes.sixhoverblocks:
      return <SixHoverBlocks block={block} />;
    case BlockTypes.happygift_activitybanner:
      return <HappyGiftActivitiesBanner block={block} />;
    case BlockTypes.logocomponent:
      return <LogoComponent block={block} />;
    case BlockTypes.AllHappyHoldersBanners:
      return <AllHappyHoldersBanners block={block} />;
    case BlockTypes.SpecialCategoriesHappyHolders:
      return <SpecialCategoriesHappyHolders block={block} />;
    case BlockTypes.greybanner:
      return <GreyBanner />;
    case BlockTypes.blackbanner:
      return <BlackBanner />;
    case BlockTypes.MainBannerHolders:
      return <MainBannerHolders block={block} />;
    case BlockTypes.stepbanner:
      return <StepBanner block={block} />;
    case BlockTypes.combinedstepbanner:
      return <CombinedStepBanner block={block} />;
    case BlockTypes.cardsbannerwithbutton:
      return <CardsBannerWithButton block={block} />;
    case BlockTypes.cardsbanner:
      return <CardsBanner />;
    case BlockTypes.categories:
      return <CategoriesSwiper block={block} />;
    case BlockTypes.product:
      return <ProductDetailsView block={block} id="" />;
    case BlockTypes.productdetailsview:
      return <ProductDetailsView2 block={block} />;
    case BlockTypes.merchants:
      return <Merchants block={block} />;
    case BlockTypes.buttonInfoBanner:
      return <ButtonInfoBanner block={block} />;
    case BlockTypes.dividerBlock:
      return <DividerBlock block={block} />;
    case BlockTypes.networks:
      return <Networks block={block} />;
    case BlockTypes.networkInfo:
      return <NetworkInfo block={block} />;
    case BlockTypes.merchant:
      return <Merchant block={block} />;
    case BlockTypes.popup:
      return <Popup block={block} />;
    case BlockTypes.bannerwithbalancecitypeople:
      return <BannerWithBalanceCityPeople block={block} />;
    case BlockTypes.bannerwithbackgroundpic:
      return <BannerWithBackgroundPic block={block} />;
    case BlockTypes.onebigbutton:
      return <OneBigButton block={block} />;
    case BlockTypes.totalinfocitypeople:
      return <TotalInfoCityPeople block={block} />;
    case BlockTypes.apartments:
      return <Apartments block={block} />;
    case BlockTypes.servicelistcitypeople:
      return <ServiceList block={block} />;
    case BlockTypes.singleservicecitypeople:
      return <SingleService block={block} />;
    case BlockTypes.balancecitypeople:
      return <BalanceCityPeople block={block} />;
    case BlockTypes.categoriesslider:
      return <CategoriesSlider block={block} />;
    case BlockTypes.companylistcitypeople:
      return <CompanyListCityPeople block={block} />;
    case BlockTypes.companydescription:
      return <CompanyDescription block={block} />;
    case BlockTypes.ordercitypeople:
      return <OrderCityPeople block={block} />;
    case BlockTypes.historycitypeople:
      return <HistoryCityPeople block={block} />;
    case BlockTypes.supplierhistorycitypeople:
      return <SupplierHistoryCityPeople block={block} />;
    case BlockTypes.historysearchcitypeople:
      return <HistorySearchCityPeople block={block} />;
    case BlockTypes.headercitypeople:
      return <HeaderCityPeople block={block} />;
    case BlockTypes.footercitypeople:
      return <FooterCityPeople block={block} />;
    case BlockTypes.faq:
      return <FAQCityPeople block={block} />;
    case BlockTypes.contactcitypeople:
      return <ContactCityPeople block={block} />;
    case BlockTypes.searchSection:
      return <SearchPageSection block={block} />;
    case BlockTypes.blogs:
      return <Blogs block={block} />;
    case BlockTypes.newsletter:
      return <Newsletter block={block} />;
    case BlockTypes.blogsTextAfter:
      return <BlogsTextAfter block={block} />;
    case BlockTypes.blog:
      return <Blog block={block} />;
    case BlockTypes.indentationBlock:
      return <IndentationBlock block={block} />;
    case BlockTypes.exchangeOption:
      return <ExchangeOption block={block} />;
    case BlockTypes.paymentMethods:
      return <PaymentMethods block={block} />;
    case BlockTypes.iframe:
      return <Iframe block={block} />;
    default:
      return <Typography>{`${t('There is no block registered with such type')}`}</Typography>;
  }
}

function TextareaSiteBuilder() {
  return <TextField fullWidth id="outlined-basic" label="טקסט לדוגמה" variant="outlined" />;
}

// function CardsWrapper() {
//   const coupons = useSelector((state) => state.product.products);
//   return <Cards coupons={coupons} />;
// }
