import { API } from '../helpers/api';

type UploadFile = File & { preview: any };

export const uploadFile = async (file: UploadFile) => {
  try {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      const { data } = await API({
        url: '/upload',
        method: 'POST',
        data: formData,
      });

      const { fileName } = data;

      return `${process.env.NEXT_PUBLIC_HOST_API}/${fileName}`;
    }
  } catch (err) {
    console.error(err);
    return '';
  }

  return '';
};
