import { FC, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Image from '../../../../components/image';
import { getContentValueFromProps } from '../../utils';
import { ComponentTypeProps } from '../../../../types/page-generator';
import SmallZipLineIcon from '../../../../assets/icons/happy-gift/smallZipLineIcon';

const FourColumnElements: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);

  const contentFirstTextColor = useMemo(
    () => getFieldValue('settings.contentFirstTextColor'),
    [getFieldValue]
  );
  const contentFirstFontSize = useMemo(
    () => getFieldValue('settings.contentFirstFontSize'),
    [getFieldValue]
  );
  const contentFirstFontWeight = useMemo(
    () => getFieldValue('settings.contentFirstFontWeight'),
    [getFieldValue]
  );
  const contentFirstFontStyle = useMemo(
    () => getFieldValue('settings.contentFirstFontStyle'),
    [getFieldValue]
  );
  const contentFirstTextDecoration = useMemo(
    () => getFieldValue('settings.contentFirstTextDecoration'),
    [getFieldValue]
  );

  const contentSecondTextColor = useMemo(
    () => getFieldValue('settings.contentSecondTextColor'),
    [getFieldValue]
  );
  const contentSecondFontSize = useMemo(
    () => getFieldValue('settings.contentSecondFontSize'),
    [getFieldValue]
  );
  const contentSecondFontWeight = useMemo(
    () => getFieldValue('settings.contentSecondFontWeight'),
    [getFieldValue]
  );
  const contentSecondFontStyle = useMemo(
    () => getFieldValue('settings.contentSecondFontStyle'),
    [getFieldValue]
  );
  const contentSecondTextDecoration = useMemo(
    () => getFieldValue('settings.contentSecondTextDecoration'),
    [getFieldValue]
  );

  const contentThirdTextColor = useMemo(
    () => getFieldValue('settings.contentThirdTextColor'),
    [getFieldValue]
  );
  const contentThirdFontSize = useMemo(
    () => getFieldValue('settings.contentThirdFontSize'),
    [getFieldValue]
  );
  const contentThirdFontWeight = useMemo(
    () => getFieldValue('settings.contentThirdFontWeight'),
    [getFieldValue]
  );
  const contentThirdFontStyle = useMemo(
    () => getFieldValue('settings.contentThirdFontStyle'),
    [getFieldValue]
  );
  const contentThirdTextDecoration = useMemo(
    () => getFieldValue('settings.contentThirdTextDecoration'),
    [getFieldValue]
  );

  const contentFourthTextColor = useMemo(
    () => getFieldValue('settings.contentFourthTextColor'),
    [getFieldValue]
  );
  const contentFourthFontSize = useMemo(
    () => getFieldValue('settings.contentFourthFontSize'),
    [getFieldValue]
  );
  const contentFourthFontWeight = useMemo(
    () => getFieldValue('settings.contentFourthFontWeight'),
    [getFieldValue]
  );
  const contentFourthFontStyle = useMemo(
    () => getFieldValue('settings.contentFourthFontStyle'),
    [getFieldValue]
  );
  const contentFourthTextDecoration = useMemo(
    () => getFieldValue('settings.contentFourthTextDecoration'),
    [getFieldValue]
  );

  const contentFirstSubtitleColor = useMemo(
    () => getFieldValue('settings.contentFirstSubtitleColor'),
    [getFieldValue]
  );
  const contentFirstSubtitleFontSize = useMemo(
    () => getFieldValue('settings.contentFirstSubtitleFontSize'),
    [getFieldValue]
  );
  const contentFirstSubtitleFontWeight = useMemo(
    () => getFieldValue('settings.contentFirstSubtitleFontWeight'),
    [getFieldValue]
  );
  const contentFirstSubtitleFontStyle = useMemo(
    () => getFieldValue('settings.contentFirstSubtitleFontStyle'),
    [getFieldValue]
  );
  const contentFirstSubtitleDecoration = useMemo(
    () => getFieldValue('settings.contentFirstSubtitleDecoration'),
    [getFieldValue]
  );

  const contentSecondSubtitleColor = useMemo(
    () => getFieldValue('settings.contentSecondSubtitleColor'),
    [getFieldValue]
  );
  const contentSecondSubtitleFontSize = useMemo(
    () => getFieldValue('settings.contentSecondSubtitleFontSize'),
    [getFieldValue]
  );
  const contentSecondSubtitleFontWeight = useMemo(
    () => getFieldValue('settings.contentSecondSubtitleFontWeight'),
    [getFieldValue]
  );
  const contentSecondSubtitleFontStyle = useMemo(
    () => getFieldValue('settings.contentSecondSubtitleFontStyle'),
    [getFieldValue]
  );
  const contentSecondSubtitleDecoration = useMemo(
    () => getFieldValue('settings.contentSecondSubtitleDecoration'),
    [getFieldValue]
  );

  const contentThirdSubtitleColor = useMemo(
    () => getFieldValue('settings.contentThirdSubtitleColor'),
    [getFieldValue]
  );
  const contentThirdSubtitleFontSize = useMemo(
    () => getFieldValue('settings.contentThirdSubtitleFontSize'),
    [getFieldValue]
  );
  const contentThirdSubtitleFontWeight = useMemo(
    () => getFieldValue('settings.contentThirdSubtitleFontWeight'),
    [getFieldValue]
  );
  const contentThirdSubtitleFontStyle = useMemo(
    () => getFieldValue('settings.contentThirdSubtitleFontStyle'),
    [getFieldValue]
  );
  const contentThirdSubtitleDecoration = useMemo(
    () => getFieldValue('settings.contentThirdSubtitleDecoration'),
    [getFieldValue]
  );

  const contentFourthSubtitleColor = useMemo(
    () => getFieldValue('settings.contentFourthSubtitleColor'),
    [getFieldValue]
  );
  const contentFourthSubtitleFontSize = useMemo(
    () => getFieldValue('settings.contentFourthSubtitleFontSize'),
    [getFieldValue]
  );
  const contentFourthSubtitleFontWeight = useMemo(
    () => getFieldValue('settings.contentFourthSubtitleFontWeight'),
    [getFieldValue]
  );
  const contentFourthSubtitleFontStyle = useMemo(
    () => getFieldValue('settings.contentFourthSubtitleFontStyle'),
    [getFieldValue]
  );
  const contentFourthSubtitleDecoration = useMemo(
    () => getFieldValue('settings.contentFourthSubtitleDecoration'),
    [getFieldValue]
  );

  const firstColor = useMemo(() => getFieldValue('settings.firstColor'), [getFieldValue]);
  const secondColor = useMemo(() => getFieldValue('settings.secondColor'), [getFieldValue]);
  const thirdColor = useMemo(() => getFieldValue('settings.thirdColor'), [getFieldValue]);
  const fourthColor = useMemo(() => getFieldValue('settings.fourthColor'), [getFieldValue]);
  const forceRtl = useMemo(() => getFieldValue('settings.forceRtl'), [getFieldValue]);

  const secondTitle = useMemo(() => {
    if (block?.secondTitleText) {
      return block?.secondTitleText;
    }
    return getFieldValue('secondTitleText');
  }, [block?.secondTitleText, getFieldValue]);

  const secondSubtitle = useMemo(() => {
    if (block?.secondSubtitleText) {
      return block?.secondSubtitleText;
    }
    return getFieldValue('secondSubtitleText');
  }, [block?.secondSubtitleText, getFieldValue]);

  const thirdTitle = useMemo(() => {
    if (block?.thirdTitleText) {
      return block?.thirdTitleText;
    }
    return getFieldValue('thirdTitleText');
  }, [block?.thirdTitleText, getFieldValue]);

  const thirdSubtitle = useMemo(() => {
    if (block?.thirdSubtitleText) {
      return block?.thirdSubtitleText;
    }
    return getFieldValue('thirdSubtitleText');
  }, [block?.thirdSubtitleText, getFieldValue]);

  const firstTitle = useMemo(() => {
    if (block?.firstTitleText) {
      return block?.firstTitleText;
    }
    return getFieldValue('firstTitleText');
  }, [block?.firstTitleText, getFieldValue]);

  const firstSubtitleText = useMemo(() => {
    if (block?.firstSubtitleText) {
      return block?.firstSubtitleText;
    }
    return getFieldValue('firstSubtitleText');
  }, [block?.firstSubtitleText, getFieldValue]);

  const fourthTitle = useMemo(() => {
    if (block?.fourthTitleText) {
      return block?.fourthTitleText;
    }
    return getFieldValue('fourthTitleText');
  }, [block?.fourthTitleText, getFieldValue]);

  const fourthSubtitleText = useMemo(() => {
    if (block?.fourthSubtitleText) {
      return block?.fourthSubtitleText;
    }
    return getFieldValue('fourthSubtitleText');
  }, [block?.fourthSubtitleText, getFieldValue]);

  const localData = [
    {
      id: 4,
      title: (
        <Typography
          sx={{
            direction: forceRtl ? 'rtl' : '',
            color: contentFourthTextColor,
            fontSize: contentFourthFontSize,
            fontWeight: contentFourthFontWeight,
            mt: 2,
            fontStyle: contentFourthFontStyle,
            textDecoration: contentFourthTextDecoration,
            textAlign: 'center',
            width: '100%',
          }}
        >
          {fourthTitle}
        </Typography>
      ),
      description: (
        <Typography
          sx={{
            direction: forceRtl ? 'rtl' : '',
            color: contentFourthSubtitleColor,
            fontSize: contentFourthSubtitleFontSize,
            fontWeight: contentFourthSubtitleFontWeight,
            mt: '-6px',
            fontStyle: contentFourthSubtitleFontStyle,
            textDecoration: contentFourthSubtitleDecoration,
            textAlign: 'center',
            width: '100%',
          }}
        >
          {fourthSubtitleText}
        </Typography>
      ),
      icon: <SmallZipLineIcon width={150} color={`${fourthColor}`} />,
    },
    {
      id: 3,
      title: (
        <Typography
          sx={{
            direction: forceRtl ? 'rtl' : '',
            color: contentThirdTextColor,
            fontSize: contentThirdFontSize,
            fontWeight: contentThirdFontWeight,
            mt: 2,
            fontStyle: contentThirdFontStyle,
            textDecoration: contentThirdTextDecoration,
            textAlign: 'center',
            width: '100%',
          }}
        >
          {thirdTitle}
        </Typography>
      ),
      description: (
        <Typography
          sx={{
            direction: forceRtl ? 'rtl' : '',
            color: contentThirdSubtitleColor,
            fontSize: contentThirdSubtitleFontSize,
            fontWeight: contentThirdSubtitleFontWeight,
            mt: '-6px',
            fontStyle: contentThirdSubtitleFontStyle,
            textDecoration: contentThirdSubtitleDecoration,
            textAlign: 'center',
            width: '100%',
          }}
        >
          {thirdSubtitle}
        </Typography>
      ),
      icon: <SmallZipLineIcon width={150} color={`${thirdColor}`} />,
    },
    {
      id: 2,
      title: (
        <Typography
          sx={{
            direction: forceRtl ? 'rtl' : '',
            color: contentSecondTextColor,
            fontSize: contentSecondFontSize,
            fontWeight: contentSecondFontWeight,
            mt: 2,
            fontStyle: contentSecondFontStyle,
            textDecoration: contentSecondTextDecoration,
            textAlign: 'center',
            width: '100%',
          }}
        >
          {secondTitle}
        </Typography>
      ),
      description: (
        <Typography
          sx={{
            direction: forceRtl ? 'rtl' : '',
            color: contentSecondSubtitleColor,
            fontSize: contentSecondSubtitleFontSize,
            fontWeight: contentSecondSubtitleFontWeight,
            fontStyle: contentSecondSubtitleFontStyle,
            textDecoration: contentSecondSubtitleDecoration,
            textAlign: 'center',
            width: '100%',
            mt: '-6px',
          }}
        >
          {secondSubtitle}
        </Typography>
      ),
      icon: <SmallZipLineIcon width={150} color={`${secondColor}`} />,
    },
    {
      id: 1,
      title: (
        <Typography
          sx={{
            direction: forceRtl ? 'rtl' : '',
            color: contentFirstTextColor,
            fontSize: contentFirstFontSize,
            fontWeight: contentFirstFontWeight,
            mt: 2,
            fontStyle: contentFirstFontStyle,
            textDecoration: contentFirstTextDecoration,
            textAlign: 'center',
            width: '100%',
          }}
        >
          {firstTitle}
        </Typography>
      ),
      description: (
        <Typography
          sx={{
            direction: forceRtl ? 'rtl' : '',
            color: contentFirstSubtitleColor,
            fontSize: contentFirstSubtitleFontSize,
            fontWeight: contentFirstSubtitleFontWeight,
            mt: '-6px',
            fontStyle: contentFirstSubtitleFontStyle,
            textDecoration: contentFirstSubtitleDecoration,
            textAlign: 'center',
            width: '100%',
          }}
        >
          {firstSubtitleText}
        </Typography>
      ),
      icon: <SmallZipLineIcon width={150} color={`${firstColor}`} />,
    },
  ];

  return (
    <Stack alignItems="center" mt={1}>
      <Grid container spacing={1} alignItems="center">
        {localData.map((item, id) => (
          <Grid
            key={id}
            item
            xs={6}
            md={4}
            lg={3}
            alignItems="center"
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Stack alignItems="flex-start">
              {item.title}
              {item.icon}
              {item.description}
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default FourColumnElements;
