import { Box, Container, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import SanitizeHTML from '../../../../utils/sanitize';
import { ComponentTypeProps } from '../../../../types/page-generator';

const CustomAbout: FC<ComponentTypeProps> = ({ block }) => {
  const { textDescription }: any = block;

  return (
    <>
      {block?.settings?.enableTitle && (
        <Box>
          <Typography
            paragraph={block?.settings?.isParagraph}
            sx={{
              mt: block?.settings?.isParagraph && 2,
              width: '100%',
              color: block?.settings?.contentTextColor,
              fontSize: block?.settings?.contentFontSize,
              fontWeight: block?.settings?.contentFontWeight,
              fontStyle: block?.settings?.contentFontStyle,
              textDecoration: block?.settings?.contentTextDecoration,
              textAlign: block?.settings?.contentAlign,
              direction: block?.settings?.forceRtl ? 'rtl' : undefined,
            }}
          >
            {block?.settings?.contentText}
          </Typography>
        </Box>
      )}
      <Stack>
        {textDescription.map((item: any, idx: string) => (
          <Box key={idx}>
            {!item.isNumberedList ? (
              <Typography
                key={idx}
                paragraph={item.isParagraph}
                sx={{
                  color: item.contentTextColor,
                  fontSize: item.contentFontSize,
                  fontWeight: item.contentFontWeight,
                  fontStyle: item.contentFontStyle,
                  textDecoration: item.contentTextDecoration,
                  textAlign: item.contentAlign,
                  mt: item.isParagraph && 2,
                  direction: item.forceRtl ? 'rtl' : undefined,
                }}
              >
                <SanitizeHTML html={item.text} />
              </Typography>
            ) : (
              <Stack
                direction="row"
                mx={item.nestingLevel ? 2 * item.nestingLevel : 2}
                gap={1}
                alignItems="baseline"
              >
                <Typography
                  paragraph={item.isParagraph}
                  sx={{
                    color: item.numberTextColor,
                    fontSize: item.numberFontSize,
                    fontWeight: item.numberFontWeight,
                    fontStyle: item.numberFontStyle,
                    textDecoration: item.numberTextDecoration,
                    textAlign: item.numberAlign,
                    mt: item.isParagraph && 2,
                    direction: item.forceRtl ? 'rtl' : undefined,
                  }}
                >
                  {item.number}
                </Typography>
                <Typography
                  paragraph={item.isParagraph}
                  sx={{
                    color: item.contentTextColor,
                    fontSize: item.contentFontSize,
                    fontWeight: item.contentFontWeight,
                    fontStyle: item.contentFontStyle,
                    textDecoration: item.contentTextDecoration,
                    textAlign: item.contentAlign,
                    mt: item.isParagraph && 2,
                    direction: item.forceRtl ? 'rtl' : undefined,
                  }}
                >
                  <SanitizeHTML html={item.numberText} />
                </Typography>
              </Stack>
            )}
          </Box>
        ))}
      </Stack>
    </>
  );
};

export default CustomAbout;
