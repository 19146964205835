import { IOSection, SectionTypes } from 'src/types/generator';

export function getCurrentSection(sections: IOSection[], sectionType: SectionTypes | string) {
  if (Array.isArray(sections) && sections.length) {
    const byUrl = sections.find((section) => section.url?.replace('/', '') === sectionType);
    if (byUrl) {
      return byUrl;
    }
    const byType = sections.find((section) => section.sectionType === sectionType);
    return byType;
  }
  return null;
}

export function getThemeSettings(sections: IOSection[]) {
  if (sections.length) {
    return sections.find((section) => section.sectionType === SectionTypes.themeSettings);
  }
  return null;
}
