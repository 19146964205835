// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import { uploadProductsImage } from '../../../../helpers/uploadProductsImage';
import { useResponsive } from '../../../../hooks/use-responsive';
import { BlockType } from '../../../../types/page-generator';
import { fDate } from '../../../../utils/format-time';
import { fShortenNumber } from '../../../../utils/format-number';

// ----------------------------------------------------------------------

type IBlogItem = {
  content: string;
  enableComments: boolean;
  enableTitle: boolean;
  enableSubtitle: boolean;
  enableLinkText: boolean;
  enablePublicationDate: boolean;
  enableShare: boolean;
  enableViews: boolean;
  enableTextAfterPhoto: boolean;
  mainImage: string;
  mainName: string;
  subTitle: string;
  linkText: string;
  name: string;
  size: string;
};

type Props = {
  post: IBlogItem;
  index?: number;
  block?: BlockType | undefined;
};

export default function PostItem({ post, index, block }: Props) {
  const theme = useTheme();
  const isMobile = useResponsive('down', 'sm');
  const mdUp = useResponsive('up', 'md');

  const { mainImage, mainName, subTitle, linkText } = post;

  const latestPost = index === 0 || index === 1 || index === 2;

  // if (mdUp && latestPost) {
  //   return (
  //     <Card>
  //       <PostContent
  //         title={mainName}
  //         createdAt={new Date()}
  //         totalViews={Math.floor(Math.random() * 100)}
  //         totalShares={Math.floor(Math.random() * 100)}
  //         totalComments={Math.floor(Math.random() * 100)}
  //         index={index}
  //         post={post}
  //       />
  //
  //       <Image
  //         alt={mainName}
  //         src={uploadProductsImage(mainImage)}
  //         sx={{
  //           width: 1,
  //           height: 360,
  //         }}
  //       />
  //     </Card>
  //   );
  // }

  return (
    <Card sx={{ height: '100%' }}>
      <Box height="100%">
        {/* <Avatar */}
        {/*  alt={author.name} */}
        {/*  src={author.avatarUrl} */}
        {/*  sx={{ */}
        {/*    left: 24, */}
        {/*    zIndex: 9, */}
        {/*    bottom: -24, */}
        {/*    position: 'absolute', */}
        {/*  }} */}
        {/* /> */}

        <Image
          alt={mainName}
          sx={{
            height: '100%',
            width: '100%',
            '& img': { objectFit: isMobile ? 'cover' : 'unset' },
          }}
          src={uploadProductsImage(mainImage)}
          // ratio="4/3"
        />
      </Box>

      <PostContent
        title={mainName}
        subTitle={subTitle}
        linkText={linkText}
        totalViews={Math.floor(Math.random() * 100)}
        totalShares={Math.floor(Math.random() * 100)}
        totalComments={Math.floor(Math.random() * 100)}
        createdAt={new Date()}
        post={post}
      />
    </Card>
  );
}

// ----------------------------------------------------------------------

type PostContentProps = {
  title: string;
  subTitle: string;
  linkText: string;
  index?: number;
  totalViews: number;
  totalShares: number;
  totalComments: number;
  createdAt: Date | string | number;
  post: IBlogItem;
  block?: BlockType | undefined;
};

export function PostContent({
  title,
  subTitle,
  linkText,
  createdAt,
  totalViews,
  totalShares,
  totalComments,
  index,
  post,
  block,
}: PostContentProps) {
  const mdUp = useResponsive('up', 'md');

  const latestPostLarge = index === 0;

  const latestPostSmall = index === 1 || index === 2;

  return (
    <CardContent
      sx={{
        // pt: 6,
        padding: '8px',
        width: 1,
        bottom: 0,
        position: 'absolute',
        '&:last-child ': { padding: 0 },
        ...((latestPostLarge || latestPostSmall) && {
          pt: 0,
          zIndex: 9,
          bottom: 0,
          position: 'absolute',
          color: 'common.white',
        }),
      }}
    >
      {post.enablePublicationDate && (
        <Typography
          variant="caption"
          component="div"
          sx={{
            mb: 1,
            textAlign: 'end',
            color: 'text.disabled',
            ...((latestPostLarge || latestPostSmall) && {
              opacity: 0.64,
              color: 'common.white',
            }),
          }}
        >
          {fDate(createdAt)}
        </Typography>
      )}
      <Stack
        sx={{
          p: 1,
          display:
            !post?.enableTitle && !post?.enableSubtitle && !post?.enableLinkText ? 'none' : '',
        }}
      >
        {post?.enableTitle && (
          <Link color="inherit">
            <TextMaxLine
              variant={mdUp && latestPostLarge ? 'h5' : 'subtitle2'}
              line={2}
              persistent
              sx={{ textAlign: 'end' }}
            >
              {title}
            </TextMaxLine>
          </Link>
        )}
        {post?.enableSubtitle && <Typography sx={{ textAlign: 'end' }}>{subTitle}</Typography>}
        {post?.enableLinkText && (
          <Typography sx={{ textDecoration: 'underline', textAlign: 'end' }}>{linkText}</Typography>
        )}
      </Stack>
      <Stack
        spacing={1.5}
        direction="row"
        justifyContent="flex-end"
        sx={{
          my: 1,
          typography: 'caption',
          color: 'text.disabled',
          ...((latestPostLarge || latestPostSmall) && {
            opacity: 0.64,
            color: 'common.white',
          }),
          display: !post.enableComments && !post.enableViews && !post.enableShare ? 'none' : '',
        }}
      >
        {post.enableComments && (
          <Stack direction="row" alignItems="center">
            <Iconify icon="eva:message-circle-fill" width={16} sx={{ mr: 0.5 }} />
            {fShortenNumber(totalComments)}
          </Stack>
        )}
        {post.enableViews && (
          <Stack direction="row" alignItems="center">
            <Iconify icon="solar:eye-bold" width={16} sx={{ mr: 0.5 }} />
            {fShortenNumber(totalViews)}
          </Stack>
        )}
        {post.enableShare && (
          <Stack direction="row" alignItems="center">
            <Iconify icon="solar:share-bold" width={16} sx={{ mr: 0.5 }} />
            {fShortenNumber(totalShares)}
          </Stack>
        )}
      </Stack>
    </CardContent>
  );
}
