import { FC, Key } from 'react';

import { Box, Grid, Stack, Button, Typography } from '@mui/material';

import { ComponentTypeProps } from '../../../../types/page-generator';

const CouponsV2: FC<ComponentTypeProps> = ({ block }) => {
  const vouchers = [
    {
      id: 1,
      url: '/assets/images/xtra/banners/coupons/1.png',
      text: 'רשתות ורדינון ונעמן',
      price: '₪200',
      buttons: [
        {
          text: 'לפרטים ולבחירה',
        },
      ],
    },
    {
      id: 2,
      url: '/assets/images/xtra/banners/coupons/2.png',
      text: 'חנויות קבוצת גולף',
      price: '₪200',
      buttons: [
        {
          text: 'לרשימת רשתות',
        },
        {
          text: 'לפרטים ולבחירה',
        },
      ],
    },
    {
      id: 3,
      url: '/assets/images/xtra/banners/coupons/3.png',
      text: 'תכשיטי Joulou',
      price: '₪200',
      buttons: [
        {
          text: 'לפרטים ולבחירה',
        },
      ],
    },
    {
      id: 4,
      url: '/assets/images/xtra/banners/coupons/4.png',
      text: 'LOVE GIFT CARD',
      price: '₪200',
      buttons: [
        {
          text: 'לרשימת רשתות',
        },
        {
          text: 'לפרטים ולבחירה',
        },
      ],
    },
    {
      id: 5,
      url: '/assets/images/xtra/banners/coupons/5.png',
      text: "ג'אפן ג'אפן",
      price: '₪200',
      buttons: [
        {
          text: 'לפרטים ולבחירה',
        },
      ],
    },
    {
      id: 6,
      url: '/assets/images/xtra/banners/coupons/6.png',
      text: 'POWER GIFT',
      price: '₪200',
      buttons: [
        {
          text: 'לרשימת רשתות',
        },
        {
          text: 'לפרטים ולבחירה',
        },
      ],
    },
    {
      id: 7,
      url: '/assets/images/xtra/banners/coupons/7.png',
      text: 'גיפטקארד למימוש באתר VN Sport',
      price: '₪200',
      buttons: [
        {
          text: 'לרשימת רשתות',
        },
        {
          text: 'לפרטים ולבחירה',
        },
      ],
    },
    {
      id: 8,
      url: '/assets/images/xtra/banners/coupons/8.png',
      text: 'אתר אונליין Raven Sport',
      price: '₪200',
      buttons: [
        {
          text: 'לפרטים ולבחירה',
        },
      ],
    },
    {
      id: 9,
      url: '/assets/images/xtra/banners/coupons/9.png',
      text: 'שוברי חופשה',
      price: '₪200',
      buttons: [
        {
          text: 'סט אימון פילאטיס ויוגה',
        },
      ],
    },
  ];
  return (
    <Grid container spacing={2}>
      {vouchers.map((voucher: any, id: Key | null | undefined) => (
        <Grid item xs={4} key={id}>
          <Box
            sx={{
              backgroundSize: 'cover',
              backgroundImage: `url(${voucher.url})`,
              backgroundRepeat: 'no-repeat',
              height: '140px',
              borderRadius: '10px',
            }}
          />
          <Stack mt={1} justifyContent="space-between">
            <Stack alignItems="center">
              <Typography>{voucher.text}</Typography>
              <Typography color="red">{voucher.price}</Typography>
            </Stack>
            <Stack direction="row-reverse" justifyContent="center" gap={1}>
              {voucher.buttons.map((button: any, idx: number) => (
                <Button
                  fullWidth={voucher.buttons.length === 1}
                  variant={idx === 0 ? 'outlined' : 'contained'}
                  sx={{ borderRadius: '15px' }}
                >
                  {button.text}
                </Button>
              ))}
            </Stack>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

export default CouponsV2;
