import NextLink from 'next/link';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Image from '../../../../components/image';
import { useResponsive } from '../../../../hooks/use-responsive';

const VideoBanner = () => {
  const smUp = useResponsive('up', 'sm');

  return (
    <Box
      sx={{
        width: '100%',
        height: '750px',
        backgroundImage: `url('/assets/images/happy-gift/top-banner-home-page.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Stack
        direction={smUp ? 'row' : 'column-reverse'}
        alignItems="center"
        justifyContent="space-between"
        height={1}
      >
        <Stack
          sx={{
            pl: '50px',
            '& .MuiTypography-root': {
              fontWeight: 1000,
              fontSize: '45px',
              color: 'secondary.contrastText',
            },
          }}
        >
          <Typography>רוצים לפנק את</Typography>
          <Typography>העובדים בארץ</Typography>
          <Typography> ובעולם?</Typography>
          <Typography>אנחנו כאן בשבילכם!</Typography>
        </Stack>
        <Link
          component={NextLink}
          href="https://home.happygift4u.co/happy-holders/"
          sx={{ width: '100%', height: smUp ? '100%' : '75%' }}
        >
          <Stack
            sx={{
              height: '100%',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            <Image
              src="/assets/images/happy-gift/happy-gift-home-page-button.svg"
              width={smUp ? '50%' : '100%'}
              sx={{ cursor: 'pointer' }}
            />
          </Stack>
        </Link>
      </Stack>
    </Box>
  );
};

export default VideoBanner;
