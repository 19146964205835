import { FC } from 'react';

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { Link, Stack, useTheme, Typography } from '@mui/material';

import Image from 'src/components/image';

import SvgColor from '../../../../components/svg-color';
import { useResponsive } from '../../../../hooks/use-responsive';
import LineIcon from '../../../../assets/icons/happy-gift/lineIcon';
import { ComponentTypeProps } from '../../../../types/page-generator';

// =====================================================================

const COMPANIES = [
  { title: 'Sano', href: '#' },
  { title: 'Pelephone', href: '#' },
  { title: 'Bank Hapoalim', href: '#' },
  { title: 'Riskified', href: '#' },
  { title: 'Bank Leumi', href: '#' },
  { title: 'Bezeq', href: '#' },
];

const ShopBanner6: FC<ComponentTypeProps> = ({ block }) => {
  const theme = useTheme();
  const smDown = useResponsive('down', 'sm');
  const buttons = [
    {
      id: 4,
      title: 'בירור יתרה',
    },
    {
      id: 3,
      title: 'בירור יתרה',
    },
    {
      id: 2,
      title: 'בירור יתרה',
    },
    {
      id: 1,
      title: 'בירור יתרה',
    },
  ];

  return (
    <>
      {!smDown ? (
        <Card
          sx={{
            width: '100%',
            borderRadius: '20px',
            backgroundSize: 'cover',
            paddingTop: 5,
            height: '210px',
            mt: '-50px',
          }}
        >
          <Stack alignItems="center">
            <Image
              src="/assets/images/happy-gift/backgrounds/ellipse.png"
              sx={{ width: '15%', position: 'absolute', top: 0 }}
            />
            <Image src="/assets/images/happy-gift/backgrounds/rectangle.png" />
          </Stack>
          <Stack
            direction="row"
            sx={{ justifyContent: 'space-between', position: 'relative', top: '-80px', px: 3 }}
          >
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Typography sx={{ color: '#5D21EC', fontSize: '18px' }}>נבחרים על ידי</Typography>
              <SvgColor
                src="/assets/icons/happy-gift/arrow_1.svg"
                sx={{ width: 55 }}
                color={theme.palette.common.white}
              />
            </Stack>
            {COMPANIES.map((company) => (
              <Link
                color={theme.palette.common.white}
                href={company.href}
                sx={{ fontSize: 22, fontWeight: theme.typography.fontWeightBold }}
              >
                {company.title}
              </Link>
            ))}
          </Stack>
          <LineIcon color="#845DF2" sx={{ position: 'relative', top: '-90px', px: 2.5 }} />
          <Stack
            direction="row"
            justifyContent="space-between"
            gap={6}
            sx={{ position: 'relative', top: '-80px', px: 2 }}
          >
            {buttons.map((button, id) => (
              <Button
                key={id}
                variant="contained"
                sx={{
                  fontWeight: 400,
                  fontSize: '16px',
                  width: '100%',
                  bgcolor: 'secondary.contrastText',
                  borderRadius: '10px',
                  color: 'black',
                  border: '1px solid #27097A',
                  '&:hover': {
                    bgcolor: 'secondary.contrastText',
                    color: 'black',
                  },
                }}
              >
                {button.title}
              </Button>
            ))}
          </Stack>
        </Card>
      ) : (
        <Card
          component={Stack}
          spacing={3}
          direction="row"
          flexWrap="wrap"
          justifyContent="space-between"
          sx={{
            width: '100%',
            borderRadius: 1.5,
            bgcolor: theme.palette.primary.main,
            py: smDown ? 4 : 6,
            px: 3,
          }}
        >
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography>נבחרים על ידי</Typography>
            <SvgColor
              src="/assets/icons/happy-gift/arrow_1.svg"
              sx={{ width: 55 }}
              color={theme.palette.common.white}
            />
          </Stack>
          {COMPANIES.map((company) => (
            <Link
              color={theme.palette.common.white}
              href={company.href}
              sx={{ fontSize: 19, fontWeight: theme.typography.fontWeightBold }}
            >
              {company.title}
            </Link>
          ))}
        </Card>
      )}
    </>
  );
};
export default ShopBanner6;
