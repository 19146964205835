import * as Yup from 'yup';
import { uniqBy } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { useForm } from 'react-hook-form';
// components
import FileUpload from 'react-material-file-upload';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactCodeInput from 'react-verification-code-input';
import { useRef, useMemo, useState, useEffect, useCallback, SetStateAction } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DatePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  Box,
  Card,
  List,
  Modal,
  Radio,
  Stack,
  Button,
  MenuItem,
  TextField,
  InputLabel,
  RadioGroup,
  Typography,
  FormControl,
  OutlinedInput,
  FormControlLabel,
} from '@mui/material';

import { useTranslate } from 'src/locales';
// import { dispatch } from 'src/redux/store';

import Grid from '@mui/material/Grid';

import { UploadBox } from 'src/components/upload';

import axios from '../../utils/axios';
import Image from '../../components/image';
import Iconify from '../../components/iconify';
import { CartInterface } from '../../types/cart';
import { useAuthContext } from '../../auth/hooks';
import { ICustomer } from '../../types/customers';
import Scrollbar from '../../components/scrollbar';
import { ConfirmDialog } from '../../components/custom-dialog';
import { ActionTypes, useAppContext } from '../../contexts/AppContext';
import FormProvider, { RHFCheckbox, RHFTextField } from '../../components/hook-form';

const SEND_OPTION = [
  { label: 'SMS', value: 'SMS' },
  { label: 'mail', value: 'Email' },
  { label: 'print', value: 'Print' },
  { label: 'WhatsApp', value: 'WhatsApp' },
];
const TEMPLATE_OPTIONS = [
  {
    label: ' תחרות בצוות',
    text: 'לאחר תחרות צוותית מוצלחת ומהנה, אנו שמחים להעניק לכם שובר מתנה!',
  },
  {
    label: 'יום כיף ',
    text: 'איזה כיף! יוצאים עם המשפחה/חברים ליום כיף? אנו שמחים להעניק לך שובר מתנה!',
  },
  {
    label: 'בריאות',
    text: 'אנו שמחים להעניק לילדכם שי חמוד ומפנק!',
  },
  {
    label: 'בריאות',
    text: 'ברוך שובך! אנו שמחים מאוד שהינך מרגיש יותר טוב, אנו שמחים לפנק אותך בשובר מתנה!',
  },
  {
    label: 'יום העצמאות',
    text: 'חג שמח! לרגל יום הולדת המדינה, אנו שמחים להעניק לך שובר מתנה!',
  },
  {
    label: 'יום הולדת',
    text: 'מזל טוב! לרגל יום הולדתך אנו שמחים לפנק אותך בשובר מתנה! שיהיה במזל טוב!',
  },
  {
    label: 'חג פסח',
    text: 'חג פסח שמח וכשר וחג אביב פורח! זוהי ההזדמנות שלנו להעניק לך שובר מתנה!',
  },
  {
    label: 'תמריצים',
    text: 'העובדים שלנו הם הכוח שלנו! אנו מודים להם על עבודתם, לכן אנו שמחים לפנק אתכם בשי מפנק!',
  },
  {
    label: 'חג פורים',
    text: 'חג פורים שמח! פורים בא רק פעם בשנה, אבל איתו מביא צחוק, שירה ואורה ומצב - רוח טוב שיספיק לכל השנה, לכן אנו שמחים להעניק לך שובר מתנה!',
  },
  {
    label: 'ראש השנה',
    text: 'שנה טוב וחג שמח! לשנה החדשה נאחל מכל הלב שתזכו להמשיך ליצור, להצליח ולשגשג, להתקדם ולהוביל. מאחלים לכם שתזכו להמשיך להנות וליצור בעבודה פורייה ועם המשפחה בשלווה והנאה. לכבוד השנה החדשה אנו שמחים להעניק לכם שובר מתנה!',
  },
  {
    label: 'פעילות צוות',
    text: 'לאחר פעילות צוות מגבשת, מוצלחת ומהנה, אנו שמחים להעניק לכם שובר מתנה!',
  },
  {
    label: 'חתונה',
    text: 'בשעה טובה ושיהיה במזל טוב! לכבוד חתונתכם אנו שמחים להעניק לכם שובר מתנה!',
  },
];
const GENDER_OPTION = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Other', value: 'Other' },
];

const ADDRESSES_OPTION = [
  { label: 'Groups', value: 'Groups' },
  { label: 'Clients', value: 'Clients' },
];

const GROUP_OPTION = [
  { label: 'Managment', value: 'Managment' },
  { label: 'Dev staf', value: 'Dev staf' },
];

const _customersList = [
  {
    id: 1,
    name: 'Jayvion Simon',
    group: 'Managment',
    status: '',
    role: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    country: '',
    state: '',
    credit: 0,
    birthday: '',
    gender: '',
  },
  {
    id: 2,
    name: 'Lucian Obrien',
    group: 'Managment',
    status: '',
    role: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    country: '',
    state: '',
    credit: 0,
    birthday: '',
    gender: '',
  },
  {
    id: 3,
    name: 'Deja Brady',
    group: 'Dev staf',
    status: '',
    role: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    country: '',
    state: '',
    credit: 0,
    birthday: '',
    gender: '',
  },
  {
    id: 4,
    name: 'Harrison Stein',
    group: 'Dev staf',
    status: '',
    role: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    country: '',
    state: '',
    credit: 0,
    birthday: '',
    gender: '',
  },
];

const categories = [
  {
    id: 1,
    title: 'תחרות בצוות',
    category: 'team',
  },
  {
    id: 2,
    title: 'יום כייף',
    category: 'party',
  },
  {
    id: 3,
    title: 'פעילות לילדים',
    category: 'children',
  },
  {
    id: 4,
    title: 'בריאות',
    category: 'health',
  },
  {
    id: 5,
    title: 'יום עצמאות',
    category: 'independence',
  },
  {
    id: 6,
    title: 'ימי הולדת',
    category: 'birthday',
  },
  {
    id: 7,
    title: 'פסח תמריצים',
    category: 'passover',
  },
  {
    id: 8,
    title: 'חג פורים',
    category: 'purim',
  },
  {
    id: 9,
    title: ' ראש השנה',
    category: 'newYear',
  },
  // {
  //   id: 10,
  //   title: 'פעילות צוות',
  //   category: 'team'
  // },
  // {
  //   id: 11,
  //   title: 'פסח',
  //   category: 'passover'
  // },
  {
    id: 12,
    title: 'חתונה',
    category: 'wedding',
  },
];

const imageBackground = [
  {
    id: 1,
    imgUrl: '/assets/images/templates/1.jpg',
    category: 'birthday',
  },
  {
    id: 2,
    imgUrl: '/assets/images/templates/2.jpg',
    category: 'wedding',
  },
  {
    id: 3,
    imgUrl: '/assets/images/templates/3.jpg',
    category: 'birthday',
  },
  {
    id: 4,
    imgUrl: '/assets/images/templates/4.jpg',
    category: 'party',
  },
  {
    id: 5,
    imgUrl: '/assets/images/templates/5.jpg',
    category: 'children',
  },
  {
    id: 6,
    imgUrl: '/assets/images/templates/6.jpg',
    category: 'party',
  },
  {
    id: 7,
    imgUrl: '/assets/images/templates/team/team_1.png',
    category: 'team',
  },
  {
    id: 8,
    imgUrl: '/assets/images/templates/team/team_2.png',
    category: 'team',
  },
  {
    id: 9,
    imgUrl: '/assets/images/templates/team/team_3.jpeg',
    category: 'team',
  },
  {
    id: 10,
    imgUrl: '/assets/images/templates/health/health_1.jpg',
    category: 'health',
  },
  {
    id: 11,
    imgUrl: '/assets/images/templates/health/health_2.jpg',
    category: 'health',
  },
  {
    id: 12,
    imgUrl: '/assets/images/templates/independence/independence_1.jpg',
    category: 'independence',
  },
  {
    id: 13,
    imgUrl: '/assets/images/templates/independence/independence_2.png',
    category: 'independence',
  },
  {
    id: 13,
    imgUrl: '/assets/images/templates/passover/passover_1.jpg',
    category: 'passover',
  },
  {
    id: 14,
    imgUrl: '/assets/images/templates/new/new_1.png',
    category: 'newYear',
  },
  {
    id: 15,
    imgUrl: '/assets/images/templates/new/new_2.png',
    category: 'newYear',
  },
  {
    id: 16,
    imgUrl: '/assets/images/templates/wedding/wedding_1.jpg',
    category: 'wedding',
  },
  {
    id: 17,
    imgUrl: '/assets/images/templates/purim/purim_1.jpeg',
    category: 'purim',
  },
  {
    id: 18,
    imgUrl: '/assets/images/templates/purim/purim_2.png',
    category: 'purim',
  },
];

// ----------------------------------------------------------------------

export default function GreetingForm({
  isEdit,
  setData,
  time,
  setTime,
  openConfirm,
  handleCloseConfirm,
  handleNextStep,
  isSiteBuilder,
  openCustomersMailingList,
  handleCloseCustomersMailingList,
  senderName,
  setSenderName,
  addressee,
  setAddressee,
  mailingMessage,
  setMailingMessage,
  cart,
  block,
}: any) {
  const { t } = useTranslate();
  const ref = useRef<any>();
  const { user } = useAuthContext();
  const { dispatch, state } = useAppContext();
  const localCart = state.cart;
  // const activeMerchant = useSelector((state) => state.merchant.activeEvent);
  // const activeHr = useSelector((state) => state.merchant.active);
  // const { isGreetingSendDataModal } = useSelector((state) => state.toggler);
  //
  // const coupons = useSelector((state) => state.product_2.products);
  // const { customerMailing, customers, groups } = useSelector((state) => state.customer);
  // const { pickedMailingCoupons } = useSelector((state) => state.product_2);
  // const groupNames = groups.map((item: any) => item.name);

  const [isMale, toggleIsMale] = useState(true);
  const [isFemale, toggleIsFemale] = useState(true);
  const [isOther, toggleIsOther] = useState(true);
  const [ageFrom, setAgeFrom] = useState(18);
  const [ageTo, setAgeTo] = useState(80);
  const [dateValue, setDateValue] = useState<any>(dayjs());
  const [timeValue, setTimeValue] = useState(new Date());

  const [uploadFirstModal, setUploadFirstModal] = useState(false);
  const [uploadSecondModal, setUploadSecondModal] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [errorFile, setErrorFile] = useState('');
  const [loading, setLoading] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const [openTimePicker, setOpenTimePicker] = useState(false);
  const [recipientsOptions, setRecipientsOptions] = useState<any[]>(GROUP_OPTION);
  const [selectedGroup, setSelectedGroup] = useState<string[]>([]);
  const [selectedClient, setSelectedClient] = useState<string[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [customerMailingList, setCustomerMailingList] = useState<ICustomer[]>([]);
  const [selectedRecipientGroup, setSelectedRecipientGroup] = useState('');
  const [budgetError, setBudgetError] = useState('');
  const [openSuccessfulLetter, setOpenSuccessfulLetter] = useState(false);
  const [pickedGroup, setPickedGroup] = useState('Main');

  const [isSms, toggleIsSms] = useState(true);
  const [isEmail, toggleIsEmail] = useState(false);
  const [isPrint, toggleIsPrint] = useState(false);
  const [isWhatsApp, toggleIsWhatsApp] = useState(false);
  const [isConfirmSendingModal, setIsConfirmSendingModal] = useState(false);
  const [codeVerify, setCodeVerify] = useState('');
  const [selectTemplateValue, setSelectTemplateValue] = useState('');
  const [typeGreedting, setTypeGreedting] = useState('Write');
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [forWhom, setForWhom] = useState<string>('My');
  const [delivary, setDelivery] = useState<string>('Immediate');
  const [openTemplates, setOpenTemplates] = useState(false);
  const [category, setCategory] = useState('team');

  const NewProductSchema = Yup.object().shape({
    name: Yup.string().required(`${t('Name is required')}`),
    images: Yup.array().required('יש צורך בתמונות'),
    description: Yup.string().required('נדרש תיאור'),
    congratulations: Yup.string().required('נדרשת ברכות'),
    sms: Yup.string().required('נדרשת ברכת sms'),
  });

  const defaultValues = {
    sender: '',
    images: '',
    description: '',
    addressee: '',
    addressees: [],
    recipients: ADDRESSES_OPTION[0].value,
    category: SEND_OPTION[0].value,
    gender: GENDER_OPTION[0].value,
    ageFrom: 18,
    ageUpTo: 80,
    phone: '',
    email: '',
    time: '',
  };

  const isExistSending = useMemo(() => {
    if (!block) return false;
    const methods = [
      block.isSendingMethodMail,
      block.isSendingMethodPrint,
      block.isSendingMethodWhatsApp,
      block.isSendingMethodSMS,
    ];
    if (methods.some((method) => typeof method !== 'boolean')) return true;
    return methods.some((method) => method);
  }, [block]);
  const isBool = useCallback((value: any) => typeof value === 'boolean', []);
  const methods = useForm<any>({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const { watch, setValue, handleSubmit } = methods;

  useEffect(() => {
    // @ts-ignore
    const subscription = watch((value) => setData(value));
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const values = watch();

  useEffect(() => {
    if (values.recipients && values.recipients === 'Groups') {
      setRecipientsOptions(GROUP_OPTION);
    }

    if (values.recipients && values.recipients === 'Clients') {
      setRecipientsOptions(_customersList);
    }
  }, [values.recipients, recipientsOptions]);

  // const filteredCoupons = coupons?.filter(
  //   (coupon: ProductInterace) => activeMerchant?.settings?.couponIds?.includes(coupon?.id)
  // );

  // const sumTotalPriceCoupons = filteredCoupons.reduce(
  //   (accumulator: any, object: ProductInterace) => accumulator + object.discountValueB2B,
  //   0
  // );

  useEffect(() => {
    if (selectedClient.length && values.recipients === 'Clients') {
      const customersListByName = _customersList.filter((customer) =>
        selectedClient.includes(customer.name)
      );
      setCustomerMailingList(customersListByName);
    }
    if (!selectedGroup.length && !selectedClient.length && customerMailingList.length) {
      // dispatch(setCustomerMailing([]));
    }

    if (selectedGroup.length && values.recipients === 'Groups') {
      const customersListByGroup = _customersList.filter((customer) =>
        selectedGroup.includes(customer.group)
      );
      setCustomerMailingList(customersListByGroup);
    }
  }, [
    selectedClient.length,
    selectedGroup.length,
    customerMailingList.length,
    selectedClient,
    values.recipients,
    selectedGroup,
  ]);

  useEffect(() => {
    if (customerMailingList.length) {
      const uniqueCustomersByID = uniqBy(customerMailingList, 'id');
      setCustomerMailingList(uniqueCustomersByID);
      // dispatch(setCustomerMailing(customerMailingList));
    }
  }, [customerMailingList, customerMailingList.length]);

  // useEffect(() => {
  //   const isIncludesGroup = customerMailing.some(
  //     (item: { group: string }) => item.group === selectedRecipientGroup
  //   );
  //
  //   if (!isIncludesGroup) {
  //     const newSelectedGroups = selectedGroup.filter((item) => item !== selectedRecipientGroup);
  //     setSelectedGroup(newSelectedGroups);
  //   } else {
  //     return;
  //   }
  //
  //   if (openCustomersMailingList && !customerMailing.length) {
  //     handleCloseCustomersMailingList();
  //   }
  //
  //   if (customerMailing.length) {
  //     const totalPriceByClient = sumTotalPriceCoupons * customerMailing.length;
  //     setTotalPrice(totalPriceByClient);
  //   } else {
  //     setTotalPrice(0);
  //   }
  //
  //   if (Number(totalPrice) < Number(activeMerchant.merchant_obligation)) {
  //     setBudgetError('');
  //   }
  // }, [
  //   activeMerchant.merchant_obligation,
  //   customerMailing,
  //   customerMailing.length,
  //   handleCloseCustomersMailingList,
  //   openCustomersMailingList,
  //   selectedGroup,
  //   selectedRecipientGroup,
  //   sumTotalPriceCoupons,
  //   totalPrice,
  // ]);

  const applyCode = async (code: string) => {
    if (code === codeVerify) {
      await onSubmit();
      setIsConfirmSendingModal(false);
    }
  };

  const onSubmit = async () => {
    try {
      if (!user?.phone) {
        setBudgetError('Specify the phone number in the profile');
        return;
      }

      const _totalPrice = Number(getTotalPriceForMailing());

      if (!_totalPrice) {
        setBudgetError('Select coupons for mailing');
        return;
      }

      // if (_totalPrice > Number(activeHr.merchant_obligation)) {
      //   setBudgetError('Top up your balance');
      //   return;
      // }
      setBudgetError('');

      // if (!codeVerify) {
      //   setIsConfirmSendingModal(true);
      //   const { data } = await API({
      //     url: `sso/otp/login`,
      //     method: 'POST',
      //     data: {
      //       phone: user.phone,
      //       botId: 331,
      //       // test: true,
      //     },
      //   });
      //   setCodeVerify(data.otp);
      //   return;
      // }
      // await API({
      //   url: `sso/otp/verify`,
      //   method: 'POST',
      //   data: {
      //     phone: user.phone,
      //     otp: codeVerify,
      //   },
      // });

      // const sendingTime = new Date(dateValue.format());
      // sendingTime.setHours(timeValue.getHours());
      // sendingTime.setMinutes(timeValue.getMinutes());

      // await API({
      //   url: `customer/${RAAYONIT_BOT_ID}/mailing`,
      //   method: 'POST',
      //   data: {
      //     groupName: pickedGroup,
      //     clientIds: [],
      //     isSms,
      //     isEmail,
      //     isPrint,
      //     isWhatsApp,
      //     eventId: activeMerchant.id,
      //     clubId: activeHr.id,
      //     message: mailingMessage,
      //     senderName,
      //     gender: { isMale, isFemale, isOther },
      //     ageFrom,
      //     ageTo,
      //     sendingTime,
      //     couponIds: pickedMailingCoupons.map((i: any) => i.id),
      //   },
      // });

      // const resultObligo = Number(activeHr.merchant_obligation) - _totalPrice;
      // dispatch(updateMerchantObligation(resultObligo));

      handleCloseConfirm();
      // dispatch(disableToggler(TOGGLER_NAMES.isGreetingSendDataModal));
      setOpenSuccessfulLetter(true);
      setCodeVerify('');
    } catch (e) {
      console.error(e);
    }
  };

  const handleDrop = useCallback(
    async (acceptedFiles: any) => {
      const file = acceptedFiles[0];
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', 'scenario/file');
        formData.append('name', file.name);
        formData.append('token', process.env.CONNECTION_TOKEN || '');

        const newFiles = {
          path: String(file.name),
          preview: URL.createObjectURL(file),
        };

        // @ts-ignore
        setValue('images', formData, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const onChangeGroup = (e: any) => {
    setPickedGroup(e.target.value);
  };
  // const handleRemoveFile = (inputFile: File | string) => {
  //   const filtered =
  //     values.images && values.images?.filter((file) => file !== inputFile);
  //   setValue('images', filtered);
  // };

  // const handleRemoveAllFiles = () => {
  //   setValue('images', []);
  // };

  const changeModal = () => {
    setUploadSecondModal(false);
    setUploadFirstModal(false);
    setFiles([]);
    setErrorFile('');
  };

  const changeFiles = (_files: SetStateAction<File[]> | any) => {
    if (_files.length) {
      if (
        _files[0]?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        _files[0]?.type === 'application/vnd.ms-excel'
      ) {
        setFiles(_files);
        setErrorFile('');
      } else {
        setErrorFile(`${t('Select an excel file')}`);
        setFiles([]);
      }
    } else {
      setFiles([]);
      setErrorFile(`${t('Choose File')}`);
    }
  };

  const downloadFile = async () => {
    if (!files.length) {
      if (!errorFile) {
        setErrorFile(`${t('Choose File')}`);
      }
    } else {
      const formData: any = new FormData();
      formData.append('excel', files[0]);
      formData.append('groupName', pickedGroup);
      // await axios.post(
      //   `customer/${RAAYONIT_BOT_ID}/${activeMerchant.id}/download/excel`,
      //   formData,
      //   {
      //     headers: {
      //       'Content-Type': 'multipart/form-data',
      //       'Accept-Language': 'en',
      //     },
      //   }
      // );
      // dispatch(setCustomerMailing(customers));
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setUploadSecondModal(false);
        setFiles([]);
        // dispatch(getCustomers(activeMerchant.id));
      }, 1000);
    }
  };

  const handleChangeClient = (event: SelectChangeEvent<typeof selectedClient>) => {
    const {
      target: { value },
    } = event;
    if (values.recipients === 'Clients') {
      setSelectedClient(typeof value === 'string' ? value.split(',') : value);
    }
  };

  const onDeleteRecipient = (id: number, name: string, group: string) => {
    // const newList = customerMailing.filter((item: ICustomer) => item.id !== id);
    const newSelectedCliens = selectedClient.filter((item: string) => item !== name);
    setSelectedRecipientGroup(group);
    setSelectedClient(newSelectedCliens);
    // dispatch(setCustomerMailing(newList));
  };

  const closeFirstModal = () => {
    setUploadFirstModal(false);
    setUploadSecondModal(true);
  };

  const getTotalPriceForMailing = () => {
    // let _totalPrice = 0;
    //
    // // eslint-disable-next-line no-restricted-syntax
    // for (const coupon of pickedMailingCoupons) {
    //   if (coupon.discountValueB2B) {
    //     _totalPrice += coupon.discountValueB2B;
    //   }
    // }
    //
    // return _totalPrice;
  };

  const handleChangeTemplete = (event: SelectChangeEvent) => {
    setSelectTemplateValue(event.target.value);
  };

  useEffect(() => {
    if (selectTemplateValue) {
      const findedOption = TEMPLATE_OPTIONS.find((option) => option.label === selectTemplateValue);

      if (findedOption) setMailingMessage(findedOption.text);
    } else {
      setMailingMessage('');
    }
  }, [selectTemplateValue, setMailingMessage]);

  const togglePersonalGreeting = (itemId: string) => {
    const updatedIds = [...selectedIds];
    const index = updatedIds.indexOf(itemId);
    if (index !== -1) {
      updatedIds.splice(index, 1);
    } else {
      updatedIds.push(itemId);
    }
    setSelectedIds(updatedIds);
  };

  const handleChangeCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategory(event.target.value as string);
  };

  const handleDropTemplate = useCallback(
    async (imagePath: string) => {
      const fileName: any = imagePath?.split('/').pop();
      fetch(imagePath)
        .then((response) => response.blob())
        .then(async (blob) => {
          const file = new File([blob], fileName, {
            lastModified: new Date().getTime(),
            type: 'image/png',
          });
          const formData = new FormData();
          formData.append('file', file);
          formData.append('type', 'scenario/file');
          formData.append('name', fileName);
          formData.append('token', process.env.NEXT_PUBLIC_CONNECTION_TOKEN || '');
          // @ts-ignore
          setValue('images', formData, { shouldValidate: true });
          const result = await axios.post('/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Accept-Language': 'en',
            },
          });
          const image = result?.data?.fileName;
          dispatch({ type: ActionTypes.ADD_GREETING_IMAGE, payload: image });
        })
        .catch((error) => console.error('Error loading image:', error))
        .finally(() => setOpenTemplates(false));
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Modal open={openTemplates} onClose={() => setOpenTemplates(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '2px solid gray.500',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Stack alignItems="flex-end" width={1}>
            <Iconify
              icon="clarity:close-line"
              sx={{ cursor: 'pointer' }}
              onClick={() => setOpenTemplates(false)}
            />
          </Stack>
          <Stack gap={2}>
            <Stack alignItems="center">
              <Typography sx={{ fontSize: '25px', fontWeight: 600 }}>
                <b>הגלריה</b>
              </Typography>
              <Typography>בחר/י תמונה</Typography>
            </Stack>
            <Stack direction="row" flexWrap="wrap" gap={3} justifyContent="center">
              <Typography>{`${t('Select a category for your background image')}`}</Typography>
              <TextField select fullWidth value={category} onChange={handleChangeCategory}>
                {categories.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.category}
                    sx={{
                      mx: 1,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
              <Scrollbar sx={{ width: '100%', height: '300px' }}>
                <Grid container spacing={1} width="100%" justifyContent="start">
                  {imageBackground
                    .filter((item) => item.category === category)
                    .map((item) => (
                      <Grid item xs={6} key={item.id}>
                        <Card
                          key={item.id}
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleDropTemplate(item.imgUrl)}
                        >
                          <Image src={item.imgUrl} />
                        </Card>
                      </Grid>
                    ))}
                </Grid>
              </Scrollbar>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <Modal open={uploadFirstModal} onClose={() => setUploadFirstModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid gray.500',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" textAlign="center" mb={2}>{`${t(
            'Your Excel file format should be the same as this example'
          )}`}</Typography>

          <Stack direction="row" width="100%" justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              sx={{ borderRadius: 2, mt: 5, width: '48%' }}
            >
              <a
                href="/customers_example.xlsx"
                download
                style={{ textDecoration: 'none', color: 'black' }}
              >
                {`${t('Download example')}`}
              </a>
            </Button>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              sx={{ borderRadius: 2, mt: 5, width: '48%' }}
              onClick={closeFirstModal}
            >
              {`${t('Yes')}`}
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal open={uploadSecondModal} onClose={changeModal}>
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid gray.500',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack alignItems="center">
            <FileUpload
              value={files}
              onChange={(_files) => changeFiles(_files)}
              multiple={false}
              sx={{ border: errorFile ? '1px solid red!important' : null }}
            />
            {errorFile && (
              <Typography
                sx={{
                  fontSize: 12,
                  marginTop: 2,
                  marginBottom: 2,
                  color: 'red',
                }}
              >
                {errorFile}
              </Typography>
            )}

            <TextField
              select
              SelectProps={{
                onChange: onChangeGroup,
                value: pickedGroup,
              }}
              label="groups"
              defaultValue="Main"
              sx={{
                width: 1,
                textTransform: 'capitalize',
                mb: 6,
              }}
            >
              {/* {groupNames.map((option: any) => ( */}
              {/*  <MenuItem */}
              {/*    key={option} */}
              {/*    value={option} */}
              {/*    sx={{ */}
              {/*      mx: 1, */}
              {/*      typography: 'body2', */}
              {/*    }} */}
              {/*  > */}
              {/*    {option} */}
              {/*  </MenuItem> */}
              {/* ))} */}
            </TextField>

            <Button
              onClick={downloadFile}
              fullWidth
              startIcon={<>{loading && <Iconify icon="line-md:loading-loop" />}</>}
              variant="contained"
              color="primary"
              sx={{ borderRadius: 2 }}
            >
              {`${t('Download')}`}
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Card
        sx={{
          p: 3,
          minWidth: '550px',
          minHeight: '740px',
          width: 1,
          '@media (min-width: 300px)  and (max-width: 900px)': {
            minWidth: '350px',
          },
        }}
      >
        <Stack spacing={3} sx={{ width: 1 }}>
          <Box>
            <Stack direction="column">
              <RadioGroup row>
                <FormControlLabel
                  control={<Radio />}
                  label={`${t('To myself')}`}
                  value="My"
                  checked={forWhom === 'My'}
                  onClick={() => {
                    setForWhom('My');
                  }}
                />
                <FormControlLabel
                  control={<Radio />}
                  label={`${t('Gift')}`}
                  value="Gift"
                  checked={forWhom === 'Gift'}
                  onClick={() => {
                    setForWhom('Gift');
                  }}
                />
              </RadioGroup>
              <RadioGroup row>
                <FormControlLabel
                  control={<Radio />}
                  label={`${t('Immediate delivery')}`}
                  value="Immediate delivery"
                  checked={delivary === 'Immediate'}
                  onClick={() => {
                    setDelivery('Immediate');
                  }}
                />
                <FormControlLabel
                  control={<Radio />}
                  label={`${t('Future delivery')}`}
                  value="Future delivery"
                  checked={delivary === 'Future'}
                  onClick={() => {
                    setDelivery('Future');
                  }}
                />
              </RadioGroup>
            </Stack>
          </Box>
          {forWhom !== 'My' ? (
            <RHFTextField
              name="sender"
              helperText={`${t("The name is usually the same as the company's name")}`}
              label={`${t('Sender name')}`}
              required
              value={senderName}
              onChange={(e) => setSenderName(e.target.value)}
            />
          ) : (
            ''
          )}
          {/* {forWhom !== 'My' ? ( */}
          {/*  <RHFTextField */}
          {/*    name="addressee" */}
          {/*    label={`${t('Recipient name')}`} */}
          {/*    required */}
          {/*    value={addressee} */}
          {/*    onChange={(e) => setAddressee(e.target.value)} */}
          {/*  /> */}
          {/* ) : ( */}
          {/*  '' */}
          {/* )} */}

          {/* <RHFSelect
            native
            name="recipients"
            required
            label={`${translate('Select recipients')}`}
          >
            {ADDRESSES_OPTION.map((option) => (
              <option key={option.label}>{option.label}</option>
            ))}
          </RHFSelect> */}
          {!cart && (
            <>
              {!values.recipients || values.recipients === 'Groups' ? (
                <TextField
                  select
                  SelectProps={{
                    onChange: onChangeGroup,
                    value: pickedGroup,
                  }}
                  label="groups"
                  defaultValue="Main"
                  sx={{
                    width: 1,
                    textTransform: 'capitalize',
                    mb: 6,
                  }}
                >
                  {/* {groupNames.map((option: any) => ( */}
                  {/*  <MenuItem */}
                  {/*    key={option} */}
                  {/*    value={option} */}
                  {/*    sx={{ */}
                  {/*      mx: 1, */}
                  {/*      typography: 'body2', */}
                  {/*    }} */}
                  {/*  > */}
                  {/*    {option} */}
                  {/*  </MenuItem> */}
                  {/* ))} */}
                </TextField>
              ) : (
                <FormControl>
                  <InputLabel>{values.recipients ? values.recipients : 'Groups'}</InputLabel>
                  <Select
                    multiple
                    value={selectedClient}
                    id="client"
                    onChange={handleChangeClient}
                    input={
                      <OutlinedInput label={values.recipients ? values.recipients : 'Groups'} />
                    }
                  >
                    {recipientsOptions.map((option) => (
                      <MenuItem key={option.id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
          {!cart && (
            <Button
              onClick={() => setUploadFirstModal(true)}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              color="primary"
              sx={{
                borderRadius: 20,
                fontSize: 12,
                width: 200,
                '@media (min-width: 300px)  and (max-width: 900px)': {
                  marginBottom: '10px!important',
                },
              }}
            >
              {`${t('Upload from Excel file')}`}
            </Button>
          )}

          {isExistSending && (
            <>
              {/* <Typography variant="subtitle2" sx={{ mb: 1 }}> */}
              {/*  {`${t('Mobile number/SMS sender name')}`} */}
              {/* </Typography> */}
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(2, 1fr)',
                  sm: 'repeat(4, 1fr)',
                }}
              >
                {(block.isSendingMethodSMS || !isBool(block.isSendingMethodSMS)) && (
                  <RHFCheckbox
                    name="sms"
                    label={`${t('SMS')}`}
                    checked={isSms}
                    onClick={() => toggleIsSms(!isSms)}
                  />
                )}
                {/* {(block.isSendingMethodMail || !isBool(block.isSendingMethodMail)) && ( */}
                {/*  <RHFCheckbox */}
                {/*    name="mail" */}
                {/*    label={`${t('Email')}`} */}
                {/*    checked={isEmail} */}
                {/*    onClick={() => toggleIsEmail(!isEmail)} */}
                {/*  /> */}
                {/* )} */}
                {(block.isSendingMethodPrint || !isBool(block.isSendingMethodPrint)) && (
                  <RHFCheckbox
                    name="print"
                    label={`${t('Print')}`}
                    checked={isPrint}
                    onClick={() => toggleIsPrint(!isPrint)}
                  />
                )}
                {/* {(block.isSendingMethodWhatsApp || !isBool(block.isSendingMethodWhatsApp)) && ( */}
                {/*  <RHFCheckbox */}
                {/*    name="whatsapp" */}
                {/*    label={`${t('WhatsApp')}`} */}
                {/*    checked={isWhatsApp} */}
                {/*    onClick={() => toggleIsWhatsApp(!isWhatsApp)} */}
                {/*  /> */}
                {/* )} */}
              </Box>
            </>
          )}
          {forWhom !== 'My' ? (
            <RadioGroup row>
              <FormControlLabel
                control={<Radio />}
                label="Write your text"
                value="Write"
                checked={typeGreedting === 'Write'}
                onClick={() => {
                  setTypeGreedting('Write');
                }}
              />
              <FormControlLabel
                control={<Radio />}
                label="Generate From AI"
                value="AI"
                checked={typeGreedting === 'AI'}
                onClick={() => {
                  setTypeGreedting('AI');
                }}
              />
              <FormControlLabel
                control={<Radio />}
                label="Choose from Template"
                value="Template"
                checked={typeGreedting === 'Template'}
                onClick={() => {
                  setTypeGreedting('Template');
                }}
              />
            </RadioGroup>
          ) : (
            ''
          )}
          {forWhom !== 'My' ? (
            <Stack
              sx={{
                display: `${typeGreedting === 'Template' ? 'flex' : 'none'}`,
              }}
            >
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {`${t('Choose template')}`}
              </Typography>
              <FormControl variant="filled" sx={{ mt: 1 }}>
                <InputLabel id="demo-simple-select-filled-label">Template</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={selectTemplateValue}
                  onChange={handleChangeTemplete}
                >
                  <MenuItem value="">
                    <em>Template</em>
                  </MenuItem>
                  {TEMPLATE_OPTIONS.map((option) => (
                    <MenuItem value={option.label}>{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          ) : (
            ''
          )}
          {forWhom !== 'My' ? (
            <Stack spacing={1}>
              <Stack>
                {typeGreedting === 'AI' ? <TextField placeholder="AI text" disabled /> : ''}
              </Stack>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {`${t('Greetings')}`}
              </Typography>
              {localCart.map((item: CartInterface, id: number) => {
                const checked = selectedIds.includes(item.id);
                return (
                  <Box>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      width={1}
                    >
                      <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                        {item?.product?.title}
                      </Typography>
                      <RHFCheckbox
                        name={`personalGreeting${id}`}
                        label={`${t('A personal greeting')}`}
                        checked={checked}
                        onClick={() => togglePersonalGreeting(item.id)}
                      />
                    </Stack>
                    <Grid container spacing={1} my={1}>
                      <Grid item md={6} xs={12}>
                        <TextField fullWidth label={`${t('Cellphone number')}*`} />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField fullWidth label={`${t('For')}*`} />
                      </Grid>
                    </Grid>
                    {selectedIds.includes(item.id) ? (
                      <RHFTextField
                        placeholder={
                          typeGreedting === 'Write'
                            ? 'Write your text'
                            : 'Write request to AI or choose template'
                        }
                        name="description"
                        required
                        multiline
                        rows={3}
                        maxRows={4}
                        value={mailingMessage}
                        onChange={(e) => setMailingMessage(e.target.value)}
                      />
                    ) : (
                      ''
                    )}
                  </Box>
                );
              })}
              {selectedIds.length ? (
                <Typography variant="subtitle2">{`${t('Uniform greetings to all')}`}</Typography>
              ) : (
                ''
              )}
              <RHFTextField
                placeholder={
                  typeGreedting === 'Write'
                    ? 'Write your text'
                    : 'Write request to AI or choose template'
                }
                name="description"
                required
                multiline
                rows={3}
                maxRows={4}
                value={mailingMessage}
                onChange={(e) => setMailingMessage(e.target.value)}
              />
            </Stack>
          ) : (
            ''
          )}
          {!cart && (
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {`${t('Gender')}`}
            </Typography>
          )}
          {!cart && (
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(2, 1fr)',
                sm: 'repeat(4, 1fr)',
              }}
            >
              <RHFCheckbox
                name="male"
                label={`${t('Male')}`}
                checked={isMale}
                onClick={() => toggleIsMale(!isMale)}
              />
              <RHFCheckbox
                name="female"
                label={`${t('Female')}`}
                checked={isFemale}
                onClick={() => toggleIsFemale(!isFemale)}
              />
              <RHFCheckbox
                name="other"
                label={`${t('Other')}`}
                onClick={() => toggleIsOther(!isMale)}
                checked={isOther}
              />
            </Box>
          )}
          {!cart && (
            <Stack spacing={1} direction="row">
              <RHFTextField
                type="number"
                name="ageFrom"
                label={`${t('Age from')}`}
                required
                value={ageFrom}
                onChange={(e) => setAgeFrom(Number(e.target.value))}
              />

              <RHFTextField
                type="number"
                name="ageUpTo"
                label={`${t('Age up to')}`}
                required
                value={ageTo}
                onChange={(e) => setAgeTo(Number(e.target.value))}
              />
            </Stack>
          )}
          {delivary === 'Future' ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack direction="row" spacing={1}>
                <TimePicker<Dayjs>
                  label={`${t('Time')}`}
                  views={['hours', 'minutes']}
                  format="HH:mm"
                  timezone="Asia/Jerusalem"
                  ampm={false}
                  value={time}
                  onChange={(newValue: any) => {
                    if (newValue) {
                      setTimeValue(new Date(newValue.format()));

                      setTime?.(newValue);
                      const formatTime = newValue.format();
                      setData?.((prevData: any) => ({
                        ...prevData,
                        time: formatTime,
                      }));
                    }
                  }}
                  open={openTimePicker}
                  onClose={() => setOpenTimePicker(false)}
                  slotProps={{
                    textField: {
                      onClick: () => setOpenTimePicker(true),
                    },
                  }}
                  sx={{ width: 0.5 }}
                />

                <DatePicker
                  label={`${t('Date')}`}
                  // defaultValue={dayjs('2022-04-17')}
                  value={dateValue}
                  onChange={(newValue) => setDateValue(newValue)}
                  open={openDatePicker}
                  onClose={() => setOpenDatePicker(false)}
                  slotProps={{
                    textField: {
                      onClick: () => setOpenDatePicker(true),
                    },
                  }}
                  sx={{
                    width: 0.5,
                  }}
                />
              </Stack>
            </LocalizationProvider>
          ) : (
            ''
          )}
          <Stack spacing={1}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {`${t('Banner')}`}
            </Typography>

            <Typography sx={{ color: 'text.secondary' }}>
              {`${t('Picture to send by email')}`}
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                '@media (min-width: 300px)  and (max-width: 900px)': {
                  flexDirection: 'column-reverse',
                },
              }}
            >
              {/* <Button variant="contained" sx={{ borderRadius: 20 }}>
                Upload image
              </Button> */}
              <UploadBox
                onDrop={handleDrop}
                placeholder={`${t('Upload image')}`}
                uploadImage
                variant="contained"
                sx={{
                  borderRadius: 20,
                  fontSize: 12,
                  '@media (min-width: 300px)  and (max-width: 900px)': {
                    // marginLeft: '16px',
                    marginTop: '10px',
                  },
                }}
              />
              <Button
                href="#"
                variant="contained"
                sx={{ borderRadius: 20, fontSize: 12 }}
                onClick={() => setOpenTemplates(true)}
              >
                {`${t('Select image from gallery')}`}
              </Button>
            </Stack>

            {/* <ConfirmDialog */}
            {/*  open={isGreetingSendDataModal} */}
            {/*  onClose={() => dispatch(disableToggler(TOGGLER_NAMES.isGreetingSendDataModal))} */}
            {/*  title={`${t('Send')}`} */}
            {/*  content={ */}
            {/*    <Stack spacing={2}> */}
            {/*      <Typography variant="subtitle2" sx={{ color: 'text.primary' }}> */}
            {/*        {`${t('Everything is ready! Do you want to send?')}`} */}
            {/*      </Typography> */}

            {/*      <Typography variant="subtitle2" sx={{ color: 'text.primary' }}> */}
            {/*        {`${t('number of customers: ')}`} {customers.length} */}
            {/*      </Typography> */}

            {/*      <Typography variant="subtitle2" sx={{ color: 'text.primary' }}> */}
            {/*        {`${t('total price: ')}`}{' '} */}
            {/*        {getTotalPriceForMailing() ? `₪ ${getTotalPriceForMailing()}` : 0} */}
            {/*      </Typography> */}

            {/*      <Typography variant="subtitle2" sx={{ color: 'text.primary' }}> */}
            {/*        {`${t('Event name: ')}`} */}
            {/*        /!*{activeMerchant.name}*!/ */}
            {/*      </Typography> */}

            {/*      {budgetError && ( */}
            {/*        <Typography variant="subtitle2" sx={{ color: 'text.error' }}> */}
            {/*          {`${t(budgetError)}`} */}
            {/*        </Typography> */}
            {/*      )} */}
            {/*    </Stack> */}
            {/*  } */}
            {/*  action={ */}
            {/*    <Button disabled={!customers.length} variant="contained" onClick={onSubmit}> */}
            {/*      {`${t('I want to send')}`} */}
            {/*    </Button> */}
            {/*  } */}
            {/* /> */}

            <ConfirmDialog
              open={openCustomersMailingList}
              onClose={handleCloseCustomersMailingList}
              title={`${t('Recipients')}`}
              content={
                <Stack spacing={2}>
                  <Scrollbar>
                    <List>
                      {/* {customerMailing.map((recipient: ICustomer) => (
                        <ListItem
                          key={recipient.id}
                          disableGutters
                          secondaryAction={
                            <IconButton
                              color="primary"
                              onClick={() =>
                                onDeleteRecipient(
                                  recipient.id,
                                  recipient.name,
                                  recipient.group,
                                )
                              }
                            >
                              <Iconify icon="eva:trash-2-outline" />
                            </IconButton>
                          }
                        >
                          <ListItemText primary={`${recipient.name}`} />
                        </ListItem>
                      ))} */}
                      {/* {customers.length && */}
                      {/*  customers.map((recipient: ICustomer) => ( */}
                      {/*    <ListItem */}
                      {/*      key={recipient.id} */}
                      {/*      disableGutters */}
                      {/*      secondaryAction={ */}
                      {/*        <IconButton */}
                      {/*          color="primary" */}
                      {/*          onClick={() => */}
                      {/*            onDeleteRecipient(recipient.id, recipient.name, recipient.group) */}
                      {/*          } */}
                      {/*        > */}
                      {/*          <Iconify icon="eva:trash-2-outline" /> */}
                      {/*        </IconButton> */}
                      {/*      } */}
                      {/*    > */}
                      {/*      <ListItemText */}
                      {/*        primary={`${recipient.firstName} ${recipient.lastName}`} */}
                      {/*      /> */}
                      {/*    </ListItem> */}
                      {/*  ))} */}
                    </List>
                  </Scrollbar>
                </Stack>
              }
              action={<></>}
            />

            <ConfirmDialog
              open={openSuccessfulLetter}
              onClose={() => setOpenSuccessfulLetter(false)}
              title={`${t('The newsletter is launched')}`}
              content={<></>}
              action={<></>}
            />

            <ConfirmDialog
              open={isConfirmSendingModal}
              onClose={() => setIsConfirmSendingModal(false)}
              title={`${t('')}`}
              content={
                <Stack alignItems="center">
                  <Typography mb={5}>Enter code from sms</Typography>
                  <Box mb={5} sx={{ direction: 'rtl' }}>
                    <ReactCodeInput
                      ref={ref}
                      autoFocus
                      fields={6}
                      onComplete={(code) => applyCode(code)}
                      // onChange={() => setError(null)}
                    />

                    <Button
                      fullWidth
                      type="submit"
                      color="primary"
                      variant="contained"
                      sx={{ height: 44, marginTop: 5 }}
                    >
                      {t('Send')}
                    </Button>
                  </Box>
                </Stack>
              }
              action={<></>}
            />
          </Stack>
        </Stack>
      </Card>
    </FormProvider>
  );
}
