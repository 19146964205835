import NextLink from 'next/link';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Image from '../../../../components/image';
import { Ib2bProduct } from '../../../../types/b2b';
import Iconify from '../../../../components/iconify';
import { getContentValueFromProps } from '../../utils';
import { ButtonField } from '../../../../types/generator';
import { BlockType } from '../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';

type Props = {
  product: Ib2bProduct | any;
  block: BlockType | undefined;
};

export const CouponDetailsSummary = ({ product, block }: Props) => {
  const { state, dispatch } = useAppContext();
  const getFieldValue = getContentValueFromProps(block);
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const openModal = (value: boolean) => {
    setModal(value);
  };
  const actionButtons = useMemo(
    () => (getFieldValue('actionButtons') as ButtonField[]) || [],
    [block?.actionButtons]
  );

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  return (
    <Stack spacing={3} sx={{ pt: 3 }}>
      <Stack spacing={2} alignItems="flex-start">
        <Image src={product.brand} />
        <Typography sx={{ fontWeight: 800, fontSize: '25px', color: 'primary.main' }}>
          {product.title}
        </Typography>
        <Typography sx={{ color: 'primary.main', fontSize: '15px' }}>
          {product.description}
        </Typography>
        <Stack direction="row" spacing={2} py={1}>
          {actionButtons.map((actionButton: ButtonField, idx: number) => (
            <Button
              component={actionButton?.link.includes('modal') ? Button : NextLink}
              variant={actionButton?.variant || 'contained'}
              color={actionButton?.color || 'primary'}
              size={actionButton?.size || 'small'}
              key={`actionButton_${idx}`}
              sx={{
                borderRadius: 10,
              }}
              href={
                (!actionButton?.link.includes('modal') &&
                  !actionButton.isDialogToggler &&
                  actionButton?.link) ||
                ''
              }
              onClick={() => {
                if (actionButton.isDialogToggler && actionButton.modalId) {
                  setActiveDialog(actionButton.modalId);
                }
              }}
            >
              {actionButton.label || `[${t('no label')}]`}
            </Button>
          ))}
        </Stack>
      </Stack>
      <Modal
        open={modal}
        onClose={() => setModal(false)}
        sx={{
          '& .MuiBackdrop-root': {
            height: '73vh',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 700,
            bgcolor: 'background.paper',
            borderRadius: '20px',
            boxShadow: 24,
            maxWidth: '700px',
            p: 4,
          }}
        >
          <Stack direction="row" justifyContent="flex-end">
            <Iconify
              icon="iconamoon:close-thin"
              onClick={() => setModal(false)}
              sx={{ cursor: 'pointer' }}
            />
          </Stack>
          <Stack alignItems="center">
            <Typography id="modal-modal-title" variant="h6" component="h2" color="secondary.main">
              RENE סניפים
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Image src="/assets/images/happy-gift/product-infomation-2.png" />
              </Grid>
              <Grid item xs={6}>
                <Image src="/assets/images/happy-gift/product-infomation-1.png" />
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Modal>
    </Stack>
  );
};
