import { FC, useMemo } from 'react';
import { useRouter } from 'next/navigation';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Image from '../../../../components/image';
import { useTranslate } from '../../../../locales';
import { getContentValueFromProps } from '../../utils';
import { ButtonField } from '../../../../types/generator';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';

type CategoryType = {
  value: string;
  title: string;
};

const SearchByCategory: FC<ComponentTypeProps> = ({ block }) => {
  const { t } = useTranslate();
  const router = useRouter();
  const getFieldValue = getContentValueFromProps(block);
  const smUp = useResponsive('up', 'sm');
  const titleText = useMemo(() => getFieldValue('titleText'), [block?.titleText]);

  const logo = useMemo(() => getFieldValue('logo'), [block?.logo]);

  const link = useMemo(() => getFieldValue('link'), [block?.link]);

  const contentTitleTextColor = useMemo(
    () => getFieldValue('settings.contentTitleTextColor'),
    [block?.settings.contentTitleTextColor]
  );
  const contentTitleFontSize = useMemo(
    () => getFieldValue('settings.contentTitleFontSize'),
    [block?.settings.contentTitleFontSize]
  );
  const contentTitleFontWeight = useMemo(
    () => getFieldValue('settings.contentTitleFontWeight'),
    [block?.settings.contentTitleFontWeight]
  );
  const contentTitleFontStyle = useMemo(
    () => getFieldValue('settings.contentTitleFontStyle'),
    [block?.settings.contentTitleFontStyle]
  );
  const contentTitleTextDecoration = useMemo(
    () => getFieldValue('settings.contentTitleTextDecoration'),
    [block?.settings.contentTitleTextDecoration]
  );
  const titleStylesAlignItems = useMemo(
    () => getFieldValue('settings.titleStylesAlignItems'),
    [block?.settings.titleStylesAlignItems]
  );

  const imagesTitleTextColor = useMemo(
    () => getFieldValue('settings.imagesTitleTextColor'),
    [block?.settings.imagesTitleTextColor]
  );
  const imagesTitleFontSize = useMemo(
    () => getFieldValue('settings.imagesTitleFontSize'),
    [block?.settings.imagesTitleFontSize]
  );
  const imagesTitleFontWeight = useMemo(
    () => getFieldValue('settings.imagesTitleFontWeight'),
    [block?.settings.imagesTitleFontWeight]
  );
  const imagesTitleFontStyle = useMemo(
    () => getFieldValue('settings.imagesTitleFontStyle'),
    [block?.settings.imagesTitleFontStyle]
  );
  const imagesTitleTextDecoration = useMemo(
    () => getFieldValue('settings.imagesTitleTextDecoration'),
    [block?.settings.imagesTitleTextDecoration]
  );
  const imagesStylesAlignItems = useMemo(
    () => getFieldValue('settings.imagesStylesAlignItems'),
    [block?.settings.imagesStylesAlignItems]
  );

  const enableTitle = useMemo(
    () => getFieldValue('settings.enableTitle'),
    [block?.settings.enableTitle]
  );

  const enableButtons = useMemo(
    () => getFieldValue('settings.enableButtons'),
    [block?.settings.enableButtons]
  );

  const categoriesImages = useMemo(
    () => getFieldValue('categoriesImages'),
    [block?.categoriesImages]
  );

  const categories = useMemo(() => {
    const categoriesValue = getFieldValue('categories');
    if (Array.isArray(categoriesValue)) {
      return categoriesValue.map((category: CategoryType) => ({
        ...category,
        image: categoriesImages[category.value] ?? '/assets/img-placeholder.svg',
      }));
    }
    return [];
  }, [block?.categories, categoriesImages]);

  const actionButtons = useMemo(
    () => (getFieldValue('actionButtons') as ButtonField[]) || [],
    [block?.actionButtons]
  );

  const column = useMemo(
    () => (getFieldValue('settings.column') as ButtonField[]) || [],
    [block?.settings.column]
  );

  // classes
  const bannerClasses = useMemo(
    () => getFieldValue('settings.bannerClasses'),
    [block?.settings.bannerClasses]
  );

  const contentClasses = useMemo(
    () => getFieldValue('settings.contentClasses'),
    [block?.settings.contentClasses]
  );

  const activeLink = link || 'category';

  return (
    <Box className={bannerClasses}>
      {enableTitle && (
        <Typography
          className={contentClasses}
          sx={{
            color: contentTitleTextColor,
            fontSize: contentTitleFontSize,
            fontWeight: contentTitleFontWeight,
            fontStyle: contentTitleFontStyle,
            textDecoration: contentTitleTextDecoration,
            textAlign: titleStylesAlignItems,
            mb: 2,
            '&::after': { width: !smUp ? '100%!important' : '' },
          }}
        >
          {titleText}
        </Typography>
      )}
      <Stack direction="row" flexWrap="wrap" px={smUp ? 20 : 0} justifyContent="center">
        <Grid container spacing={1} justifyContent="center">
          {categories.map((item: CategoryType & { image?: string }, ind: number) => (
            <Grid
              key={ind}
              item
              md={+column === 4 ? 3 : 4}
              xs={+column === 4 ? 3 : 4}
              sx={{ position: 'relative', cursor: 'pointer' }}
              onClick={() => router.push(`${activeLink}/${item.value}`)}
            >
              <Image src={item.image} sx={{ borderRadius: 1.25 }} />
              <Box
                component={Stack}
                alignItems={imagesStylesAlignItems}
                sx={{
                  borderTopRightRadius: 1.25,
                  borderTopLefttRadius: 1.25,
                  bgcolor: 'rgba(255, 255, 255, 0.7)',
                  position: 'absolute',
                  top: '0px',
                  width: '100%',
                  height: '45%',
                  pt: '10px',
                  px: 2,
                }}
              >
                <Image src={logo} sx={{ width: '50%', '& img': { objectFit: 'contain' } }} />
                <Typography
                  sx={{
                    color: imagesTitleTextColor,
                    fontSize: imagesTitleFontSize,
                    fontStyle: imagesTitleFontStyle,
                    fontWeight: imagesTitleFontWeight,
                    textDecoration: imagesTitleTextDecoration,
                    textAlign: imagesStylesAlignItems,
                  }}
                >
                  {item.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        {enableButtons && (
          <Stack direction="row" spacing={2} py={1}>
            {actionButtons.map((actionButton: ButtonField, idx: number) => (
              // @ts-ignore - проблемы с совместимостью типов, не кртичино
              <Button
                variant={actionButton.variant}
                color={actionButton.color}
                size={actionButton.size}
                key={`actionButton_${idx}`}
                sx={{ borderRadius: 10, fontWeight: 400, height: !smUp ? '40px' : '50px' }}
              >
                {actionButton.label || `[${t('no label')}]`}
              </Button>
            ))}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
export default SearchByCategory;
