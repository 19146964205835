import { FC, useMemo } from 'react';

import { Box, Stack, Button, Container } from '@mui/material';

import { useAuthContext } from 'src/auth/hooks';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';

import Iconify from 'src/components/iconify';

import { ButtonField } from 'src/types/generator';
import { ComponentTypeProps } from 'src/types/page-generator';

import { getContentValueFromProps } from '../../utils';
import { PROJECT_NAME } from '../../../../config-global';

const OneBigButton: FC<ComponentTypeProps> = ({ block }) => {
  const { dispatch } = useAppContext();
  const { isAuthenticated, logout } = useAuthContext();
  const getFieldValue = getContentValueFromProps(block);
  const isCityPeople = useMemo(() => PROJECT_NAME.toLowerCase() === 'citypeople', []);

  const [actionButton] = useMemo(
    () => (getFieldValue('actionButtons') as ButtonField[]) || [],
    [block?.actionButtons]
  );

  const bannerClasses = useMemo(
    () => getFieldValue('settings.bannerClasses'),
    [block?.settings.bannerClasses]
  );

  const buttonColor = useMemo(
    () => getFieldValue('settings.buttonColor') ?? 'secondary',
    [block?.settings.buttonColor]
  );

  const buttonWidth = useMemo(
    () => getFieldValue('settings.buttonWidth') ?? '70%',
    [block?.settings.buttonWidth]
  );

  const buttonMinWidth = useMemo(
    () => getFieldValue('settings.buttonMinWidth') ?? '100',
    [block?.settings.buttonMinWidth]
  );

  const buttonHoverColor = useMemo(
    () => getFieldValue('settings.buttonHoverColor') ?? 'secondary.light',
    [block?.settings.buttonHoverColor]
  );

  const withArrowIcon = useMemo(
    () => getFieldValue('settings.withArrowIcon'),
    [block?.settings.withArrowIcon]
  );

  const isAuthButton = useMemo(
    () => getFieldValue('settings.isAuthButton'),
    [block?.settings.isAuthButton]
  );

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  // TODO: change later to simple class
  const mb =
    bannerClasses && bannerClasses.includes('marginBottom')
      ? `${bannerClasses.split('marginBottom-')[1]}px`
      : 0;

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={2}
        className={bannerClasses}
        sx={{ mb }}
      >
        <Stack direction="column" width="100%" alignItems="center" spacing={2} py={1}>
          {isAuthButton ? (
            <>
              {!isAuthenticated ? (
                // @ts-ignore - проблемы с совместимостью типов, не критично
                <Button
                  variant={actionButton.variant || 'contained'}
                  color={buttonColor}
                  size={actionButton.size || 'large'}
                  endIcon={
                    withArrowIcon ? <Iconify icon="material-symbols-light:arrow-back-ios" /> : false
                  }
                  sx={{
                    borderRadius: 10,
                    fontWeight: 400,
                    m: '0 auto',
                    width: buttonWidth,
                    minWidth: `${buttonMinWidth}px`,
                    ...(buttonHoverColor && {
                      '&:hover': { backgroundColor: buttonHoverColor },
                    }),
                  }}
                  href={
                    (!actionButton?.link.includes('modal') &&
                      !actionButton.isDialogToggler &&
                      actionButton?.link) ||
                    ''
                  }
                  onClick={() => {
                    if (actionButton.isDialogToggler && actionButton.modalId) {
                      setActiveDialog(actionButton.modalId);
                    }
                  }}
                >
                  {actionButton.label || '[no label]'}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color={buttonColor}
                  size={actionButton.size || 'large'}
                  sx={{ borderRadius: 10, fontWeight: 400, width: buttonWidth }}
                  onClick={() => {
                    logout();
                    dispatch({
                      type: ActionTypes.GET_APARTMENTS,
                      payload: { apartments: [], count: 0 },
                    });
                    dispatch({ type: ActionTypes.SET_SELECTED_APARTMENT, payload: null });
                    dispatch({ type: ActionTypes.SET_SELECTED_MERCHANT, payload: null });
                  }}
                >
                  {isCityPeople ? 'להתנתק' : 'LOG OUT'}
                </Button>
              )}
            </>
          ) : (
            <Button
              variant={actionButton.variant || 'contained'}
              color={buttonColor}
              size={actionButton.size || 'large'}
              endIcon={
                withArrowIcon ? <Iconify icon="material-symbols-light:arrow-back-ios" /> : false
              }
              sx={{
                borderRadius: 10,
                fontWeight: 400,
                m: '0 auto',
                width: buttonWidth,
                minWidth: `${buttonMinWidth}px`,
                ...(buttonHoverColor && {
                  '&:hover': { backgroundColor: buttonHoverColor },
                }),
              }}
              href={
                (!actionButton?.link.includes('modal') &&
                  !actionButton.isDialogToggler &&
                  actionButton?.link) ||
                ''
              }
              onClick={() => {
                if (actionButton.isDialogToggler && actionButton.modalId) {
                  setActiveDialog(actionButton.modalId);
                }
              }}
            >
              {actionButton.label || '[no label]'}
            </Button>
          )}
        </Stack>
      </Box>
    </Container>
  );
};

export default OneBigButton;
