import { FC, useMemo } from 'react';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Box, Stack, Button, Typography } from '@mui/material';

import Iconify from '../../../../components/iconify';
import { getContentValueFromProps } from '../../utils';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { useResponsive } from '../../../../hooks/use-responsive';

const Search: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const isMobile = useResponsive('down', 'sm');
  const mainTitle = useMemo(() => getFieldValue('mainTitle'), [block?.mainTitle]);

  // Button style
  const buttonLabel = useMemo(() => getFieldValue('buttonLabel'), [block?.buttonLabel]);
  const buttonIcon = useMemo(() => getFieldValue('buttonIcon'), [block?.buttonIcon]);
  const buttonSize = useMemo(() => getFieldValue('buttonSize'), [block?.buttonSize]);
  const buttonBgColor = useMemo(() => getFieldValue('buttonBgColor'), [block?.buttonBgColor]);
  const buttonColor = useMemo(() => getFieldValue('buttonColor'), [block?.buttonColor]);
  const buttonBorderRadius = useMemo(
    () => getFieldValue('buttonBorderRadius'),
    [block?.buttonBorderRadius]
  );

  const {
    forceRtl,
    headingTitleFontSize,
    headingTitleFontStyle,
    headingTitleFontWeight,
    headingTitleTextDecoration,
    headingTextAlignItems,
    firstGradientColorStop,
    secondGradientColorStop,
    thirdGradientColorStop,
    firstGradientColor,
    secondGradientColor,
    thirdGradientColor,
    addGradientStyle,
    headingTitleTextColor,
    headingAlign,
  } = block?.settings || {};

  const firstStop = firstGradientColorStop ? `${firstGradientColorStop}%` : '40%';
  const secondStop = secondGradientColorStop ? `${secondGradientColorStop}%` : '80%';
  const thirdStop = secondGradientColorStop ? `${thirdGradientColorStop}%` : '100%';

  const colorTitle = addGradientStyle
    ? {
        background: `linear-gradient(90deg, ${firstGradientColor} ${firstStop}, ${secondGradientColor} ${secondStop}, ${thirdGradientColor} ${thirdStop} )`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
      }
    : { color: headingTitleTextColor };

  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '0px',
        backgroundSize: 'cover',
        padding: 5,
      }}
    >
      {block?.settings?.enableTitle && (
        <Typography
          sx={{
            textAlign: headingAlign,
            fontSize: isMobile ? '30px' : headingTitleFontSize,
            fontWeight: headingTitleFontWeight,
            ...colorTitle,
          }}
        >
          {mainTitle}
        </Typography>
      )}
      <Stack
        direction={!isMobile ? 'row-reverse' : 'column-reverse'}
        gap={2}
        alignItems="center"
        mt={5}
      >
        <Stack
          direction="row-reverse"
          alignItems="center"
          spacing={1}
          width={isMobile ? '100%' : '20%'}
        >
          {block?.settings?.enableTrash && (
            <Box>
              <Iconify icon="solar:trash-bin-trash-bold" color="red" sx={{ cursor: 'pointer' }} />
            </Box>
          )}
          <Button
            variant="contained"
            fullWidth
            size={buttonSize}
            sx={{
              borderRadius: buttonBorderRadius,
              background: buttonBgColor,
              color: buttonColor,
              width: '100%',
              '&:hover': {
                background: buttonBgColor,
                color: buttonColor,
              },
            }}
            endIcon={<Iconify icon={buttonIcon} />}
          >
            {buttonLabel}
          </Button>
        </Stack>
        <TextField
          placeholder="חיפוש..."
          sx={{
            color: '#C684FF',
            width: '100%',
            borderRadius: '10px',
            border: '1px solid #C684FF',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: '#C684FF' }} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Box>
  );
};

export default Search;
