import { FC } from 'react';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import SanitizeHTML from 'src/utils/sanitize';

import Image from 'src/components/image';

import LineIcon from '../../../../assets/icons/happy-gift/lineIcon';
import { ComponentTypeProps } from '../../../../types/page-generator';

// =====================================================================

const ShopBanner5: FC<ComponentTypeProps> = ({ block }) => {
  const images = [
    {
      id: 1,
      img: '/assets/images/happy-gift/banners/group/product3.png',
    },
    {
      id: 2,
      img: '/assets/images/happy-gift/banners/group/product2.png',
    },
    {
      id: 3,
      img: '/assets/images/happy-gift/banners/group/product1.png',
    },
  ];

  const title = 'הנמכרים ביותר';
  const contentText = 'ההטבות הכי נמכרים';

  return (
    <Card
      sx={{
        width: '100%',
        borderRadius: '20px',
        border: '1px solid #27097A',
        backgroundSize: 'cover',
        padding: '35px 24px 24px 24px',
      }}
    >
      <Stack alignItems="center">
        <Typography
          sx={{
            fontWeight: 600,
            color: '#27097A',
            fontSize: '36px',
            textAlign: 'start',
            // mb: 2,
          }}
        >
          {title}
        </Typography>
        <LineIcon color="#27097A" width="40%" />
        <Typography
          sx={{
            direction: 'rtl',
            color: '#27097A',
            fontSize: '20px',
          }}
        >
          <SanitizeHTML html={contentText} />
        </Typography>
      </Stack>
      <Grid
        container
        spacing={10}
        flexDirection="row-reverse"
        alignItems="center"
        justifyContent="space-between"
        px={2}
        py={2}
      >
        {images.map((item, id) => (
          <Grid item xs={4} alignItems="center" key={id}>
            <Image src={item.img} sx={{ width: '100%' }} />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};
export default ShopBanner5;
