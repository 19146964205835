import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import { useTranslate } from 'src/locales';

import Image from '../../../../../../components/image';
import Filterv2 from '../../../../../products/filterv2';
import Iconify from '../../../../../../components/iconify';
import { BlockType } from '../../../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../../../contexts/AppContext';

export default function Place({ block }: { block: BlockType }) {
  const { t } = useTranslate();
  const { dispatch } = useAppContext();

  const { settings } = block;
  const { showFilter } = settings;

  const onClose = () => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: null });
  };

  return (
    <>
      <Stack direction="row" justifyContent="flex-end">
        <Iconify icon="iconamoon:close-thin" onClick={() => onClose()} sx={{ cursor: 'pointer' }} />
      </Stack>
      {showFilter && <Filterv2 />}
      <Stack alignItems="center">
        <Typography id="modal-modal-title" variant="h6" component="h2" color="primary.main">
          RENE סניפים
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Image src="/assets/images/happy-gift/product-infomation-2.png" />
          </Grid>
          <Grid item xs={6}>
            <Image src="/assets/images/happy-gift/product-infomation-1.png" />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
