import { useState } from 'react';

import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import { Box, Stack, TextField, Typography } from '@mui/material';

import { useTranslate } from '../../../../../../locales';
import { ButtonField } from '../../../../../../types/generator';
import { ComponentTypeProps } from '../../../../../../types/page-generator';

export default function MatchMe({ block }: ComponentTypeProps) {
  const { t } = useTranslate();
  const actionButtons: any = block?.actionButtons || [];
  const [activeType, setActiveType] = useState('');
  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 20,
      label: '20',
    },
    {
      value: 30,
      label: '30',
    },
    {
      value: 40,
      label: '40',
    },
    {
      value: 50,
      label: '50',
    },
    {
      value: 60,
      label: '60',
    },
    {
      value: 70,
      label: '70',
    },
    {
      value: 80,
      label: '80',
    },
    {
      value: 90,
      label: '90',
    },
    {
      value: 100,
      label: '100',
    },
  ];
  return (
    <Stack alignItems="center" gap={1} sx={{ direction: 'rtl' }}>
      <Typography variant="h3">Choose parameters</Typography>

      <Stack direction="row" gap={1}>
        <Box
          onClick={() => setActiveType('Buy')}
          sx={{
            cursor: 'pointer',
            color: 'green',
            padding: 1,
            border: '1px solid green',
            borderRadius: '5px',
            fontSize: '20px',
            backgroundColor: activeType === 'Buy' ? '#C8ED98' : '',
          }}
        >
          Buy
        </Box>
        <Box
          onClick={() => setActiveType('Sell')}
          sx={{
            cursor: 'pointer',
            color: 'red',
            padding: 1,
            border: '1px solid red',
            borderRadius: '5px',
            fontSize: '20px',
            backgroundColor: activeType === 'Sell' ? '#FFCCCC' : '',
          }}
        >
          Sell
        </Box>
      </Stack>
      <Stack direction="row" width={1} gap={1} alignItems="center">
        <Typography sx={{ width: 0.5 }}>Currency type:</Typography>
        <TextField select label="Currency" sx={{ width: 0.5 }}>
          {['$', '€', '₪', '₴', '₽'].map((card, id) => (
            <option key={id} value={card}>
              {card}
            </option>
          ))}
        </TextField>
      </Stack>
      <Stack direction="row" width={1} gap={1} alignItems="center">
        <Typography sx={{ width: 0.5 }}>Amount:</Typography>
        <TextField sx={{ width: 0.5 }} />
      </Stack>
      <Stack direction="row" width={1} gap={1} alignItems="center">
        <Typography sx={{ width: 0.5 }}>Distance:</Typography>
        <Slider
          aria-label="Custom marks"
          defaultValue={20}
          step={10}
          valueLabelDisplay="auto"
          marks={marks}
        />
      </Stack>
      <Stack
        direction="row"
        width="100%"
        spacing={2}
        py={1}
        alignItems="center"
        justifyContent="center"
      >
        {actionButtons.map((actionButton: ButtonField, idx: number) => (
          // @ts-ignore - проблемы с совместимостью типов, не критично
          <Button
            type={actionButton.label !== 'cancel' && actionButton.label !== 'ביטול' && 'submit'}
            variant={actionButton.variant}
            color={actionButton.color}
            size={actionButton.size}
            key={`actionButton_${idx}`}
            sx={{ borderRadius: 0, fontWeight: 400, width: '50%' }}
            href={
              (!actionButton?.link.includes('modal') &&
                !actionButton.isDialogToggler &&
                actionButton?.link) ||
              ''
            }
            onClick={() => {}}
          >
            {actionButton.label || `[${t('no label')}]`}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
}
