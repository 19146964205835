import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Paper, Stack, TextField } from '@mui/material';

import { useTranslate } from 'src/locales';

import Image from '../../../../../../components/image';
import { BlockType } from '../../../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../../../contexts/AppContext';

export default function HappyHr({ block }: { block: BlockType }) {
  const { t } = useTranslate();
  const { dispatch } = useAppContext();

  const { settings } = block;
  const { showPhoneNumber } = settings;

  const NewSchema = Yup.object().shape({
    type: Yup.string(),
  });

  const defaultValues = {
    type: 'Voucher',
  };

  const methods = useForm({
    resolver: yupResolver(NewSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {});

  const onClose = () => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: null });
  };

  return (
    <Paper component={Stack} direction="column" spacing={2}>
      <Stack alignItems="center" gap={2}>
        <Image src="/assets/images/happy-gift/logo.png" />
        <Image src="/assets/images/happy-gift/banners/modal_banner_happy_hr.png" />
        <Typography sx={{ fontSize: '24px', fontWeight: 600, color: 'primary.main' }}>
          מלאו את הפרטים ונשמח לחזור אליכם בהקדם:
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField fullWidth label="*שם" />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="שם משפחה" />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="*מספר הטלפון" />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="*מייל" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="הודעה (במידה ותרצו להגיד לנו משהו)" />
          </Grid>
        </Grid>
        <Stack direction="row-reverse" gap={2}>
          <Button variant="outlined" onClick={() => onClose()}>
            ביטול
          </Button>
          <Button variant="contained" color="secondary">
            לשלוח טופס
          </Button>
        </Stack>
        <Typography sx={{ fontSize: '20px', fontWeight: 400, color: 'primary.main' }}>
          אנחנו כאן לשירותכם גם בטלפון 03-5106070
        </Typography>
      </Stack>
    </Paper>
  );
}
