import useSWR from 'swr';
import { useMemo } from 'react';

import { fetcher, endpoints } from 'src/utils/axios';

import { IProductItem } from 'src/types/product';

import { getDataFromLocaleStorage } from '../helpers/localStorage';
import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys';
import { API } from '../helpers/api';
import { BOT_ID } from '../config-global';
import { ActionType, ActionTypes } from '../contexts/AppContext';

type GetCategoriesProps = {
  limit?: number;
  offset?: number;
  categoryIds?: string[];
  dispatch: React.Dispatch<ActionType>;
  search?: string;
  productIds?: string[] | string;
  tagsIds?: string | string[];
};

export function useGetActivitiesByMerchant() {
  const { account } = getDataFromLocaleStorage(LOCAL_STORAGE_KEYS.subdomain);
  const URL = [endpoints.merchant.activities, { params: { merchantId: account.merchant.id } }];

  const { data } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      activity: data?.payload && data?.payload[0],
    }),
    [data?.payload]
  );

  return memoizedValue;
}

export function useGetBuilderSettings(activity: any) {
  const URL = [endpoints.merchant.byId, { params: { activityId: activity?.id } }];

  const { data } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      items: (data as IProductItem[]) || [],
    }),
    [data]
  );

  return memoizedValue;
}

export function useGetMerchants(activity: any) {
  const URL = [endpoints.merchant.all, { params: { activityId: activity?.id } }];

  const { data } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      items: (data as IProductItem[]) || [],
    }),
    [data]
  );

  return memoizedValue;
}

export const getMerchant = async (
  id: string | string[] | number,
  dispatch: React.Dispatch<ActionType>
) => {
  try {
    const relations = BOT_ID === '502' ? ['network'] : ['network', 'asterisks'];
    const { data } = await API({
      url: `/merchant/?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        id,
        relations,
      },
    });
    dispatch({
      type: ActionTypes.GET_MERCHANT,
      payload: { merchant: data.payload },
    });
    return data.payload;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getMerchants = async ({
  limit,
  offset,
  dispatch,
  search,
  tagsIds,
  categoryIds,
}: GetCategoriesProps) => {
  try {
    dispatch({ type: ActionTypes.LOADING_SEARCH_PRODUCTS, payload: true });
    const { data } = await API({
      url: `/merchant/all?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        relations: ['smbAccount', 'network', 'asterisks'],
        limit,
        offset,
        search,
        tagsIds,
        categoriesIds: categoryIds,
      },
    });
    // console.log('data', data);
    dispatch({
      type: ActionTypes.GET_MERCHANTS,
      payload: { merchants: data.payload, count: data.count },
    });
    // dispatch({ type: ActionTypes.LOADING_SEARCH_PRODUCTS, payload: true });
  } catch (e) {
    console.error(e);
  }
};

export const getMerchantsTags = async ({ limit, offset, dispatch }: GetCategoriesProps) => {
  try {
    const { data } = await API({
      url: `/merchant/getTags?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        limit,
        offset,
      },
    });
    dispatch({
      type: ActionTypes.GET_MERCHANTS_TAGS,
      payload: { merchantsTags: data.payload },
    });
    return data.payload;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const getMerchantsCategories = async ({ limit, offset, dispatch }: GetCategoriesProps) => {
  try {
    const { data } = await API({
      url: `/product-category/getAll?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        limit,
        offset,
      },
    });
    dispatch({
      type: ActionTypes.GET_MERCHANTS_CATEGORIES,
      payload: { merchantsCategories: data.payload },
    });
    return data.payload;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const getMerchantsByProductIds = async ({
  limit,
  offset,
  dispatch,
  search,
  productIds,
  categoryIds,
}: GetCategoriesProps) => {
  try {
    const { data } = await API({
      url: `/merchant/getMerchantsByProduct?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        relations: ['network', 'asterisks'],
        active: true,
        limit,
        offset,
        search,
        productIds,
        categoryIds,
      },
    });
    // console.log('data', data);
    dispatch({
      type: ActionTypes.GET_MERCHANTS,
      payload: { merchants: data.payload, count: data.count },
    });
  } catch (e) {
    console.error(e);
  }
};

export const getMerchantsByProductIdsForReverse = async ({
  limit,
  offset,
  dispatch,
  search,
  productIds,
}: GetCategoriesProps) => {
  try {
    const { data } = await API({
      url: `/merchant/getMerchantsByProduct?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        // relations: ['smbAccount', 'network'],
        active: true,
        limit,
        offset,
        search,
        productIds,
      },
    });
    return data.payload;
  } catch (e) {
    console.error(e);
    return null;
  }
};

// export const getMerchantsByProductId = async ({
//   limit,
//   offset,
//   dispatch,
//   search,
// }: GetCategoriesProps) => {
//   try {
//     const { data } = await API({
//       url: `/merchant/getMerchantByProductId?botId=${BOT_ID}`,
//       method: 'GET',
//       params: {
//         // relations: ['smbAccount'],
//         // limit,
//         // offset,
//         // search,
//       },
//     });
//     console.log('data', data);
//
//     dispatch({
//       type: ActionTypes.GET_MERCHANTS,
//       payload: { merchants: data.payload, count: data.count },
//     });
//   } catch (e) {
//     console.error(e);
//   }
// };
