import { FC } from 'react';

import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';

import { CustomSlider } from './custom-slider';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import BigZipLineIcon from '../../../../assets/icons/happy-gift/bigZipLine';

export const SpecialCategoriesHappyHolders: FC<ComponentTypeProps> = ({ block }) => {
  const theme = useTheme();
  const smDown = useResponsive('down', 'sm');

  return (
    <Stack alignItems="center" my={4} width={1}>
      <Typography sx={{ color: '#3A0D5E', fontWeight: 800, fontSize: '35px' }}>
        הקטגוריות הפופולריות ביותר
      </Typography>
      <BigZipLineIcon width={smDown ? '100%' : '45%'} />
      <CustomSlider block={block} />
    </Stack>
  );
};
