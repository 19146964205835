import { FC, Key } from 'react';
import Slider from 'react-slick';

import Box from '@mui/material/Box';

import ShopBanner from './shop-banner';
import ShopBanner2 from './shop-banner-2';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import ThreeColumnsInfoWithIcons from './three-column-info-with-icons';

const AllShopBanners: FC<ComponentTypeProps> = ({ block }) => {
  const smDown = useResponsive('down', 'sm');
  const title = 'קטגוריות פופולריות';
  const cards = [
    {
      id: 1,
      component: <ShopBanner block={block} />,
    },
    {
      id: 2,
      component: <ShopBanner2 block={block} />,
    },
    {
      id: 3,
      component: <ThreeColumnsInfoWithIcons block={block} />,
    },
  ];

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Box
      sx={{
        mt: 4,
        '& .slick-slide': {
          height: smDown ? '600px' : '450px',
          direction: 'ltr',
          display: 'flex',
          alignItems: 'flex-end',
        },
        '& .slick-slider': {
          '& .slick-dots': {
            position: 'absolute',
            bottom: '50px',
            right: smDown ? '30%' : '43%',
            width: '150px',
            '& .slick-active': {
              '& button': {
                '&::before': {
                  color: '#27097A !important',
                },
              },
            },
            '& li': {
              '& button': {
                width: '20%',
                '&::before': {
                  color: '#27097A !important',
                },
              },
            },
          },
        },
      }}
    >
      <Slider {...settings}>
        {cards.map((item: any, index: Key) => (
          <Box key={index}>{item.component}</Box>
        ))}
      </Slider>
    </Box>
  );
};

export default AllShopBanners;
