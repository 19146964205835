import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Chip, Stack, Button } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Iconify from '../../../components/iconify';
import FormProvider, { RHFSelect, RHFAutocomplete } from '../../../components/hook-form';
import Image from '../../../components/image';
import { BlockType } from '../../../types/page-generator';

type Props = {
  onClose: Function;
  block: BlockType | undefined;
};

export const DialogCustom = ({ onClose, block }: Props) => {
  const [currentModal, setCurrentModal] = useState(1);

  const enableNewTransfer = block?.settings?.enableNewTransfer;
  const enableContact = block?.settings?.enableContact;
  const enableTransferType = block?.settings?.enableTransferType;
  const enablePhoneNumber = block?.settings?.enablePhoneNumber;
  const enableTransferAmount = block?.settings?.enableTransferAmount;
  const enableCardName = block?.settings?.enableCardName;

  const CommentSchema = Yup.object().shape({
    comment: Yup.string().required('Comment is required'),
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  });

  const defaultValues = {
    comment: '',
    name: '',
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      console.info('DATA', data);
    } catch (error) {
      console.error(error);
    }
  });
  return (
    <>
      {currentModal === 1 && (
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <DialogTitle maxWidth="100%">
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ fontWeight: 800 }}>העברה חדשה</Typography>
              <Typography sx={{ fontWeight: 800 }}>account`s name </Typography>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack gap={1}>
              {enableNewTransfer && (
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ fontWeight: 800 }}>למי?</Typography>
                  <Stack direction="row">
                    <Typography sx={{ fontWeight: 800 }}> העברה חדשה</Typography>
                    <Iconify icon="charm:plus" />
                  </Stack>
                </Stack>
              )}
              {enableContact && (
                <RHFAutocomplete
                  name="contact"
                  label="בחר איש קשר"
                  options={['איגור גוברמן', '2איגור גוברמן']}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                  multiple
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        sx={{ borderRadius: '20px' }}
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                />
              )}
              {enableTransferType && (
                <RHFSelect name="status" label="בחר סוג העברה">
                  {['העברת כספים', 'העברת נקודות', 'העברת קופונים והטבות'].map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </RHFSelect>
              )}
              {enablePhoneNumber && (
                <TextField fullWidth value="" onChange={() => {}} placeholder="הקלד מספר הטלפון " />
              )}
              {enableTransferAmount && (
                <TextField fullWidth value="" onChange={() => {}} placeholder="₪ סכום להעברה" />
              )}
              {enableCardName && (
                <RHFSelect name="status" label="בחר שם הכרטיס">
                  {[' B24online'].map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </RHFSelect>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" justifyContent="flex-end" gap={1} width={1}>
              <Button
                variant="contained"
                onClick={() => setCurrentModal(2)}
                sx={{ color: 'white', bgcolor: 'black' }}
              >
                להעביר
              </Button>
              <Button
                variant="outlined"
                onClick={() => onClose()}
                sx={{
                  color: 'black',
                  borderColor: 'grey',
                }}
              >
                ביטול
              </Button>
            </Stack>
          </DialogActions>
        </FormProvider>
      )}
      {currentModal === 2 && (
        <Stack>
          <DialogTitle maxWidth="100%">
            <Typography color="red" sx={{ textAlign: 'center' }}>
              אנא לוודא שהמידע הבא נכון
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ mb: 2 }}
              alignItems="center"
            >
              <Typography sx={{ fontWeight: 800 }}>העברה חדשה ל-</Typography>
              <Typography sx={{ fontWeight: 800 }}>account`s name </Typography>
            </Stack>
            <Grid container spacing={1}>
              {enableCardName && (
                <>
                  <Grid item xs={6}>
                    {' '}
                    <Typography>איש קשר</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: 800 }}>איגור גוברמן</Typography>
                  </Grid>
                </>
              )}
              {enableCardName && (
                <>
                  <Grid item xs={6}>
                    {' '}
                    <Typography>סוג העברה</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: 800 }}>העברת כספים</Typography>
                  </Grid>
                </>
              )}
              {enableCardName && (
                <>
                  <Grid item xs={6}>
                    <Typography>למספר הטלפון</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: 800 }}>052 699 3881</Typography>
                  </Grid>
                </>
              )}
              {enableCardName && (
                <>
                  <Grid item xs={6}>
                    <Typography>סכום להעברה</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: 800 }}>₪500</Typography>
                  </Grid>
                </>
              )}
              {enableCardName && (
                <>
                  <Grid item xs={6}>
                    <Typography>העברה מהכרטיס</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: 800 }}>B24online</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" justifyContent="space-between" gap={1} width={1}>
              <Button
                variant="contained"
                onClick={() => setCurrentModal(3)}
                sx={{ color: 'white', bgcolor: 'black', fontSize: '12px' }}
              >
                אישור ההעברה
              </Button>
              <Button
                variant="outlined"
                onClick={() => setCurrentModal(1)}
                sx={{
                  color: 'black',
                  borderColor: 'grey',
                  fontSize: '12px',
                }}
              >
                חזרה להזנת נתונים
              </Button>
            </Stack>
          </DialogActions>
        </Stack>
      )}
      {currentModal === 3 && (
        <Stack>
          <DialogTitle maxWidth="100%">
            <Typography variant="h4" sx={{ textAlign: 'center' }}>
              ההעברה בוצעה בהצלחה!
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Stack direction="row" justifyContent="center">
              <Image src="/assets/illustrations/illustration_order_complete.png" />
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography>תודה על ביצוע ההעברה:</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ my: 3 }}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  {' '}
                  <Typography>מספר ההעברה</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: 800 }}>2222-333-555</Typography>
                </Grid>
              </Grid>
            </Stack>
            <Grid container spacing={1}>
              {enableCardName && (
                <>
                  <Grid item xs={6}>
                    {' '}
                    <Typography>איש קשר</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: 800 }}>איגור גוברמן</Typography>
                  </Grid>
                </>
              )}
              {enableCardName && (
                <>
                  <Grid item xs={6}>
                    {' '}
                    <Typography>סוג העברה</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: 800 }}>העברת כספים</Typography>
                  </Grid>
                </>
              )}
              {enableCardName && (
                <>
                  <Grid item xs={6}>
                    <Typography>למספר הטלפון</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: 800 }}>052 699 3881</Typography>
                  </Grid>
                </>
              )}
              {enableCardName && (
                <>
                  <Grid item xs={6}>
                    <Typography>סכום להעברה</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: 800 }}>₪500</Typography>
                  </Grid>
                </>
              )}
              {enableCardName && (
                <>
                  <Grid item xs={6}>
                    <Typography>העברה מהכרטיס</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: 800 }}>B24online</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" justifyContent="space-between" gap={1} width={1}>
              <Button
                variant="contained"
                onClick={() => onClose()}
                sx={{ color: 'white', bgcolor: 'black', fontSize: '12px' }}
              >
                תודה, אפשר לסגור
              </Button>
              <Button
                variant="outlined"
                onClick={() => setCurrentModal(1)}
                sx={{
                  color: 'black',
                  borderColor: 'grey',
                  fontSize: '12px',
                }}
              >
                להעביר עוד
              </Button>
            </Stack>
          </DialogActions>
        </Stack>
      )}
    </>
  );
};
