'use client';

import NextLink from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';
import { FC, useRef, useState, useEffect } from 'react';

import Skeleton from '@mui/material/Skeleton';
import { Box, Grid, Stack, Button, Typography } from '@mui/material';

import Image from '../../../../components/image';
import { useTranslate } from '../../../../locales';
import { ButtonField } from '../../../../types/generator';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';

export const HeadActiveBanner: FC<ComponentTypeProps> = ({ block }) => {
  const { t } = useTranslate();
  const swiperRef = useRef<SwiperCore | null>(null);
  const { state, dispatch } = useAppContext();
  const mdUp = useResponsive('up', 'md');
  const smUp = useResponsive('up', 'sm');
  const isMobile = useResponsive('down', 'sm');
  const title: any = block?.mainTitle || '';
  const subTitle: any = block?.subTitle || '';
  const actionButtons = block?.actionButtons || [];
  const combinedSlider: any = block?.combinedSlider || [];
  const [isLoaded, setIsLoaded] = useState(false);

  const img: any = block?.image || '';
  const lineStyle = block?.settings?.enableLine
    ? {
        borderBottom: `${block?.settings?.lineSize} solid`,
        borderColor: block?.settings?.lineColor,
      }
    : {};

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      {isLoaded ? (
        <Box
          sx={{
            height: 1,
            ...lineStyle,
            display: isMobile && block?.settings?.disableOnMobile ? 'none' : 'block',
          }}
        >
          {block?.settings?.enableSlider ? (
            <Swiper
              speed={500}
              slidesPerView="auto"
              autoplay
              loop
              mousewheel={{
                forceToAxis: true,
                sensitivity: 1,
                releaseOnEdges: true,
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
            >
              {combinedSlider.map((item: any, ind: number) => (
                <SwiperSlide key={ind}>
                  <Grid
                    container
                    sx={{
                      height: 1,
                    }}
                  >
                    <Grid item md={6} xs={12} height={1}>
                      <Image
                        src={item?.image}
                        sx={{
                          transform: item.disableMirrorReflection ? '' : 'scaleX(-1)',
                          '& img': { objectFit: 'contain' },
                        }}
                        width={isMobile ? '90vw' : '100%'}
                        height={isMobile ? '30vh' : '20vw'}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Stack
                        alignItems="center"
                        gap={2}
                        height={1}
                        justifyContent={item?.verticalPosition}
                      >
                        <Typography
                          sx={{
                            mt: 1,
                            textAlign: item.titleAlign,
                            width: item.titleWidth,
                            color: item.titleTextColor,
                            fontSize: { md: item.titleFontSize, xs: '25px' },
                            fontWeight: item.titleFontWeight,
                            fontStyle: item.titleFontStyle,
                            textDecoration: item.titleTextDecoration,
                            lineHeight: 1,
                          }}
                        >
                          {item.mainTitle}
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: item.subtitleAlign,
                            width: item.subTitleWidth,
                            color: item.subTitleTextColor,
                            fontSize: { md: item.subTitleFontSize, xs: '15px' },
                            fontWeight: item.subTitleFontWeight,
                            fontStyle: item.subTitleFontStyle,
                            textDecoration: item.subTitleTextDecoration,
                          }}
                        >
                          {item.subTitle}
                        </Typography>
                        <Stack
                          alignItems="center"
                          justifyContent={item?.buttonsAlign}
                          spacing={1}
                          direction="row"
                          width={1}
                          sx={{ mb: { md: 0, xs: 1 } }}
                        >
                          {item.actionButtons.map((menuButton: ButtonField, idx: number) => (
                            // @ts-ignore - проблемы с совместимостью типов, не кртичино
                            <Button
                              component={
                                menuButton?.link.includes('modal') || menuButton.link.includes('#')
                                  ? Button
                                  : NextLink
                              }
                              variant={menuButton?.variant || 'contained'}
                              color={menuButton?.color || 'primary'}
                              size={menuButton?.size || 'medium'}
                              key={`menuButton_${idx}`}
                              sx={{
                                borderRadius: block?.settings?.isAlternativeDesign ? '5px' : 10,
                                height: !smUp ? '40px' : 'auto',
                                fontSize: !smUp ? '10px' : '',
                                px: !smUp ? '6px' : '',
                                textWrap: 'nowrap',
                              }}
                              href={
                                (!menuButton?.link.includes('modal') &&
                                  !menuButton.isDialogToggler &&
                                  !menuButton.link.includes('#') &&
                                  menuButton?.link) ||
                                ''
                              }
                              onClick={() => {
                                if (menuButton.isDialogToggler && menuButton.modalId) {
                                  setActiveDialog(menuButton.modalId);
                                }
                                if (menuButton.link.includes('#')) {
                                  const newLink = menuButton?.link.replace('#', '');
                                  window.location.href = `#${newLink}`;
                                }
                              }}
                            >
                              {menuButton.label || `[${t('no label')}]`}
                            </Button>
                          ))}
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <Grid
              container
              sx={{
                height: 1,
              }}
            >
              <Grid item md={6} xs={12} height={1}>
                <Image
                  src={img}
                  sx={{
                    transform: block?.settings?.disableMirrorReflection ? '' : 'scaleX(-1)',
                  }}
                  width={1}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Stack
                  alignItems="center"
                  gap={2}
                  height={1}
                  justifyContent={block?.settings?.verticalPosition}
                >
                  <Typography
                    sx={{
                      mt: 1,
                      textAlign: block?.settings?.titleAlign,
                      width: block?.settings?.titleWidth,
                      color: block?.settings?.titleTextColor,
                      fontSize: block?.settings?.titleFontSize,
                      fontWeight: block?.settings?.titleFontWeight,
                      fontStyle: block?.settings?.titleFontStyle,
                      textDecoration: block?.settings?.titleTextDecoration,
                      lineHeight: 1,
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: block?.settings?.subtitleAlign,
                      width: block?.settings?.subTitleWidth,
                      color: block?.settings?.subTitleTextColor,
                      fontSize: block?.settings?.subTitleFontSize,
                      fontWeight: block?.settings?.subTitleFontWeight,
                      fontStyle: block?.settings?.subTitleFontStyle,
                      textDecoration: block?.settings?.subTitleTextDecoration,
                    }}
                  >
                    {subTitle}
                  </Typography>
                  <Stack
                    alignItems="center"
                    justifyContent={block?.settings?.buttonsAlign}
                    spacing={1}
                    direction="row"
                    width={1}
                  >
                    {actionButtons.map((menuButton: ButtonField, idx: number) => (
                      // @ts-ignore - проблемы с совместимостью типов, не кртичино
                      <Button
                        variant={menuButton.variant}
                        color={menuButton.color}
                        size={menuButton.size}
                        key={`menuButton_${idx}`}
                        sx={{
                          borderRadius: block?.settings?.buttonBorderRadius,
                          fontSize: block?.settings?.buttonFontSize || '14px',
                          fontWeight: block?.settings?.buttonFontWeight || 400,
                          height: block?.settings?.buttonHeight || '40px',
                        }}
                        href={
                          (!menuButton?.link.includes('modal') &&
                            !menuButton.isDialogToggler &&
                            !menuButton.link.includes('#') &&
                            menuButton?.link) ||
                          ''
                        }
                        onClick={() => {
                          if (menuButton.isDialogToggler && menuButton.modalId) {
                            setActiveDialog(menuButton.modalId);
                          }
                          if (menuButton.link.includes('#')) {
                            const newLink = menuButton?.link.replace('#', '');
                            window.location.href = `#${newLink}`;
                          }
                        }}
                      >
                        {menuButton.label || `[${t('no label')}]`}
                      </Button>
                    ))}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          )}
        </Box>
      ) : (
        <Skeleton variant="rectangular" width="100%" height={160} />
      )}
    </>
  );
};
