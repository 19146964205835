'use client';

import { FC, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Grid, Stack, Button, Typography } from '@mui/material';

import Iconify from '../../../../components/iconify';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';

export const ButtonInfoBanner: FC<ComponentTypeProps> = ({ block }) => {
  const { dispatch } = useAppContext();
  const banners: any = block?.banners || [];
  const isMobile = useResponsive('down', 'sm');
  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <Box mt={!isMobile ? 4 : 0}>
      {isLoaded ? (
        <Stack gap={3} direction={isMobile ? 'column' : 'row'}>
          {banners.map((banner: any) => (
            <Grid
              container
              spacing={1}
              alignItems="flex-end"
              sx={
                block?.settings?.enableBackgroundImage && !isMobile
                  ? {
                      borderRadius: '10px',
                      border: '1px solid',
                      borderColor: banner?.borderColor,
                      height: '360px',
                      backgroundImage: `url(${banner?.backgroundImage})`,
                      backgroundPosition: '50%',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                    }
                  : {
                      borderRadius: '10px',
                      border: '1px solid',
                      borderColor: banner?.borderColor,
                      p: 1,
                    }
              }
            >
              <Stack
                direction="row"
                width={1}
                sx={
                  block?.settings?.enableBackgroundImage && !isMobile
                    ? {
                        background: 'rgba(255, 255, 255, 0.5)',
                      }
                    : {}
                }
              >
                <Grid item xs={8} md={8}>
                  <Stack
                    sx={{
                      px: block?.settings?.enableBackgroundImage && !isMobile ? 1 : 0,
                    }}
                  >
                    <Typography
                      color={
                        block?.settings?.enableBackgroundImage && !isMobile ? 'black' : 'black'
                      }
                      sx={{
                        fontSize: isMobile
                          ? banner?.mainContentFontSizeForMobile
                          : banner?.mainContentFontSize,
                        fontWeight: banner?.mainContentFontWeight,
                        fontStyle: banner?.mainContentFontStyle,
                        alignItems: banner?.mainContentAlign,
                      }}
                    >
                      {banner?.mainTitle || ''}
                    </Typography>
                    <Typography
                      color={banner?.subtitleTextColor}
                      sx={{
                        fontSize: isMobile
                          ? banner?.subtitleFontSizeForMobile
                          : banner?.subtitleFontSize,
                        fontWeight: banner?.subtitleFontWeight,
                        width: '80%',
                        fontStyle: banner?.subtitleFontStyle,
                        alignItems: banner?.subtitleAlign,
                        color: block?.settings?.enableBackgroundImage ? 'black' : '',
                      }}
                    >
                      {banner?.mainSubtitleTitle || ''}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Stack
                    direction="row"
                    spacing={2}
                    py={2}
                    justifyContent="center"
                    height={1}
                    alignItems="flex-end"
                    p={isMobile ? 0 : 2}
                  >
                    {banner?.actionButtons.map((menuButton: any, idx: number) => (
                      <Button
                        key={idx}
                        variant={menuButton?.buttonVariant || 'contained'}
                        // fullWidth
                        size={menuButton?.buttonSize}
                        sx={{
                          width: menuButton?.buttonWidth,
                          borderRadius: menuButton?.buttonBorderRadius,
                          height: '30px',
                          background:
                            menuButton?.buttonVariant === 'contained' && menuButton?.buttonBgColor,
                          color:
                            menuButton?.buttonVariant === 'outlined'
                              ? menuButton?.buttonBgColor
                              : menuButton?.buttonColor,
                          fontWeight: 400,
                          borderColor:
                            menuButton?.buttonVariant === 'outlined' && menuButton?.buttonBgColor,
                          '&:hover': {
                            background:
                              menuButton?.buttonVariant === 'contained'
                                ? menuButton?.buttonBgColor
                                : '',
                            color:
                              menuButton?.buttonVariant === 'outlined'
                                ? menuButton?.buttonBgColor
                                : menuButton?.buttonColor,
                            borderColor:
                              menuButton?.buttonVariant === 'outlined' && menuButton?.buttonBgColor,
                          },
                        }}
                        endIcon={<Iconify icon={menuButton?.buttonIcon} />}
                        href={
                          (!menuButton?.link?.includes('modal') &&
                            !menuButton.isDialogToggler &&
                            !menuButton.link?.includes('#') &&
                            menuButton?.link) ||
                          ''
                        }
                        onClick={() => {
                          if (menuButton.isDialogToggler && menuButton.modalId) {
                            setActiveDialog(menuButton.modalId);
                          }
                          if (menuButton.link.includes('#')) {
                            const newLink = menuButton?.link.replace('#', '');
                            window.location.href = `#${newLink}`;
                          }
                        }}
                      >
                        {menuButton?.buttonLabel}
                      </Button>
                    ))}
                  </Stack>
                </Grid>
              </Stack>
            </Grid>
          ))}
        </Stack>
      ) : (
        <Skeleton variant="rectangular" width="100%" height={160} />
      )}
    </Box>
  );
};
