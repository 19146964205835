import ClosetIcon from '../assets/icons/city-people/closetIcon';
import TableIcon from '../assets/icons/city-people/tableIcon';
import BathroomIcon from '../assets/icons/city-people/bathroomIcon';
import StatusChangeIcon from '../assets/icons/city-people/statusChangeIcon';
import DoorIcon from '../assets/icons/city-people/doorIcon';
import { CategoryCP } from '../types/category-city-people';

export const CATEGORIES_CP: CategoryCP[] = [
  {
    id: '1b321b00-3057-4183-b466-3dbb36651ad5',
    image: <DoorIcon />,
    title: 'דלתות פנים',
  },
  {
    id: '56d2c735-ecb3-4696-adf9-c15f2b829ca1',
    image: <StatusChangeIcon />,
    title: 'דלתות פנים',
  },
  {
    id: '5c05608e-0bd2-4ea1-9faf-52b438582057',
    image: <BathroomIcon />,
    title: 'קרמיקה וכלים סניטריים',
  },
  {
    id: '2df3fffb-8ad1-4b49-9e64-3fbb0a277860',
    image: <TableIcon />,
    title: 'שיש מטבח',
  },
  {
    id: '87b96c18-dd44-455d-9e6f-532b3f514776',
    image: <ClosetIcon />,
    title: 'ארונות מטבח',
  },
];
