import { forwardRef } from 'react';
import { useRouter } from 'next/navigation';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ListItemButton from '@mui/material/ListItemButton';
import { alpha, styled, useTheme } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

import Iconify from 'src/components/iconify';

import { useAuthContext } from '../../../../auth/hooks';
import { NavItemProps, NavItemStateProps } from '../types';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';
import { useContentContext } from '../../../../components/content';

// ----------------------------------------------------------------------

export const NavItem = forwardRef<HTMLDivElement, NavItemProps>(
  ({ title, path, icon, open, active, hasChild, externalLink, ...other }, ref) => {
    const theme = useTheme();
    const { dispatch } = useAppContext();
    const { openSite } = useContentContext();
    const { user, isAuthenticated } = useAuthContext();
    const router = useRouter();

    const openLoginForm = (pageName: string) => {
      router.push(pageName);
    };
    const renderContent = (
      <StyledNavItem ref={ref} open={open} active={active} {...other} disableRipple>
        <Box component="span" sx={{ mr: 2, display: 'inline-flex' }}>
          {icon}
        </Box>

        <Box component="span" sx={{ flexGrow: 1 }}>
          {title}
        </Box>

        {hasChild && (
          <Iconify
            width={22}
            color={alpha(theme.palette.primary.light, 0.75)}
            icon={open ? 'ic:round-minus' : 'ic:round-plus'}
          />
        )}
      </StyledNavItem>
    );

    if (hasChild) {
      return renderContent;
    }

    if (externalLink)
      return (
        <Link
          href={path}
          // target="_blank"
          rel="noopener"
          color="inherit"
          underline="none"
        >
          {renderContent}
        </Link>
      );

    if (user) {
      return (
        <Link component={RouterLink} href={path} color="inherit" underline="none">
          {renderContent}
        </Link>
      );
    }

    return (
      <Link onClick={() => openLoginForm(path)} color="inherit" underline="none">
        {renderContent}
      </Link>
    );
  }
);

// ----------------------------------------------------------------------

const StyledNavItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<NavItemStateProps>(({ active, theme, isChild }) => ({
  ...theme.typography.subtitle1,
  color: alpha(theme.palette.primary.light, 0.75),
  fontWeight: isChild ? theme.typography.fontWeightLight : theme.typography.fontWeightBold,
  borderBottom: `1px solid ${alpha(theme.palette.primary.light, isChild ? 0.2 : 0.5)}`,
  height: 48,
  ':hover': { background: 'transparent' },
  ...(active && {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightSemiBold,
  }),
}));
