import { useState } from 'react';

import { Stack } from '@mui/material';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';

import { ComponentTypeProps } from '../../../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../../../contexts/AppContext';

export default function Distance({ block }: ComponentTypeProps) {
  const { dispatch, state } = useAppContext();
  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };
  const [color, setColor] = useState('#66FF00');
  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 20,
      label: '20',
    },
    {
      value: 30,
      label: '30',
    },
    {
      value: 40,
      label: '40',
    },
    {
      value: 50,
      label: '50',
    },
    {
      value: 60,
      label: '60',
    },
    {
      value: 70,
      label: '70',
    },
    {
      value: 80,
      label: '80',
    },
    {
      value: 90,
      label: '90',
    },
    {
      value: 100,
      label: '100',
    },
  ];
  const handleChange = (event: React.SyntheticEvent, value: number | number[]) => {
    if (value === 0) {
      setColor('#00FF00');
    }
    if (value === 10) {
      setColor('#33FF00');
    }
    if (value === 20) {
      setColor('#66FF00');
    }
    if (value === 30) {
      setColor('#99FF00');
    }
    if (value === 40) {
      setColor('#CCFF00');
    }
    if (value === 50) {
      setColor('#FFFF00');
    }
    if (value === 60) {
      setColor('#FFCC00');
    }
    if (value === 70) {
      setColor('#FF9900');
    }
    if (value === 80) {
      setColor('#FF6600');
    }
    if (value === 90) {
      setColor('#FF3300');
    }
    if (value === 100) {
      setColor('#FF0000');
    }
  };
  return (
    <Stack alignItems="center" sx={{ height: '150px', justifyContent: 'flex-end' }}>
      <Slider
        aria-label="Custom marks"
        defaultValue={20}
        step={10}
        valueLabelDisplay="auto"
        marks={marks}
        // @ts-ignore
        onChange={handleChange}
        sx={{ color }}
      />
      <Stack
        direction="row"
        width="100%"
        spacing={2}
        py={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Button onClick={() => setActiveDialog(null)}>Apply</Button>
        <Button onClick={() => setActiveDialog(null)}>Close</Button>
      </Stack>
    </Stack>
  );
}
