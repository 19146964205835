import Slider from 'react-slick';
import { FC, Key, useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Modal, Stack, useTheme, TextField } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import Image from '../../../../components/image';
import SanitizeHTML from '../../../../utils/sanitize';
import { ComponentTypeProps } from '../../../../types/page-generator';

const CARDS = [
  {
    logo: '/assets/images/happy-gift/logo/happy-gift-card.png',
    heroImg: '/assets/images/happy-gift/banners/whiteCardImage.png',
    description:
      'Gift Card מפנק הניתן למימוש ישיר במגוון רחב של רשתות אופנה, מסעדות בתי קפה ועוד מגוון הטבות המחכות לכם באתר ההטבות של HappyGift, שם תוכלו למצוא הטבות נוספות במגוון תחומים הניתנות למימוש באמצעות התחברות לאתר לאחר הקשת קוד הכרטיס שתקבלו *הכרטיס ניתן לטעינה חוזרת',
  },
  {
    logo: '/assets/images/happy-gift/logo/happy-gift-multi.png',
    heroImg: '/assets/images/happy-gift/banners/redCardImage.png',
    description:
      'Gift Card מפנק הניתן למימוש ישיר במסעדות וברשתות : Frame, פיצה עגבניה, GUESS , H&O , צומת ספרים, סולתם, ורדינון, נעמן, אינטימה, כיתן, פולגת, טימברלנד, אימפוריום, אופטיקנה, נאוטיקה, golf, golf kids, golf&co, DKNY, Concept store , שגב sam edelman ועוד',
  },
  {
    logo: '/assets/images/happy-gift/happy-gift-global-banner.png',
    heroImg: '/assets/images/happy-gift/banners/cardholder.png',
    description:
      'הגיפט קארד היחיד שמאפשר מימוש בכל מקום בארץ ובעולם, אפשרות לגיפט קארד לאיביי, איקאה, זארה, Next, amazon ועודמותגים בארץ ובעולם כולל רשתות מזון ובנוסף הטבות למחזיקי הכרטיס דרך אתר מיקרודיל כולל הנחות אטרקטביות.',
  },
];

const AllHappyHrBanners: FC<ComponentTypeProps> = ({ block }) => {
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const smDown = useResponsive('down', 'md');

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Box
      sx={{
        '& .slick-slide': {
          direction: 'rtl',
        },
        '& .slick-slider': {
          '& .slick-dots': {
            '& .slick-active': {
              '& button': {
                '&::before': {
                  color: `${theme.palette.primary.main} !important`,
                },
              },
            },
            '& li': {
              margin: '0 !important',
              width: '15px',
              '& button': {
                width: '15px',
                '&::before': {
                  color: `${theme.palette.primary.main} !important`,
                },
              },
            },
          },
        },
      }}
    >
      <Slider {...settings}>
        {CARDS.map((item: any, idx: Key) => (
          <Box
            key={`card_slider_item_${idx}`}
            sx={{
              width: '100%',
            }}
          >
            <Grid container spacing={2} flexDirection="row-reverse" alignItems="center">
              <Grid
                order={smDown ? 1 : 0}
                item
                md={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <Stack direction="column" alignItems="flex-end" gap={2}>
                  <Image src={item.logo} sx={{ width: '50%', '& img': { objectFit: 'contain' } }} />
                  <Typography
                    sx={{
                      fontWeight: 400,
                      color: 'secondary.main',
                      fontSize: '16px',
                      textAlign: 'end',
                    }}
                  >
                    {item.description}
                  </Typography>
                  <Typography
                    sx={{
                      color: 'secondary.main',
                      fontSize: '12px',
                      textAlign: 'end',
                    }}
                  >
                    <SanitizeHTML html="*הכרטיס ניתן לטעינה חוזרת" />
                  </Typography>
                  <Typography
                    sx={{
                      color: 'secondary.main',
                      fontSize: '12px',
                      textDecoration: 'underline',
                      textAlign: 'end',
                    }}
                  >
                    רשימת בתי עסק מכבדים
                  </Typography>

                  <Stack direction="row" spacing={2} py={1} gap={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      sx={{ borderRadius: 10, fontWeight: 400 }}
                      onClick={() => setOpenModal(true)}
                    >
                      קבלו הצעת מחיר
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      sx={{ borderRadius: 10, fontWeight: 400 }}
                    >
                      Happy חנות
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ position: 'relative' }}>
                  <Image src={item.heroImg} sx={{ width: '100%', borderRadius: '20px' }} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Slider>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            border: '2px solid gray.500',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack alignItems="center" gap={2}>
            <Image src="/assets/images/happy-gift/logo.png" />
            <Image src="/assets/images/happy-gift/banners/modal_banner_happy_hr.png" />
            <Typography sx={{ fontSize: '24px', fontWeight: 600, color: 'primary.main' }}>
              מלאו את הפרטים ונשמח לחזור אליכם בהקדם:
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField fullWidth label="*שם" />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="שם משפחה" />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="*מספר הטלפון" />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="*מייל" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="הודעה (במידה ותרצו להגיד לנו משהו)" />
              </Grid>
            </Grid>
            <Stack direction="row-reverse" gap={2}>
              <Button variant="outlined" onClick={() => setOpenModal(false)}>
                ביטול
              </Button>
              <Button variant="contained" color="secondary">
                לשלוח טופס
              </Button>
            </Stack>
            <Typography sx={{ fontSize: '20px', fontWeight: 400, color: 'primary.main' }}>
              אנחנו כאן לשירותכם גם בטלפון 03-5106070
            </Typography>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default AllHappyHrBanners;
