import { FC, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';

import { Box, Grid, Stack, IconButton, Typography } from '@mui/material';

import { useGetMerchantCategories } from 'src/api/shop';
import { CATEGORIES_CP } from 'src/_mock/_categories-CP';

import Iconify from 'src/components/iconify';

import { CategoryCP } from 'src/types/category-city-people';
import { ComponentTypeProps } from 'src/types/page-generator';

const CATEGORIES_LIMIT = 20;

const CategoriesSlider: FC<ComponentTypeProps> = ({ block }) => {
  const [page, setPage] = useState(0);

  const { categories } = useGetMerchantCategories({
    limit: CATEGORIES_LIMIT,
    offset: page * CATEGORIES_LIMIT,
  });

  const swiperRef = useRef<SwiperCore | null>(null);

  return (
    <Stack direction="row-reverse" alignItems="center" justifyContent="center" spacing={2}>
      <IconButton onClick={() => swiperRef.current?.slideNext()}>
        <Iconify icon="eva:chevron-left-fill" />
      </IconButton>
      <Box
        sx={{
          width: 240,
          overflowX: 'hidden',
          div: {
            display: 'flex',
          },
        }}
      >
        <Swiper
          speed={500}
          slidesPerView="auto"
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {categories.map(({ id, title }: CategoryCP) => (
            <SwiperSlide
              key={id}
              style={{
                width: 'auto',
                flexShrink: 'inherit',
                marginLeft: 20,
              }}
            >
              <Grid
                container
                direction="column"
                wrap="nowrap"
                sx={{
                  width: 60,
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <Grid item xs={12} alignItems="start">
                  {CATEGORIES_CP.find((cat: CategoryCP) => id === cat.id)?.image || ''}
                </Grid>
                <Grid item xs={12} alignItems="center">
                  <Typography
                    sx={{
                      textAlign: 'center',
                      color: 'text.secondary',
                      fontWeight: 400,
                      fontSize: 10,
                    }}
                  >
                    {title}
                  </Typography>
                </Grid>
              </Grid>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      <IconButton onClick={() => swiperRef.current?.slidePrev()}>
        <Iconify icon="eva:chevron-right-fill" />
      </IconButton>
    </Stack>
  );
};

export default CategoriesSlider;
