import { FC } from 'react';
import { format } from 'date-fns';

import Stack from '@mui/material/Stack';
import { Box, Button, Container, Typography } from '@mui/material';

import { useAppContext } from 'src/contexts/AppContext';
import { BASIC_URL_FOR_IMAGES } from 'src/constants/common';
import { DEFAULT_MERCHANT_IMAGE } from 'src/_mock/_companies';

import { PriceInterface } from 'src/types/b2b';
import { PriceTypeEnum } from 'src/types/product';
import { ComponentTypeProps } from 'src/types/page-generator';

const BUTTON_TEXT = 'רשימת ספקים';

export const SingleService: FC<ComponentTypeProps> = ({ block }) => {
  // const [page, setPage] = useState(0);
  // const LIMIT = 12;
  // const { merchants, totalCount } = useSelector((state) => state.merchant);
  // useEffect(() => {
  //   dispatch(getAllMerchantsCount({ offset: 0, limit: LIMIT }));
  // }, []);
  // useEffect(() => {
  //   dispatch(getAllMerchants({ offset: page * LIMIT, limit: LIMIT }));
  // }, [page]);
  // const handleChangePage = (event: any, newPage: number) => {
  //   setPage(newPage - 1);
  // };
  const { state } = useAppContext();
  const voucher = state.selectedProduct;
  const balance = voucher
    ? voucher.prices.find((p: PriceInterface) => p.type === PriceTypeEnum.MAX_PRICE)?.value
    : 0;
  const date = voucher?.createdAt ? format(new Date(voucher.createdAt), 'dd.MM.yyyy') : '';

  const imageLink = state.selectedProduct?.mainImageURL
    ? `${BASIC_URL_FOR_IMAGES}${state.selectedProduct.mainImageURL}`
    : state.selectedProduct?.localMainImage || DEFAULT_MERCHANT_IMAGE;

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        alignItems="center"
        py={1}
        px={1}
        sx={{
          margin: '0 auto',
          width: { sx: '100%', sm: '340px' },
          borderRadius: 2,
          boxShadow: '0px 2px 5px 0px rgba(57, 159, 164, 0.25)',
          '&:hover': { bgcolor: 'rgba(57, 159, 164, 0.25) ' },
        }}
        onClick={() => {}}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          gap={1}
          mx={1}
        >
          <Box
            // m={1}
            width={130}
            height={100}
            borderRadius={2}
            sx={{
              background: '#FFFFFF',
              backgroundImage: `url('${imageLink}')`,
              // backgroundPosition: '50%',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          />
          <Stack>
            <Typography variant="h6" color="secondaryContrastText">
              {voucher?.title || 'voucher name'}
            </Typography>
            <Typography fontSize={10}>
              {voucher?.description} {date}
            </Typography>
            <Typography
              bgcolor="#00B8D9"
              variant="h6"
              color="white"
              textAlign="center"
              lineHeight={1}
            >
              ₪{balance}
            </Typography>
            <Button variant="outlined" sx={{ borderRadius: 2, mt: 1 }}>
              {BUTTON_TEXT}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
};
