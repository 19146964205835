import { FC, useState } from 'react';
import { useParams } from 'next/navigation';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box, Tab, Card, Tabs } from '@mui/material';

import Image from 'src/components/image';

import { useTranslate } from '../../../../locales';
import { getContentValueFromProps } from '../../utils';
import { providers } from '../../../../_mock/_providers';
import { ComponentTypeProps } from '../../../../types/page-generator';

const Provider: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const { id } = useParams();
  const { t } = useTranslate();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const currentItem = id ? providers[+id - 1] : providers[0];

  return (
    <Card>
      <Stack alignItems="center" justifyContent="center">
        <Image src={currentItem?.image} sx={{ width: '30%', height: '30%' }} />
      </Stack>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: 2 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={`${t('מידע')}`} sx={{ textTransform: 'none' }} />
          <Tab label={`${t('פרטים נוספים')}`} sx={{ textTransform: 'none' }} />
        </Tabs>
        {value === 0 && (
          <Stack gap={1} mt={1}>
            <Typography sx={{ color: '#7512B2', fontWeight: 1000, fontSize: '20px' }}>
              {currentItem?.title}
            </Typography>
            <Typography>{currentItem?.content}</Typography>
          </Stack>
        )}
        {value === 1 && (
          <Stack gap={1} mt={1}>
            <Typography sx={{ color: '#7512B2', fontWeight: 1000, fontSize: '20px' }}>
              INFO
            </Typography>
            <Typography>{currentItem?.description}</Typography>
          </Stack>
        )}
      </Box>
    </Card>
  );
};

export default Provider;
