import { Box, Stack, TextField } from '@mui/material';
import { useLocales, useTranslate } from 'src/locales';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import Image from '../../../../components/image';
import FormProvider, {
  RHFCheckbox,
  RHFRadioGroup,
  RHFTextField,
} from '../../../../components/hook-form';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { ButtonField } from '../../../../types/generator';
import { addLead } from '../../../../api/lead';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';

export default function Newsletter({ block }: ComponentTypeProps) {
  const { t } = useTranslate();
  const { dispatch, state } = useAppContext();
  const combinedBanner: any = block?.combinedBanner || [];
  const buttonText: any = block?.buttonText || '';
  const actionButtons: any = block?.actionButtons || [];
  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date: any) => {
    if (selectedDate) {
      return;
    }
    setSelectedDate(date);
  };
  const {
    titleTextColor,
    titleFontSize,
    titleFontWeight,
    titleFontStyle,
    titleTextDecoration,
    titleTextAlignItems,
  } = (block && block.settings) || {};

  const labels = combinedBanner
    .filter((item: any) => item.type !== 'image')
    .map((item: any) => ({
      type: item.type,
      label: item.label,
      name: item.name,
      required: item.required,
      errorMessage: item.errorMessage,
    }));

  const schemaObject = labels.reduce((acc: any, item: any) => {
    if (item.type === 'text') {
      if (item.required) {
        acc[item.name] = Yup.string().required(`${item.errorMessage || 'Required fields'}`);
      } else {
        acc[item.name] = Yup.string();
      }
    }
    if (item.type === 'checkbox') {
      if (item.required) {
        acc[item.name] = Yup.boolean().required(`${item.errorMessage || 'Required fields'}`);
      } else {
        acc[item.label] = Yup.boolean();
      }
    }
    return acc;
  }, {});

  const NewSchema = Yup.object().shape(schemaObject);

  const defaultValues = labels.reduce((acc: any, item: any) => {
    if (item.type === 'text') {
      acc[item.name] = '';
    }
    if (item.type === 'checkbox') {
      acc[item.name] = false;
    }
    return acc;
  }, {});

  const methods = useForm({
    resolver: yupResolver(NewSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      reset();
      // const dataToSend = convertKeysToLowerCase(data);
      dispatch(await addLead(data));
      dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: null });
    } catch (error) {
      console.error(error);
    }
  });
  return (
    <Stack alignItems="center" gap={2}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          {combinedBanner.map((item: any) => {
            let size;
            if (item.size === '100%') {
              size = 12;
            } else if (item.size === '91%') {
              size = 11;
            } else if (item.size === '83%') {
              size = 10;
            } else if (item.size === '75%') {
              size = 9;
            } else if (item.size === '66%') {
              size = 8;
            } else if (item.size === '58%') {
              size = 7;
            } else if (item.size === '50%') {
              size = 6;
            } else if (item.size === '41%') {
              size = 5;
            } else if (item.size === '33%') {
              size = 4;
            } else if (item.size === '25%') {
              size = 3;
            } else if (item.size === '16%') {
              size = 2;
            } else {
              size = 1;
            }
            return (
              <>
                {item.type === 'image' && (
                  <Stack alignItems="center" sx={{ width: '100%' }}>
                    <Image src={item.image} />
                  </Stack>
                )}
                {item.type === 'text' && (
                  <Grid item xs={size}>
                    <RHFTextField fullWidth label={item.label} name={item.label} />
                  </Grid>
                )}
                {item.type === 'dataPicker' && (
                  <Grid item xs={size}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{ width: '100%' }}
                        label={item.label}
                        onChange={handleDateChange}
                      />
                    </LocalizationProvider>
                  </Grid>
                )}
                {item.type === 'typography' && (
                  <Box sx={{ width: '100%' }}>
                    <Typography
                      sx={{
                        fontSize: item.titleFontSize,
                        fontWeight: item.titleFontWeight,
                        color: item.titleTextColor,
                        fontStyle: item.titleFontStyle,
                        textDecoration: item.titleTextDecoration,
                        textAlign: item.titleTextAlignItems,
                        width: '100%',
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                )}
                {item.type === 'checkbox' && (
                  <Grid item xs={size}>
                    <RHFCheckbox
                      name={item.label}
                      label={item.label}
                      sx={{ color: 'primary.main' }}
                    />
                  </Grid>
                )}
              </>
            );
          })}
          {block?.settings?.enablePriority && (
            <Stack width={1} ml={1} gap={2}>
              <RHFRadioGroup
                row
                name="priority"
                label="Priority"
                options={[
                  { label: `LOW`, value: 'LOW' },
                  { label: `HIGH`, value: 'HIGH' },
                ]}
              />
              {/* <RHFAutocomplete */}
              {/*  fullWidth */}
              {/*  name="tagsIds" */}
              {/*  label="Tags" */}
              {/*  multiple */}
              {/*  freeSolo */}
              {/*  options={[ */}
              {/*    { id: 1, label: 'tag1' }, */}
              {/*    { id: 2, label: 'tag2' }, */}
              {/*  ].map((option) => option.label)} */}
              {/*  ChipProps={{ size: 'small' }} */}
              {/* /> */}
            </Stack>
          )}
        </Grid>
        <Stack direction="row" width="100%" spacing={2} py={1} alignItems="center">
          {actionButtons.map((actionButton: ButtonField, idx: number) => (
            // @ts-ignore - проблемы с совместимостью типов, не критично
            <Button
              type={actionButton.label !== 'cancel' && actionButton.label !== 'ביטול' && 'submit'}
              variant={actionButton.variant}
              color={actionButton.color}
              size={actionButton.size}
              key={`actionButton_${idx}`}
              sx={{ borderRadius: 0, fontWeight: 400, width: '50%' }}
            >
              {actionButton.label || `[${t('no label')}]`}
            </Button>
          ))}
        </Stack>
        <Box sx={{ width: '100%' }}>
          <Typography
            sx={{
              fontSize: titleFontSize,
              fontWeight: titleFontWeight,
              color: titleTextColor,
              fontStyle: titleFontStyle,
              textDecoration: titleTextDecoration,
              alignItems: titleTextAlignItems,
              textAlign: 'center',
            }}
          >
            {buttonText}
          </Typography>
        </Box>
      </FormProvider>
    </Stack>
  );
}
