import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';
import React, { FC, useRef, useState, useEffect } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import Container from '@mui/material/Container';

import { useRouter } from '../../../../routes/hooks';
import { getProduct } from '../../../../api/product';
import { IMerchant } from '../../../../types/merchant';
import { ComponentTypeProps } from '../../../../types/page-generator';
import MerchantCard from '../../../../components/cards/merchant-card';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';
import { getMerchant, getMerchantsByProductIdsForReverse } from '../../../../api/merchant';
import { ProductCard } from '../../../products/product-card';
import { useResponsive } from '../../../../hooks/use-responsive';
import Scrollbar from '../../../../components/scrollbar';

export const ReverseBlock: FC<ComponentTypeProps> = ({ block }) => {
  const swiperRef = useRef<SwiperCore | null>(null);
  const isMobile = useResponsive('down', 'sm');
  const [localProduct, setLocalProduct] = useState<any>([]);
  const [show, setShow] = useState<any>(true);
  const [localMerchants, setLocalMerchants] = useState<IMerchant[]>([]);
  const [currentMerchants, setCurrentMerchants] = useState<IMerchant[]>([]);
  const [merchantView, setMerchantView] = useState<IMerchant[]>([]);
  const { dispatch, state } = useAppContext();
  const router = useRouter();
  const product: any = block?.product ? block?.product : [];
  const merchant: any = block?.merchant ? block?.merchant : [];
  const communicationWithReverse: boolean = state.communicationWithReverse;

  useEffect(() => {
    if (communicationWithReverse) {
      getProduct(product && product?.value, dispatch).then((value: any) => {
        const activeCategories = state.activeCategoriesFromReverse;
        if (activeCategories.some((coupon: { id: any }) => coupon.id === value?.categories[0].id)) {
          return;
        }
        dispatch({
          type: ActionTypes.SET_ACTIVE_CATEGORIES_FROM_REVERSE,
          payload: { category: value?.categories[0].id },
        });

        setLocalProduct(value);
      });
    } else {
      getProduct(product && product?.value, dispatch).then((value: any) => {
        setLocalProduct(value);
      });
    }
  }, [state.activeCategory, communicationWithReverse]);

  useEffect(() => {
    if (communicationWithReverse) {
      if (state?.activeCategory.length) {
        const id = localProduct?.categories?.find(
          (category: any) => category.id === state?.activeCategory[0]?.id
        );
        if (id) setShow(true);
        else setShow(false);
      } else setShow(true);
    }
  }, [state?.activeCategory]);

  useEffect(() => {
    if (product) {
      getMerchantsByProductIdsForReverse({
        limit: 9999,
        offset: 0,
        dispatch,
        productIds: product?.value,
      }).then((r: IMerchant[]) => setLocalMerchants(r));
    }
  }, []);

  useEffect(() => {
    if (localProduct?.length) {
      const array: string[] = [];
      const activeCategories = state.activeCategoriesFromReverse;
      const categoryId = localProduct?.categories[0].id;
      const isCategoryExist = activeCategories.some((item: string) => item === categoryId);
      if (!isCategoryExist) {
        array.push(categoryId);
      }
    }
  }, [localProduct, communicationWithReverse]);

  useEffect(() => {
    const array: any = [];
    if (merchant) {
      merchant.forEach((item: { title: string; value: number }) => {
        getMerchant(item.value, dispatch).then((it) => {
          array.push(it);
        });
      });
      setMerchantView(array);
    }
  }, []);

  useEffect(() => {
    const array: IMerchant[] = [];
    const addedNetworkIds = new Set<string>();

    localMerchants?.forEach((item: IMerchant) => {
      if (!item.networkId || !addedNetworkIds.has(item.networkId)) {
        array.push(item);
        if (item.networkId) {
          addedNetworkIds.add(item.networkId);
        }
      }
    });
    setCurrentMerchants(array);
  }, [localMerchants]);

  const merchantsToView = merchant && merchant?.length ? merchantView : currentMerchants;

  return (
    <Container sx={{ my: 1 }}>
      {show && (
        <Box>
          {block?.settings?.enableTitle && (
            <Typography
              sx={{
                color: block?.settings?.contentTitleTextColor,
                fontSize: block?.settings?.contentTitleFontSize,
                fontWeight: block?.settings?.contentTitleFontWeight,
                fontStyle: block?.settings?.contentTitleFontStyle,
                textDecoration: block?.settings?.contentTitleTextDecoration,
                alignItems: block?.settings?.contentTextAlignItems,
              }}
            >
              {product?.title}
            </Typography>
          )}
          {!isMobile ? (
            <Stack direction="row" sx={{ '& .swiper-initialized': { mx: 2 } }}>
              <Box
                sx={{
                  mr: 5,
                  width: '235px',
                  '& .MuiGrid-root': {
                    width: '235px',
                    '& .MuiPaper-elevation': { width: '256px' },
                  },
                }}
              >
                <ProductCard
                  // @ts-ignore
                  block={block}
                  product={localProduct}
                  mode="notCustom"
                  handleOpenPriceChange={() => {}}
                />
              </Box>
              <Swiper
                direction="horizontal"
                spaceBetween={20}
                speed={1000}
                slidesPerView="auto"
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                }}
              >
                {merchantsToView?.map((item: IMerchant) => (
                  <SwiperSlide
                    style={{ width: 'auto', flexShrink: 'inherit', marginLeft: 3 }}
                    key={item?.id}
                  >
                    <Box mx={1}>
                      <MerchantCard
                        key={item.id}
                        merchant={item}
                        product={localProduct}
                        block={block}
                      />
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Stack>
          ) : (
            <Scrollbar>
              <Stack direction="row" mb={1}>
                <Box
                  sx={{
                    mr: 4,
                    width: '235px',
                    '& .MuiGrid-root': {
                      // width: '235px',
                      '& .MuiPaper-elevation': { width: '256px' },
                    },
                  }}
                >
                  <ProductCard
                    // @ts-ignore
                    block={block}
                    product={localProduct}
                    mode="notCustom"
                    handleOpenPriceChange={() => {}}
                  />
                </Box>
                {currentMerchants?.map((item: IMerchant, id) => (
                  <Box mx={1} key={id}>
                    <MerchantCard
                      key={item.id}
                      merchant={item}
                      product={localProduct}
                      block={block}
                    />
                  </Box>
                ))}
              </Stack>
            </Scrollbar>
          )}
        </Box>
      )}
    </Container>
  );
};

export default ReverseBlock;
