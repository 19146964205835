// @mui
import { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import InputAdornment from '@mui/material/InputAdornment';

// utils
import Iconify from 'src/components/iconify';

import { useTranslate } from '../../locales';
import { fCurrency } from '../../utils/format-number';
import { UsedVirtualCard } from '../../types/checkout';
import { useAppContext } from '../../contexts/AppContext';

type Props = {
  totalSum: number;
  discount?: number;
  subTotal: number;
  shipping?: number;
  //
  onEdit?: VoidFunction;
  onApplyDiscount?: (discount: number) => void;
};

export default function CheckoutSummary({
  totalSum,
  discount,
  subTotal,
  shipping,
  onEdit,
  onApplyDiscount,
}: Props) {
  const [_discount, setDiscount] = useState('');
  const displayShipping = shipping !== null ? 'Free' : '-';
  const { t } = useTranslate();
  const { state } = useAppContext();

  const usedVirtualCards = useMemo(
    () => state.checkout.usedVirtualCards,
    [state.checkout.usedVirtualCards]
  );

  return (
    <Card sx={{ mb: 3 }}>
      <CardHeader
        title={`${t('Order summary')}`}
        action={
          onEdit && (
            <Button size="small" onClick={onEdit} startIcon={<Iconify icon="solar:pen-bold" />}>
              {`${t('Edit')}`}
            </Button>
          )
        }
      />

      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {`${t('Sub Total')}`}
            </Typography>
            <Typography variant="subtitle2">{fCurrency(subTotal)}</Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {`${t('Discount')}`}
            </Typography>
            <Typography variant="subtitle2">{discount ? fCurrency(-discount) : '-'}</Typography>
          </Stack>

          {Array.isArray(usedVirtualCards) &&
            !!usedVirtualCards.length &&
            usedVirtualCards.map(({ id, name, cost }: UsedVirtualCard) => (
              <Stack key={id} direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {name}
                </Typography>
                <Typography variant="subtitle2">{cost ? fCurrency(-cost) : '-'}</Typography>
              </Stack>
            ))}

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {`${t('Shipping')}`}
            </Typography>
            <Typography variant="subtitle2">
              {shipping ? fCurrency(shipping) : displayShipping}
            </Typography>
          </Stack>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle1"> {`${t('Total')}`}</Typography>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="subtitle1" sx={{ color: 'error.main' }}>
                {fCurrency(totalSum)}
              </Typography>
              <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                {`${t('(VAT included if applicable)')}`}
              </Typography>
            </Box>
          </Stack>

          {onApplyDiscount && (
            <TextField
              onChange={(e: any) => setDiscount(e.target.value)}
              fullWidth
              placeholder={`${t('Discount codes / Gifts')}`}
              value={_discount}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button color="primary" onClick={() => onApplyDiscount(5)} sx={{ mr: -0.5 }}>
                      {`${t('Apply')}`}
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
