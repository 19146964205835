import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Image from '../../../../components/image';
import { useResponsive } from '../../../../hooks/use-responsive';
import BigZipLineIcon from '../../../../assets/icons/happy-gift/bigZipLine';

export const GreyBanner = () => {
  const smDown = useResponsive('down', 'sm');
  return (
    <Card
      sx={{
        width: '100%',
        borderRadius: '20px',
        backgroundImage: `url('/assets/images/happy-gift/backgrounds/grey_background.png')`,
        backgroundSize: 'cover',
        padding: smDown ? '25px!important' : 5,
      }}
    >
      <Grid container spacing={2} flexDirection={smDown ? 'column-reverse' : 'row'} alignItems="">
        <Grid
          item
          md={7}
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: smDown ? '100%' : '50%',
            justifyContent: 'center',
          }}
        >
          <Stack direction="column" alignItems="flex-start" gap={2} ml={smDown ? '' : '75px'}>
            <Typography
              sx={{
                fontWeight: 600,
                fontStyle: '',
                textDecoration: '',
                color: 'primary.contrastText',
                fontSize: '28px',
                direction: 'ltr',
                textAlign: 'start',
                width: '100%',
                lineHeight: 1.2,
                // mb: 2,
              }}
            >
              מגוון יתרונות:
              <br />
              מסעדות, אופנה ופנאי למחזיקי כרטיסי
              <br />
              <strong style={{ color: '#27097A' }}>HappyGift</strong> Global PAI
            </Typography>
            <BigZipLineIcon color="white" />
            <Typography
              sx={{
                fontWeight: '',
                fontStyle: '',
                textDecoration: '',
                color: 'primary.contrastText',
                fontSize: '12px',
                direction: 'ltr',
                textAlign: 'start',
                width: '100%',
                lineHeight: 1,
                mt: '-10px',
                // mb: 2,
              }}
            >
              כדי לבדוק את המתנה שלך ואת האפשרויות שלה, אנא לחץ על הכפתור למטה
            </Typography>

            <Stack direction="row" spacing={2} py={1} mt="-15px">
              <Button
                variant="contained"
                size="small"
                sx={{
                  borderRadius: 10,
                  fontSize: '10px',
                  fontWeight: 400,
                  bgcolor: '#27097A',
                  px: '20px',
                  mt: 1,
                  '&:hover': { bgcolor: '#27097A' },
                }}
              >
                לפרטים נוספים
              </Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <Box sx={{ position: 'relative' }}>
            <Image src="/assets/images/happy-gift/cardholder.png" sx={{ width: '90%' }} />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};
