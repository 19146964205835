import NextLink from 'next/link';
import { m } from 'framer-motion';

// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper, { PaperProps } from '@mui/material/Paper';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import { useTranslate } from 'src/locales';
import { useAppContext } from 'src/contexts/AppContext';
// assets
import { SeverErrorIllustration } from 'src/assets/illustrations';

// components
import Iconify from 'src/components/iconify';

// types

// ----------------------------------------------------------------------

interface Props extends DialogProps {
  onReset: VoidFunction;
}

export default function CheckoutOrderError({ open, onReset }: Props) {
  const { t } = useTranslate();
  const { state } = useAppContext();

  const renderError = state.newOrder ? (
    <Stack
      spacing={5}
      sx={{
        m: 'auto',
        maxWidth: 480,
        textAlign: 'center',
        px: { xs: 2, sm: 0 },
        py: 3,
      }}
    >
      <Typography variant="h4">{`${t('Error')}`}</Typography>

      <SeverErrorIllustration sx={{ height: 260 }} />
      <Stack spacing={2}>
        <Typography>{t('The payment was unsuccessful')}</Typography>
        <Link>{state.newOrder.orderNumber}</Link>
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Stack
        spacing={2}
        justifyContent="space-between"
        direction={{ xs: 'column-reverse', sm: 'row' }}
      >
        <Button
          component={NextLink}
          href="/"
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          onClick={onReset}
          startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
        >
          {t('Continue Shopping')}
        </Button>
      </Stack>
    </Stack>
  ) : null;

  return (
    <Dialog
      fullWidth
      fullScreen
      open={open}
      PaperComponent={(props: PaperProps) => (
        <Box
          component={m.div}
          sx={{
            width: 1,
            height: 1,
            p: { md: 3 },
          }}
        >
          <Paper {...props}>{props.children}</Paper>
        </Box>
      )}
    >
      {renderError}
    </Dialog>
  );
}
