import { FC, useMemo } from 'react';

import { Box, Stack, alpha, useTheme, Typography } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import DoneIcon from 'src/assets/icons/happy-gift/doneIcon';
import GiftIcon from 'src/assets/icons/happy-gift/giftIcon';
import SmileIcon from 'src/assets/icons/happy-gift/smileIcon';
import PencilIcon from 'src/assets/icons/happy-gift/pencilIcon';
import StatisticBudgetIcon from 'src/assets/icons/happy-gift/statisticBudgetIcon';

import SvgColor from 'src/components/svg-color';

import { getContentValueFromProps } from '../../utils';
import { ComponentTypeProps } from '../../../../types/page-generator';

const StepBannerItem = ({
  icon,
  text,
  number,
}: {
  icon: any;
  text: string;
  number: number | string;
}) => {
  const theme = useTheme();
  const smDown = useResponsive('down', 'sm');

  return (
    <Stack flexDirection={smDown ? 'column' : 'row'} alignItems="center" spacing={1}>
      <Box display="flex" alignItems="center" flexDirection="row">
        <Stack direction="column" alignItems="center">
          <Box>
            {number === 'done' ? (
              <DoneIcon width={60} />
            ) : (
              <Typography color="white" fontSize="44px" fontWeight="light">
                {number}
              </Typography>
            )}
          </Box>
          <Stack
            spacing={1}
            alignItems="center"
            justifyContent="flex-start"
            border="3px solid white"
            borderRadius={3}
            px={1}
            py={2}
            width={120}
            height={180}
          >
            <Box height={75} display="flex" flexDirection="column" justifyContent="center">
              {icon}
            </Box>
            <Typography textAlign="center" color="white">
              {text}
            </Typography>
          </Stack>
        </Stack>
      </Box>
      {number === 'done' ? (
        ''
      ) : (
        <Box sx={{ mt: smDown ? 4 : 9.5, ...(smDown && { mb: 2 }) }}>
          <SvgColor
            src="/assets/icons/happy-gift/arrow_1.svg"
            color={theme.palette.common.white}
            sx={{ width: 70, ...(smDown && { transform: 'rotate(-90deg)' }) }}
          />
        </Box>
      )}
    </Stack>
  );
};

const StepBanner: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const theme = useTheme();
  const smDown = useResponsive('down', 'sm');

  const zipImage = useMemo(() => getFieldValue('zipImage'), [getFieldValue]);

  const STEPS = [
    {
      stepNumber: 1,
      icon: <StatisticBudgetIcon width={70} color="#FFFFFF" />,
      text: 'להגדיר תקציב',
    },
    {
      stepNumber: 2,
      icon: <GiftIcon width={70} color="#FFFFFF" />,
      text: 'לבחור אירוע',
    },
    {
      stepNumber: 3,
      icon: <PencilIcon width={70} color="#FFFFFF" />,
      text: 'להתאים עיצוב ייחודי',
    },
    {
      stepNumber: 4,
      icon: (
        <SvgColor
          color={theme.palette.common.white}
          sx={{ width: 85, height: 70 }}
          src="/assets/icons/happy-gift/2_icons_in_orange_banner.svg"
        />
      ),
      text: 'לבחור שיטת הפצה',
    },
    {
      stepNumber: 'done',
      icon: <SmileIcon width={70} color="#FFFFFF" />,
      text: 'והנה עובד מרוצה!',
    },
  ];

  return (
    <Box
      bgcolor={alpha(theme.palette.primary.light, 0.9)}
      dir="rtl"
      py={5}
      display="flex"
      alignItems="center"
      flexDirection="column"
      borderRadius={1.5}
    >
      <Typography color="primary.contrastText" variant="h3">
        איך זה עובד?
      </Typography>

      <Stack flexDirection={smDown ? 'column' : 'row'} gap={1}>
        {STEPS.map((step, idx) => (
          <Stack key={idx} flexDirection="row" display="flex" alignItems="center">
            <StepBannerItem icon={step.icon} text={step.text} number={step.stepNumber} />
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default StepBanner;
