import { API } from 'src/helpers/api';
import { ActionType, ActionTypes } from 'src/contexts/AppContext';

import { GiftType } from '../types/gift';

interface GetGiftsProps {
  activityId: string;
  dispatch: React.Dispatch<ActionType>;
}

export const getGifts = async ({ activityId, dispatch }: GetGiftsProps) => {
  try {
    const { data } = await API({
      url: `gift/${activityId}`,
      method: 'GET',
    });

    dispatch({
      type: ActionTypes.GET_GIFTS,
      payload: { gifts: data.payload },
    });
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    console.error(e);
  }
};

interface SelectedGiftProps {
  giftId: string;
  giftType: GiftType;
  selectedId: string;
  botId: number;
  dispatch: React.Dispatch<ActionType>;
}
export const selectedGift = async ({
  giftId,
  giftType,
  selectedId,
  botId,
  dispatch,
}: SelectedGiftProps) => {
  try {
    const { data } = await API({
      url: `/gift/select`,
      method: 'POST',
      data: {
        type: giftType,
        selectedId,
        giftId,
        botId,
      },
    });

    dispatch({
      type: ActionTypes.SELECT_GIFT_SUCCESS,
      payload: { gifts: data.payload },
    });
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    console.error(e);
  }
};
