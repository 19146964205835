// ----------------------------------------------------------------------
/* eslint-disable import/no-cycle */
import { Ib2bProduct } from './b2b';
import { ISMBAccount } from './smbAccount';

export type IOrderTableFilterValue = string | Date | null;

export type IOrderTableFilters = {
  name: string;
  status: string;
  startDate: Date | null;
  endDate: Date | null;
};

// ----------------------------------------------------------------------

export type IOrderHistory = {
  orderTime: Date;
  paymentTime: Date;
  deliveryTime: Date;
  completionTime: Date;
  timeline: {
    title: string;
    time: Date;
  }[];
};

export type IOrderShippingAddress = {
  fullAddress: string;
  phoneNumber: string;
};

export type IOrderPayment = {
  cardType: string;
  cardNumber: string;
};

export type IOrderDelivery = {
  shipBy: string;
  speedy: string;
  trackingNumber: string;
};

export type IOrderCustomer = {
  id: string;
  name: string;
  email: string;
  avatarUrl: string;
  ipAddress: string;
};

export type IOrderProductItem = {
  id: string;
  sku: string;
  name: string;
  price: number;
  coverUrl: string;
  quantity: number;
};

export type IOrderItem = {
  id: string;
  taxes: number;
  status: string;
  shipping: number;
  discount: number;
  subTotal: number;
  orderNumber: string;
  totalAmount: number;
  totalQuantity: number;
  history: IOrderHistory;
  customer: IOrderCustomer;
  delivery: IOrderDelivery;
  items: IOrderProductItem[];
  createdAt: Date;
};

export type OrderItemInterface = {
  id: string;
  amount: number;
  createdAt: string;
  discountPercent: string;
  quantity: number;
  productId: string;
  products: Ib2bProduct[];
  product: Ib2bProduct;
  cards: IIssuedCard[];
};

export type IOrder = {
  id: string;
  orderNumber: string;
  status: string;
  documentImageUrl: string | null;
  createdAt: Date;
  smbAccount: ISMBAccount;
  orderItems: OrderItemInterface[];
};

export type IIssuedCard = {
  id: string;
  userId: string;
  botId: number;
  cardNumber: string;
  type: IIssuedCardType;
  status: IIssuedCardStatus;
  balance: string;
  createdAt: string;
  product?: Ib2bProduct;
};

export enum IIssuedCardType {
  BENEFIT = 'BENEFIT',
  LOAD = 'LOAD',
  // TODO: add more
}

export enum IIssuedCardStatus {
  // TODO: check if they are really like this on the backend
  NOT_USED = 'NOT_USED',
  USED = 'USED',
  PARTIALLY_USED = 'PARTIALLY_USED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
}
