import Button from '@mui/material/Button';
import { Stack, Typography } from '@mui/material';

import { useTranslate } from '../../../../../../locales';
import { ButtonField } from '../../../../../../types/generator';
import { ComponentTypeProps } from '../../../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../../../contexts/AppContext';

export default function DealFinished({ block }: ComponentTypeProps) {
  const { t } = useTranslate();
  const { dispatch, state } = useAppContext();
  const actionButtons: any = block?.actionButtons || [];
  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  return (
    <Stack alignItems="center">
      <Stack
        sx={{
          border: '1px solid black',
          borderRadius: '10px',
          width: '100%',
          direction: 'rtl',
          p: 1,
          '& .MuiTypography-root': { textAlign: 'center' },
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 1000 }}>
          Deal finished
        </Typography>
        <Typography>Seller: Tomi/Buyer: Pony</Typography>
        <Typography>Amount: 1000$-Rate:3.75</Typography>
        <Typography>Totall in NIS: 3750</Typography>
        <Typography>Place: Beersheva</Typography>
      </Stack>
      <Stack direction="row" width="100%" spacing={2} py={1} alignItems="center">
        {actionButtons.map((actionButton: ButtonField, idx: number) => (
          // @ts-ignore - проблемы с совместимостью типов, не критично
          <Button
            type={actionButton.label !== 'cancel' && actionButton.label !== 'ביטול' && 'submit'}
            variant={actionButton.variant}
            color={actionButton.color}
            size={actionButton.size}
            key={`actionButton_${idx}`}
            sx={{ borderRadius: 0, fontWeight: 400, width: '50%' }}
            href={
              (!actionButton?.link.includes('modal') &&
                !actionButton.isDialogToggler &&
                actionButton?.link) ||
              ''
            }
            onClick={() => {
              if (actionButton.isDialogToggler && actionButton.modalId) {
                setActiveDialog(actionButton.modalId);
              }
              if (actionButton.link === '#close') {
                setActiveDialog(null);
              }
            }}
          >
            {actionButton.label || `[${t('no label')}]`}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
}
