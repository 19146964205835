import Link from 'next/link';
import { useParams } from 'next/navigation';
import { FC, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import SanitizeHTML from 'src/utils/sanitize';

import { useTranslate } from 'src/locales';
import { getProduct } from 'src/api/product';
import { addToBasket } from 'src/api/basket';
import { getMerchant } from 'src/api/merchant';
import { useAuthContext } from 'src/auth/hooks';
import { extractPrices } from 'src/helpers/extractPrices';
import Scrollbar from 'src/extra-components/bazaar/Scrollbar';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import { uploadProductsImage } from 'src/helpers/uploadProductsImage';
import { checkIfProductInCart } from 'src/helpers/checkIfProductInCart';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import BasicModal from 'src/components/modals/basic-modal';

import { Ib2bProduct } from 'src/types/b2b';
import { IProduct } from 'src/types/product';
import { IMerchant } from 'src/types/merchant';
import { BlockType, ComponentTypeProps } from 'src/types/page-generator';
import { useContentContext } from '../../../../components/content';

export const ProductDescription: FC<ComponentTypeProps> = ({ block }) => {
  const router = useRouter();
  const { dispatch, state } = useAppContext();
  const { cart } = state;
  const smUp = useResponsive('up', 'sm');
  const branches = useBoolean();
  const { t } = useTranslate();
  const { id } = useParams();
  const [product, setProduct] = useState<any>([]);
  const [merchant, setMerchant] = useState<any>([]);
  const productId = localStorage.getItem('productId') || '';
  const { user } = useAuthContext();
  const { openSite } = useContentContext();

  const isInCart = !!product?.id && checkIfProductInCart(cart, product?.id);

  const info = (value: Ib2bProduct, localBlock: BlockType | undefined) => {
    localStorage.setItem('product', JSON.stringify(value));
    if (localBlock !== undefined) {
      localStorage.setItem('settings', JSON.stringify(localBlock));
    }
    router.push(`/product/${value.id}`);
  };

  useEffect(() => {
    getProduct(productId).then((item: IProduct[]) => setProduct(item));
  }, [productId]);

  useEffect(() => {
    getMerchant(id, dispatch).then((value: IMerchant[]) => setMerchant(value));
  }, []);

  const { basePrice: discountPrice, price } = extractPrices(product?.prices);

  const allPrice = product?.prices ? product?.prices[0]?.value : '';
  const OPTIONS = ['0', '1'];

  const imageLink =
    (product?.mainImageURL
      ? `${uploadProductsImage(`${product?.mainImageURL}`)}`
      : product?.merchants &&
        product?.merchants.length &&
        `${uploadProductsImage(`${product?.merchants[0].mainImageURL}`)}`) || '';
  return (
    <Box sx={{ position: 'relative' }}>
      {/* <Box */}
      {/* sx={{ */}
      {/*   position: 'relative', */}
      {/*   width: '100%', */}
      {/*   height: '200px', */}
      {/*   backgroundSize: 'contain', */}
      {/*   backgroundImage: `url(${uploadProductsImage(merchant?.mainImageURL)})`, */}
      {/* }} */}
      {/* > */}
      {block?.settings?.enableButtonBranches && (
        <Button
          variant="contained"
          sx={{
            position: 'absolute',
            left: 0,
            borderRadius: block?.settings?.buttonBranchesBorderRadius,
            bgcolor: block?.settings?.buttonBranchesBgColor,
            color: block?.settings?.buttonBranchesColor,
            '&:hover': {
              bgcolor: block?.settings?.buttonBranchesBgColor,
              color: block?.settings?.buttonBranchesColor,
            },
          }}
          endIcon={<Iconify icon={block?.settings?.buttonBranchesIcon || ''} />}
          onClick={branches.onTrue}
        >
          {block?.settings?.buttonBranchesLabel
            ? block?.settings?.buttonBranchesLabel
            : `${t('Branches')}`}
        </Button>
      )}
      {/* </Box> */}
      <Grid container sx={{ my: 2 }}>
        <Grid item md={9} xs={12} sx={{ p: 5 }}>
          <Typography
            sx={{
              color: block?.settings.contentTitleTextColor,
              fontSize: block?.settings.contentTitleFontSize,
              fontWeight: block?.settings.contentTitleFontWeight,
              fontStyle: block?.settings.contentTitleFontStyle,
              textDecoration: block?.settings.contentTitleTextDecoration,
              flex: 1,
              textAlign: block?.settings.contentTextAlignItems,
            }}
          >
            <SanitizeHTML html={product?.description} />
          </Typography>
        </Grid>
        <Grid item md={3} xs={12}>
          <Card
            sx={{
              borderRadius: '5px',
              pb: 1,
              maxWidth: '360px',
              cursor: 'pointer',
              boxShadow: 5,
              maxHeight: '385px',
              height: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <Image
              src={`${uploadProductsImage(merchant?.mainImageURL)}`}
              sx={{
                mx: 1,
                position: 'absolute',
                '& img': {
                  objectFit: 'contain',
                  width: '60px',
                  height: '60px',
                },
              }}
            />
            <Box
              onClick={() => router.push(`/product/${product?.id}`)}
              sx={{
                backgroundSize: 'cover',
                backgroundImage: `url(${uploadProductsImage(imageLink)})`,
                backgroundRepeat: 'no-repeat',
                height: '140px',
                borderRadius: '10px',
              }}
            />
            <Typography
              sx={{
                my: 1,
                mx: 3,
                color: block?.settings.contentTextColor,
                fontSize: block?.settings.contentFontSize,
                fontWeight: block?.settings.contentFontWeight,
                fontStyle: block?.settings.contentFontStyle,
                textDecoration: block?.settings.contentTextDecoration,
                flex: 1,
                textAlign: block?.settings.contentAlign,
              }}
            >
              {product?.title}
            </Typography>
            {!price && !allPrice ? (
              <Stack
                direction="row-reverse"
                justifyContent="space-between"
                alignItems="center"
                px={1}
                mb={1}
              >
                {block?.settings?.enableButtonAdd && (
                  <Button
                    fullWidth
                    variant="outlined"
                    sx={{
                      height: '34px',
                      borderRadius: block?.settings.buttonBorderRadius,
                      fontWeight: 400,
                      bgcolor: block?.settings.buttonBgColor,
                      color: block?.settings.buttonColor,
                      '&:hover': {
                        bgcolor: block?.settings.buttonBgColor,
                        color: block?.settings.buttonColor,
                      },
                    }}
                  >
                    כניסה לאתר
                  </Button>
                )}
              </Stack>
            ) : (
              <Stack
                direction="row-reverse"
                justifyContent="space-between"
                alignItems="center"
                px={1}
              >
                {block?.settings?.enableButton && (
                  <>
                    {isInCart ? (
                      <Button
                        variant="contained"
                        color="success"
                        sx={{
                          height: '34px',
                          borderRadius: '25px',
                          fontWeight: 400,
                        }}
                        endIcon={<Iconify icon="ep:success-filled" />}
                      >
                        בעגלה
                      </Button>
                    ) : (
                      <Button
                        // disabled={!price}
                        variant={block?.settings?.buttonVariant || 'contained'}
                        sx={{
                          height: '34px',
                          borderRadius: block?.settings.buttonBorderRadius,
                          fontWeight: 400,
                          bgcolor:
                            block?.settings?.buttonVariant === 'outlined'
                              ? ''
                              : block?.settings.buttonBgColor,
                          color:
                            block?.settings?.buttonVariant === 'outlined'
                              ? block?.settings.buttonBgColor
                              : block?.settings.buttonColor,
                          borderColor:
                            block?.settings?.buttonVariant === 'outlined' &&
                            block?.settings?.buttonBgColor,
                          '&:hover': {
                            background:
                              block?.settings?.buttonVariant === 'contained'
                                ? block?.settings.buttonBgColor
                                : '',
                            color:
                              block?.settings?.buttonVariant === 'outlined'
                                ? block?.settings.buttonBgColor
                                : block?.settings.buttonColor,
                            borderColor:
                              block?.settings?.buttonVariant === 'outlined' &&
                              block?.settings.buttonBgColor,
                          },
                        }}
                        endIcon={<Iconify icon={block.settings.buttonIcon} />}
                        onClick={() => {
                          if (!user) {
                            dispatch({
                              type: ActionTypes.SET_ACTIVE_DIALOG,
                              payload: 'login',
                            });
                          } else if (!block?.settings?.linkOnProductInfo) {
                            addToBasket(
                              state.smbAccount.id,
                              product.id,
                              (block?.settings?.enableDiscount && discountPrice) || price,
                              imageLink,
                              dispatch,
                              cart
                            );
                          } else info(product, block);
                        }}
                      >
                        {block?.settings?.buttonLabel ? block?.settings?.buttonLabel : 'לרכישה'}
                      </Button>
                    )}
                  </>
                )}
                {block?.settings?.enableCount && (
                  <TextField
                    select
                    SelectProps={{ native: true }}
                    sx={{
                      '& .MuiNativeSelect-select': {
                        py: 1,
                        px: 2,
                      },
                    }}
                  >
                    {OPTIONS.map((option, idx) => (
                      <option key={idx} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                )}
                {block?.settings?.enablePrice && (
                  <Stack>
                    {block.settings.enableDiscount && (
                      <Typography
                        sx={{
                          color: block.settings.topPriceColor,
                          textDecoration: 'line-through',
                          fontSize: block.settings.topPriceFontSize,
                        }}
                      >
                        ₪{price}
                      </Typography>
                    )}
                    {block.settings.enableDiscount ? (
                      <Typography
                        sx={{
                          color: block.settings.bottomPriceColor,
                          fontSize: block.settings.bottomPriceFontSize,
                        }}
                      >
                        ₪{discountPrice}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: block.settings.bottomPriceColor,
                          fontSize: block.settings.bottomPriceFontSize,
                        }}
                      >
                        ₪{block.settings.enableSwitch ? allPrice : price}
                      </Typography>
                    )}
                  </Stack>
                )}
              </Stack>
            )}
            <Grid
              container
              sx={{
                p: 1,
                display:
                  !block?.settings?.enableAllBalance && !block?.settings?.enableAllAvailable
                    ? 'none'
                    : '',
              }}
            >
              <Grid item md={6}>
                {block?.settings?.enableAllBalance && (
                  <Stack direction="row">
                    <Typography>נשאר: 199</Typography>
                  </Stack>
                )}
              </Grid>
              <Grid item md={6}>
                {block?.settings?.enableAllAvailable && (
                  <Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography sx={{ mx: 1 }}>כמות</Typography>
                      <TextField
                        sx={{
                          '& .MuiNativeSelect-select': { py: 0 },
                        }}
                        select
                        fullWidth
                        SelectProps={{
                          native: true,
                          sx: { textTransform: 'capitalize' },
                        }}
                        onChange={() => {}}
                      >
                        {[1, 2, 3].map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </TextField>
                    </Stack>
                    <Typography sx={{ fontSize: '10px', textAlign: 'end' }}>
                      ניתן לקנייה: 1
                    </Typography>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <BasicModal open={branches.value} onClose={() => branches.onFalse()}>
        <Stack alignItems="flex-start">
          <Typography id="modal-modal-title" variant="h6" component="h2" color="secondary.main">
            סניפים
          </Typography>
          <TextField
            sx={{ py: 2 }}
            fullWidth
            placeholder="חיפוש..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
          <Scrollbar sx={{ height: smUp ? '400px' : '150px', width: '100%' }}>
            {merchant?.network && (
              <Stack gap={2} height="450px">
                <Typography>{merchant?.network?.name}</Typography>
                <Image
                  src={uploadProductsImage(
                    merchant?.network?.mainImageURL
                      ? uploadProductsImage(merchant?.network?.mainImageURL)
                      : '/assets/images/network-default.png'
                  )}
                  sx={{ '& img': { width: '100px' } }}
                />
                <SanitizeHTML html={merchant?.network?.content} />
                <Link href={merchant?.url} target="_blank">
                  <Typography sx={{ textDecoration: 'underline' }}>
                    לאתר בית העסק לחץ כאן
                  </Typography>
                </Link>
                <Typography
                  sx={{
                    textAlign: 'start',
                    width: '100%',
                    fontSize: '10px',
                    mt: 1,
                    height: '10px',
                    color: 'red',
                  }}
                >
                  {merchant?.asterisks && merchant?.asterisks[0] && '*'}
                  {merchant?.asterisks && merchant?.asterisks[0]?.description}
                </Typography>
              </Stack>
            )}
          </Scrollbar>
        </Stack>
      </BasicModal>
    </Box>
  );
};
