import { FC } from 'react';

import { Box, Stack, Container, Typography } from '@mui/material';

// import { getContentValueFromProps } from '../../utils';
import { ComponentTypeProps } from 'src/types/page-generator';

const TotalInfoCityPeople: FC<ComponentTypeProps> = ({ block }) => {
  // const sxWide = {
  //   color: 'secondary.main',
  //   fontSize: '13px',
  //   width: '50%',
  //   textAlign: 'center',
  // };
  // const sxNarrow = {
  //   color: 'secondary.main',
  //   fontSize: '13px',
  //   width: '100%',
  //   textAlign: 'center',
  // };
  // const getFieldValue = getContentValueFromProps(block);
  //
  // const contentTitleTextColor = useMemo(
  //   () => getFieldValue('settings.contentTitleTextColor'),
  //   [block.settings.contentTitleTextColor],
  // );
  const contentTitleTextColor = 'black';
  const contentTitleFontSize = 16;
  const contentNumberFontSize = 22;
  // const contentTitleFontSize = useMemo(
  //   () => getFieldValue('settings.contentTitleFontSize'),
  //   [block.settings.contentTitleFontSize],
  // );
  const contentTitleFontWeight = 400;
  const contentNumberFontWeight = 400;
  // const contentTitleFontWeight = useMemo(
  //   () => getFieldValue('settings.contentTitleFontWeight'),
  //   [block.settings.contentTitleFontWeight],
  // );
  // const contentTitleFontStyle = useMemo(
  //   () => getFieldValue('settings.contentTitleFontStyle'),
  //   [block.settings.contentTitleFontStyle],
  // );
  // const contentTitleTextDecoration = useMemo(
  //   () => getFieldValue('settings.contentTitleTextDecoration'),
  //   [block.settings.contentTitleTextDecoration],
  // );
  //
  // const contentSubtitleTextColor = useMemo(
  //   () => getFieldValue('settings.contentSubtitleTextColor'),
  //   [block.settings.contentSubtitleTextColor],
  // );
  // const contentSubtitleFontSize = useMemo(
  //   () => getFieldValue('settings.contentSubtitleFontSize'),
  //   [block.settings.contentSubtitleFontSize],
  // );
  // const contentSubtitleFontWeight = useMemo(
  //   () => getFieldValue('settings.contentSubtitleFontWeight'),
  //   [block.settings.contentSubtitleFontWeight],
  // );
  // const contentSubtitleFontStyle = useMemo(
  //   () => getFieldValue('settings.contentSubtitleFontStyle'),
  //   [block.settings.contentSubtitleFontStyle],
  // );
  // const contentSubtitleTextDecoration = useMemo(
  //   () => getFieldValue('settings.contentSubtitleTextDecoration'),
  //   [block.settings.contentSubtitleTextDecoration],
  // );
  //
  // const subtitleWidth = useMemo(
  //   () => getFieldValue('settings.subtitleWidth'),
  //   [block.settings.subtitleWidth],
  // );
  //
  // const title = useMemo(() => {
  //   if (block.titleText) {
  //     return block.titleText;
  //   }
  //   return getFieldValue('titleText');
  // }, [block]);
  //
  // const subTitle = useMemo(() => {
  //   if (block.subtitleText) {
  //     return block.subtitleText;
  //   }
  //   return getFieldValue('subtitleText');
  // }, [block]);

  const forceRtl = true;

  return (
    <Container maxWidth="sm">
      <Box
        p={1}
        sx={{
          backgroundColor: 'rgb(171 224 227 / 50%)',
          width: '90%',
          borderRadius: 2,
          margin: '0 auto',
        }}
      >
        <Stack alignItems="center" gap={1}>
          {/* <Typography
          className={headingClasses}
          sx={{
            direction: forceRtl ? 'rtl' : '',
            color: contentTitleTextColor,
            fontSize: contentTitleFontSize,
            fontWeight: contentTitleFontWeight,
            fontStyle: contentTitleFontStyle,
            textDecoration: contentTitleTextDecoration,
          }}
        >
          {title}
        </Typography>
        <Typography
          className={contentClasses}
          sx={{
            direction: forceRtl ? 'rtl' : '',
            color: contentSubtitleTextColor,
            fontSize: contentSubtitleFontSize,
            fontWeight: contentSubtitleFontWeight,
            fontStyle: contentSubtitleFontStyle,
            textDecoration: contentSubtitleTextDecoration,
            textAlign: 'center',
            width: subtitleWidth,
          }}
        >
          {subTitle}
        </Typography> */}
          <Stack direction="row" gap={2} alignItems="center">
            <Stack direction="row" gap={1} alignItems="baseline">
              <Typography
                sx={{
                  // direction: forceRtl ? 'rtl' : '',
                  color: contentTitleTextColor,
                  fontSize: contentTitleFontSize,
                  fontWeight: contentTitleFontWeight,
                  // fontStyle: contentTitleFontStyle,
                }}
              >
                דירות גן
              </Typography>
              <Typography
                sx={{
                  // direction: forceRtl ? 'rtl' : '',
                  color: 'rgb(237, 148, 105)',
                  fontSize: contentNumberFontSize,
                  fontWeight: contentNumberFontWeight,
                  // fontStyle: contentTitleFontStyle,
                }}
              >
                26
              </Typography>
            </Stack>

            <Stack direction="row" gap={1} alignItems="baseline">
              <Typography
                // className={headingClasses}
                sx={{
                  direction: forceRtl ? 'rtl' : '',
                  color: contentTitleTextColor,
                  fontSize: contentTitleFontSize,
                  fontWeight: contentTitleFontWeight,
                  // fontStyle: contentTitleFontStyle,
                }}
              >
                פרויקטים
              </Typography>
              <Typography
                // className={headingClasses}
                sx={{
                  direction: forceRtl ? 'rtl' : '',
                  color: 'rgb(227, 130, 217)',
                  fontSize: contentNumberFontSize,
                  fontWeight: contentNumberFontWeight,
                  // fontStyle: contentTitleFontStyle,
                }}
              >
                23
              </Typography>
            </Stack>
          </Stack>

          <Stack direction="row" gap={1} alignItems="baseline">
            <Typography
              // className={headingClasses}
              sx={{
                direction: forceRtl ? 'rtl' : '',
                color: contentTitleTextColor,
                fontSize: contentTitleFontSize,
                fontWeight: contentTitleFontWeight,
                // fontStyle: contentTitleFontStyle,
              }}
            >
              פנטהאוזים יוקרתיים
            </Typography>
            <Typography
              // className={headingClasses}
              sx={{
                direction: forceRtl ? 'rtl' : '',
                color: 'rgb(26, 152, 182)',
                fontSize: contentNumberFontSize,
                fontWeight: contentNumberFontWeight,
                // fontStyle: contentTitleFontStyle,
              }}
            >
              37
            </Typography>
          </Stack>
          <Stack direction="row" gap={1} alignItems="baseline">
            <Typography
              // className={headingClasses}
              sx={{
                direction: forceRtl ? 'rtl' : '',
                color: contentTitleTextColor,
                fontSize: contentTitleFontSize,
                fontWeight: contentTitleFontWeight,
                // fontStyle: contentTitleFontStyle,
              }}
            >
              דירות במיקומים הכי טובים
            </Typography>
            <Typography
              // className={headingClasses}
              sx={{
                direction: forceRtl ? 'rtl' : '',
                color: 'rgb(0, 0, 0)',
                fontSize: contentNumberFontSize,
                fontWeight: contentNumberFontWeight,
                // fontStyle: contentTitleFontStyle,
              }}
            >
              180
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
};

export default TotalInfoCityPeople;
