'use client';

import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import Image from '../../../../../../components/image';
import Iconify from '../../../../../../components/iconify';
import FormProvider, { RHFTextField } from '../../../../../../components/hook-form';
import LoginForm from '../login-form/loginForm';
import { ComponentTypeProps } from '../../../../../../types/page-generator';
import { useBoolean } from '../../../../../../hooks/use-boolean';
import { useResponsive } from '../../../../../../hooks/use-responsive';
import { ActionTypes, useAppContext } from '../../../../../../contexts/AppContext';
import { useAuthContext } from '../../../../../../auth/hooks';

type Props = {
  checkBalance: {
    value: boolean;
    onFalse: () => void;
  };
};

export default function CheckCard({ block }: ComponentTypeProps) {
  const { user } = useAuthContext();
  const { dispatch, state } = useAppContext();
  const balance = `${Math.floor(Math.random() * 100) * 100}`;
  const viewBalance = useBoolean();
  const loginForm = useBoolean();
  const [localData, setLocalData] = useState({ checkNumberCard: '' });
  const smUp = useResponsive('up', 'sm');
  const mainImage: string = block?.mainImage as string;
  const FormSchema = Yup.object().shape({
    checkNumberCard: Yup.string().min(16, 'הקלד 16 ספרות'),
  });

  const defaultValues = {
    checkNumberCard: '',
  };

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      reset();
      viewBalance.onTrue();
      // @ts-ignore
      setLocalData(data);
      console.info('DATA', data);
      const localVirtualCard = {
        balance,
        cardNumber: data.checkNumberCard,
        cardType: {
          id: '010fd9bf-a456-4079-b35f-485cb11fcc47',
          name: 'Happy Gift Gold',
          description: 'Happy Gift Gold',
          mainImageURL: '/500/cardType/ac85dbeb-190e-4b99-9478-a07b78656ca2.png',
          deletedDate: null,
        },
        cardTypeId: `010fd9bf-a456-4079-b35f-485cb11fcc${Math.floor(Math.random())}`,
        customerId: 'd288caec-8845-4553-9cfc-2ee8151faeac',
        id: `0a845ac6-3011-431a-b6f6-1a62573c15${Math.floor(Math.random())}`,
        isGlobal: true,
        transactions: [],
      };
      dispatch({ type: ActionTypes.SET_LOCAL_VIRTUAL_CARD, payload: localVirtualCard });
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <>
      {loginForm.value ? (
        <LoginForm block={block} />
      ) : (
        <Stack gap={2}>
          {block?.settings?.enableImageFromCheckModal && (
            <Stack alignItems="center">
              <Image src={mainImage || ''} sx={{ width: '20%' }} />
            </Stack>
          )}
          {viewBalance.value ? (
            <Stack gap={3}>
              <Stack
                color="secondary.main"
                direction="row-reverse"
                gap={2}
                alignItems="center"
                justifyContent="flex-end"
                // sx={{ flexWrap: 'wrap' }}
              >
                <Typography sx={{ fontSize: '20px', fontWeight: 700 }}> {balance} שח</Typography>
                <Typography fontWeight={600}>{localData.checkNumberCard}</Typography>
                <Iconify icon="solar:wad-of-money-bold" />
                <Typography fontWeight={600}>יתרתך בכרטיס</Typography>
              </Stack>
              <Stack direction="row" gap={1}>
                <LoadingButton
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    viewBalance.onFalse();
                    if (!user) {
                      loginForm.onTrue();
                    } else {
                      dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: '' });
                    }
                  }}
                >
                  טעינה
                </LoadingButton>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    viewBalance.onFalse();
                  }}
                >
                  סגירה
                </Button>
              </Stack>
            </Stack>
          ) : (
            <FormProvider methods={methods} onSubmit={onSubmit}>
              <Stack gap={3}>
                <Stack>
                  <RHFTextField name="checkNumberCard" label="מספר כרטיס" type="number" />
                </Stack>
                <Stack direction="row" gap={1} sx={{ height: smUp ? '' : '35px' }}>
                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    type="submit"
                    loading={isSubmitting}
                  >
                    כניסה
                  </LoadingButton>
                  <Button variant="outlined" color="secondary">
                    סגירה
                  </Button>
                </Stack>
              </Stack>
            </FormProvider>
          )}
        </Stack>
      )}
    </>
  );
}
