// mui
import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

// constants
import { BASIC_URL_FOR_IMAGES } from 'src/constants/common';

// components
import Image from 'src/components/image';

// types
import { IVirtualCard } from 'src/types/club-cards';

//
import ModalAddBalance from './modal-add-balance';
import { BlockType } from '../../../types/page-generator';
import { getContentValueFromProps } from '../../generate-page/utils';

type Props = {
  card: IVirtualCard;
  changeOpenProduct: () => void;
  isOpenProduct?: boolean;
  block: BlockType | undefined;
  openHistory: Function;
  openModal: Function;
  onChangeClubCardInfo: Function;
};

const VirtualCardBlock = ({
  card,
  changeOpenProduct,
  isOpenProduct,
  block,
  openHistory,
  openModal,
  onChangeClubCardInfo,
}: Props) => {
  const getFieldValue = getContentValueFromProps(block);
  const primaryTitleTextColor = useMemo(
    () => getFieldValue('settings.primaryTitleTextColor'),
    [block]
  );

  const primaryTitleFontSize = useMemo(
    () => getFieldValue('settings.primaryTitleFontSize'),
    [block]
  );

  const primaryTitleFontWeight = useMemo(
    () => getFieldValue('settings.primaryTitleFontWeight'),
    [block]
  );

  const primaryTitleFontStyle = useMemo(
    () => getFieldValue('settings.primaryTitleFontStyle'),
    [block]
  );

  const primaryTitleTextDecoration = useMemo(
    () => getFieldValue('settings.primaryTitleTextDecoration'),
    [block]
  );

  const secondaryTitleTextColor = useMemo(
    () => getFieldValue('settings.secondaryTitleTextColor'),
    [block]
  );

  const secondaryTitleFontSize = useMemo(
    () => getFieldValue('settings.secondaryTitleFontSize'),
    [block]
  );

  const secondaryTitleFontWeight = useMemo(
    () => getFieldValue('settings.secondaryTitleFontWeight'),
    [block]
  );

  const secondaryTitleFontStyle = useMemo(
    () => getFieldValue('settings.secondaryTitleFontStyle'),
    [block]
  );

  const secondaryTitleTextDecoration = useMemo(
    () => getFieldValue('settings.secondaryTitleTextDecoration'),
    [block]
  );

  const addBalance = useBoolean();
  const smUp = useResponsive('up', 'sm');
  const defaultImage =
    process.env.NEXT_PUBLIC_PROJECT_NAME === 'xtra'
      ? '/assets/images/xtra/default.png'
      : '/assets/images/happy-gift/banners/blue-card.png';

  const imageURL = card.cardType?.mainImageURL
    ? `${BASIC_URL_FOR_IMAGES}${card.cardType.mainImageURL}`
    : defaultImage;

  return (
    <Paper elevation={3} sx={{ px: 2, py: 1, borderRadius: 1.5, width: '100%' }}>
      <Stack gap={3} sx={{ mt: 2, mb: 0, cursor: 'pointer' }}>
        <Stack direction={smUp ? 'row' : 'column'} gap={2}>
          <Box sx={{ position: 'relative', flex: 1, width: 300, height: 200 }}>
            <Image
              onClick={() => openHistory(card.id)}
              src={card.type === 'local' ? `${card?.cardType?.mainImageURL}` : imageURL}
              sx={{
                borderRadius: '15px',
                width: '300px',
                height: '200px',
                '& img': { objectFit: 'unset' },
              }}
            />
          </Box>
          <Stack gap={1} justifyContent="center">
            {block?.settings?.enableName && (
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                width={1}
                sx={{ textWrap: 'balance' }}
              >
                <Typography
                  sx={{
                    color: secondaryTitleTextColor,
                    fontWeight: secondaryTitleFontWeight,
                    fontSize: secondaryTitleFontSize,
                    fontStyle: secondaryTitleFontStyle,
                    textDecoration: secondaryTitleTextDecoration,
                  }}
                >
                  כרטיס מספר:
                </Typography>
                <Typography
                  sx={{
                    color: primaryTitleTextColor,
                    fontWeight: primaryTitleFontWeight,
                    fontSize: primaryTitleFontSize,
                    fontStyle: primaryTitleFontStyle,
                    textDecoration: primaryTitleTextDecoration,
                  }}
                >
                  {' '}
                  {card.cardNumber}{' '}
                </Typography>
              </Stack>
            )}
            {block?.settings?.enableBalance && (
              <Stack direction="row" gap={1} alignItems="center" sx={{ textWrap: 'balance' }}>
                <Typography
                  sx={{
                    color: secondaryTitleTextColor,
                    fontWeight: secondaryTitleFontWeight,
                    fontSize: secondaryTitleFontSize,
                    fontStyle: secondaryTitleFontStyle,
                    textDecoration: secondaryTitleTextDecoration,
                  }}
                >
                  יתרת הכרטיס:
                </Typography>
                <Typography
                  sx={{
                    color: primaryTitleTextColor,
                    fontWeight: primaryTitleFontWeight,
                    fontSize: primaryTitleFontSize,
                    fontStyle: primaryTitleFontStyle,
                    textDecoration: primaryTitleTextDecoration,
                  }}
                >
                  <bdi>₪{card.balance}</bdi>
                </Typography>
              </Stack>
            )}
            {block?.settings?.enableName && (
              <Stack direction="row" gap={1} alignItems="center" sx={{ textWrap: 'balance' }}>
                <Typography
                  sx={{
                    color: secondaryTitleTextColor,
                    fontWeight: secondaryTitleFontWeight,
                    fontSize: secondaryTitleFontSize,
                    fontStyle: secondaryTitleFontStyle,
                    textDecoration: secondaryTitleTextDecoration,
                  }}
                >
                  סוג כרטיס:
                </Typography>
                <Typography
                  sx={{
                    color: primaryTitleTextColor,
                    fontWeight: primaryTitleFontWeight,
                    fontSize: primaryTitleFontSize,
                    fontStyle: primaryTitleFontStyle,
                    textDecoration: primaryTitleTextDecoration,
                  }}
                >
                  {card.cardType?.name || '—'}
                </Typography>
              </Stack>
            )}
            {block?.settings?.enableValidityDate && (
              <>
                {card?.cardType?.deletedDate && (
                  <Stack direction="row" gap={1} alignItems="center" sx={{ textWrap: 'balance' }}>
                    <Typography
                      sx={{
                        color: secondaryTitleTextColor,
                        fontWeight: secondaryTitleFontWeight,
                        fontSize: secondaryTitleFontSize,
                        fontStyle: secondaryTitleFontStyle,
                        textDecoration: secondaryTitleTextDecoration,
                      }}
                    >
                      תאריך תפוגה:
                    </Typography>
                    <Typography
                      sx={{
                        color: primaryTitleTextColor,
                        fontWeight: primaryTitleFontWeight,
                        fontSize: primaryTitleFontSize,
                        fontStyle: primaryTitleFontStyle,
                        textDecoration: primaryTitleTextDecoration,
                      }}
                    >
                      {card?.cardType?.deletedDate || '--'}
                    </Typography>
                  </Stack>
                )}
              </>
            )}
          </Stack>
        </Stack>
        {card.isGlobal ? (
          <Stack direction="row-reverse" justifyContent="flex-end" width={1} gap={3}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                color: 'secondary.contrastText',
                padding: !smUp ? '6px 6px 6px 6px' : '',
                fontSize: !smUp ? '12px' : '',
              }}
              onClick={changeOpenProduct}
            >
              {isOpenProduct ? 'החזר' : 'איפה לממש'}
            </Button>
            {/* <Button */}
            {/*  variant="outlined" */}
            {/*  // color="secondary" */}
            {/*  sx={{ */}
            {/*    color: 'black', */}
            {/*    borderColor: 'secondary.main', */}
            {/*    padding: !smUp ? '6px 6px 6px 6px' : '', */}
            {/*    fontSize: !smUp ? '12px' : '', */}
            {/*  }} */}
            {/*  onClick={changeOpenProduct} */}
            {/* > */}
            {/*  ניהול */}
            {/* </Button> */}
            <Button
              variant="outlined"
              // color="secondary"
              sx={{
                color: 'black',
                borderColor: 'black',
                padding: !smUp ? '6px 6px 6px 6px' : '',
                fontSize: !smUp ? '12px' : '',
              }}
              onClick={() => openModal()}
            >
              העבורות
            </Button>

            <Button
              variant="contained"
              color="secondary"
              onClick={addBalance.onTrue}
              sx={{
                padding: !smUp ? '6px 6px 6px 6px' : '',
                fontSize: !smUp ? '12px' : '',
              }}
            >
              טעינת כרטיס
            </Button>
          </Stack>
        ) : (
          <Stack direction="row-reverse" justifyContent="flex-end" width={1} gap={3}>
            <Button
              variant="outlined"
              onClick={() => onChangeClubCardInfo()}
              sx={{
                color: 'black',
                borderColor: 'secondary.main',
                padding: !smUp ? '6px 6px 6px 6px' : '',
                fontSize: !smUp ? '12px' : '',
              }}
            >
              היסטוריה
            </Button>

            <Button
              variant="outlined"
              // color="secondary"
              sx={{
                color: 'black',
                borderColor: 'black',
                padding: !smUp ? '6px 6px 6px 6px' : '',
                fontSize: !smUp ? '12px' : '',
              }}
            >
              טעינת כרטיס
            </Button>

            <Button
              variant="contained"
              color="primary"
              sx={{
                color: 'white',
                bgcolor: 'black',
                padding: !smUp ? '6px 6px 6px 6px' : '',
                fontSize: !smUp ? '12px' : '',
              }}
            >
              יתרת כרטיס
            </Button>
          </Stack>
        )}
      </Stack>
      <ModalAddBalance open={addBalance.value} handleClose={addBalance.onFalse} block={block} />
    </Paper>
  );
};
export default VirtualCardBlock;
