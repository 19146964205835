'use client';

// types
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
// @mui
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import FormProvider, { RHFUploadBox } from 'src/components/hook-form';
// components

import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CardHeader from '@mui/material/CardHeader';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../components/iconify';
import { fDate } from '../../../../utils/format-time';
import { _userBannersHistory } from '../../../../_mock';
import { IUserSocialLink } from '../../../../types/user';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';

// ----------------------------------------------------------------------

type Props = {
  socialLinks: IUserSocialLink;
};

export default function AccountSocialLinks({ socialLinks }: Props) {
  const { t } = useTranslation();
  const isMobile = useResponsive('down', 'sm');
  const NewSchema = Yup.object().shape({});
  const showMore = useBoolean();
  const invoices = _userBannersHistory;
  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(NewSchema),
    defaultValues,
    mode: 'onChange',
  });

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
    trigger,
    resetField,
  } = methods;

  const onSubmit = handleSubmit(async (data) => {});

  const ScrollbarContainer = styled('div')({
    display: 'flex',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  });

  return (
    <Stack width={1}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack width={1} gap={1}>
          <Typography width={1} sx={{ fontSize: isMobile ? '18px' : '30px', fontWeight: 600 }}>
            פרסם בפורטל לקידום מכירות לאלפי לקוחות פוטנציאלים
          </Typography>
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 1 },
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <Stack
                  direction={!isMobile ? 'row' : 'column'}
                  width={1}
                  alignItems="center"
                  gap={2}
                >
                  <Typography sx={{ fontSize: '18px' }}>
                    עמוד ראשי באנר בגודל <b>359x249</b>
                  </Typography>
                  {!isMobile && (
                    <DatePicker
                      sx={{ width: '15%' }}
                      label={t('Start date')}
                      format="dd/MM/yyyy"
                      onChange={() => {}}
                      slotProps={{ textField: { fullWidth: true } }}
                    />
                  )}
                  {!isMobile && (
                    <DatePicker
                      sx={{ width: '15%' }}
                      label={t('Due date')}
                      format="dd/MM/yyyy"
                      onChange={() => {}}
                      slotProps={{ textField: { fullWidth: true } }}
                    />
                  )}
                  {isMobile && (
                    <Stack direction="row" gap={1} width={1}>
                      <DatePicker
                        sx={{ width: '100%' }}
                        label={t('Start date')}
                        format="dd/MM/yyyy"
                        onChange={() => {}}
                        slotProps={{ textField: { fullWidth: true } }}
                      />
                      <DatePicker
                        sx={{ width: '100%' }}
                        label={t('Due date')}
                        format="dd/MM/yyyy"
                        onChange={() => {}}
                        slotProps={{ textField: { fullWidth: true } }}
                      />
                    </Stack>
                  )}
                  <Typography sx={{ fontSize: '18px' }}>לעלות קובץ</Typography>
                  <RHFUploadBox name="image" />
                  <Typography sx={{ fontSize: '18px' }}> 1290 ש``ח לפני מע``מ</Typography>
                </Stack>
              </DemoContainer>
            </LocalizationProvider>
          </FormControl>
        </Stack>
      </FormProvider>
      <Stack width={1}>
        <Card>
          <CardHeader title="היסטוריה" />

          <Stack spacing={1.5} sx={{ px: 3, pt: 3 }}>
            <ScrollbarContainer>
              <Stack gap={2} width={1}>
                {(showMore.value ? invoices : invoices.slice(0, 8)).map((invoice) => (
                  <Stack
                    key={invoice.id}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography>{invoice.bannerTo}</Typography>
                    <Typography>
                      באנר בגודל <b>{invoice.bannerSize}</b>{' '}
                    </Typography>

                    <Typography>{fDate(invoice.createdAt)}</Typography>
                    <Typography>{fDate(invoice.createdAt)}</Typography>

                    <Typography sx={{ color: invoice.status === 'אושר' ? 'green' : 'red' }}>
                      {invoice.status}
                    </Typography>

                    <Link color="inherit" underline="always" variant="body2" href="#">
                      לבטל
                    </Link>
                  </Stack>
                ))}
              </Stack>
            </ScrollbarContainer>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </Stack>

          <Stack alignItems="flex-start" sx={{ p: 2 }}>
            <Button
              size="small"
              color="inherit"
              startIcon={
                <Iconify
                  icon={
                    showMore.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'
                  }
                />
              }
              onClick={showMore.onToggle}
            >
              {showMore.value ? `${t(`Show Less`)}` : `${t(`Show More`)}`}
            </Button>
          </Stack>
        </Card>
      </Stack>
    </Stack>
  );
}
