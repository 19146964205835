import isEqual from 'lodash/isEqual';
import { useParams } from 'next/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';
import { FC, useRef, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Grid, Stack, Button, Pagination, Typography } from '@mui/material';

import { ITag } from '../../../../types/tag';
import Image from '../../../../components/image';
import SanitizeHTML from '../../../../utils/sanitize';
import NetworkToolbar from './network/network-toolbar';
import { getNetworkById } from '../../../../api/network';
import { ICategoryItem } from '../../../../types/product';
import { useBoolean } from '../../../../hooks/use-boolean';
import { IBranche, INetwork } from '../../../../types/networks';
import { useAppContext } from '../../../../contexts/AppContext';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import MerchantCard from '../../../../components/cards/merchant-card';
import { uploadProductsImage } from '../../../../helpers/uploadProductsImage';
import { Ib2bTableFilter, Ib2bTableFilterValue } from '../../../../types/b2b';
import { getMerchantsTags, getMerchantsCategories } from '../../../../api/merchant';

export const NetworkInfo: FC<ComponentTypeProps> = ({ block }) => {
  const swiperRef = useRef<SwiperCore | null>(null);
  const isMobile = useResponsive('down', 'sm');
  const defaultFilters: Ib2bTableFilter = {
    name: '',
    type: [],
    category: [],
    area: [],
    price: [0, 3000],
    search: '',
  };
  const toggleDrawer = useBoolean();
  const LIMIT = block?.settings?.limit || 9;
  const [currentNetwork, setCurrentNetwork] = useState<INetwork | null>(null);
  const [localTags, setLocaleTags] = useState<ITag[]>([]);
  const [localCategories, setLocalCategories] = useState<ICategoryItem[]>([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [currentPage, setCurrentPage] = useState(1);
  const canReset = !isEqual(defaultFilters, filters);
  const params = useParams();
  const { dispatch, state } = useAppContext();

  const dataFiltered = currentNetwork
    ? applyFilter({ inputData: currentNetwork.branches, filters })
    : [];

  useEffect(() => {
    getMerchantsTags({
      dispatch,
    }).then((tags: ITag[]) => setLocaleTags(tags));
    getMerchantsCategories({
      dispatch,
    }).then((categories: ICategoryItem[]) => setLocalCategories(categories));
    getNetworkById({ dispatch }).then((networks: INetwork[]) => {
      const result = networks?.find((item: INetwork) => item.id === params.id);
      if (result) setCurrentNetwork(result);
    });
  }, []);

  const count = currentNetwork?.branches.length || 0;

  const startIndex = (currentPage - 1) * LIMIT;
  const endIndex = Math.min(startIndex + LIMIT, dataFiltered.length);

  const visibleData = dataFiltered.slice(startIndex, endIndex);

  const handleFilters = (name: string, filterValue: Ib2bTableFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: filterValue,
    }));
  };

  const handleResetFilters = () => {
    setFilters(defaultFilters);
  };

  const handleResetName = () => {
    setFilters((prevState) => ({
      ...prevState,
      name: '',
    }));
  };

  const handlePageChange = (event: any, newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <Stack>
      <Grid
        container
        spacing={1}
        alignItems="center"
        sx={{ flexDirection: { md: 'row', xs: 'column-reverse' } }}
      >
        <Grid item md={6} xs={12}>
          <Stack>
            <Typography
              className={block?.settings?.headingClasses}
              sx={{
                width: '100%',
                color: block?.settings?.headTextColor,
                fontSize: block?.settings?.headFontSize,
                fontWeight: block?.settings?.headFontWeight,
                alignItems: block?.settings?.contentAlign,
              }}
            >
              {currentNetwork?.name}
            </Typography>
            <Typography
              className={block?.settings?.contentClasses}
              sx={{
                width: '100%',
                color: block?.settings?.contentTextColor,
                fontSize: block?.settings?.contentFontSize,
                fontWeight: block?.settings?.contentFontWeight,
                alignItems: block?.settings?.titleContentAlign,
              }}
            >
              <SanitizeHTML html={currentNetwork?.content || ''} />
            </Typography>
          </Stack>
        </Grid>

        <Grid item md={6} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Image
            className={block?.settings?.imagesClasses}
            src={uploadProductsImage(currentNetwork?.mainImageURL || '')}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        {block?.settings?.enableFilter && (
          <NetworkToolbar
            block={block}
            filters={filters}
            onFilters={handleFilters}
            areaOptions={localTags && localTags?.map((mTag: ITag) => mTag.name)}
            categoryOptions={localCategories}
            handleResetFilters={handleResetFilters}
            handleResetName={handleResetName}
            canReset={canReset}
          />
        )}
      </Box>
      <Button
        sx={{ mt: 2, display: { md: 'none', xs: '' } }}
        variant="contained"
        color="primary"
        onClick={toggleDrawer.onTrue}
      >
        Open filter
      </Button>
      <Drawer open={toggleDrawer.value} onClose={toggleDrawer.onFalse}>
        {block?.settings?.enableFilter && (
          <NetworkToolbar
            block={block}
            filters={filters}
            onFilters={handleFilters}
            areaOptions={localTags && localTags?.map((mTag: ITag) => mTag.name)}
            categoryOptions={localCategories}
            handleResetFilters={handleResetFilters}
            handleResetName={handleResetName}
            canReset={canReset}
          />
        )}
      </Drawer>

      {dataFiltered && visibleData && (
        <Grid container spacing={3} rowGap={3} justifyContent="center" my={2}>
          {isMobile ? (
            <Box sx={{ '& .swiper-slide': { width: '70vw', margin: 1 } }}>
              <Box
                sx={{
                  width: '90vw',
                }}
              >
                <Swiper
                  speed={500}
                  slidesPerView="auto"
                  mousewheel={{
                    forceToAxis: true,
                    sensitivity: 1,
                    releaseOnEdges: true,
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                >
                  {visibleData.map((merchant: IBranche, id: number) => (
                    <SwiperSlide>
                      <Grid
                        item
                        md={3}
                        xs={12}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                        key={id}
                      >
                        <MerchantCard merchant={merchant} block={block} network={currentNetwork} />
                      </Grid>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            </Box>
          ) : (
            <>
              {visibleData.map((merchant: IBranche, id: number) => (
                <SwiperSlide>
                  <Grid
                    item
                    md={3}
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                    key={id}
                  >
                    <MerchantCard merchant={merchant} block={block} network={currentNetwork} />
                  </Grid>
                </SwiperSlide>
              ))}
            </>
          )}
        </Grid>
      )}
      {dataFiltered?.length && block?.settings?.enablePagination ? (
        <Stack justifyContent="center" mt={1}>
          <Pagination
            onChange={handlePageChange}
            count={Math.ceil(count / LIMIT)}
            color="primary"
            sx={{ margin: '0 auto' }}
          />
        </Stack>
      ) : (
        ''
      )}
    </Stack>
  );
};
export default NetworkInfo;

function applyFilter({ inputData, filters }: { inputData: IBranche[]; filters: Ib2bTableFilter }) {
  const { name, area, category } = filters;

  if (name) {
    inputData = inputData.filter(
      (merchant) => merchant.title.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  // if (area.length) {
  //   inputData = inputData.filter((product) => area.includes(product.area));
  // }

  // if (category.length) {
  //   inputData = inputData.filter((product) =>
  //     category.includes(product.category),
  //   );
  // }

  return inputData;
}
