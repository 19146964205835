'use client';

import { FC } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { Grid, Paper } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Image from '../../../../components/image';
import Iconify from '../../../../components/iconify';
import { CouponDetailsSummary } from './coupon-details-summary';
import { ComponentTypeProps } from '../../../../types/page-generator';
import ProductDetailsCarousel from '../../../product/product-details-carousel';

const CouponDetailsView: FC<ComponentTypeProps> = ({ block }) => {
  const product = {
    id: '1',
    img: '/assets/images/happy-gift/products/1.png',
    brand: '/assets/images/happy-gift/products/brands/1.png',
    title: 'סאפ koning 300 כולל משלוח עד הבית',
    price: 990,
    gift: false,
    type: 'home',
    category: 'home',
    area: 'home',
    images: [
      '/assets/images/happy-gift/products/1.png',
      '/assets/images/happy-gift/products/1.png',
      '/assets/images/happy-gift/products/1.png',
    ],
    description:
      'רנה היא רשת בתי קפה פופולארית ומוכרת מאוד באזור הצפון. הרשת הוקמה בשנת 2003 ומלווה את אוכלוסיית הצפון באזורי עסקים, אזורי בילוי וקניונים מרכזיים. הרשת ידועה באווירה והעיצוב החדשני והצעיר של סניפיה, המנות הגדולות המוגשות במחיר סביר, ובאווירה צעירה ודינמית. לאחר פניות רבות של אנשים המעוניינים לקבל זיכיון מהרשת, החליטה הרשת להרחיב את פעילותה לפרישה ארצית באמצעות מודת הזכיינות, וכעת מציעה את ההזדמנות ליזמים צעירים המעוניינים לפתוח סניף של הרשת בכל רחבי הארץ.',
    phone: '085 222 222',
    link: 'לאתר בית העסק לחץ כאן',
  };
  const CARDS = [
    { image: '/assets/images/happy-gift/banners/blackCardImage.png' },
    { image: '/assets/images/happy-gift/banners/blueCardImage.png' },
    { image: '/assets/images/happy-gift/banners/redCardImage.png' },
    { image: '/assets/images/happy-gift/banners/whiteCardImage.png' },
  ];
  return (
    <Container>
      <Stack direction="row" width={1} justifyContent="center" my={2}>
        <Typography sx={{ fontSize: '56px', fontWeight: 600, color: '#EB157B' }}>
          השוברים שלנו
        </Typography>
      </Stack>
      <Card sx={{ p: 3 }}>
        <Paper elevation={5} sx={{ px: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={5}>
              <CouponDetailsSummary product={product} block={block} />
            </Grid>
            <Grid item xs={12} md={6} lg={7}>
              <ProductDetailsCarousel product={product} />
            </Grid>
          </Grid>
          {/* <Divider */}
          {/*  sx={{ borderStyle: 'dashed', borderColor: 'primary.main', width: '100%', my: 2 }} */}
          {/* /> */}
          {/* <Stack> */}
          {/*  <Typography sx={{ fontSize: '20px', fontWeight: 800, color: 'primary.main', ml: 2 }}> */}
          {/*    פרטים */}
          {/*  </Typography> */}
          {/*  <Image src="/assets/images/happy-gift/Vector-6.png" width="10%" /> */}
          {/* </Stack> */}
          <br />
        </Paper>
        <br />
        <Paper elevation={5}>
          <br />
          <Stack alignItems="flex-start" pr={2}>
            <Typography
              sx={{
                fontSize: '20px',
                fontWeight: 800,
                color: 'primary.main',
                ml: 2,
              }}
            >
              פרטים
            </Typography>
            <Image src="/assets/images/happy-gift/Vector-6.png" sx={{ width: '10%' }} />
          </Stack>
          <Stack direction="row-reverse" gap={3} mt={2} px={2}>
            <Stack gap={1} sx={{ width: '30%', px: 1 }}>
              <Stack sx={{ color: 'primary.main', flexDirection: 'row', alignItems: 'center' }}>
                <Iconify icon="iconoir:home-alt" />
                <Typography sx={{ fontSize: '20px', fontWeight: 800 }}>ספק</Typography>
              </Stack>
              <Image src={product.brand} sx={{ width: '20%' }} />
              <Typography sx={{ fontSize: '20px', fontWeight: 800, color: 'primary.main' }}>
                {product.title}
              </Typography>
              <Stack direction="row" sx={{ color: 'primary.main' }}>
                <Iconify icon="fluent:call-28-filled" color="primary.main" />
                <Typography>{product.phone}</Typography>
              </Stack>
              <Stack direction="row" sx={{ color: 'primary.main' }}>
                <Iconify icon="fluent:globe-32-filled" color="primary.main" width={25} />
                <Typography>{product.link}</Typography>
              </Stack>
            </Stack>
            <Stack gap={1} sx={{ width: '30%', px: 1 }}>
              <Stack sx={{ color: 'primary.main', flexDirection: 'row', alignItems: 'center' }}>
                <Iconify icon="cil:book" />
                <Typography sx={{ fontSize: '20px', fontWeight: 800 }}>אופן מימוש</Typography>
              </Stack>
              <Box component="li" sx={{ fontSize: '15px', color: 'primary.main' }}>
                שובר דיגיטלי המגיע ישירות לתיבת האימייל.
              </Box>
              <Box component="li" sx={{ fontSize: '15px', color: 'primary.main' }}>
                יש להגיע עם השובר המודפס ולהציג את קוד הקופון בתחילת ההזמנה
              </Box>
              <Box component="li" sx={{ fontSize: '15px', color: 'primary.main' }}>
                יש להגיע עם השובר המודפס ולהציג את קוד הקופון בתחילת ההזמנה
              </Box>
              <Box component="li" sx={{ fontSize: '15px', color: 'primary.main' }}>
                {`השובר מטעם חברת "קשרים פלוס" בע"מ - סליקת השובר מתבצעת בתוך מערכת microdeal בדף
                הספק.`}
              </Box>
            </Stack>
            <Stack sx={{ width: '30%' }} gap={1}>
              <Stack sx={{ color: 'primary.main', flexDirection: 'row', alignItems: 'center' }}>
                <Iconify icon="fluent:info-28-regular" />
                <Typography sx={{ fontSize: '20px', fontWeight: 800 }}>האותיות הקטנות</Typography>
              </Stack>
              <Box component="li" sx={{ fontSize: '15px', color: 'primary.main' }}>
                לא יינתן עודף לקופון שלא נוצל במלואו
              </Box>
              <Box component="li" sx={{ fontSize: '15px', color: 'primary.main' }}>
                ההטבה מקנה כניסה חד פעמית ליחיד
              </Box>
              <Box component="li" sx={{ fontSize: '15px', color: 'primary.main' }}>
                בתיאום מראש *חברת קשרים פלוס אינה אחראית לטיב ואיכות השירות\המוצר ו\או כל עניין
                הנובע מכך
              </Box>
              <Box component="li" sx={{ fontSize: '15px', color: 'primary.main' }}>
                התמונה להמחשה בלבד
              </Box>
              <Box component="li" sx={{ fontSize: '15px', color: 'primary.main' }}>
                {`ט.ל.ח הערות לספק *השובר מטעם חברת "קשרים פלוס" בעמ - סליקת השובר מתבצעת בתוך מערכת
                microdeal בדף הספק`}
              </Box>
            </Stack>
          </Stack>
        </Paper>
      </Card>
      <Stack alignItems="center" mt={5}>
        <Typography sx={{ fontSize: '30px', fontWeight: 800, color: 'primary.main' }}>
          תוים מכובדים
        </Typography>
        <Image src="/assets/images/happy-gift/Vector-4.png" width="40%" />
        <Box display="flex" mr={10}>
          {CARDS.map((card, id) => (
            <Box mr={-10} key={id}>
              <Image src={card.image} alt="#" />
            </Box>
          ))}
        </Box>
      </Stack>
    </Container>
  );
};
export default CouponDetailsView;
