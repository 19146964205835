import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField, ButtonGroup } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';
import { addToBasket } from 'src/api/basket';
import { useAuthContext } from 'src/auth/hooks';
import HotIcon from 'src/assets/icons/happy-gift/hotIcon';
import { extractPrices } from 'src/helpers/extractPrices';
import { generateOptions } from 'src/helpers/generateOptions';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import { uploadProductsImage } from 'src/helpers/uploadProductsImage';
import { checkIfProductInCart } from 'src/helpers/checkIfProductInCart';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

import { BlockType } from 'src/types/page-generator';
import { Ib2bProduct, ProvidersEnum } from 'src/types/b2b';
import { useContentContext } from '../../components/content';

type openPrice = {
  price: string;
  title: string;
  value: string;
};

type Props = {
  block: BlockType | undefined;
  product: Ib2bProduct;
  mode: string;
  handleOpenPriceChange: Function;
  openPriceProducts?: openPrice[] | any;
};

export const BannerProductCard = ({
  block,
  product,
  mode,
  handleOpenPriceChange,
  openPriceProducts,
}: Props) => {
  const { t } = useTranslate();
  const defaultLimit = 1;
  const isStock = product.provider === ProvidersEnum.STOCK;
  const limit =
    isStock && product.productCodeStockCount ? +product.productCodeStockCount : defaultLimit;
  const OPTIONS = generateOptions(1, limit);
  const { dispatch, state } = useAppContext();
  const { user } = useAuthContext();
  const { openSite } = useContentContext();
  const { cart } = state;
  const router = useRouter();
  const [count, setCount] = useState<string>('');
  const isInCart = checkIfProductInCart(cart, product.id);

  const settings = (): any => {
    if (mode === 'notCustom') return block?.settings;
    return block;
  };
  const { enableTimer, timerBgcolor, timerColor, variant } = settings();
  const {
    businessPrice,
    basePrice: discountPrice,
    price,
    maxPrice,
    minPrice,
  } = extractPrices(product?.prices);
  const isOpenPrice = !businessPrice && !price && !!maxPrice && !!minPrice;

  const allPrice = product?.prices ? product?.prices[0]?.value : '';

  const imageLink =
    (product.mainImageURL
      ? `${uploadProductsImage(`${product.mainImageURL}`)}`
      : product.merchants &&
        product.merchants.length &&
        `${uploadProductsImage(`${product.merchants[0].mainImageURL}`)}`) ||
    '/assets/images/product-default.png';

  const localDataString: string | null = localStorage.getItem('openPrice');
  const localData: any = localDataString ? JSON.parse(localDataString) : [];

  const value: number | undefined = localData.find(
    (item: openPrice) => item.value === product.id
  )?.price;

  const coincidence = openPriceProducts?.find((item: openPrice) => item.value === product.id);

  let currentPriceView = coincidence?.price;
  if (coincidence && maxPrice && coincidence?.price > maxPrice) currentPriceView = maxPrice;
  if (coincidence && minPrice && coincidence?.price < minPrice) currentPriceView = minPrice;

  useEffect(() => {
    const current = localData?.find((item: openPrice) => item.value === product.id);
    setCount(current?.price);
  }, [state]);

  const info = (val: Ib2bProduct, localBlock: BlockType | undefined) => {
    localStorage.setItem('product', JSON.stringify(val));
    if (localBlock !== undefined) {
      localStorage.setItem('settings', JSON.stringify(localBlock));
    }
    router.push(`/product/${val.id}`);
  };

  const handleClick = () => {
    const currentPageUrl = window.location.href;
    let params = '?';
    if (block?.settings?.enableDiscount) params += params === '?' ? 'ds' : ',ds';
    if (block?.settings?.enableBalance) params += params === '?' ? 'ba' : ',ba';
    if (block?.settings?.enableCount) params += params === '?' ? 'co' : ',co';
    if (block?.settings?.enableAvailable) params += params === '?' ? 'av' : ',av';
    if (block?.settings?.enableAvailableLimit) params += params === '?' ? 'av' : ',avl';
    const url = `${currentPageUrl}product/${product.id}/${params}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert('Copied');
      })
      .catch((error) => {
        console.error('Failed to copy: ', error);
      });
  };

  return (
    <Grid item md={3}>
      <Card>
        {enableTimer && (
          <Stack
            direction="row-reverse"
            alignItems="center"
            justifyContent={enableTimer ? 'space-between' : 'center'}
          >
            <ButtonGroup
              variant="contained"
              sx={{
                '& .MuiButtonGroup-contained': {
                  borderRadius: '5px',
                },
                '& .MuiButtonBase-root': {
                  '&:first-of-type': { borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' },
                  '&:last-of-type': { borderTopRightRadius: '5px', borderBottomRightRadius: '5px' },
                  // borderRadius: '5px',
                  backgroundColor: timerBgcolor,
                  color: timerColor,
                  height: '25px',
                  alignItems: 'center',
                  fontSize: '10px',
                  p: 0.5,
                  '&:hover': {
                    backgroundColor: timerBgcolor,
                    color: timerColor,
                  },
                },
              }}
            >
              <Button>10 שעות</Button>
              <Button>10 דקות</Button>
              <Button>10 שניות</Button>
            </ButtonGroup>
            {block?.settings?.enableBrands ? (
              <Image src={product?.brand} sx={{ width: '30%', height: '50px' }} />
            ) : (
              <Box sx={{ height: '50px' }} />
            )}
          </Stack>
        )}
        <Stack sx={{ p: 1 }} gap={1}>
          <Image
            onClick={() => {
              info(product && product, block);
            }}
            src={imageLink}
            sx={{
              height: '180px',
              cursor: 'pointer',
              '& img': { objectFit: 'unset' },
            }}
          />
          {block?.settings?.enableHotImage && (
            <HotIcon
              color={block?.settings?.hotImageColor}
              bgcolor={block?.settings?.hotImageBgcolor}
              sx={{
                position: 'absolute',
                width: '11%',
                height: '7%',
                top: enableTimer ? '60px' : '20px',
                left: '10px',
              }}
            />
          )}
          <Typography
            sx={{
              color: block?.settings?.contentTextColor,
              fontSize: block?.settings?.contentFontSize,
              fontWeight: block?.settings?.contentFontWeight,
              height: '60px',
              fontStyle: block?.settings?.contentFontStyle,
              textDecoration: block?.settings?.contentTextDecoration,
            }}
          >
            {product?.title}
          </Typography>
          {variant === 'v1' ? (
            <Stack
              direction="row-reverse"
              justifyContent="space-between"
              gap={1}
              alignItems="center"
              width={1}
            >
              {block?.settings?.enableButton && (
                <>
                  {isInCart ? (
                    <Button
                      variant="contained"
                      color="success"
                      sx={{
                        width: '70%',
                        height: '34px',
                        borderRadius: '25px',
                        fontWeight: 400,
                      }}
                      endIcon={<Iconify icon="ep:success-filled" />}
                    >
                      בעגלה
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size={block?.settings?.buttonSize}
                      onClick={() => {
                        if (!user) {
                          dispatch({
                            type: ActionTypes.SET_ACTIVE_DIALOG,
                            payload: 'login',
                          });
                        } else if (!block?.settings?.linkOnProductInfo) {
                          // if (isOpenPrice && !coincidence) return;
                          let currentPrice;

                          if (value && !coincidence) {
                            currentPrice = value;
                            if (minPrice && value < minPrice) currentPrice = minPrice;
                            if (maxPrice && value > maxPrice) currentPrice = maxPrice;
                          } else if (isOpenPrice && coincidence) {
                            currentPrice = coincidence.price;
                            if (+coincidence.price > maxPrice) currentPrice = maxPrice;
                            if (+coincidence.price < minPrice) currentPrice = minPrice;
                          } else {
                            currentPrice =
                              (block?.settings?.enableDiscount && discountPrice) || price;
                          }
                          addToBasket(
                            state.smbAccount.id,
                            product.id,
                            currentPrice,
                            imageLink,
                            dispatch,
                            cart
                          );
                        } else info(product, block);
                      }}
                      sx={{
                        borderRadius: block?.settings?.buttonBorderRadius,
                        height: '30px',
                        width: '70%',
                        background: block?.settings?.buttonBgColor,
                        color: block?.settings?.buttonColor,
                        fontWeight: 400,
                        '&:hover': {
                          background: block?.settings?.buttonBgColor,
                          color: block?.settings?.buttonColor,
                        },
                      }}
                      endIcon={<Iconify icon={block?.settings?.buttonIcon} />}
                    >
                      {block?.settings?.buttonLabel}
                    </Button>
                  )}
                </>
              )}
              {block?.settings?.enablePrice && (
                <>
                  {!isOpenPrice && (
                    <Stack>
                      {block.settings.enableDiscount && (
                        <Typography
                          sx={{
                            color: block.settings.topPriceColor,
                            textDecoration: 'line-through',
                            fontSize: block.settings.topPriceFontSize,
                          }}
                        >
                          ₪{price}
                        </Typography>
                      )}
                      {block.settings.enableDiscount ? (
                        <Typography
                          sx={{
                            color: block.settings.bottomPriceColor,
                            fontSize: block.settings.bottomPriceFontSize,
                          }}
                        >
                          ₪{discountPrice}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            color: block.settings.bottomPriceColor,
                            fontSize: block.settings.bottomPriceFontSize,
                          }}
                        >
                          ₪{block.settings.enableSwitch ? allPrice : price}
                        </Typography>
                      )}
                    </Stack>
                  )}
                  {isOpenPrice && (
                    <>
                      {coincidence && coincidence?.price ? (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography
                            sx={{
                              color: block?.settings?.bottomPriceColor,
                              fontSize: block?.settings?.bottomPriceFontSize,
                            }}
                          >
                            ₪{coincidence && coincidence?.price && currentPriceView}
                          </Typography>
                        </Stack>
                      ) : (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <TextField
                            name="openPrice"
                            placeholder={`${t('Sum')}`}
                            sx={{ width: 60, '& input': { p: 1 } }}
                            value={count}
                            onChange={(e) => {
                              handleOpenPriceChange(e, product, maxPrice, minPrice);
                              setCount(e.target.value);
                            }}
                          />
                          <Typography
                            sx={{
                              color: 'text.secondary',
                              fontSize: 16,
                            }}
                          >
                            ₪
                          </Typography>
                        </Stack>
                      )}
                    </>
                  )}
                </>
              )}
            </Stack>
          ) : (
            <Stack
              direction="column"
              justifyContent="space-between"
              gap={1}
              alignItems="center"
              width={1}
            >
              {block?.settings?.enablePrice && (
                <>
                  {!isOpenPrice && (
                    <Stack width={0.3}>
                      {block.settings.enableDiscount && (
                        <Typography
                          sx={{
                            color: block.settings.topPriceColor,
                            textDecoration: 'line-through',
                            fontSize: block.settings.topPriceFontSize,
                          }}
                        >
                          ₪{price}
                        </Typography>
                      )}
                      {block.settings.enableDiscount ? (
                        <Typography
                          sx={{
                            color: block.settings.bottomPriceColor,
                            fontSize: block.settings.bottomPriceFontSize,
                          }}
                        >
                          ₪{discountPrice}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            color: block.settings.bottomPriceColor,
                            fontSize: block.settings.bottomPriceFontSize,
                          }}
                        >
                          ₪{block.settings.enableSwitch ? allPrice : price}
                        </Typography>
                      )}
                    </Stack>
                  )}
                  {isOpenPrice && (
                    <>
                      {coincidence && coincidence?.price ? (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography
                            sx={{
                              color: block?.settings?.bottomPriceColor,
                              fontSize: block?.settings?.bottomPriceFontSize,
                            }}
                          >
                            ₪{coincidence && coincidence?.price && currentPriceView}
                          </Typography>
                        </Stack>
                      ) : (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <TextField
                            name="openPrice"
                            placeholder={`${t('Sum')}`}
                            sx={{ width: 60, '& input': { p: 1 } }}
                            value={count}
                            onChange={(e) => {
                              handleOpenPriceChange(e, product, maxPrice, minPrice);
                              setCount(e.target.value);
                            }}
                          />
                          <Typography
                            sx={{
                              color: 'text.secondary',
                              fontSize: 16,
                            }}
                          >
                            ₪
                          </Typography>
                        </Stack>
                      )}
                    </>
                  )}
                </>
              )}
              {block?.settings?.enableButton && (
                <Button
                  variant="contained"
                  size={block?.settings?.buttonSize}
                  sx={{
                    width: '70%',
                    borderRadius: block?.settings?.buttonBorderRadius,
                    height: '30px',
                    background: block?.settings?.buttonBgColor,
                    color: block?.settings?.buttonColor,
                    fontWeight: 400,
                    fontSize: '12px',
                    '&:hover': {
                      background: block?.settings?.buttonBgColor,
                      color: block?.settings?.buttonColor,
                    },
                  }}
                  endIcon={<Iconify icon={block?.settings?.buttonIcon} />}
                  onClick={() => {
                    if (!user) {
                      dispatch({
                        type: ActionTypes.SET_ACTIVE_DIALOG,
                        payload: 'login',
                      });
                    } else if (!block?.settings?.linkOnProductInfo) {
                      // if (isOpenPrice && !coincidence) return;
                      let currentPrice;
                      if (value) {
                        currentPrice = value;
                        if (minPrice && value < minPrice) currentPrice = minPrice;
                        if (maxPrice && value > maxPrice) currentPrice = maxPrice;
                      } else if (isOpenPrice && coincidence) {
                        currentPrice = coincidence.price;
                        if (+coincidence.price > maxPrice) currentPrice = maxPrice;
                        if (+coincidence.price < minPrice) currentPrice = minPrice;
                      } else {
                        currentPrice = (block?.settings?.enableDiscount && discountPrice) || price;
                      }
                      addToBasket(
                        state.smbAccount.id,
                        product.id,
                        currentPrice,
                        imageLink,
                        dispatch,
                        cart
                      );
                    } else info(product, block);
                  }}
                >
                  {block?.settings?.buttonLabel}
                </Button>
              )}
            </Stack>
          )}
          {variant === 'v1' && (
            <Grid
              container
              sx={{
                p: 1,
                display:
                  !block?.settings?.enableBalance &&
                  !block?.settings?.enableAvailable &&
                  !block?.settings?.enableShare
                    ? 'none'
                    : '',
              }}
            >
              <Grid item md={6}>
                {block?.settings?.enableBalance && (
                  <Stack direction="row">
                    <Typography>נשאר: 199</Typography>
                  </Stack>
                )}
                {block?.settings?.enableShare && (
                  <Iconify
                    icon="solar:share-bold"
                    width={16}
                    sx={{ mr: 0.5, cursor: 'pointer' }}
                    onClick={handleClick}
                  />
                )}
              </Grid>
              <Grid item md={6}>
                <Stack
                  sx={{
                    display:
                      !block?.settings?.enableAvailable && !block?.settings?.enableCount
                        ? 'none'
                        : '',
                  }}
                >
                  {block?.settings?.enableCount && (
                    <Stack direction="row" justifyContent="space-between">
                      <Typography sx={{ mx: 1 }}>כמות</Typography>
                      <TextField
                        sx={{
                          '& .MuiNativeSelect-select': { py: 0 },
                        }}
                        select
                        fullWidth
                        SelectProps={{
                          native: true,
                          sx: { textTransform: 'capitalize' },
                        }}
                        onChange={() => {}}
                      >
                        {OPTIONS.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </TextField>
                    </Stack>
                  )}
                  {block?.settings?.enableAvailable && (
                    <Typography
                      sx={{
                        fontSize: !block?.settings?.enableCount ? '15px' : '10px',
                        textAlign: 'end',
                      }}
                    >
                      ניתן לקנייה: 1
                    </Typography>
                  )}
                </Stack>
              </Grid>
            </Grid>
          )}
          {variant === 'v1' && block?.settings?.enableSocialLinks && (
            <Stack direction="row-reverse" gap={1} alignItems="flex-end">
              <Stack direction="row" justifyContent="flex-start" sx={{ mt: 2 }} spacing={1}>
                {block?.settings?.socialLinks?.map((socialLink: any, idx: any) => (
                  <Box
                    key={`socialLinkItem_${idx}`}
                    bgcolor={
                      block?.settings?.customSocialLinkColor
                        ? block?.settings?.socialLinksBgcolor
                        : ''
                    }
                    borderRadius={50}
                    width={28}
                    height={28}
                    display="flex"
                    flexDirection="row"
                    // component={Link}
                    // href={socialLink.path}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      ':hover': {
                        bgcolor: block?.settings?.customSocialLinkColor
                          ? block?.settings?.socialLinksBgcolor
                          : '',
                        transition: '.25s ease all',
                        cursor: 'pointer',
                        svg: {
                          color: block?.settings?.customSocialLinkColor
                            ? block?.settings?.socialLinksColor
                            : '',
                        },
                      },
                    }}
                  >
                    <Iconify
                      width={16}
                      height={16}
                      color={
                        block?.settings?.customSocialLinkColor
                          ? block?.settings?.socialLinksColor
                          : ''
                      }
                      icon={socialLink.icon ?? 'material-symbols-light:link'}
                    />
                  </Box>
                ))}
              </Stack>
              <Typography
                sx={{
                  color: block?.settings?.socialContentTextColor,
                  fontSize: block?.settings?.socialContentFontSize,
                  fontWeight: block?.settings?.socialContentFontWeight,
                  fontStyle: block?.settings?.socialContentFontStyle,
                  textDecoration: block?.settings?.socialContentTextDecoration,
                }}
              >
                {block?.settings?.socialContentTitle}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Card>
    </Grid>
  );
};
