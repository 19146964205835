import { FC, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';

import Grid from '@mui/material/Grid';
import { Box, Stack, Button } from '@mui/material';

import Image from '../../../../components/image';
import Iconify from '../../../../components/iconify';
import { getContentValueFromProps } from '../../utils';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';
import { useRouter } from '../../../../routes/hooks';

type ButtonItem = {
  img: string;
  button: any;
  banner: any;
};

const CatalogBoomBuy: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const { t } = useTranslation();
  const isMobile = useResponsive('down', 'sm');
  const router = useRouter();
  const enableFirstButton = useMemo(
    () => getFieldValue('settings.enableFirstButton'),
    [block?.settings.enableFirstButton]
  );
  const enableSecondButton = useMemo(
    () => getFieldValue('settings.enableSecondButton'),
    [block?.settings.enableSecondButton]
  );
  const enableThirdButton = useMemo(
    () => getFieldValue('settings.enableThirdButton'),
    [block?.settings.enableThirdButton]
  );
  const enableFourthButton = useMemo(
    () => getFieldValue('settings.enableFourthButton'),
    [block?.settings.enableFourthButton]
  );
  const swiperRef = useRef<SwiperCore | null>(null);
  const firstImage = useMemo(() => getFieldValue('firstImage'), [block?.firstImage]);
  const secondImage = useMemo(() => getFieldValue('secondImage'), [block?.secondImage]);
  const thirdImage = useMemo(() => getFieldValue('thirdImage'), [block?.thirdImage]);
  const fourthImage = useMemo(() => getFieldValue('fourthImage'), [block?.fourthImage]);

  const firstBannerHref = useMemo(() => getFieldValue('firstBannerHref'), [block?.firstBannerHref]);
  const firstBannerModal = useMemo(
    () => getFieldValue('firstBannerModal'),
    [block?.firstBannerModal]
  );
  const firstBannerDrawer = useMemo(
    () => getFieldValue('firstBannerDrawer'),
    [block?.firstBannerDrawer]
  );

  const secondBannerHref = useMemo(
    () => getFieldValue('secondBannerHref'),
    [block?.secondBannerHref]
  );
  const secondBannerModal = useMemo(
    () => getFieldValue('secondBannerModal'),
    [block?.secondBannerModal]
  );
  const secondBannerDrawer = useMemo(
    () => getFieldValue('secondBannerDrawer'),
    [block?.secondBannerDrawer]
  );

  const thirdBannerHref = useMemo(() => getFieldValue('thirdBannerHref'), [block?.thirdBannerHref]);
  const thirdBannerModal = useMemo(
    () => getFieldValue('thirdBannerModal'),
    [block?.thirdBannerModal]
  );
  const thirdBannerDrawer = useMemo(
    () => getFieldValue('thirdBannerDrawer'),
    [block?.thirdBannerDrawer]
  );

  const fourthBannerHref = useMemo(
    () => getFieldValue('fourthBannerHref'),
    [block?.fourthBannerHref]
  );
  const fourthBannerModal = useMemo(
    () => getFieldValue('fourthBannerModal'),
    [block?.fourthBannerModal]
  );
  const fourthBannerDrawer = useMemo(
    () => getFieldValue('fourthBannerDrawer'),
    [block?.fourthBannerDrawer]
  );

  // First button settings
  const firstButtonLabel = useMemo(
    () => getFieldValue('firstButtonLabel'),
    [block?.firstButtonLabel]
  );
  const firstButtonHref = useMemo(() => getFieldValue('firstButtonHref'), [block?.firstButtonHref]);
  const firstButtonIcon = useMemo(() => getFieldValue('firstButtonIcon'), [block?.firstButtonIcon]);
  const firstButtonBgColor = useMemo(
    () => getFieldValue('firstButtonBgColor'),
    [block?.firstButtonBgColor]
  );
  const firstButtonColor = useMemo(
    () => getFieldValue('firstButtonColor'),
    [block?.firstButtonColor]
  );
  const firstButtonSize = useMemo(() => getFieldValue('firstButtonSize'), [block?.firstButtonSize]);
  const firstButtonVariant = useMemo(
    () => getFieldValue('firstButtonVariant'),
    [block?.firstButtonVariant]
  );
  const firstButtonDrawer = useMemo(
    () => getFieldValue('firstButtonDrawer'),
    [block?.firstButtonDrawer]
  );
  const firstButtonModal = useMemo(
    () => getFieldValue('firstButtonModal'),
    [block?.firstButtonModal]
  );

  // Second button settings
  const secondButtonLabel = useMemo(
    () => getFieldValue('secondButtonLabel'),
    [block?.secondButtonLabel]
  );
  const secondButtonHref = useMemo(
    () => getFieldValue('secondButtonHref'),
    [block?.secondButtonHref]
  );
  const secondButtonIcon = useMemo(
    () => getFieldValue('secondButtonIcon'),
    [block?.secondButtonIcon]
  );
  const secondButtonBgColor = useMemo(
    () => getFieldValue('secondButtonBgColor'),
    [block?.secondButtonBgColor]
  );
  const secondButtonColor = useMemo(
    () => getFieldValue('secondButtonColor'),
    [block?.secondButtonColor]
  );
  const secondButtonSize = useMemo(
    () => getFieldValue('secondButtonSize'),
    [block?.secondButtonSize]
  );
  const secondButtonVariant = useMemo(
    () => getFieldValue('secondButtonVariant'),
    [block?.secondButtonVariant]
  );
  const secondButtonDrawer = useMemo(
    () => getFieldValue('secondButtonDrawer'),
    [block?.secondButtonDrawer]
  );
  const secondButtonModal = useMemo(
    () => getFieldValue('secondButtonModal'),
    [block?.secondButtonModal]
  );

  // Third button settings
  const thirdButtonLabel = useMemo(
    () => getFieldValue('thirdButtonLabel'),
    [block?.thirdButtonLabel]
  );
  const thirdButtonHref = useMemo(() => getFieldValue('thirdButtonHref'), [block?.thirdButtonHref]);
  const thirdButtonIcon = useMemo(() => getFieldValue('thirdButtonIcon'), [block?.thirdButtonIcon]);
  const thirdButtonBgColor = useMemo(
    () => getFieldValue('thirdButtonBgColor'),
    [block?.thirdButtonBgColor]
  );
  const thirdButtonColor = useMemo(
    () => getFieldValue('thirdButtonColor'),
    [block?.thirdButtonColor]
  );
  const thirdButtonSize = useMemo(() => getFieldValue('thirdButtonSize'), [block?.thirdButtonSize]);
  const thirdButtonVariant = useMemo(
    () => getFieldValue('thirdButtonVariant'),
    [block?.thirdButtonVariant]
  );
  const thirdButtonDrawer = useMemo(
    () => getFieldValue('thirdButtonDrawer'),
    [block?.thirdButtonDrawer]
  );
  const thirdButtonModal = useMemo(
    () => getFieldValue('thirdButtonModal'),
    [block?.thirdButtonModal]
  );

  // Fourth button settings
  const fourthButtonLabel = useMemo(
    () => getFieldValue('fourthButtonLabel'),
    [block?.fourthButtonLabel]
  );
  const fourthButtonHref = useMemo(
    () => getFieldValue('fourthButtonHref'),
    [block?.fourthButtonHref]
  );
  const fourthButtonIcon = useMemo(
    () => getFieldValue('fourthButtonIcon'),
    [block?.fourthButtonIcon]
  );
  const fourthButtonBgColor = useMemo(
    () => getFieldValue('fourthButtonBgColor'),
    [block?.fourthButtonBgColor]
  );
  const fourthButtonColor = useMemo(
    () => getFieldValue('fourthButtonColor'),
    [block?.fourthButtonColor]
  );
  const fourthButtonSize = useMemo(
    () => getFieldValue('fourthButtonSize'),
    [block?.fourthButtonSize]
  );
  const fourthButtonVariant = useMemo(
    () => getFieldValue('fourthButtonVariant'),
    [block?.fourthButtonVariant]
  );
  const fourthButtonDrawer = useMemo(
    () => getFieldValue('fourthButtonDrawer'),
    [block?.fourthButtonDrawer]
  );
  const fourthButtonModal = useMemo(
    () => getFieldValue('fourthButtonModal'),
    [block?.fourthButtonModal]
  );

  const variant = useMemo(() => getFieldValue('settings.variant'), [block?.settings.variant]);
  const { state, dispatch } = useAppContext();

  const indentation = useMemo(
    () => getFieldValue('settings.indentation'),
    [block?.settings.indentation]
  );

  const items: ButtonItem[] = [
    {
      img: firstImage,
      button: {
        label: firstButtonLabel,
        href: firstButtonHref,
        icon: firstButtonIcon,
        size: firstButtonSize,
        bgcolor: firstButtonBgColor,
        color: firstButtonColor,
        variant: firstButtonVariant,
        isDialogToggler: firstButtonDrawer,
        modalId: firstButtonModal,
        show: enableFirstButton,
      },
      banner: {
        href: firstBannerHref,
        modalId: firstBannerModal,
        isDialogToggler: firstBannerDrawer,
      },
    },
    {
      img: secondImage,
      button: {
        label: secondButtonLabel,
        href: secondButtonHref,
        icon: secondButtonIcon,
        size: secondButtonSize,
        bgcolor: secondButtonBgColor,
        color: secondButtonColor,
        variant: secondButtonVariant,
        isDialogToggler: secondButtonDrawer,
        modalId: secondButtonModal,
        show: enableSecondButton,
      },
      banner: {
        href: secondBannerHref,
        modalId: secondBannerModal,
        isDialogToggler: secondBannerDrawer,
      },
    },
    {
      img: thirdImage,
      button: {
        label: thirdButtonLabel,
        href: thirdButtonHref,
        icon: thirdButtonIcon,
        size: thirdButtonSize,
        bgcolor: thirdButtonBgColor,
        color: thirdButtonColor,
        variant: thirdButtonVariant,
        isDialogToggler: thirdButtonDrawer,
        modalId: thirdButtonModal,
        show: enableThirdButton,
      },
      banner: {
        href: thirdBannerHref,
        modalId: thirdBannerModal,
        isDialogToggler: thirdBannerDrawer,
      },
    },
    {
      img: fourthImage,
      button: {
        label: fourthButtonLabel,
        href: fourthButtonHref,
        icon: fourthButtonIcon,
        size: fourthButtonSize,
        bgcolor: fourthButtonBgColor,
        color: fourthButtonColor,
        variant: fourthButtonVariant,
        isDialogToggler: fourthButtonDrawer,
        modalId: fourthButtonModal,
        show: enableFourthButton,
      },
      banner: {
        href: fourthBannerHref,
        modalId: fourthBannerModal,
        isDialogToggler: fourthBannerDrawer,
      },
    },
  ];

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const clickToTheCard = (item: any) => {
    if (block?.settings?.variant === 'card') {
      if (item?.banner?.isDialogToggler) {
        setActiveDialog(item?.banner?.modalId);
      } else if (item?.banner?.href) router.push(`/${item?.banner?.href}`);
    }
  };

  return (
    <>
      {variant === 'v1' ? (
        <Grid container sx={{ height: '375px' }} spacing={indentation}>
          {items.map((item, id) => (
            <Grid item md={3} xs={6}>
              <Box
                key={id}
                sx={{
                  position: 'relative',
                  backgroundPosition: '50%',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  width: '100%',
                  height: '100%',
                  cursor: block?.settings?.variant === 'card' ? 'pointer' : '',
                }}
                onClick={() => clickToTheCard(item)}
              >
                <Image src={item.img} />
                <Stack width={1} alignItems="center">
                  <Button
                    variant={item.button.variant || 'contained'}
                    size={isMobile ? 'small' : item.button.size || 'medium'}
                    sx={{
                      display: !item.button.show ? 'none' : '',
                      borderRadius: 10,
                      position: 'absolute',
                      bottom: isMobile ? '20px' : '25px',
                      color: item.button.color,
                      bgcolor: item.button.bgcolor,
                      '&:hover': {
                        color: item.button.color,
                        bgcolor: item.button.bgcolor,
                      },
                    }}
                    endIcon={<Iconify icon={item?.button.icon || ''} />}
                    href={
                      block?.settings?.variant === 'button' &&
                      ((!item.button.href.includes('modal') &&
                        !item.button.isDialogToggler &&
                        !item.button.href.includes('#') &&
                        item.button.href) ||
                        '')
                    }
                    onClick={() => {
                      if (block?.settings?.variant === 'button') {
                        if (item.button.isDialogToggler && item.button.modalId) {
                          setActiveDialog(item.button.modalId);
                        }
                        if (item.button.href.includes('#')) {
                          const newLink = item.button?.href.replace('#', '');
                          window.location.href = `#${newLink}`;
                        }
                      }
                    }}
                  >
                    {item.button.label || `[${t('no  label')}]`}
                  </Button>
                </Stack>
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          {!isMobile ? (
            <Box
              sx={{
                width: '100%',
                borderRadius: '0px',
                backgroundSize: 'cover',
                padding: 5,
              }}
            >
              <Stack direction="row" height="255px">
                {items.map((item, id) => (
                  <Box
                    key={`${item.img}_${id}`}
                    sx={{
                      position: 'relative',
                      backgroundImage: `url(${item.img})`,
                      backgroundPosition: '50%',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      width: '100%',
                      height: '100%',
                      cursor: block?.settings?.variant === 'card' ? 'pointer' : '',
                    }}
                    onClick={() => clickToTheCard(item)}
                  >
                    <Stack width={1} alignItems="center">
                      <Button
                        variant={item.button.variant || 'contained'}
                        size={isMobile ? 'small' : item.button.size || 'medium'}
                        sx={{
                          display: !item.button.show ? 'none' : '',
                          borderRadius: 10,
                          position: 'absolute',
                          bottom: isMobile ? '20px' : '25px',
                          color: item.button.color,
                          bgcolor: item.button.bgcolor,
                          '&:hover': {
                            color: item.button.color,
                            bgcolor: item.button.bgcolor,
                          },
                        }}
                        endIcon={<Iconify icon={item?.button.icon || ''} />}
                        href={
                          block?.settings?.variant === 'button' &&
                          ((!item.button.href.includes('modal') &&
                            !item.button.isDialogToggler &&
                            !item.button.href.includes('#') &&
                            item.button.href) ||
                            '')
                        }
                        onClick={() => {
                          if (block?.settings?.variant === 'button') {
                            if (item.button.isDialogToggler && item.button.modalId) {
                              setActiveDialog(item.button.modalId);
                            }
                            if (item.button.href.includes('#')) {
                              const newLink = item.button?.href.replace('#', '');
                              window.location.href = `#${newLink}`;
                            }
                          }
                        }}
                      >
                        {item.button.label || `[${t('no  label')}]`}
                      </Button>
                    </Stack>
                  </Box>
                ))}
              </Stack>
            </Box>
          ) : (
            <Box
              sx={{
                '& .swiper-slide': { width: '70vw', margin: 1 },
                '& .swiper-wrapper': { width: '100vw' },
              }}
            >
              <Stack direction="row" height="255px">
                <Swiper
                  speed={500}
                  slidesPerView="auto"
                  mousewheel={{
                    forceToAxis: true,
                    sensitivity: 1,
                    releaseOnEdges: true,
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                >
                  {items.map((item, id) => (
                    <SwiperSlide key={`${item.img}_${id}`}>
                      <Box
                        key={`${item.img}_${id}`}
                        sx={{
                          position: 'relative',
                          backgroundImage: `url(${item.img})`,
                          backgroundPosition: '50%',
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <Stack width={1} alignItems="center">
                          <Button
                            variant={item.button.variant || 'contained'}
                            size={item.button.size || 'medium'}
                            href={item.button.href}
                            sx={{
                              display: !item.button.show ? 'none' : '',
                              borderRadius: 10,
                              position: 'absolute',
                              bottom: '25px',
                              color: item.button.color,
                              bgcolor: item.button.bgcolor,
                              '&:hover': {
                                color: item.button.color,
                                bgcolor: item.button.bgcolor,
                              },
                            }}
                            endIcon={<Iconify icon={item?.button.icon || ''} />}
                          >
                            {item.button.label || `[${t('no label')}]`}
                          </Button>
                        </Stack>
                      </Box>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Stack>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default CatalogBoomBuy;
