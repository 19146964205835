import { FC } from 'react';

import { Card, Grid, Stack, Button, Typography } from '@mui/material';

import Image from '../../../../components/image';
import { ComponentTypeProps } from '../../../../types/page-generator';

const PastimeBanner: FC<ComponentTypeProps> = ({ block }) => (
  <Card>
    <Grid container spacing={2} direction="row-reverse">
      <Grid item md={6} xs={12}>
        <Stack alignItems="center" my={3}>
          <Image
            src="/assets/images/happy-gift/banners/two_people.png"
            sx={{ borderRadius: '20px', width: '70%' }}
          />
        </Stack>
      </Grid>
      <Grid item md={6} xs={12}>
        <Stack sx={{ color: '#3A0D5E', my: 2 }} gap={2}>
          <Typography sx={{ fontSize: '25px', fontWeight: 600 }}>בילוי זוגי</Typography>
          <Stack>
            <Typography sx={{ fontWeight: 600 }}>
              השובר מעניק אפשרות בחירה בין ההצעות הבאות:
            </Typography>
            <Typography>
              ארוחת בוקר זוגית / זיכוי כספי בשווי 85 / 75 / 65 ₪ במאות מסעדות בארץ
            </Typography>
          </Stack>
          <Typography>
            זיכוי כספי בשווי 85 / 75 / 65 ₪ ברשתות אופנה ופנאי, ספורט, מוצרים לבית, מתנות ועוד...
          </Typography>
          <Typography>
            {' '}
            זוג כרטיסים לסרט ברשת סינמה סיטי / מגוון אטרקציות לבחירה בפריסה ארצית{' '}
          </Typography>
          <Typography>זיכוי כספי בשווי 85 / 75 / 65 ₪ בהזמנת טיפול במגוון אתרי ספא</Typography>
          <Typography> ניתן לקבל שובר זה גם בערכים של 95 / 90 / 80 / 70 ₪</Typography>
          <Button sx={{ bgcolor: '#EB157B', color: 'white', borderRadius: '20px' }}>
            אני רוצה לשמח את העובדים שלי{' '}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  </Card>
);
export default PastimeBanner;
