import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import Typography from '@mui/material/Typography';

import { useRouter } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';
import { addToBasket } from 'src/api/basket';
import { useAuthContext } from 'src/auth/hooks';
import { extractPrices } from 'src/helpers/extractPrices';
import { generateOptions } from 'src/helpers/generateOptions';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import { uploadProductsImage } from 'src/helpers/uploadProductsImage';
import { checkIfProductInCart } from 'src/helpers/checkIfProductInCart';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

import { BlockType } from 'src/types/page-generator';
import { Ib2bProduct, ProvidersEnum } from 'src/types/b2b';
import { useResponsive } from '../../hooks/use-responsive';
import { useContentContext } from '../../components/content';
import addToCart from '../../extra-components/bazaar/product-cards/product-card-9/add-to-cart';
import { useGetProduct } from '../../api/product';

type openPrice = {
  price: string;
  title: string;
  value: string;
};

interface ProductOpenPrice {
  price: number;
  title: string;
  value: string;
}

type Props = {
  block: BlockType;
  localSettings: BlockType;
  product: Ib2bProduct;
  mode: string;
  openPriceProducts?: openPrice[] | any;
  handleOpenPriceChange: Function;
  productOpenPrices?: ProductOpenPrice[];
};

export const ProductCard = ({
  block,
  localSettings,
  product,
  mode,
  openPriceProducts,
  handleOpenPriceChange,
  productOpenPrices,
}: Props) => {
  const { user } = useAuthContext();
  const { openSite } = useContentContext();
  const router = useRouter();
  const { t } = useTranslate();
  const defaultLimit = 1;
  const isStock = product?.provider === ProvidersEnum.STOCK;
  const isMobile = useResponsive('down', 'sm');
  const limit =
    isStock && product?.productCodeStockCount ? +product.productCodeStockCount : defaultLimit;
  const OPTIONS = generateOptions(1, limit);
  const { dispatch, state } = useAppContext();
  const { cart } = state;
  const [count, setCount] = useState<string>('');
  const localDataString: string | null = localStorage.getItem('openPrice');
  const localData: any = localDataString ? JSON.parse(localDataString) : [];
  const isInCart = checkIfProductInCart(cart, product?.id);
  const { product: localProduct } = useGetProduct(product?.id);
  const settings = () => {
    if (mode !== 'notCustom' && !localSettings?.settings?.generalSettings) return block;
    if (mode !== 'notCustom' && localSettings?.settings?.generalSettings)
      return localSettings.settings;
    return block?.settings;
  };

  const info = (value: Ib2bProduct, localBlock: BlockType | undefined) => {
    localStorage.setItem('product', JSON.stringify(value));
    if (localBlock !== undefined) {
      localStorage.setItem('settings', JSON.stringify(localBlock));
    }
    router.push(`/product/${value.id}`);
  };

  const value: number | undefined = localData.find(
    (item: ProductOpenPrice) => item.value === product?.id
  )?.price;

  const coincidence = openPriceProducts?.find((item: openPrice) => item.value === product?.id);

  useEffect(() => {
    const updatedCard = state?.openPricesProducts?.map((item: ProductOpenPrice) => {
      if (item.value === product?.id) {
        return {
          ...item,
          price: count,
        };
      }
      return item;
    });
    dispatch({ type: ActionTypes.SET_OPEN_PRICE_PRODUCTS, payload: updatedCard });
  }, [count]);
  useEffect(() => {
    const current = localData?.find((item: openPrice) => item.value === product?.id);
    setCount(current?.price);
  }, [state]);

  const {
    contentTextColor,
    contentFontSize,
    contentFontWeight,
    contentFontStyle,
    contentTextDecoration,
    contentAlign,
    enableButtonAdd,
    buttonBorderRadius,
    buttonBgColor,
    buttonColor,
    enableButton,
    buttonIcon,
    enableCount,
    enablePrice,
    enableSwitch,
    enableDiscount,
    topPriceColor,
    topPriceFontSize,
    bottomPriceColor,
    bottomPriceFontSize,
    enableBalance,
    enableShare,
    enableAvailable,
    enableAvailableLimit,
    buttonLabel,
    buttonLabelNoPrice,
  } = settings();

  const { businessPrice, basePrice, price, maxPrice, minPrice } = extractPrices(product?.prices);

  // const isOpenPrice = !businessPrice && !price && !!maxPrice && !!minPrice;
  const isOpenPrice = !businessPrice && !price;

  const allPrice = product?.prices ? product?.prices[0]?.value : '';

  let currentPriceView = coincidence?.price;
  if (coincidence && maxPrice && coincidence?.price > maxPrice) currentPriceView = maxPrice;
  if (coincidence && minPrice && coincidence?.price < minPrice) currentPriceView = minPrice;

  const imageLink =
    (localProduct?.mainImageURL
      ? `${uploadProductsImage(`${localProduct?.mainImageURL}`)}`
      : localProduct?.merchants &&
        localProduct?.merchants.length &&
        `${uploadProductsImage(`${localProduct?.merchants[0].mainImageURL}`)}`) || '';

  const handleClick = () => {
    const currentPageUrl = window.location.href;
    let params = '?';
    if (enableDiscount) params += params === '?' ? 'ds' : ',ds';
    if (enableBalance) params += params === '?' ? 'ba' : ',ba';
    if (enableCount) params += params === '?' ? 'co' : ',co';
    if (enableAvailable) params += params === '?' ? 'av' : ',av';
    if (enableAvailableLimit) params += params === '?' ? 'av' : ',avl';
    const url = `${currentPageUrl}product/${product?.id}/${params}`;
    if (navigator.share) {
      navigator
        ?.share({
          url,
        })
        .then(() => console.log(''))
        .catch((error) => console.error('Error', error));
    } else {
      navigator.clipboard
        ?.writeText(url)
        ?.then(() => {})
        .catch((error) => {
          console.error('Failed to copy: ', error);
        });
    }
  };

  return (
    <Grid
      item
      md={block?.settings?.enableRotation ? 4 : 3}
      xs={12}
      sx={{
        height: 'auto',
        //   my: block?.settings?.enableRotation && '50px',
        //   mx: block?.settings?.enableRotation && 2,
      }}
    >
      <Card
        sx={{
          borderRadius: '5px',
          pb: 1,
          maxWidth: '360px',
          cursor: 'pointer',
          boxShadow: 5,
          maxHeight: '385px',
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          // transform: block?.settings?.enableRotation && 'rotate(-30deg)',
        }}
      >
        <Box
          onClick={() => {
            info(product && product, block);
          }}
          sx={{
            backgroundSize: 'cover',
            backgroundImage: `url(${imageLink})`,
            backgroundRepeat: 'no-repeat',
            height: '140px',
            borderRadius: '10px',
            transform: block?.settings?.enableRotation && 'rotate(-20deg)',
            mt: block?.settings?.enableRotation && '50px',
            width: block?.settings?.enableRotation ? '85%' : '100%',
            mx: block?.settings?.enableRotation && 'auto',
          }}
        />
        {product?.brand && !product?.gift && (
          <Box
            sx={{
              borderRadius: '15px',
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: 'secondary.contrastText',
              m: 1,
            }}
          >
            <Image src={product?.brand} sx={{ borderRadius: '15px' }} />
          </Box>
        )}
        <Box height="70px">
          <Typography
            sx={{
              my: 1,
              mx: 2,
              color: contentTextColor,
              fontSize: contentFontSize,
              fontWeight: contentFontWeight,
              fontStyle: contentFontStyle,
              textDecoration: contentTextDecoration,
              flex: 1,
              textAlign: contentAlign,
              // transform: block?.settings?.enableRotation && 'rotate(-20deg)',
              mt: block?.settings?.enableRotation && '60px',
            }}
          >
            {product?.title}
          </Typography>
        </Box>
        {!price && !allPrice ? (
          <Stack
            direction="row-reverse"
            justifyContent="space-between"
            alignItems="center"
            px={1}
            mb={1}
          >
            {enableButtonAdd && (
              <Button
                fullWidth
                variant="outlined"
                sx={{
                  height: '34px',
                  borderRadius: buttonBorderRadius,
                  fontWeight: 400,
                  bgcolor: buttonBgColor,
                  color: buttonColor,
                  '&:hover': {
                    bgcolor: buttonBgColor,
                    color: buttonColor,
                  },
                }}
              >
                {buttonLabelNoPrice || 'כניסה לאתר'}
              </Button>
            )}
          </Stack>
        ) : (
          <Stack direction="row-reverse" justifyContent="space-between" alignItems="center" px={1}>
            {enableButton && (
              <>
                {isInCart ? (
                  <Button
                    variant="contained"
                    color="success"
                    sx={{
                      height: '34px',
                      borderRadius: '25px',
                      fontWeight: 400,
                    }}
                    endIcon={<Iconify icon="ep:success-filled" />}
                  >
                    בעגלה
                  </Button>
                ) : (
                  <Button
                    // disabled={!price}
                    onClick={() => {
                      if (!user) {
                        dispatch({
                          type: ActionTypes.SET_ACTIVE_DIALOG,
                          payload: 'login',
                        });
                      } else if (!block?.settings?.linkOnProductInfo) {
                        // if (isOpenPrice && !coincidence) return;
                        let currentPrice;
                        if (value && !coincidence) {
                          currentPrice = value;
                          if (minPrice && value < minPrice) currentPrice = minPrice;
                          if (maxPrice && value > maxPrice) currentPrice = maxPrice;
                        } else if (isOpenPrice && coincidence) {
                          currentPrice = coincidence.price;
                          // @ts-ignore
                          if (+coincidence.price > maxPrice) currentPrice = maxPrice;
                          // @ts-ignore
                          if (+coincidence.price < minPrice) currentPrice = minPrice;
                        } else {
                          currentPrice = (enableDiscount && basePrice) || price;
                        }
                        addToBasket(
                          state.smbAccount.id,
                          product?.id,
                          currentPrice,
                          imageLink,
                          dispatch,
                          cart
                        );
                      } else info(product, block);
                    }}
                    variant={block?.settings?.buttonVariant || 'contained'}
                    sx={{
                      height: '34px',
                      borderRadius: buttonBorderRadius,
                      fontWeight: 400,
                      bgcolor: block?.settings?.buttonVariant === 'outlined' ? '' : buttonBgColor,
                      color:
                        block?.settings?.buttonVariant === 'outlined' ? buttonBgColor : buttonColor,
                      borderColor:
                        block?.settings?.buttonVariant === 'outlined' &&
                        block?.settings?.buttonBgColor,
                      '&:hover': {
                        background:
                          block?.settings?.buttonVariant === 'contained' ? buttonBgColor : '',
                        color:
                          block?.settings?.buttonVariant === 'outlined'
                            ? buttonBgColor
                            : buttonColor,
                        borderColor: block?.settings?.buttonVariant === 'outlined' && buttonBgColor,
                      },
                    }}
                    endIcon={<Iconify icon={buttonIcon} />}
                  >
                    {buttonLabel || 'לרכישה'}
                  </Button>
                )}
              </>
            )}
            {enableCount && (
              <TextField
                select
                SelectProps={{ native: true }}
                sx={{
                  '& .MuiNativeSelect-select': {
                    py: 1,
                    px: 2,
                  },
                }}
              >
                {OPTIONS.map((option, idx) => (
                  <option key={idx} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            )}
            {enablePrice && (
              <>
                {!isOpenPrice && (
                  <Stack>
                    {!enableSwitch && enableDiscount && (
                      <Typography
                        sx={{
                          color: topPriceColor,
                          textDecoration: 'line-through',
                          fontSize: topPriceFontSize,
                        }}
                      >
                        ₪{price}
                      </Typography>
                    )}
                    {!enableSwitch && enableDiscount ? (
                      <Typography
                        sx={{
                          color: bottomPriceColor,
                          fontSize: bottomPriceFontSize,
                        }}
                      >
                        ₪{basePrice}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: bottomPriceColor,
                          fontSize: bottomPriceFontSize,
                        }}
                      >
                        ₪{enableSwitch ? allPrice : price}
                      </Typography>
                    )}
                  </Stack>
                )}
                {isOpenPrice && (
                  <>
                    {coincidence && coincidence?.price ? (
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography
                          sx={{
                            color: bottomPriceColor,
                            fontSize: bottomPriceFontSize,
                          }}
                        >
                          ₪{coincidence && coincidence?.price && currentPriceView}
                        </Typography>
                      </Stack>
                    ) : (
                      <Stack direction="row" spacing={1} alignItems="center">
                        <TextField
                          name="openPrice"
                          placeholder={`${t('Sum')}`}
                          sx={{ width: 60, '& input': { p: 1 } }}
                          value={count}
                          onChange={(e) => {
                            handleOpenPriceChange(e, product, maxPrice, minPrice);
                            setCount(e.target.value);
                          }}
                        />
                        <Typography
                          sx={{
                            color: 'text.secondary',
                            fontSize: 16,
                          }}
                        >
                          ₪
                        </Typography>
                      </Stack>
                    )}
                  </>
                )}
              </>
            )}
          </Stack>
        )}
        <Grid
          container
          justifyContent="space-between"
          sx={{
            p: 1,
            // height: '50px',
            mb: 0,
            mt: 1,
            py: 0,
            display: !enableBalance && !enableAvailable && !enableShare ? 'none' : '',
          }}
        >
          <Grid item md={6}>
            {enableBalance && (
              <Stack direction="row">
                <Typography>נשאר: 199</Typography>
              </Stack>
            )}
            {enableShare && (
              <Iconify icon="solar:share-bold" width={16} sx={{ mr: 0.5 }} onClick={handleClick} />
            )}
          </Grid>
          <Grid item md={6}>
            <Stack>
              {enableAvailable && (
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ mx: 1 }}>כמות</Typography>
                  <TextField
                    sx={{
                      '& .MuiNativeSelect-select': { py: 0 },
                    }}
                    select
                    fullWidth={!isMobile}
                    SelectProps={{
                      native: true,
                      sx: { textTransform: 'capitalize' },
                    }}
                    onChange={() => {}}
                  >
                    {OPTIONS.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </TextField>
                </Stack>
              )}
              {enableAvailableLimit && (
                <Typography sx={{ fontSize: enableAvailable ? '10px' : '16px', textAlign: 'end' }}>
                  ניתן לקנייה: {limit}
                </Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
