import isEqual from 'lodash/isEqual';
import { useParams } from 'next/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';
import { FC, useRef, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import { Grid, Pagination } from '@mui/material';
import Typography from '@mui/material/Typography';

import { ITag } from '../../../../types/tag';
import { useTranslate } from '../../../../locales';
import { getProduct } from '../../../../api/product';
import Iconify from '../../../../components/iconify';
import { INetwork } from '../../../../types/networks';
import MerchantToolbar from './merchants/merchant-toolbar';
import { useAppContext } from '../../../../contexts/AppContext';
import { useResponsive } from '../../../../hooks/use-responsive';
import NetworkCard from '../../../../components/cards/network-cards';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { getNetworks, getNetworksTags } from '../../../../api/network';
import { getMerchantsTags, getMerchantsCategories } from '../../../../api/merchant';
import { Ib2bProduct, Ib2bTableFilter, Ib2bTableFilterValue } from '../../../../types/b2b';

export const Networks: FC<ComponentTypeProps> = ({ block }) => {
  const swiperRef = useRef<SwiperCore | null>(null);
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const isMobile = useResponsive('down', 'sm');
  const { t } = useTranslate();
  const LIMIT = block?.settings?.limit || 12;
  const params = useParams();
  const { dispatch, state } = useAppContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [product, setProduct] = useState<Ib2bProduct | any>([]);
  // const [itemsPerPage, setItemsPerPage] = useState(16);

  const handlePageChange = (event: any, newPage: number) => {
    setCurrentPage(newPage);
    // dispatch(getProducts(activeCategory.id, newPage, itemsPerPage));
  };
  const defaultFilters: Ib2bTableFilter = {
    name: '',
    type: [],
    category: [],
    area: [],
    price: [0, 3000],
    search: '',
  };

  const [filters, setFilters] = useState(defaultFilters);
  const canReset = !isEqual(defaultFilters, filters);

  const dataFiltered = applyFilter({
    inputData: state.networks,
    filters,
  });

  useEffect(() => {
    getNetworksTags({
      limit: 999,
      offset: 1,
      dispatch,
    });
  }, []);
  useEffect(() => {
    getMerchantsTags({
      limit: 999,
      offset: 0,
      dispatch,
    });
    getMerchantsCategories({
      limit: 999,
      offset: 0,
      dispatch,
    });
  }, []);

  useEffect(() => {
    const networkTagIds: string[] = [];
    const merchantTagIds: string[] = [];
    const { area, type } = filters;
    type.forEach((fType: string) =>
      networkTagIds.push(String(state.networksTags.find((nt: ITag) => nt.name === fType)?.id))
    );
    area.forEach((fType: string) =>
      merchantTagIds.push(String(state.merchantsTags.find((mt: ITag) => mt.name === fType)?.id))
    );

    if (params.title === 'product') {
      getNetworks(params.id, {
        limit: block?.settings.limit || 12,
        offset: (currentPage - 1) * LIMIT,
        dispatch,
        search: filters.name,
        tagsIds: networkTagIds,
        merchantTagIds,
      });
    } else {
      getNetworks([], {
        limit: block?.settings.limit || 12,
        offset: (currentPage - 1) * LIMIT,
        dispatch,
        search: filters.name,
        tagsIds: networkTagIds,
        merchantTagIds,
      });
    }
  }, [currentPage, filters]);

  const handleFilters = (name: string, filterValue: Ib2bTableFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: filterValue,
    }));
  };

  useEffect(() => {
    if (params.title === 'product') {
      const fetchProduct = async () => {
        const _product = await getProduct(params.id);
        if (_product) {
          setProduct(_product);
        }
      };
      fetchProduct();
    }
  }, []);

  const handleResetFilters = () => {
    setFilters(defaultFilters);
  };

  const handleResetType = () => {
    handleFilters('type', []);
  };

  const handleResetArea = () => {
    handleFilters('area', []);
  };

  const handleResetCategory = () => {
    handleFilters('category', []);
  };

  const filterView = (
    <>
      {block?.settings?.enableFilter && (
        <MerchantToolbar
          block={block}
          filters={filters}
          onFilters={handleFilters}
          //
          typeOptions={state.networksTags.map((mTag: ITag) => mTag.name)}
          categoryOptions={state.merchantsCategories}
          areaOptions={state.merchantsTags.map((mTag: ITag) => mTag.name)}
          handleResetFilters={handleResetFilters}
          canReset={canReset}
          handleResetCategory={handleResetCategory}
          handleResetArea={handleResetArea}
          handleResetTags={handleResetType}
          handleResetName={() => {}}
        />
      )}
    </>
  );

  return (
    <>
      <Stack flexWrap="wrap" flexDirection="row-reverse" justifyContent="center" gap={3}>
        {isMobile ? (
          <>
            <Stack width={1}>
              <Iconify icon="mdi:filter" onClick={toggleDrawer(true)} />
            </Stack>
            <Drawer open={open} onClose={toggleDrawer(false)}>
              {filterView}
            </Drawer>
          </>
        ) : (
          <>{filterView}</>
        )}

        <Grid container spacing={3} rowGap={3} justifyContent="center">
          {isMobile ? (
            <Box sx={{ '& .swiper-slide': { width: '70vw', margin: 1 } }}>
              <Box
                sx={{
                  width: '90vw',
                }}
              >
                <Swiper
                  speed={500}
                  slidesPerView="auto"
                  mousewheel={{
                    forceToAxis: true,
                    sensitivity: 1,
                    releaseOnEdges: true,
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                >
                  {dataFiltered?.map((network: any) => (
                    <SwiperSlide>
                      <Grid key={network.id} item md={4} xs={12}>
                        <NetworkCard network={network} product={product} block={block} />
                      </Grid>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            </Box>
          ) : (
            <>
              {dataFiltered?.map((network: any) => (
                <Grid key={network.id} item md={4} xs={12}>
                  <NetworkCard network={network} product={product} block={block} />
                </Grid>
              ))}
            </>
          )}
          {!dataFiltered.length && <Typography variant="h2">{`${t('No data')}`}</Typography>}
        </Grid>
      </Stack>
      <br />
      {dataFiltered.length ? (
        <Stack justifyContent="center">
          <Pagination
            onChange={handlePageChange}
            count={Math.ceil(state.networksCount / LIMIT)}
            color="primary"
            sx={{ margin: '0 auto' }}
          />
        </Stack>
      ) : (
        ''
      )}
      <br />
    </>
  );
};

function applyFilter({ inputData, filters }: { inputData: INetwork[]; filters: Ib2bTableFilter }) {
  // const { name, area, category } = filters;

  // if (name) {
  //   inputData = inputData.filter(
  //     (product) =>
  //       product.title.toLowerCase().indexOf(name.toLowerCase()) !== -1,
  //   );
  // }

  // if (area.length) {
  //   inputData = inputData.filter((product) => area.includes(product.area));
  // }

  // if (category.length) {
  //   inputData = inputData.filter((product) =>
  //     category.includes(product.category),
  //   );
  // }

  return inputData;
}
