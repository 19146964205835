import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import Image from '../image';

import { useTranslate } from '../../locales';

interface Props {
  productId: string;
  isGiftCardType: boolean;
  name: string;
  coverUrl: string;
  // onClick: (productId: string, isGiftCardType: boolean) => void;
  onClick: any; // TODO: check Button onClick
}

const SelectGiftItem = ({ productId, isGiftCardType, name, coverUrl, onClick }: Props) => {
  const { t } = useTranslate();

  const handleSelect = () => {
    onClick(productId, isGiftCardType);
  };

  const renderImg = (
    <Box sx={{ position: 'relative', p: 1, maxWidth: 250, minHeight: 160, margin: '0 auto' }}>
      <Image
        alt={name}
        src={coverUrl}
        sx={{
          borderRadius: 1.5,
        }}
      />
    </Box>
  );

  const renderContent = (
    <Stack spacing={2.5} sx={{ p: 3, pt: 2 }}>
      <Typography
        color="inherit"
        variant="subtitle2"
        sx={{ lineClamp: 2, overflow: 'hidden', minHeight: 45 }}
      >
        {name}
      </Typography>
      <Button
        variant="outlined"
        sx={{
          bgcolor: 'primary.light',
          color: 'white',
          width: '50%',
          margin: '0 auto',
          '&:hover': { bgcolor: 'primary.light' },
        }}
        onClick={onClick}
      >
        {t('Select')}
      </Button>
    </Stack>
  );

  return (
    <Link sx={{ userSelect: 'none', WebkitUserDrag: 'none' }} href="#" onClick={handleSelect}>
      <Card sx={{ maxWidth: 260, margin: '25px auto' }}>
        {renderImg}

        {renderContent}
      </Card>
    </Link>
  );
};

export default SelectGiftItem;
