import { FC, useState } from 'react';
import { useRouter } from 'next/navigation';

import Stack from '@mui/material/Stack';
import { Box, Tab, Card, Grid, Tabs } from '@mui/material';

import Image from 'src/components/image';

import { useTranslate } from '../../../../locales';
import Iconify from '../../../../components/iconify';
import { getContentValueFromProps } from '../../utils';
import { providers } from '../../../../_mock/_providers';
import { ComponentTypeProps } from '../../../../types/page-generator';

const AllProviders: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const { t } = useTranslate();
  const [value, setValue] = useState(0);
  const router = useRouter();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Card>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: 2 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label={
              <Stack direction="row" alignItems="center" gap={1}>
                <Iconify icon="solar:user-id-bold" />

                <span>{`${t('עיקרית')}`} </span>
              </Stack>
            }
            sx={{ textTransform: 'none' }}
          />
          <Tab
            label={
              <Stack direction="row" alignItems="center" gap={1}>
                <Iconify icon="fluent:info-28-filled" />

                <span>{`${t('מידע ביומטרי')}`} </span>
              </Stack>
            }
            sx={{ textTransform: 'none' }}
          />
          <Tab
            label={
              <Stack direction="row" alignItems="center" gap={1}>
                <Iconify icon="ph:list-checks-fill" />
                <span>{`${t('חשוב')}`} </span>
              </Stack>
            }
            sx={{ textTransform: 'none' }}
          />
          <Tab
            label={
              <Stack direction="row" alignItems="center" gap={1}>
                <Iconify icon="solar:document-text-bold" />
                <span>{`${t('כרטיסים')}`} </span>
              </Stack>
            }
            sx={{ textTransform: 'none' }}
          />
        </Tabs>
        {value === 0 && (
          <Grid container spacing={1}>
            {providers.map((item, index) => (
              <Grid item md={2.4} xs={6} key={index} sx={{ cursor: 'pointer' }}>
                <Image
                  src={item.image}
                  sx={{ borderRadius: 1.25, width: '100%', height: '100%' }}
                  onClick={() => router.push(`/provider/${item.id}`)}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Card>
  );
};

export default AllProviders;
