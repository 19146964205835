import { API } from 'src/helpers/api';
import { BOT_ID } from 'src/config-global';
import { ActionType, ActionTypes, useAppContext } from 'src/contexts/AppContext';

export type GetProductProps = {
  limit?: number;
  offset?: number;
  minPrice?: number;
  maxPrice?: number;
  dispatch?: React.Dispatch<ActionType>;
  search?: string;
  categoriesIds?: string[];
  priceType?: string;
  tagsIds?: string[];
  merchantTagIds?: string[];
  id?: string;
};

export const getNetworks = async (
  productIds: string | string[],
  { limit, offset, dispatch, search, tagsIds, merchantTagIds }: GetProductProps
) => {
  try {
    const { data } = await API({
      url: `network/all?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        active: true,
        relations: ['branches', 'asterisks', 'branches.asterisks'],
        limit,
        offset,
        search,
        productIds,
        tagsIds,
        merchantTagIds,
      },
    });
    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_NETWORKS,
        payload: { networks: data.payload, count: data.count },
      });
    }
    return data.payload;
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
  return true;
};

export const getNetworksTags = async ({ limit, offset, dispatch, search }: GetProductProps) => {
  try {
    const { data } = await API({
      url: `network/getTags?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        active: true,
        relations: ['branches', 'asterisks', 'branches.asterisks'],
        limit,
        offset,
        search,
      },
    });
    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_NETWORKS_TAGS,
        payload: { networksTags: data.payload },
      });
    }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
};

export const getNetworkById = async ({ limit, offset, dispatch, search }: GetProductProps) => {
  try {
    const { data } = await API({
      url: `network/all?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        active: true,
        relations: ['branches', 'asterisks', 'branches.asterisks'],
        limit,
        offset,
        search,
      },
    });
    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_NETWORKS_TAGS,
        payload: { networksTags: data.payload },
      });
    }
    return data.payload;
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
  return null;
};

export const getNetworkByIds = async ({ id, dispatch }: GetProductProps) => {
  try {
    const { data } = await API({
      url: `network`,
      method: 'GET',
      params: {
        active: true,
        id,
        relations: ['branches', 'asterisks'],
      },
    });
    return data.payload;
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
  return null;
};
