import { FC, Key } from 'react';

import { Box, Grid, Stack, Typography } from '@mui/material';

import Iconify from '../../../../components/iconify';
import { ComponentTypeProps } from '../../../../types/page-generator';

const VouchersV3: FC<ComponentTypeProps> = ({ block }) => {
  const vouchers = [
    {
      id: 1,
      url: '/assets/images/xtra/banners/people/12.png',
      text: 'שוברי חופשה',
    },
    {
      id: 2,
      url: '/assets/images/xtra/banners/people/13.png',
      text: 'אופנה',
    },
    {
      id: 3,
      url: '/assets/images/xtra/banners/people/14.png',
      text: 'מסעדות',
    },
    {
      id: 4,
      url: '/assets/images/xtra/banners/people/15.png',
      text: 'אטרקציות ואקסטרים',
    },
    {
      id: 5,
      url: '/assets/images/xtra/banners/people/16.png',
      text: 'רשתות מזון',
    },
    {
      id: 6,
      url: '/assets/images/xtra/banners/people/17.png',
      text: 'מוצרים',
    },
    {
      id: 7,
      url: '/assets/images/xtra/banners/people/18.png',
      text: 'טיפוח',
    },
    {
      id: 8,
      url: '/assets/images/xtra/banners/people/19.png',
      text: 'סרטים פנאי ותרבות',
    },
    {
      id: 9,
      url: '/assets/images/xtra/banners/people/11.png',
      text: 'שוברי חופשה',
    },
  ];
  return (
    <Grid container spacing={2}>
      {vouchers.map((voucher: any, id: Key | null | undefined) => (
        <Grid item xs={4} key={id}>
          <Box
            sx={{
              backgroundSize: 'cover',
              backgroundImage: `url(${voucher.url})`,
              backgroundRepeat: 'no-repeat',
              height: '140px',
              borderRadius: '10px',
            }}
          />
          <Stack direction="row" justifyContent="space-between" mt={1}>
            <Typography>{voucher.text}</Typography>
            <Iconify icon="mingcute:arrow-left-line" />
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

export default VouchersV3;
