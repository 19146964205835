import { FC, useMemo } from 'react';

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
// eslint-disable-next-line import/no-cycle
import { useTheme } from '@mui/material/styles';
import { Box, Link, Stack, Typography } from '@mui/material';

import { getContentValueFromProps } from '../../utils';
import SvgColor from '../../../../components/svg-color';
import { ButtonField } from '../../../../types/generator';
import { useResponsive } from '../../../../hooks/use-responsive';
import LineIcon from '../../../../assets/icons/happy-gift/lineIcon';
import { ComponentTypeProps } from '../../../../types/page-generator';

// =====================================================================

const ButtonsBannerHappyHr: FC<ComponentTypeProps> = ({ block }) => {
  const theme = useTheme();
  const smDown = useResponsive('down', 'sm');
  const getFieldValue = getContentValueFromProps(block);

  const backgroundColor = useMemo(
    () => getFieldValue('settings.backgroundColor'),
    [block?.settings.backgroundColor]
  );
  const lastButtonColor = useMemo(
    () => getFieldValue('settings.lastButtonColor'),
    [block?.settings.lastButtonColor]
  );
  const widthIcon = useMemo(() => getFieldValue('settings.widthIcon'), [block?.settings.widthIcon]);
  const iconColor = useMemo(() => getFieldValue('settings.iconColor'), [block?.settings.iconColor]);

  const actionButtons = useMemo(
    () => (getFieldValue('actionButtons') as ButtonField[]) || [],
    [block?.actionButtons]
  );

  const secondActionButtons = useMemo(
    () => (getFieldValue('secondActionButtons') as ButtonField[]) || [],
    [block?.secondActionButtons]
  );

  const contentTextColor = useMemo(
    () => getFieldValue('settings.contentTextColor'),
    [block?.settings.contentTextColor]
  );
  const contentFontSize = useMemo(
    () => getFieldValue('settings.contentFontSize'),
    [block?.settings.contentFontSize]
  );
  const contentFontWeight = useMemo(
    () => getFieldValue('settings.contentFontWeight'),
    [block?.settings.contentFontWeight]
  );
  const contentFontStyle = useMemo(
    () => getFieldValue('settings.contentFontStyle'),
    [block?.settings.contentFontStyle]
  );
  const contentTextDecoration = useMemo(
    () => getFieldValue('settings.contentTextDecoration'),
    [block?.settings.contentTextDecoration]
  );

  const lastButtonTextColor = useMemo(
    () => getFieldValue('settings.lastButtonTextColor'),
    [block?.settings.lastButtonTextColor]
  );
  const lastButtonFontSize = useMemo(
    () => getFieldValue('settings.lastButtonFontSize'),
    [block?.settings.lastButtonFontSize]
  );
  const lastButtonFontWeight = useMemo(
    () => getFieldValue('settings.lastButtonFontWeight'),
    [block?.settings.lastButtonFontWeight]
  );
  const lastButtonFontStyle = useMemo(
    () => getFieldValue('settings.lastButtonFontStyle'),
    [block?.settings.lastButtonFontStyle]
  );
  const lastButtonTextDecoration = useMemo(
    () => getFieldValue('settings.lastButtonTextDecoration'),
    [block?.settings.lastButtonTextDecoration]
  );

  const variant = useMemo(() => getFieldValue('settings.variant'), [block?.settings.variant]);
  const ellipseColor = useMemo(
    () => getFieldValue('settings.ellipseColor'),
    [block?.settings.ellipseColor]
  );
  const lineColor = useMemo(() => getFieldValue('settings.lineColor'), [block?.settings.lineColor]);

  return (
    <Box sx={{ position: 'relative', mt: variant ? '70px' : '' }}>
      {variant && (
        <SvgColor
          src="/assets/images/happy-gift/backgrounds/ellipse.svg"
          sx={{
            width: '100%',
            height: smDown ? '80%' : '100%',
            position: 'absolute',
            top: { md: '-75px', xs: '-200px' },
            color: ellipseColor,
          }}
        />
      )}
      <Card
        component={Stack}
        spacing={3}
        direction="row"
        flexWrap="wrap"
        justifyContent="space-between"
        sx={{
          width: '100%',
          borderRadius: 1.5,
          bgcolor: backgroundColor,
          py: smDown ? 4 : 6,
          px: 3,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography
            color={lastButtonColor}
            sx={{
              cursor: 'pointer',
              color: lastButtonTextColor,
              fontSize: lastButtonFontSize,
              fontStyle: lastButtonFontStyle,
              fontWeight: lastButtonFontWeight,
              textDecoration: lastButtonTextDecoration,
            }}
          >
            נבחרים על ידי
          </Typography>
          <SvgColor
            src="/assets/icons/happy-gift/arrow_1.svg"
            sx={{ width: widthIcon === 'small' ? 35 : 55 }}
            color={iconColor}
          />
        </Stack>
        {actionButtons.map((company, idx) => (
          <Link
            key={idx}
            href={company.link}
            sx={{
              color: contentTextColor,
              fontSize: contentFontSize,
              fontStyle: contentFontStyle,
              fontWeight: contentFontWeight,
              textDecoration: contentTextDecoration,
            }}
          >
            {company.label}
          </Link>
        ))}
      </Card>
      {variant && <LineIcon color={lineColor} sx={{ position: 'relative', top: '-50px', px: 1 }} />}
      {variant && (
        <Stack
          direction="row"
          justifyContent={smDown ? 'center' : 'space-between'}
          gap={smDown ? 3 : 6}
          flexWrap={smDown ? 'wrap' : 'nowrap'}
          sx={{ position: 'relative', top: '-35px', px: 2 }}
        >
          {secondActionButtons.map((button, id) => (
            <Button
              key={id}
              variant="contained"
              sx={{
                fontWeight: 400,
                fontSize: '16px',
                width: '100%',
                bgcolor: 'secondary.contrastText',
                borderRadius: '10px',
                color: 'black',
                border: '1px solid #27097A',
                '&:hover': {
                  bgcolor: 'secondary.contrastText',
                  color: 'black',
                },
              }}
            >
              {button.label}
            </Button>
          ))}
        </Stack>
      )}
    </Box>
  );
};
export default ButtonsBannerHappyHr;
