// locales
import { useState, useCallback } from 'react';

import Box from '@mui/material/Box';
// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { useTranslate } from 'src/locales';

// components
import Iconify from 'src/components/iconify';
import { usePopover } from 'src/components/custom-popover';

import { IWalletProductTableFilter, IWalletProductTableFilterValue } from '../../../types/wallet';

// types

// ----------------------------------------------------------------------

type Props = {
  filters: IWalletProductTableFilter;
  onFilters: (name: string, value: IWalletProductTableFilterValue) => void;
  //
  typeOptions: string[];
  categoryOptions: string[];
  areaOptions: string[];
  handleResetFilters: Function;
  canReset: boolean;
};

export default function WalletToolBar({
  filters,
  onFilters,
  typeOptions,
  categoryOptions,
  areaOptions,
  handleResetFilters,
  canReset,
}: Props) {
  const { t } = useTranslate();

  const popover = usePopover();

  const [valueSlider, setValue] = useState<number[]>([0, 3000]);

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('name', event.target.value);
    },
    [onFilters]
  );

  const handleFilterArea = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'area',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterCategory = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'category',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterType = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'type',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  return (
    <Box sx={{ direction: 'rtl' }}>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <IconButton onClick={() => handleResetFilters()} disabled={!canReset}>
          <Iconify icon="basil:trash-solid" color={!canReset ? 'grey.500' : 'secondary.main'} />
        </IconButton>

        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 200 },
          }}
        >
          <InputLabel>{t('אזור')}</InputLabel>

          <Select
            multiple
            value={filters.area}
            onChange={handleFilterArea}
            input={<OutlinedInput label="אזור" />}
            renderValue={(selected) => selected.map((value) => value).join(', ')}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            {['home', 'spa'].map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox disableRipple size="small" checked={filters.area.includes(option)} />
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 200 },
          }}
        >
          <InputLabel>{t('קטגוריה')}</InputLabel>

          <Select
            multiple
            value={filters.category}
            onChange={handleFilterCategory}
            input={<OutlinedInput label="קטגוריה" />}
            renderValue={(selected) => selected.map((value) => value).join(', ')}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            {['home', 'spa'].map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox disableRipple size="small" checked={filters.category.includes(option)} />
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 200 },
          }}
        >
          <InputLabel>{t('סוג')}</InputLabel>

          <Select
            multiple
            value={filters.type}
            onChange={handleFilterType}
            input={<OutlinedInput label="סוג" />}
            renderValue={(selected) => selected.map((value) => value).join(', ')}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            {['home', 'spa'].map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox disableRipple size="small" checked={filters.type.includes(option)} />
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <TextField
            sx={{ '& input': { direction: 'ltr' } }}
            fullWidth
            value={filters.name}
            onChange={handleFilterName}
            placeholder={t('חיפוש...')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
}
