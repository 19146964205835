import { FC, useEffect, useMemo } from 'react';

import { Box, Container, Stack, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';

import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import { useAuthContext } from 'src/auth/hooks';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

import { ComponentTypeProps } from 'src/types/page-generator';
import { CustomerHistoryTransaction } from 'src/types/history-city-people';

import { getApartments, getHistory } from 'src/api/citypeople';
import { BASIC_URL_FOR_IMAGES } from 'src/constants/common';
import { DEFAULT_MERCHANT_IMAGE } from 'src/_mock/_companies';
import { getContentValueFromProps } from '../../utils';

const MERCHANT_TITLE = 'ספק: ';
const DATE_TITLE = 'תאריך: ';
const PRICE_TITLE = 'סכום: ';

const defaultImage =
  'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjQiPjxwYXRoIGQ9Ik0yMDAtMTIwcS0zMyAwLTU2LjUtMjMuNVQxMjAtMjAwdi01NjBxMC0zMyAyMy41LTU2LjVUMjAwLTg0MGg1NjBxMzMgMCA1Ni41IDIzLjVUODQwLTc2MHY1NjBxMCAzMy0yMy41IDU2LjVUNzYwLTEyMEgyMDBabTAtODBoNTYwdi01NjBIMjAwdjU2MFptNDAtODBoNDgwTDU3MC00ODAgNDUwLTMyMGwtOTAtMTIwLTEyMCAxNjBabS00MCA4MHYtNTYwIDU2MFoiLz48L3N2Zz4=")';

const HistoryCityPeople: FC<ComponentTypeProps> = ({ block }) => {
  const { state, dispatch } = useAppContext();
  const { user } = useAuthContext();

  const getFieldValue = getContentValueFromProps(block);

  const isClient = !state?.customer?.merchants?.length;
  // const isClient = true;
  const modalId = useMemo(() => getFieldValue('openDocumentModalId'), [block?.openDocumentModalId]);
  const isAlternativeDesign = useMemo(
    () => getFieldValue('settings.isAlternativeDesign') ?? false,
    [block?.settings.isAlternativeDesign]
  );
  const getImageLink = (imgLink: string) =>
    imgLink ? `${BASIC_URL_FOR_IMAGES}${imgLink}` : DEFAULT_MERCHANT_IMAGE;
  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const displayedOrderHistory = useMemo(
    () => (state.filterQuery ? state.filteredOrderHistory : state.orderHistory),
    [state.filterQuery, state.filteredOrderHistory, state.orderHistory]
  );

  useEffect(() => {
    getHistory(isClient, state.customer?.id || null, null, dispatch).catch((e) => {
      console.log(`Error during getHistory: ${e}`);
    });

    if (!state.apartments.length && user)
      getApartments(user.id, dispatch).catch((e) => {
        console.log(`Error during fetch apartments: ${e}`);
      });
  }, [state.customer]);

  return (
    <Container maxWidth="sm">
      <Stack
        direction="column"
        alignItems="center"
        // gap={2}
        sx={{
          margin: '0 auto',
          width: { xs: '100%' },
          mb: 10,
        }}
      >
        {isAlternativeDesign ? (
          <Stack
            width="100%"
            direction="row"
            spacing={1}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Image
              src="/assets/images/city-people/asterisk.png"
              sx={{
                width: '26px',
                height: '26px',
                '& img': { objectFit: 'contain' },
                transform: 'translateY(-15%)',
              }}
            />
            <Typography
              sx={{
                mb: '10px',
                fontWeight: 100,
                fontSize: 24,
                lineHeight: '24px',
              }}
            >
              {state.apartments?.length ? state.apartments[0].title : ''}
            </Typography>
          </Stack>
        ) : (
          <TextField fullWidth label="חיפוש" sx={{ my: 1 }} />
        )}
        {!state.orderHistory.length && (
          <Typography
            sx={{ margin: '0 auto' }}
            textAlign="center"
            variant="h6"
            color="secondaryContrastText"
          >
            אין היסטוריה
          </Typography>
        )}
        {displayedOrderHistory.map((item: unknown) => {
          const { id, amount, order, cardTypeName, orderNumber, merchantTitle } =
            item as CustomerHistoryTransaction;
          const merchantImg = order?.smbAccount?.merchant?.mainImageURL || '';
          const orderImg = order?.documentImageUrl || '';
          const date = order ? format(new Date(order.createdAt), 'dd MMM yyyy HH:mm') : '';

          return (
            <Stack
              key={id}
              direction="row"
              justifyContent="space-between"
              gap={isAlternativeDesign ? 0 : 1}
              sx={{
                width: '100%',
                ...(isAlternativeDesign && {
                  border: (theme) => `solid 1px ${theme.palette.primary.main}`,
                  borderRadius: 3,
                  p: 2,
                  mb: 2,
                }),
              }}
            >
              <Stack direction="row" gap={isAlternativeDesign ? 4 : 1}>
                <Box
                  sx={{
                    maxWidth: isAlternativeDesign ? 120 : 100,
                    width: isAlternativeDesign ? 120 : 100,
                    maxHeight: isAlternativeDesign ? 120 : 100,
                    height: isAlternativeDesign ? 120 : 100,
                    backgroundImage: merchantImg
                      ? `url('${getImageLink(merchantImg)}')`
                      : defaultImage,
                    backgroundPosition: '50%',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    ...(isAlternativeDesign && {
                      border: (theme) => `solid 1px ${theme.palette.primary.main}`,
                      borderRadius: 3,
                    }),
                  }}
                />

                <Stack>
                  <Typography
                    fontWeight={isAlternativeDesign ? 100 : 600}
                    component="span"
                    sx={{ textWrap: 'balance' }}
                  >
                    {orderNumber}
                  </Typography>

                  <Typography fontWeight={600} component="span" noWrap>
                    {MERCHANT_TITLE}
                    <Typography
                      fontWeight={isAlternativeDesign ? 100 : 600}
                      component="span"
                      sx={{ textWrap: 'balance' }}
                    >
                      {merchantTitle}
                    </Typography>
                  </Typography>

                  {!isAlternativeDesign && (
                    <Typography fontWeight={600} component="span">
                      {cardTypeName}
                    </Typography>
                  )}

                  <Typography fontWeight={600} component="span" noWrap>
                    {DATE_TITLE}
                    <Typography
                      fontWeight={isAlternativeDesign ? 100 : 600}
                      component="span"
                      noWrap
                    >
                      {date}
                    </Typography>
                  </Typography>

                  <Typography fontWeight={600} component="span" noWrap>
                    {PRICE_TITLE}
                    <Typography
                      fontWeight={isAlternativeDesign ? 100 : 600}
                      component="span"
                      noWrap
                    >
                      {amount} ₪
                    </Typography>
                  </Typography>

                  {isAlternativeDesign && orderImg && (
                    <Iconify
                      justifySelf="flex-start"
                      icon="carbon:document-view"
                      width={36}
                      onClick={() => {
                        if (modalId) {
                          dispatch({ type: ActionTypes.ADD_GREETING_IMAGE, payload: orderImg });
                          setActiveDialog(modalId);
                        }
                      }}
                    />
                  )}
                </Stack>
              </Stack>

              {!isAlternativeDesign && orderImg && (
                <Box width={42}>
                  <Iconify
                    justifySelf="flex-start"
                    icon="carbon:document-view"
                    width="100%"
                    onClick={() => {
                      if (modalId) {
                        dispatch({ type: ActionTypes.ADD_GREETING_IMAGE, payload: orderImg });
                        setActiveDialog(modalId);
                      }
                    }}
                  />
                </Box>
              )}
            </Stack>
          );
        })}
      </Stack>
    </Container>
  );
};

export default HistoryCityPeople;
