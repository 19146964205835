import { FC } from 'react';

import Box from '@mui/material/Box';

import { ComponentTypeProps } from '../../../../types/page-generator';

export const Iframe: FC<ComponentTypeProps> = ({ block }) => {
  const url: any = block?.url;
  return (
    <Box
      sx={{
        margin: 'auto',
        width: `${block?.settings?.width}px` || '50%',
        height: `${block?.settings?.height}px` || '100%',
      }}
    >
      <iframe
        src={url}
        title="Payment"
        width="100%"
        height="100%"
        allow="payment"
        style={{ border: 0 }}
      />
    </Box>
  );
};
