import useSWR from 'swr';
import { useMemo } from 'react';
import { lowerCase } from 'lodash';

import { API, hosts } from 'src/utils/b24-axios';
import { fetcher, endpoints } from 'src/utils/axios';

import { BOT_ID, RAAYONIT_BOT_ID } from 'src/config-global';

import { ICategoryItem } from 'src/types/b2b';
import { useAppContext } from '../contexts/AppContext';

// ----------------------------------------------------------------------

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const FALLBACK_DATA = { payload: [], count: 0 };
const FALLBACK_DATA_SINGLE = { payload: null };

// ----------------------------------------------------------------------\

type GetProductProps = {
  limit: number;
  offset: number;
  minPrice?: number;
  maxPrice?: number;
  relations?: string[];
  search?: string;
};

export function useGetProducts(props: GetProductProps) {
  const local: any = localStorage.getItem('account');
  const account = JSON.parse(local);
  const params = { ...props, botId: BOT_ID, active: true, smbAccountId: account?.id };
  const URL = [endpoints.product.getAll, { params }];
  return useGetProductsByURL(URL);
}

// ----------------------------------------------------------------------

type GetCategoriesProps = {
  limit: number;
  offset: number;
  search?: string;
  relations?: string[];
};

export function useGetCategories(props: GetCategoriesProps) {
  const { state } = useAppContext();
  const { smbAccount } = state;
  const { limit, offset, search, relations } = props;
  const URL = [
    endpoints.category.all,
    { params: { smbAccountId: smbAccount.id, limit: 1000, offset, relations } },
  ];

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);
  const { payload, count: categoriesCount } = data ?? FALLBACK_DATA;

  // TODO: Временное решение по поиску. Удалить, как только такая возможность появится на стороне API.
  const categoriesUncut = payload.filter((category: ICategoryItem) =>
    search ? lowerCase(category.title).includes(lowerCase(search)) : true
  );

  const categories = categoriesUncut.slice(limit);

  const memoizedValue = useMemo(
    () => ({
      categories,
      categoriesCount,
      categoriesError: error,
      categoriesLoading: isLoading,
      categoriesValidating: isValidating,
      categoriesEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetMerchantCategories(props: GetCategoriesProps) {
  const { limit, offset } = props;
  const URL = [endpoints.merchant.categories, { params: { limit: 20, offset } }];

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);
  const { payload, count: categoriesCount } = data ?? FALLBACK_DATA;

  const memoizedValue = useMemo(
    () => ({
      categories: payload,
      categoriesCount,
      categoriesError: error,
      categoriesLoading: isLoading,
      categoriesValidating: isValidating,
      categoriesEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

type GetMerchantsProps = {
  limit?: number;
  offset?: number;
  search?: string;
  relations?: string[];
  type?: ('merchant' | 'mall' | 'hr')[];
};

export function useGetMerchants(props: GetMerchantsProps) {
  const URL = [endpoints.merchant.all, { params: { ...props, botId: BOT_ID, active: true } }];

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);
  const { payload: merchants, count: merchantsCount } = data ?? FALLBACK_DATA;

  const memoizedValue = useMemo(
    () => ({
      merchants,
      totalCount: merchantsCount,
      merchantsError: error,
      merchantsLoading: isLoading,
      merchantsValidating: isValidating,
      merchantsEmpty: !isLoading && !data?.length,
      categories: data?.categories,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

type GetMerchantProps = {
  id: string;
  relations?: ('products' | 'products.categories' | 'network' | 'categories' | 'smbAccount')[];
};

export function useGetMerchant(props: GetMerchantProps) {
  const URL = [endpoints.merchant.byId, { params: { ...props, botId: BOT_ID } }];

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);
  const { payload: merchant } = data ?? FALLBACK_DATA_SINGLE;

  const memoizedValue = useMemo(
    () => ({
      merchant,
      merchantError: error,
      merchantLoading: isLoading,
      merchantValidating: isValidating,
      merchantEmpty: !isLoading && !merchant,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetFilialsByCouponId(couponId: string | number) {
  const URL = `store/coupon/fillials/${couponId}`;

  const { data, isLoading, error, isValidating } = API.get(URL);

  const memoizedValue = useMemo(
    () => ({
      filials: (data as Record<string, any>[]) || [], // TODO: Type for filial,
      filialsError: error,
      filialsLoading: isLoading,
      filialsValidating: isValidating,
      filialsEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetWalletAsterisks(couponId = 290 /*  временный хардкод */) {
  const URL = `coupon/wallet/${couponId}?relations=asterisks`;

  const { data, isLoading, error, isValidating } = API.get(URL);

  const memoizedValue = useMemo(
    () => ({
      wallet: (data.data as Record<string, any>) || {}, // TODO: Type for wallet,
      walletError: error,
      walletLoading: isLoading,
      walletValidating: isValidating,
      walletEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetOrdersByMerchantId(id: number) {
  const URL = `store/order/bot/${RAAYONIT_BOT_ID}?merchantId=${id}`;

  const { data, isLoading, error, isValidating } = API.get(URL);

  const memoizedValue = useMemo(
    () => ({
      orders: (data.data as Record<string, any>[]) || [], // TODO: Type for order,
      ordersError: error,
      ordersLoading: isLoading,
      ordersValidating: isValidating,
      ordersEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetOrder(orderId: number) {
  const URL = `order/${orderId}`;

  const { data, isLoading, error, isValidating } = API.get(URL);

  const memoizedValue = useMemo(
    () => ({
      order: (data.data as Record<string, any>) || {}, // TODO: Type for order,
      orderError: error,
      orderLoading: isLoading,
      orderValidating: isValidating,
      orderEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetOrders() {
  const URL = 'order';

  const { data, isLoading, error, isValidating } = API.get(URL);

  const memoizedValue = useMemo(
    () => ({
      orders: (data as Record<string, any>[]) || [], // TODO: Type for order,
      ordersError: error,
      ordersLoading: isLoading,
      ordersValidating: isValidating,
      ordersEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetCart() {
  const URL = 'cart';

  const { data, isLoading, error, isValidating } = API.get(URL);

  const memoizedValue = useMemo(
    () => ({
      cart: (data as Record<string, any>) || {}, // TODO: Type for cart,
      cartError: error,
      cartLoading: isLoading,
      cartValidating: isValidating,
      cartEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetBalance(orderId: number) {
  const URL = `/multipass/card/${orderId}/balance`;

  const { data, isLoading, error, isValidating } = API.get(URL);

  const memoizedValue = useMemo(
    () => ({
      balance: (data as Record<string, any>) || {}, // TODO: Type for balance,
      balanceError: error,
      balanceLoading: isLoading,
      balanceValidating: isValidating,
      balanceEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetUsageMultiPassCards() {
  const URL = 'multipass/usage-history';

  const { data, isLoading, error, isValidating } = API.get(URL);

  const memoizedValue = useMemo(
    () => ({
      multipassCards: (data.items as Record<string, any>[]) || [], // TODO: Type for multipassCard,
      multipassCardsError: error,
      multipassCardsLoading: isLoading,
      multipassCardsValidating: isValidating,
      multipassCardsEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetUnusedMultiPassCards() {
  const URL = 'multipass/unused-cards/rsmb';

  const { data, isLoading, error, isValidating } = API.get(URL);

  const memoizedValue = useMemo(
    () => ({
      multipassCards: (data.items as Record<string, any>[]) || [], // TODO: Type for multipassCard,
      multipassCardsError: error,
      multipassCardsLoading: isLoading,
      multipassCardsValidating: isValidating,
      multipassCardsEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetCouponSubsidies(clubId: number) {
  const URL = `store/coupon/subsidy/${clubId}`;

  const { data, isLoading, error, isValidating } = API.get(URL);

  const memoizedValue = useMemo(
    () => ({
      couponSubsidies: (data.items as Record<string, any>[]) || [], // TODO: Type for couponSubsidy,
      couponSubsidiesError: error,
      couponSubsidiesLoading: isLoading,
      couponSubsidiesValidating: isValidating,
      couponSubsidiesEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export async function getOpenPriceCoupons(clubId: number, couponId: number, price: number) {
  const URL = `store/coupon/${RAAYONIT_BOT_ID}/open`;

  let data: any;
  let error: Error | string | null = null;

  try {
    data = await API.post(URL, { couponId, clubId, price });
  } catch (err) {
    error = err;
  }

  return {
    coupons: (data as Record<string, any>[]) || [], // TODO: Type for coupon,
    couponsError: error,
    couponsEmpty: !data,
  };
}

// ----------------------------------------------------------------------
//
// ----------------------------------------------------------------------

// TEMPLATE TO GET PRODUCTS BY URL

function useGetProductsByURL(URL: string | (string | { params: GetProductProps })[]) {
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);
  const { payload: products, count: productsCount } = data ?? FALLBACK_DATA;

  const memoizedValue = useMemo(
    () => ({
      products,
      productsCount,
      productsError: error,
      productsLoading: isLoading,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

function useGetProductByURL(URL: string, host = hosts.api) {
  const { data, isLoading, error, isValidating } = API.get(URL, host);

  const memoizedValue = useMemo(
    () => ({
      product: (data as Record<string, any>) || {}, // TODO: Type for products,
      productError: error,
      productLoading: isLoading,
      productValidating: isValidating,
      productEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
