'use client';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { Ib2bProduct } from '../../types/b2b';
import { useRouter } from '../../routes/hooks';
import { useGetProduct } from '../../api/product';
import { BlockType } from '../../types/page-generator';
import { useResponsive } from '../../hooks/use-responsive';
import { extractPrices } from '../../helpers/extractPrices';
import { uploadProductsImage } from '../../helpers/uploadProductsImage';

type Props = {
  block: BlockType;
  product: Ib2bProduct;
};

export const AlternativeProduct = ({ block, product }: Props) => {
  const { product: localProduct } = useGetProduct(product?.id);
  const isMobile = useResponsive('down', 'sm');
  const router = useRouter();

  const imageLink =
    (localProduct?.mainImageURL
      ? `${uploadProductsImage(`${localProduct?.mainImageURL}`)}`
      : localProduct?.merchants &&
        localProduct?.merchants.length &&
        `${uploadProductsImage(`${localProduct?.merchants[0]?.mainImageURL}`)}`) || '';
  const { price, basePrice, businessPrice } = extractPrices(localProduct?.prices);

  return (
    <Grid
      item
      xs={block?.settings?.partialSize && !isMobile ? 2 : 4}
      md={block?.settings?.partialSize && !isMobile ? 2 : 4}
      display="flex"
      width={1}
      alignItems="center"
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      <Stack width={1} alignItems="center" height={!isMobile ? '100%' : '98px'}>
        <Box
          onClick={() => {
            router.push(`/product/${product.id}`);
          }}
          sx={{
            backgroundSize: 'cover',
            backgroundImage: `url(${imageLink})`,
            backgroundRepeat: 'no-repeat',
            height: {
              xs: '100%',
              md: block?.settings?.partialSize && !isMobile ? '100px' : '180px',
            },
            borderRadius: '10px',
            transform: block?.settings?.enableRotation && 'rotate(-20deg)',
            mt: block?.settings?.enableRotation && '50px',
            width: block?.settings?.enableRotation ? '85%' : '100%',
            mx: block?.settings?.enableRotation && 'auto',
            cursor: 'pointer',
          }}
        />
      </Stack>
      <Stack sx={{ fontSize: '14px', fontStyle: 'normal', fontWeight: 400, lineHeight: '26px' }}>
        <Box sx={{ opacity: 0.5 }}>שובר ₪{price}</Box>
        <Box>שובר ₪{businessPrice}</Box>
      </Stack>
    </Grid>
  );
};
