import { IProduct } from './product';
import { ICustomer } from './customers';
import { ICardType } from './club-cards';

export type IGiftActivityId = string;

export interface IGift {
  id: string;
  botId: number;
  activityId: string;
  createdAt: Date;
  customers: ICustomer[];
  products: IProduct[];
  virtualCardTypes: ICardType[];
}

export interface IGiftState extends IGift {
  isLoading: boolean;
  error: Error | string | null;
  botId: number;
}

export enum GiftType {
  Card = 'cardType',
  Product = 'product',
}
