import NextLink from 'next/link';
import React, { FC, useState } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { TabList, TabPanel, TabContext } from '@mui/lab';

import { useTranslate } from 'src/locales';

import Image from '../../../../components/image';
import Iconify from '../../../../components/iconify';
import { ButtonField } from '../../../../types/generator';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';

const HappyGiftActivitiesBanner: FC<ComponentTypeProps> = ({ block }) => {
  const { dispatch } = useAppContext();
  const { t } = useTranslate();
  const combinedBanner: any = block?.combinedBanner || [{ tabName: '' }];
  const localValue: string = (combinedBanner[0]?.tabName || '') as string;
  const [value, setValue] = useState(localValue);
  // const indexTab = TABS.findIndex((tab) => tab?.value === value);
  const mainTitle: string = typeof block?.mainTitle === 'string' ? block?.mainTitle : '';
  const mainSubtitleTitle: string =
    typeof block?.mainSubtitleTitle === 'string' ? block?.mainSubtitleTitle : '';
  const isMobile = useResponsive('down', 'sm');
  const mdUp = useResponsive('up', 'md');
  const smUp = useResponsive('up', 'sm');
  const {
    mainTitleTextColor,
    mainTitleFontSize,
    mainTitleFontWeight,
    mainTitleFontStyle,
    mainTitleTextDecoration,
    mainTextAlignItems,
    mainSubtitleTextColor,
    mainSubtitleFontSize,
    mainSubtitleFontWeight,
    mainSubtitleFontStyle,
    mainSubtitleTextDecoration,
    mainSubtitleTextAlignItems,
  } = block?.settings || {};

  // useEffect(() => {
  //   const tabTimeout = setTimeout(() => {
  //     if (indexTab === TABS.length - 1) {
  //       setValue(TABS[0].value);
  //     } else {
  //       setValue(TABS[indexTab + 1].value);
  //     }
  //   }, 4000);
  //
  //   return () => {
  //     clearTimeout(tabTimeout);
  //   };
  // }, [value]);

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="xl" sx={{ height: isMobile ? '760px' : '555px' }}>
      <Stack alignItems="center" mt={3}>
        <Typography
          sx={{
            lineHeight: isMobile ? 'normal' : '',
            color: mainTitleTextColor,
            fontSize: mainTitleFontSize,
            fontWeight: mainTitleFontWeight,
            fontStyle: mainTitleFontStyle,
            textDecoration: mainTitleTextDecoration,
            textAlign: mainTextAlignItems,
            width: '100%',
          }}
        >
          {mainTitle}
        </Typography>
        <Typography
          variant="caption"
          sx={{
            lineHeight: isMobile ? 'normal' : '',
            color: mainSubtitleTextColor,
            fontSize: mainSubtitleFontSize,
            fontWeight: mainSubtitleFontWeight,
            fontStyle: mainSubtitleFontStyle,
            textDecoration: mainSubtitleTextDecoration,
            textAlign: mainSubtitleTextAlignItems,
            width: '100%',
          }}
        >
          {mainSubtitleTitle}
        </Typography>
      </Stack>
      <Box mt={4} height="40vh">
        <TabContext value={value}>
          <Box
            sx={
              !isMobile
                ? {
                    borderBottom: 1,
                    borderColor: 'divider',
                    '& .MuiTabs-root': {
                      '& .MuiTabs-scroller': {
                        '& .MuiTabs-flexContainer': {
                          justifyContent: 'center',
                        },
                      },
                    },
                  }
                : {}
            }
          >
            <TabList onChange={handleChange} aria-label="slider-tablist">
              {combinedBanner.map((tab: any) => (
                <Tab
                  key={tab?.tabName}
                  label={tab?.tabName}
                  icon={<Iconify icon={tab?.tabIcon} />}
                  value={tab?.tabName}
                />
              ))}
            </TabList>
          </Box>
          {combinedBanner.map((tab: any) => (
            <TabPanel value={tab?.tabName} key={tab?.tabName}>
              <Grid container alignItems="center" flexWrap="wrap-reverse" spacing={1} dir="rtl">
                <Grid
                  xs={12}
                  sm={6}
                  component={Box}
                  height="80%"
                  alignItems="center"
                  display="flex"
                >
                  <Stack spacing={4}>
                    <Typography
                      sx={{
                        lineHeight: isMobile ? 'normal' : '',
                        color: tab?.headingTitleTextColor,
                        fontSize: tab?.headingTitleFontSize,
                        fontWeight: tab?.headingTitleFontWeight,
                        fontStyle: tab?.headingTitleFontStyle,
                        textDecoration: tab?.headingTitleTextDecoration,
                        textAlign: tab?.headingTextAlignItems,
                        width: '100%',
                      }}
                    >
                      {tab?.headingTitle}
                    </Typography>
                    <Typography
                      sx={{
                        lineHeight: isMobile ? 'normal' : '',
                        color: tab?.titleTextColor,
                        fontSize: tab?.titleFontSize,
                        fontWeight: tab?.titleFontWeight,
                        fontStyle: tab?.titleFontStyle,
                        textDecoration: tab?.titleTextDecoration,
                        textAlign: tab?.titleTextAlignItems,
                        width: isMobile ? '100%' : '70%',
                      }}
                    >
                      {tab?.title}
                    </Typography>
                    <Typography
                      sx={{
                        lineHeight: isMobile ? 'normal' : '',
                        color: tab?.subtitleTextColor,
                        fontSize: tab?.subtitleFontSize,
                        fontWeight: tab?.subtitleFontWeight,
                        fontStyle: tab?.subtitleFontStyle,
                        textDecoration: tab?.subtitleTextDecoration,
                        textAlign: tab?.subtitleTextAlignItems,
                        width: '100%',
                      }}
                    >
                      {tab?.subtitle}
                    </Typography>
                    <Stack direction="row" spacing={2} py={1}>
                      {tab?.actionButtons?.map((actionButton: ButtonField, idx: number) => (
                        <Button
                          component={actionButton?.link.includes('modal') ? Button : NextLink}
                          variant={actionButton?.variant || 'contained'}
                          color={actionButton?.color || 'primary'}
                          size={actionButton?.size || 'small'}
                          key={`menuButton_${idx}`}
                          sx={{
                            borderRadius: 10,
                            height: !smUp ? '40px' : '',
                            fontSize: !smUp ? '10px' : '',
                            px: !smUp ? '6px' : '',
                          }}
                          href={
                            (!actionButton?.link.includes('modal') &&
                              !actionButton.isDialogToggler &&
                              actionButton?.link) ||
                            ''
                          }
                          onClick={() => {
                            if (actionButton.isDialogToggler && actionButton.modalId) {
                              setActiveDialog(actionButton.modalId);
                            }
                          }}
                        >
                          {actionButton.label || `[${t('no label')}]`}
                        </Button>
                      ))}
                    </Stack>
                  </Stack>
                </Grid>
                <Grid
                  xs={12}
                  sm={6}
                  component={Box}
                  position="relative"
                  sx={{
                    // minHeight: { xs: '20dvh', md: '40dvh' },
                    img: { objectFit: 'cover', borderRadius: 3 },
                  }}
                >
                  <Image src={tab?.mainImage} sx={{ width: '90%' }} />
                </Grid>
              </Grid>
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </Container>
  );
};

export default HappyGiftActivitiesBanner;
