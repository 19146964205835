import { FC, useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';

import Image from '../../../../components/image';
import { useRouter } from '../../../../routes/hooks';
import { ComponentTypeProps } from '../../../../types/page-generator';

type Item = {
  image: string;
  name: string;
  title: string;
  link: string;
  enableTitle: boolean;
};

const PhotoDescription: FC<ComponentTypeProps> = ({ block }) => {
  const router = useRouter();
  const combinedBanner: any = block?.combinedBanner;
  const [count, setCount] = useState(3);
  useEffect(() => {
    if (block?.settings?.countInLine === '6') setCount(2);
    if (block?.settings?.countInLine === '5') setCount(2.4);
    if (block?.settings?.countInLine === '4') setCount(3);
    if (block?.settings?.countInLine === '3') setCount(4);
    if (block?.settings?.countInLine === '2') setCount(6);
    if (block?.settings?.countInLine === '1') setCount(12);
  }, [block?.settings?.countInLine]);

  const goToLink = (item: Item) => {
    if (item?.link && block?.settings?.enableLinks) {
      router.push(item?.link);
    }
  };

  let height = '250px';
  if (count === 6) height = '300px';
  if (count === 12) height = '500px';

  return (
    <Grid container spacing={block?.settings?.indentation} justifyContent="center">
      {combinedBanner.map((item: Item, id: any) => (
        <Grid
          item
          md={count}
          xs={6}
          onClick={() => goToLink(item)}
          sx={{ cursor: 'pointer' }}
          key={id}
        >
          <Image
            src={item.image}
            sx={{
              width: '100%',
              height,
              borderRadius: '10px',
              '& img': { objectFit: 'cover' },
            }}
          />
          {!!item?.enableTitle && (
            <Typography
              sx={{
                wordWrap: 'break-word',
                width: '100%',
                color: block?.settings?.contentTextColor,
                fontSize: block?.settings?.contentFontSize,
                fontWeight: block?.settings?.contentFontWeight,
                textAlign: block?.settings?.contentAlign,
              }}
            >
              {item.title}
            </Typography>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default PhotoDescription;
