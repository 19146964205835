import { Paper, Stack, TextField } from '@mui/material';

import { BlockType } from '../../../../../../types/page-generator';

export default function LeadV1({ block }: { block: BlockType }) {
  const { settings } = block;
  const { showPhoneNumber } = settings;

  return (
    <Paper component={Stack} direction="column" spacing={2}>
      <TextField label="First Name" />
      <TextField label="Last name" />
      {showPhoneNumber && <TextField label="Phone number" />}
    </Paper>
  );
}
