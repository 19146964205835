import { useEffect } from 'react';

import SelectGift from './select-gift';
import { getGifts } from '../../api/gifts';
import { ACTIVITY_ID } from '../../config-global';
import { useAppContext } from '../../contexts/AppContext';
import { IGift } from '../../types/gift';

const SelectGiftWrapper = () => {
  const { state, dispatch } = useAppContext();

  useEffect(() => {
    const fetchGifts = async () => {
      if (ACTIVITY_ID) {
        await getGifts({ activityId: ACTIVITY_ID, dispatch });
      }
    };
    fetchGifts();
  }, []);

  return state.gifts.map((gift: IGift) => <SelectGift gift={gift} /* onClose={() => {}} */ />);
};

export default SelectGiftWrapper;
