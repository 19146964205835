'use client';

import { useParams } from 'next/navigation';
import { FC, useMemo, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Grid, Paper } from '@mui/material';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Image from '../../../components/image';
import { Ib2bProduct } from '../../../types/b2b';
import Iconify from '../../../components/iconify';
import { getProduct } from '../../../api/product';
import SanitizeHTML from '../../../utils/sanitize';
import { getMerchant } from '../../../api/merchant';
import { IMerchant } from '../../../types/merchant';
import { getAllVirtualCards } from '../../../api/clubCards';
import { useAppContext } from '../../../contexts/AppContext';
import { useResponsive } from '../../../hooks/use-responsive';
import { extractPrices } from '../../../helpers/extractPrices';
import ProductDetailsCarousel from '../product-details-carousel';
import { BASIC_URL_FOR_IMAGES } from '../../../constants/common';
import { ComponentTypeProps } from '../../../types/page-generator';
import { getContentValueFromProps } from '../../generate-page/utils';
import { uploadProductsImage } from '../../../helpers/uploadProductsImage';
import { ProductDetailsSummary } from '../../b2c-shop/product-details-summary';

const ProductDetailsView: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const params = useParams();
  const [product, setProduct] = useState<Ib2bProduct | any>([]);
  const [currentProduct, setCurrentProduct] = useState<Ib2bProduct | any>([]);
  const [merchant, setMerchant] = useState<IMerchant | any>([]);
  const { dispatch, state } = useAppContext();
  const isMobile = useResponsive('down', 'sm');
  const topPriceFontSize = useMemo(() => getFieldValue('settings.topPriceFontSize'), [block]);
  const bottomPriceFontSize = useMemo(() => getFieldValue('settings.bottomPriceFontSize'), [block]);
  const change = typeof localStorage !== 'undefined' ? localStorage.getItem('change') || '' : '';
  const productToView = params.title === 'card' ? currentProduct : product;
  const { price, businessPrice } = extractPrices(product?.prices);

  useEffect(() => {
    if (params.title !== 'card') localStorage.setItem('change', '');
  }, []);

  useEffect(() => {
    getAllVirtualCards(dispatch, state.smbAccount.id);
  }, []);

  useEffect(() => {
    if (change === 'product') {
      const fetchProduct = async () => {
        const _product = await getProduct(params.id);
        if (_product) {
          setCurrentProduct(_product);
        }
      };
      fetchProduct();
    } else {
      const fetchVirtualCard = async () => {
        state.allVirtualCards?.map((value: any) => {
          if (value.id === params.id) {
            setCurrentProduct(value);
          }
          return true;
        });
      };
      fetchVirtualCard();
    }
  }, [change, state.allVirtualCards]);

  useEffect(() => {
    if (
      (params.title === 'product' && change === '') ||
      (params.title === 'card' && change === '')
    ) {
      const fetchProduct = async () => {
        const _product = await getProduct(params.id);
        if (_product) {
          setProduct(_product);
        }
      };
      fetchProduct();
    } else if (params.title === 'merchant' && change === '') {
      const fetchMerchant = async () => {
        const _merchant = await getMerchant(params.id, dispatch);
        if (_merchant !== undefined && _merchant !== null) {
          setMerchant(_merchant);
        }
      };
      fetchMerchant();
    }
  }, []);

  const imageProductLink =
    (product?.mainImageURL
      ? `${uploadProductsImage(product?.mainImageURL)}`
      : product?.merchants?.length &&
      `${uploadProductsImage(product?.merchants[0].mainImageURL)}`) ||
    '/assets/images/product-default.png';

  const imageMerchantLink = merchant?.mainImageURL
    ? `${BASIC_URL_FOR_IMAGES}${merchant?.mainImageURL}`
    : '/assets/images/merchant-default.png';

  const imageLink = params.title === 'product' ? imageProductLink : imageMerchantLink;
  let localProduct = [];
  if (params.title === 'product' || 'card') localProduct = productToView;
  if (params.title === 'merchant') localProduct = merchant;
  return (
    <>
      {!block?.settings?.isAlternativeDesign ? (
        <>
          {!localProduct ? (
            <Stack direction={!isMobile ? 'row' : 'column'} spacing={1}>
              <Skeleton
                variant="rounded"
                width={isMobile ? '100%' : '50%'}
                height={isMobile ? 260 : 660}
              />
              <Skeleton
                variant="rounded"
                width={isMobile ? '100%' : '50%'}
                height={isMobile ? 260 : 660}
              />
            </Stack>
          ) : (
            <Container sx={{ mb: 0 }}>
              <Paper elevation={5} sx={{ px: 3 }}>
                <Grid container spacing={3} flexDirection={isMobile ? 'column-reverse' : 'row'}>
                  <Grid item xs={12} md={6} lg={5}>
                    <ProductDetailsSummary
                      product={localProduct}
                      block={block}
                      imageLink={imageLink}
                      topPriceFontSize={topPriceFontSize}
                      bottomPriceFontSize={bottomPriceFontSize}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={7}>
                    <ProductDetailsCarousel product={localProduct} imageLink={imageLink} />
                  </Grid>
                </Grid>
                <br />
              </Paper>
              <br />
              {block?.settings?.enableInfoBlock && (
                <Paper elevation={5} sx={{ px: 2 }}>
                  <br />
                  <Stack alignItems="flex-start" pr={2}>
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontWeight: 800,
                        color: 'primary.main',
                        ml: 2,
                      }}
                    >
                      פרטים
                    </Typography>
                    <Image
                      src="/assets/images/happy-gift/Vector-6.png"
                      sx={{ width: isMobile ? '50%' : '10%' }}
                    />
                  </Stack>
                  <Stack direction={isMobile ? 'column' : 'row'} gap={3} mt={2}>
                    <Stack
                      gap={1}
                      sx={{
                        width: isMobile ? '100%' : '28%',
                        px: 1,
                      }}
                    >
                      <Stack
                        gap={1}
                        sx={{
                          color: 'primary.main',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Iconify icon="iconoir:home-alt" ml={1} />
                        <Typography sx={{ fontSize: '20px', fontWeight: 800 }}>ספק</Typography>
                      </Stack>
                      <Image src={product?.brand} sx={{ width: isMobile ? '100%' : '28%' }} />
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: 600,
                          color: 'primary.main',
                        }}
                      >
                        {product?.title}
                      </Typography>
                      <Typography sx={{ fontSize: '15px', color: 'primary.main' }}>
                        חוגים לכל הגילאים מהקטנים ועד הגדולים וכל זה בשביל לתת מענה לכל הילדים שקצת
                        פחות מתחברים לספורט או לכדור ויכולים למצוא את עצמם נהנים בהתאם לרמה שלהם.
                      </Typography>
                    </Stack>

                    <Stack
                      gap={1}
                      sx={{
                        width: isMobile ? '100%' : '16%',
                        px: 1,
                      }}
                    >
                      <Stack
                        gap={1}
                        sx={{
                          color: 'primary.main',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Iconify icon="teenyicons:clock-outline" ml={1} />
                        <Typography sx={{ fontSize: '20px', fontWeight: 800 }}>ספק</Typography>
                      </Stack>
                      <Typography sx={{ fontSize: '20px', color: 'primary.main' }}>
                        13/03/2024
                      </Typography>
                    </Stack>
                    <Stack
                      gap={1}
                      sx={{
                        width: isMobile ? '100%' : '28%',
                        px: 1,
                      }}
                    >
                      <Stack
                        gap={1}
                        sx={{
                          color: 'primary.main',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Iconify icon="cil:book" ml={1} />
                        <Typography sx={{ fontSize: '20px', fontWeight: 800 }}>
                          אופן מימוש
                        </Typography>
                      </Stack>
                      <Box component="li" sx={{ fontSize: '15px', color: 'primary.main' }}>
                        שובר דיגיטלי המגיע ישירות לתיבת האימייל.
                      </Box>
                      <Box component="li" sx={{ fontSize: '15px', color: 'primary.main' }}>
                        יש להגיע עם השובר המודפס ולהציג את קוד הקופון בתחילת ההזמנה
                      </Box>
                      <Box component="li" sx={{ fontSize: '15px', color: 'primary.main' }}>
                        יש להגיע עם השובר המודפס ולהציג את קוד הקופון בתחילת ההזמנה
                      </Box>
                      <Box component="li" sx={{ fontSize: '15px', color: 'primary.main' }}>
                        {`השובר מטעם חברת "קשרים פלוס" בע"מ - סליקת השובר מתבצעת בתוך מערכת microdeal בדף
                הספק.`}
                      </Box>
                    </Stack>
                    <Stack sx={{ width: isMobile ? '100%' : '28%' }} gap={1}>
                      <Stack
                        gap={1}
                        sx={{
                          color: 'primary.main',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Iconify icon="fluent:info-28-regular" ml={1} />
                        <Typography sx={{ fontSize: '20px', fontWeight: 800 }}>
                          האותיות הקטנות
                        </Typography>
                      </Stack>
                      <Box component="li" sx={{ fontSize: '15px', color: 'primary.main' }}>
                        לא יינתן עודף לקופון שלא נוצל במלואו
                      </Box>
                      <Box component="li" sx={{ fontSize: '15px', color: 'primary.main' }}>
                        ההטבה מקנה כניסה חד פעמית ליחיד
                      </Box>
                      <Box component="li" sx={{ fontSize: '15px', color: 'primary.main' }}>
                        בתיאום מראש *חברת קשרים פלוס אינה אחראית לטיב ואיכות השירות\המוצר ו\או כל
                        עניין הנובע מכך
                      </Box>
                      <Box component="li" sx={{ fontSize: '15px', color: 'primary.main' }}>
                        התמונה להמחשה בלבד
                      </Box>
                      <Box component="li" sx={{ fontSize: '15px', color: 'primary.main' }}>
                        {`ט.ל.ח הערות לספק *השובר מטעם חברת "קשרים פלוס" בעמ - סליקת השובר מתבצעת בתוך מערכת
                microdeal בדף הספק`}
                      </Box>
                    </Stack>
                  </Stack>
                </Paper>
              )}
            </Container>
          )}{' '}
        </>
      ) : (
        <Stack sx={{ mt: isMobile ? '-120px' : 0 }}>
          {params?.title === 'product' && (
            <Grid
              container
              direction="row"
              gap={1}
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <Grid item xs={3}>
                <Image
                  src={imageProductLink}
                  sx={{
                    width: isMobile ? '72px' : 'auto',
                    height: isMobile ? '72px' : 'auto',
                    borderRadius: '15px',
                    '& img': { objectFit: 'cover' },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Stack>
                  <Typography
                    sx={{
                      color: 'cornflowerblue',
                      fontSize: '16px',
                      opacity: 0.5,
                      textWrap: 'balance',
                    }}
                  >
                    {product.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: isMobile ? 'white' : 'black',
                      fontWeight: 1000,
                      fontSize: '25px',
                      opacity: isMobile ? 1 : 0.5,
                    }}
                  >
                    ₪{price}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  sx={{
                    height: '40px',
                    borderRadius: '15px',
                    color: 'white',
                    background: 'cornflowerblue',
                    fontSize: isMobile ? '10px' : '16px',
                    fontWeight: 100,
                    px: 1.1,
                  }}
                  endIcon={<Iconify icon="ep:arrow-left" />}
                >
                  בתי עסק מכבדים
                </Button>
              </Grid>
            </Grid>
          )}
          {params?.title === 'merchant' && (
            <Grid
              container
              direction="row"
              alignItems="flex-end"
              justifyContent="space-between"
              mt={2}
            >
              <Grid item xs={4}>
                <Image
                  src={imageMerchantLink}
                  sx={{
                    width: isMobile ? '72px' : '100%',
                    height: isMobile ? '72px' : '100%',
                    borderRadius: '15px',
                    '& img': { objectFit: 'cover' },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={8}
                sx={{
                  display: isMobile ? 'block' : 'flex',
                  justifyContent: isMobile ? '' : 'center',
                }}
              >
                <Stack>
                  <Typography
                    sx={{
                      color: isMobile ? 'cornflowerblue' : 'black',
                      fontSize: '16px',
                      opacity: 0.5,
                    }}
                  >
                    {merchant.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: isMobile ? 'white' : 'black',
                      fontWeight: 1000,
                      fontSize: '25px',
                      opacity: isMobile ? 1 : 0.5,
                    }}
                  >
                    {merchant.title}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          )}
          {params?.title === 'product' && (
            <>
              <Stack mt={5}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sx={{ opacity: 0.5 }}>
                    שם שובר
                  </Grid>
                  <Grid item xs={6}>
                    {product?.title}
                  </Grid>
                  <Grid item xs={6} sx={{ opacity: 0.5 }}>
                    תוקף
                  </Grid>
                  <Grid item xs={6}>
                    12.12.24
                  </Grid>
                  <Grid item xs={6} sx={{ opacity: 0.5 }}>
                    שובר בשווי ₪{price}
                  </Grid>
                  <Grid item xs={6}>
                    משלמים ₪{businessPrice}
                  </Grid>
                </Grid>
              </Stack>
              <Divider sx={{ mt: 1 }} />
              <Stack mt={2} sx={{ opacity: 0.5 }}>
                <Typography>תנאי המבצע</Typography>
                <Typography>
                  <SanitizeHTML html={product?.content} />
                </Typography>
              </Stack>
            </>
          )}
          {params?.title === 'merchant' && (
            <>
              <Stack mt={5}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sx={{ opacity: 0.5 }}>
                    שם בית העסק
                  </Grid>
                  <Grid item xs={6}>
                    {merchant?.title}
                  </Grid>
                  <Grid item xs={6} sx={{ opacity: 0.5 }}>
                    תוקף המבצע
                  </Grid>
                  <Grid item xs={6}>
                    12/12/24
                  </Grid>
                </Grid>
              </Stack>
              <Divider sx={{ mt: 1 }} />
              <Stack mt={2} sx={{ opacity: 0.5 }}>
                <Typography>תנאי המבצע</Typography>
                <Typography>
                  <SanitizeHTML html={merchant?.content} />
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      )}
    </>
  );
};

export default ProductDetailsView;
