import React from 'react';

import { Box } from '@mui/material';

import Image from 'src/components/image';

import { ComponentTypeProps } from 'src/types/page-generator';
import { useAppContext } from 'src/contexts/AppContext';

export const DocumentView = ({ block }: ComponentTypeProps) => {
  const { state } = useAppContext();

  return (
    <Box
      maxWidth="460px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        m: '20px auto',
        p: 2,
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: 2.5,
        backdropFilter: 'blur(20px)',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        backgroundImage:
          'linear-gradient(62deg, rgba(255, 86, 48, 0.12) 0%, rgba(0, 184, 217, 0.12) 100%)',
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Image
        src={state.greetingImage}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          borderRadius: 0.5,
        }}
      />
    </Box>
  );
};
