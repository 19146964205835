import { FC, useMemo } from 'react';

import { Card, Stack, Button, Typography } from '@mui/material';

import { useTranslate } from '../../../../locales';
import Iconify from '../../../../components/iconify';
import { getContentValueFromProps } from '../../utils';
import { ComponentTypeProps } from '../../../../types/page-generator';
import Image from '../../../../components/image';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';

const Banner1: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const { t } = useTranslate();
  const isMobile = useResponsive('down', 'sm');

  const title = useMemo(() => getFieldValue('title'), [block?.title]);
  const backgroundImage = useMemo(() => getFieldValue('backgroundImage'), [block?.backgroundImage]);
  const backgroundImageMobile = useMemo(
    () => getFieldValue('backgroundImageMobile'),
    [block?.backgroundImageMobile]
  );
  const subtitle = useMemo(() => getFieldValue('subtitle'), [block?.subtitle]);
  const enableButton = useMemo(
    () => getFieldValue('settings.enableButton'),
    [block?.settings.enableButton]
  );
  const enableTitle = useMemo(
    () => getFieldValue('settings.enableTitle'),
    [block?.settings.enableTitle]
  );
  const enableSubtitle = useMemo(
    () => getFieldValue('settings.enableSubtitle'),
    [block?.settings.enableSubtitle]
  );
  const buttonLabel = useMemo(() => getFieldValue('buttonLabel'), [block?.buttonLabel]);
  const buttonHref = useMemo(() => getFieldValue('buttonHref'), [block?.buttonHref]);
  const buttonDrawer = useMemo(() => getFieldValue('buttonDrawer'), [block?.buttonDrawer]);
  const buttonModal = useMemo(() => getFieldValue('buttonModal'), [block?.buttonModal]);
  const buttonIcon = useMemo(() => getFieldValue('buttonIcon'), [block?.buttonIcon]);
  const buttonSize = useMemo(() => getFieldValue('buttonSize'), [block?.buttonSize]);
  const buttonBgColor = useMemo(() => getFieldValue('buttonBgColor'), [block?.buttonBgColor]);
  const buttonColor = useMemo(() => getFieldValue('buttonColor'), [block?.buttonColor]);
  // Heading style
  const headingTitleTextColor = useMemo(
    () => getFieldValue('settings.headingTitleTextColor'),
    [block?.settings.headingTitleTextColor]
  );
  const headingTitleFontSize = useMemo(
    () => getFieldValue('settings.headingTitleFontSize'),
    [block?.settings.headingTitleFontSize]
  );
  const headingTitleFontWeight = useMemo(
    () => getFieldValue('settings.headingTitleFontWeight'),
    [block?.settings.headingTitleFontWeight]
  );
  const headingTitleFontStyle = useMemo(
    () => getFieldValue('settings.headingTitleFontStyle'),
    [block?.settings.headingTitleFontStyle]
  );
  const headingTitleTextDecoration = useMemo(
    () => getFieldValue('settings.headingTitleTextDecoration'),
    [block?.settings.headingTitleTextDecoration]
  );
  const headingTextAlignItems = useMemo(
    () => getFieldValue('settings.headingTextAlignItems'),
    [block?.settings.headingTextAlignItems]
  );

  // Content style
  const contentTitleTextColor = useMemo(
    () => getFieldValue('settings.contentTitleTextColor'),
    [block?.settings.contentTitleTextColor]
  );
  const contentTitleFontSize = useMemo(
    () => getFieldValue('settings.contentTitleFontSize'),
    [block?.settings.contentTitleFontSize]
  );
  const contentTitleFontWeight = useMemo(
    () => getFieldValue('settings.contentTitleFontWeight'),
    [block?.settings.contentTitleFontWeight]
  );
  const contentTitleFontStyle = useMemo(
    () => getFieldValue('settings.contentTitleFontStyle'),
    [block?.settings.contentTitleFontStyle]
  );
  const contentTitleTextDecoration = useMemo(
    () => getFieldValue('settings.contentTitleTextDecoration'),
    [block?.settings.contentTitleTextDecoration]
  );
  const contentTextAlignItems = useMemo(
    () => getFieldValue('settings.contentTextAlignItems'),
    [block?.settings.contentTextAlignItems]
  );

  // Align style
  const position = useMemo(() => getFieldValue('settings.position'), [block?.settings.position]);
  const contentAlign = useMemo(
    () => getFieldValue('settings.contentAlign'),
    [block?.settings.contentAlign]
  );
  const { dispatch, state } = useAppContext();

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const style = isMobile
    ? {
        backgroundImage: isMobile ? `url(${backgroundImage})` : '',
        backgroundSize: 'cover',
        padding: 5,
        height: '190px',
      }
    : {
        backgroundSize: 'auto',
        backgroundRepeat: 'no-repeat',
      };
  const click = () => {
    if (isMobile) {
      if (buttonDrawer) setActiveDialog(buttonModal);
      if (buttonHref.includes('#')) {
        const newLink = buttonHref.replace('#', '');
        window.location.href = `#${newLink}`;
      }
    }
  };
  return (
    <Card
      onClick={() => click()}
      component={Stack}
      justifyContent={position}
      alignItems={contentAlign}
      sx={{
        width: '100%',
        borderRadius: '0px',
        // backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'auto',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Image
        src={!isMobile ? backgroundImage : backgroundImageMobile}
        sx={{ width: '100%', height: '100%' }}
      />
      <Stack
        alignItems="flex-start"
        gap={2}
        sx={{
          position: 'absolute',
          p: 3,
        }}
      >
        {!isMobile && enableTitle && (
          <Typography
            sx={{
              width: '100%',
              fontWeight: contentTitleFontWeight,
              fontSize: contentTitleFontSize,
              color: contentTitleTextColor,
              fontStyle: contentTitleFontStyle,
              textDecoration: contentTitleTextDecoration,
              textAlign: headingTextAlignItems,
            }}
          >
            {title}
          </Typography>
        )}
        {!isMobile && enableSubtitle && (
          <Typography
            sx={{
              width: '100%',
              fontWeight: headingTitleFontWeight,
              fontSize: headingTitleFontSize,
              color: headingTitleTextColor,
              fontStyle: headingTitleFontStyle,
              textDecoration: headingTitleTextDecoration,
              textAlign: contentTextAlignItems,
            }}
          >
            {subtitle}
          </Typography>
        )}
        {!isMobile && enableButton && (
          <Button
            variant="contained"
            size={isMobile ? 'small' : buttonSize}
            sx={{
              bottom: isMobile ? '12px' : '',
              // display: !enableButton ? 'none' : '',
              fontSize: isMobile ? '8px' : '12px',
              direction: 'rtl',
              borderRadius: '20px',
              color: buttonColor,
              bgcolor: buttonBgColor,
              '&:hover': {
                color: buttonColor,
                bgcolor: buttonBgColor,
              },
            }}
            href={
              (!buttonHref.includes('modal') &&
                !buttonHref.includes('#') &&
                !buttonDrawer &&
                buttonHref) ||
              ''
            }
            onClick={() => {
              if (buttonDrawer && buttonModal) {
                setActiveDialog(buttonModal);
              }
              if (buttonHref.includes('#')) {
                const newLink = buttonHref.replace('#', '');
                window.location.href = `#${newLink}`;
              }
            }}
            endIcon={<Iconify icon={buttonIcon || ''} />}
          >
            {buttonLabel || `[${t('no label')}]`}
          </Button>
        )}
      </Stack>
    </Card>
  );
};

export default Banner1;
