'use client';

import Slider from 'react-slick';
import { FC, Key, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import ShopBanner3 from './shop-banner-3';
import ShopBanner4 from './shop-banner-4';
import ShopBanner5 from './shop-banner-5';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';

const AllShopBanners2: FC<ComponentTypeProps> = ({ block }) => {
  const title = 'קטגוריות פופולריות';
  const isMobile = useResponsive('down', 'sm');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const cards = [
    {
      id: 3,
      component: <ShopBanner5 block={block} />,
    },
    {
      id: 1,
      component: <ShopBanner3 block={block} />,
    },
    {
      id: 2,
      component: <ShopBanner4 block={block} />,
    },
  ];

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      {isLoaded ? (
        <Box sx={{ display: block?.settings?.disableOnMobile && isMobile ? 'none' : 'block' }}>
          <Box
            sx={{
              '& .slick-slide': {
                height: isMobile ? '100%' : '430px',
                direction: 'rtl',
                mb: '25px',
                display: 'grid!important',
                alignItems: 'end',
              },
              '& .slick-slider': {
                '& .slick-dots': {
                  position: 'absolute',
                  bottom: isMobile ? '' : '35px',
                  right: isMobile ? '' : '43%',
                  width: isMobile ? '100%' : '150px',
                  '& .slick-active': {
                    '& button': {
                      '&::before': {
                        color: '#A9A9A9 !important',
                      },
                    },
                  },
                  '& li': {
                    '& button': {
                      width: '20%',
                      '&::before': {
                        color: '#A9A9A9 !important',
                      },
                    },
                  },
                },
              },
            }}
          >
            <Slider {...settings}>
              {cards.map((item: any, index: Key) => (
                <Box key={index}>{item.component}</Box>
              ))}
            </Slider>
          </Box>
        </Box>
      ) : (
        <Skeleton variant="rectangular" width="100%" height={160} />
      )}
    </>
  );
};

export default AllShopBanners2;
