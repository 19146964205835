import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';

import Box from '@mui/material/Box';

import { useAppContext } from 'src/contexts/AppContext';

import { IVirtualCard } from 'src/types/club-cards';

import { RenderInfoPage } from './render-info-page';
import { BlockType } from '../../../types/page-generator';
import VirtualCardBlock from './wallet-virtual-card-block';
import { useResponsive } from '../../../hooks/use-responsive';

type Props = {
  setOpenProduct: (card: IVirtualCard | null) => void;
  openProduct: IVirtualCard | null;
  block: BlockType | undefined;
  openHistory: Function;
  openModal: Function;
  onChangeClubCardInfo: Function;
};

const WalletCardsTab = ({
  setOpenProduct,
  openProduct,
  block,
  openHistory,
  openModal,
  onChangeClubCardInfo,
}: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const { state } = useAppContext();
  const swiperRef = useRef<SwiperCore | null>(null);
  const {
    state: { virtualCards },
  } = useAppContext();

  const changeOpenProduct = (card: IVirtualCard | null) => {
    setOpenProduct(card);
  };
  // const localVirtualCards = [
  //   {
  //     id: '2be801f7-dac8-4136-8189-44245b96037d',
  //     cardNumber: '89781344742669',
  //     balance: '70.00',
  //     isGlobal: true,
  //     cardTypeId: '5829e017-129c-4d9e-9e2a-a548857f2e41',
  //     customerId: 'c40d04ee-13c7-4af6-a77e-6ec78e4ff943',
  //     cardType: null,
  //     transactions: [
  //       {
  //         id: 'a46fbf1f-68af-4a5d-a9ca-f2cb32204fbf',
  //         amount: '100.00',
  //         orderId: null,
  //         relatedActivityTransactionId: '8387c36f-7985-4f6f-8950-b352a034dd02',
  //         virtualCardId: '2be801f7-dac8-4136-8189-44245b96037d',
  //         transactionType: 'CREDIT',
  //       },
  //       {
  //         id: '6ec4debb-bfa0-4c0c-bbda-90479515a7fe',
  //         amount: '25.00',
  //         orderId: null,
  //         relatedActivityTransactionId: 'b88f073a-2dd6-46f8-9c74-f543f58b0568',
  //         virtualCardId: '2be801f7-dac8-4136-8189-44245b96037d',
  //         transactionType: 'DEBIT',
  //       },
  //       {
  //         id: '20378430-8868-4d6b-a9e1-eaae9ac6100e',
  //         amount: '1.00',
  //         orderId: null,
  //         relatedActivityTransactionId: '24f134fc-ef3c-4f30-983b-0b251f5e55a8',
  //         virtualCardId: '2be801f7-dac8-4136-8189-44245b96037d',
  //         transactionType: 'DEBIT',
  //       },
  //       {
  //         id: '8bfe4847-90c1-4227-b362-5a03b439b34a',
  //         amount: '1.00',
  //         orderId: null,
  //         relatedActivityTransactionId: '877bc937-41f2-4a5d-9192-8a170e0f5042',
  //         virtualCardId: '2be801f7-dac8-4136-8189-44245b96037d',
  //         transactionType: 'DEBIT',
  //       },
  //       {
  //         id: 'b279f68a-8a05-472e-9db2-a9e9d9a44d62',
  //         amount: '3.00',
  //         orderId: null,
  //         relatedActivityTransactionId: '255bf0da-f10a-436b-8e39-53734cb850cd',
  //         virtualCardId: '2be801f7-dac8-4136-8189-44245b96037d',
  //         transactionType: 'DEBIT',
  //       },
  //       {
  //         id: '5f31d94d-978a-495b-8abf-3dfa497e33fc',
  //         amount: '0.00',
  //         orderId: null,
  //         relatedActivityTransactionId: '9f996e46-859e-4c44-8603-b614e02b8194',
  //         virtualCardId: '2be801f7-dac8-4136-8189-44245b96037d',
  //         transactionType: 'DEBIT',
  //       },
  //     ],
  //   },
  //   {
  //     id: '2be801f7-dac8-4136-8189-44245b96037d',
  //     cardNumber: '89781344742669',
  //     balance: '70.00',
  //     isGlobal: true,
  //     cardTypeId: '5829e017-129c-4d9e-9e2a-a548857f2e41',
  //     customerId: 'c40d04ee-13c7-4af6-a77e-6ec78e4ff943',
  //     cardType: null,
  //     transactions: [
  //       {
  //         id: 'a46fbf1f-68af-4a5d-a9ca-f2cb32204fbf',
  //         amount: '100.00',
  //         orderId: null,
  //         relatedActivityTransactionId: '8387c36f-7985-4f6f-8950-b352a034dd02',
  //         virtualCardId: '2be801f7-dac8-4136-8189-44245b96037d',
  //         transactionType: 'CREDIT',
  //       },
  //       {
  //         id: '6ec4debb-bfa0-4c0c-bbda-90479515a7fe',
  //         amount: '25.00',
  //         orderId: null,
  //         relatedActivityTransactionId: 'b88f073a-2dd6-46f8-9c74-f543f58b0568',
  //         virtualCardId: '2be801f7-dac8-4136-8189-44245b96037d',
  //         transactionType: 'DEBIT',
  //       },
  //       {
  //         id: '20378430-8868-4d6b-a9e1-eaae9ac6100e',
  //         amount: '1.00',
  //         orderId: null,
  //         relatedActivityTransactionId: '24f134fc-ef3c-4f30-983b-0b251f5e55a8',
  //         virtualCardId: '2be801f7-dac8-4136-8189-44245b96037d',
  //         transactionType: 'DEBIT',
  //       },
  //       {
  //         id: '8bfe4847-90c1-4227-b362-5a03b439b34a',
  //         amount: '1.00',
  //         orderId: null,
  //         relatedActivityTransactionId: '877bc937-41f2-4a5d-9192-8a170e0f5042',
  //         virtualCardId: '2be801f7-dac8-4136-8189-44245b96037d',
  //         transactionType: 'DEBIT',
  //       },
  //       {
  //         id: 'b279f68a-8a05-472e-9db2-a9e9d9a44d62',
  //         amount: '3.00',
  //         orderId: null,
  //         relatedActivityTransactionId: '255bf0da-f10a-436b-8e39-53734cb850cd',
  //         virtualCardId: '2be801f7-dac8-4136-8189-44245b96037d',
  //         transactionType: 'DEBIT',
  //       },
  //       {
  //         id: '5f31d94d-978a-495b-8abf-3dfa497e33fc',
  //         amount: '0.00',
  //         orderId: null,
  //         relatedActivityTransactionId: '9f996e46-859e-4c44-8603-b614e02b8194',
  //         virtualCardId: '2be801f7-dac8-4136-8189-44245b96037d',
  //         transactionType: 'DEBIT',
  //       },
  //     ],
  //   },
  //   {
  //     id: '2be801f7-dac8-4136-8189-44245b96037d',
  //     cardNumber: '89781344742669',
  //     balance: '70.00',
  //     isGlobal: true,
  //     cardTypeId: '5829e017-129c-4d9e-9e2a-a548857f2e41',
  //     customerId: 'c40d04ee-13c7-4af6-a77e-6ec78e4ff943',
  //     cardType: null,
  //     transactions: [
  //       {
  //         id: 'a46fbf1f-68af-4a5d-a9ca-f2cb32204fbf',
  //         amount: '100.00',
  //         orderId: null,
  //         relatedActivityTransactionId: '8387c36f-7985-4f6f-8950-b352a034dd02',
  //         virtualCardId: '2be801f7-dac8-4136-8189-44245b96037d',
  //         transactionType: 'CREDIT',
  //       },
  //       {
  //         id: '6ec4debb-bfa0-4c0c-bbda-90479515a7fe',
  //         amount: '25.00',
  //         orderId: null,
  //         relatedActivityTransactionId: 'b88f073a-2dd6-46f8-9c74-f543f58b0568',
  //         virtualCardId: '2be801f7-dac8-4136-8189-44245b96037d',
  //         transactionType: 'DEBIT',
  //       },
  //       {
  //         id: '20378430-8868-4d6b-a9e1-eaae9ac6100e',
  //         amount: '1.00',
  //         orderId: null,
  //         relatedActivityTransactionId: '24f134fc-ef3c-4f30-983b-0b251f5e55a8',
  //         virtualCardId: '2be801f7-dac8-4136-8189-44245b96037d',
  //         transactionType: 'DEBIT',
  //       },
  //       {
  //         id: '8bfe4847-90c1-4227-b362-5a03b439b34a',
  //         amount: '1.00',
  //         orderId: null,
  //         relatedActivityTransactionId: '877bc937-41f2-4a5d-9192-8a170e0f5042',
  //         virtualCardId: '2be801f7-dac8-4136-8189-44245b96037d',
  //         transactionType: 'DEBIT',
  //       },
  //       {
  //         id: 'b279f68a-8a05-472e-9db2-a9e9d9a44d62',
  //         amount: '3.00',
  //         orderId: null,
  //         relatedActivityTransactionId: '255bf0da-f10a-436b-8e39-53734cb850cd',
  //         virtualCardId: '2be801f7-dac8-4136-8189-44245b96037d',
  //         transactionType: 'DEBIT',
  //       },
  //       {
  //         id: '5f31d94d-978a-495b-8abf-3dfa497e33fc',
  //         amount: '0.00',
  //         orderId: null,
  //         relatedActivityTransactionId: '9f996e46-859e-4c44-8603-b614e02b8194',
  //         virtualCardId: '2be801f7-dac8-4136-8189-44245b96037d',
  //         transactionType: 'DEBIT',
  //       },
  //     ],
  //   },
  // ];
  const virtualCardsView = virtualCards.concat(state.localVirtualCards);
  return !openProduct ? (
    <>
      {isMobile ? (
        <Box sx={{ '& .swiper-slide': { width: '85vw', height: 'auto', margin: 1 } }}>
          <Box
            sx={{
              width: '100vw',
            }}
          >
            <Swiper
              speed={500}
              slidesPerView="auto"
              loop
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
            >
              {virtualCardsView.concat(state.localVirtualCards).map((card: IVirtualCard) => (
                <SwiperSlide>
                  <VirtualCardBlock
                    key={card.id}
                    card={card}
                    changeOpenProduct={() => changeOpenProduct(card)}
                    block={block}
                    openHistory={openHistory}
                    openModal={openModal}
                    onChangeClubCardInfo={onChangeClubCardInfo}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </Box>
      ) : (
        <>
          {!block?.settings?.enableSlider ? (
            <Box
              width={1}
              gap={1}
              display="grid"
              gridTemplateColumns={{
                sm: 'repeat(1, 1fr)',
                lg: 'repeat(2, 1fr)',
              }}
              sx={{
                pt: 2.5,
                justifyItems: 'center',
              }}
            >
              {virtualCardsView.map((card: IVirtualCard) => (
                <SwiperSlide>
                  <VirtualCardBlock
                    key={card.id}
                    card={card}
                    changeOpenProduct={() => changeOpenProduct(card)}
                    block={block}
                    openHistory={openHistory}
                    openModal={openModal}
                    onChangeClubCardInfo={onChangeClubCardInfo}
                  />
                </SwiperSlide>
              ))}
            </Box>
          ) : (
            <Box sx={{ '& .swiper-slide': { width: 'auto', height: 'auto', margin: 1 } }}>
              <Box
                sx={{
                  width: '70vw',
                }}
              >
                <Swiper
                  speed={500}
                  slidesPerView="auto"
                  loop
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                >
                  {virtualCardsView.concat(state.localVirtualCards).map((card: IVirtualCard) => (
                    <SwiperSlide>
                      <VirtualCardBlock
                        key={card.id}
                        card={card}
                        changeOpenProduct={() => changeOpenProduct(card)}
                        block={block}
                        openHistory={openHistory}
                        openModal={openModal}
                        onChangeClubCardInfo={onChangeClubCardInfo}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  ) : (
    <RenderInfoPage
      card={openProduct}
      returnToAllCards={() => changeOpenProduct(null)}
      block={block}
    />
  );
};

export default WalletCardsTab;
