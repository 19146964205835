import { useRouter } from 'next/navigation';
import { FC, useMemo, useEffect } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Card, CardMedia, Container, TextField, CardActions } from '@mui/material';

import { addToBasket } from '../../../../api/basket';
import { getContentValueFromProps } from '../../utils';
import Carousel from '../../../../components/carousel';
import { PriceTypeEnum } from '../../../../types/product';
import SvgColor from '../../../../components/svg-color';
import { useAuthContext } from '../../../../auth/hooks';
import { getProductByCategory } from '../../../../api/product';
import { BASIC_URL_FOR_IMAGES } from '../../../../constants/common';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';
import { uploadProductsImage } from '../../../../helpers/uploadProductsImage';
import { useContentContext } from '../../../../components/content';

type CategoryType = {
  value: string;
  title: string;
};

const HottestOffers: FC<ComponentTypeProps> = ({ block }) => {
  const { dispatch, state } = useAppContext();
  const getFieldValue = getContentValueFromProps(block);
  const { user } = useAuthContext();
  const { openSite } = useContentContext();
  const { cart } = state;
  const router = useRouter();
  const mainTitle = useMemo(() => getFieldValue('mainTitle'), [block?.mainTitle]);

  const categories = useMemo(() => {
    const categoriesValue = getFieldValue('categories');
    if (Array.isArray(categoriesValue)) {
      return categoriesValue.map((category: CategoryType) => ({
        ...category,
      }));
    }
    return [];
  }, [block?.categories]);

  const headingTitleTextColor = useMemo(
    () => getFieldValue('settings.headingTitleTextColor'),
    [block?.settings.headingTitleTextColor]
  );
  const headingTitleFontSize = useMemo(
    () => getFieldValue('settings.headingTitleFontSize'),
    [block?.settings.headingTitleFontSize]
  );
  const headingTitleFontWeight = useMemo(
    () => getFieldValue('settings.headingTitleFontWeight'),
    [block?.settings.headingTitleFontWeight]
  );
  const headingTitleFontStyle = useMemo(
    () => getFieldValue('settings.headingTitleFontStyle'),
    [block?.settings.headingTitleFontStyle]
  );
  const headingTitleTextDecoration = useMemo(
    () => getFieldValue('settings.headingTitleTextDecoration'),
    [block?.settings.headingTitleTextDecoration]
  );
  const headingTextAlignItems = useMemo(
    () => getFieldValue('settings.headingTextAlignItems'),
    [block?.settings.headingTextAlignItems]
  );

  const forceRtl = useMemo(() => getFieldValue('settings.forceRtl'), [block?.settings.forceRtl]);

  const contentTitleTextColor = useMemo(
    () => getFieldValue('settings.contentTitleTextColor'),
    [block?.settings.contentTitleTextColor]
  );
  const contentTitleFontSize = useMemo(
    () => getFieldValue('settings.contentTitleFontSize'),
    [block?.settings.contentTitleFontSize]
  );
  const contentTitleFontWeight = useMemo(
    () => getFieldValue('settings.contentTitleFontWeight'),
    [block?.settings.contentTitleFontWeight]
  );
  const contentTitleFontStyle = useMemo(
    () => getFieldValue('settings.contentTitleFontStyle'),
    [block?.settings.contentTitleFontStyle]
  );
  const contentTitleTextDecoration = useMemo(
    () => getFieldValue('settings.contentTitleTextDecoration'),
    [block?.settings.contentTitleTextDecoration]
  );
  const contentTextAlignItems = useMemo(
    () => getFieldValue('settings.contentTextAlignItems'),
    [block?.settings.contentTextAlignItems]
  );

  const title = 'ההצעות החמות ביותר';
  const cards = [
    {
      id: 5,
      img: '/assets/images/happy-gift/banners/offer_5.png',
      title: 'ארוחה זוגית מפנקת באווזי הכשרה שכונת התקווה',
    },
    {
      id: 4,
      img: '/assets/images/happy-gift/banners/offer_4.png',
      title: 'זיכוי כספי בשווי 100 ש"ח לרכישה באתר Wine Club',
    },
    {
      id: 3,
      img: '/assets/images/happy-gift/banners/offer_3.png',
      title: 'הבועה- קיט ציוד וחומרים להכנת 10 ליטר בירה',
    },
    {
      id: 2,
      img: '/assets/images/happy-gift/banners/offer_2.png',
      title: 'זיכוי כספי בשווי 100 ₪ למסעדת "6 במאי"',
    },
    {
      id: 1,
      img: '/assets/images/happy-gift/banners/offer_1.png',
      title: 'זיכוי כספי בשווי 100 ש"ח למסעדת מרינה ביי הרצליה',
    },
  ];
  const OPTIONS = ['1', '2', '3'];

  const responsive = [
    { breakpoint: 1024, settings: { slidesToShow: 3 } },
    { breakpoint: 650, settings: { slidesToShow: 2 } },
    { breakpoint: 426, settings: { slidesToShow: 1 } },
  ];

  const { productsByCategory } = state;

  useEffect(() => {
    const categoryIds: any[] = [];
    if (categories) categories.map((category) => categoryIds.push(category.value));
    getProductByCategory(state.smbAccount.id, categoryIds, dispatch);
  }, [categories]);

  const defaultImage =
    process.env.NEXT_PUBLIC_PROJECT_NAME === 'xtra'
      ? '/assets/images/xtra/default.png'
      : '/assets/images/happy-gift/banners/blueCardImage.png';

  return (
    <Stack sx={{ overflow: 'hidden' }}>
      <Typography
        sx={{
          direction: forceRtl ? 'rtl' : undefined,
          color: headingTitleTextColor,
          fontSize: headingTitleFontSize,
          fontStyle: headingTitleFontStyle,
          fontWeight: headingTitleFontWeight,
          textDecoration: headingTitleTextDecoration,
          textAlign: headingTextAlignItems,
        }}
      >
        {mainTitle}
      </Typography>
      <Stack direction="row" gap={2}>
        <Container maxWidth="xl" sx={{ mt: 1 }}>
          <Carousel autoplay arrows={false} slidesToShow={4} responsive={responsive}>
            {productsByCategory?.map((card, ind) => {
              const discountPrice =
                card?.prices?.find((p: any) => p.type === PriceTypeEnum.DISCOUNT)?.value || 0;
              const price =
                card?.prices?.find((p: any) => p.type === PriceTypeEnum.PRICE)?.value || 0;
              const imageLink =
                (card?.mainImageURL
                  ? `${uploadProductsImage(`${card?.mainImageURL}`)}`
                  : card?.merchants &&
                    card?.merchants.length &&
                    `${uploadProductsImage(`${card?.merchants[0].mainImageURL}`)}`) || '';
              return (
                <Card
                  key={ind}
                  sx={{ maxWidth: 250, height: '300px', borderRadius: '5px', cursor: 'pointer' }}
                  onClick={() => router.push(`product/${card?.id}`)}
                >
                  <Box
                    sx={{
                      width: '25px',
                      height: '25px',
                      bgcolor: 'white',
                      position: 'absolute',
                      top: '10px',
                      left: '10px',
                      zIndex: 10,
                      borderRadius: '5px',
                    }}
                  >
                    <SvgColor
                      src="/assets/images/happy-gift/logo/hot.svg"
                      sx={{ margin: 'auto', color: 'primary.main' }}
                    />
                  </Box>
                  <CardMedia
                    component="img"
                    height="150"
                    image={imageLink || defaultImage}
                    sx={{ borderRadius: '15px' }}
                  />
                  <CardContent sx={{ px: 2, py: 1 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: contentTitleTextColor,
                        fontSize: contentTitleFontSize,
                        fontStyle: contentTitleFontStyle,
                        fontWeight: contentTitleFontWeight,
                        textDecoration: contentTitleTextDecoration,
                        textAlign: contentTextAlignItems,
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {card.title}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Stack
                      width={1}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ position: 'absolute', bottom: 0, right: 0, px: 1, py: 1 }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => {
                          if (!user) {
                            dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: 'login' });
                          } else {
                            addToBasket(
                              state.smbAccount.id,
                              card.id,
                              price,
                              imageLink,
                              dispatch,
                              cart
                            );
                          }
                        }}
                        sx={{
                          height: '70%',
                          borderRadius: '25px',
                          fontWeight: 400,
                          bgcolor: '#27097A',
                          '&:hover': {
                            bgcolor: '#27097A',
                            color: 'secondary.contrastText',
                          },
                        }}
                      >
                        לרכישה
                      </Button>
                      <TextField
                        select
                        SelectProps={{ native: true }}
                        sx={{
                          '& .MuiNativeSelect-select': {
                            py: 1,
                            px: 2,
                          },
                        }}
                      >
                        {OPTIONS.map((option, id) => (
                          <option key={id} value={option}>
                            {option}
                          </option>
                        ))}
                      </TextField>
                      <Stack>
                        {/* <Typography */}
                        {/*  sx={{ */}
                        {/*    color: '#5D21EC', */}
                        {/*    textDecoration: 'line-through', */}
                        {/*    fontSize: '14px', */}
                        {/*  }} */}
                        {/* > */}
                        {/*  ₪{discountPrice} */}
                        {/* </Typography> */}
                        <Typography
                          sx={{
                            color: '#27097A',
                            fontSize: '18px',
                            fontWeight: 600,
                          }}
                        >
                          ₪{price || 99}
                        </Typography>
                      </Stack>
                    </Stack>
                  </CardActions>
                </Card>
              );
            })}
          </Carousel>
        </Container>
      </Stack>
    </Stack>
  );
};

export default HottestOffers;
