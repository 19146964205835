import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { Paper, Stack, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

import { useTranslate } from 'src/locales';

import Image from '../../../../../../components/image';
import Iconify from '../../../../../../components/iconify';
import { useBoolean } from '../../../../../../hooks/use-boolean';
import { BlockType } from '../../../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../../../contexts/AppContext';
import FormProvider, { RHFTextField } from '../../../../../../components/hook-form';
import { PROJECT_NAME } from '../../../../../../config-global';

export default function AddCard({ block }: { block: BlockType }) {
  const { t } = useTranslate();
  const { dispatch } = useAppContext();
  const addBalance = useBoolean();
  const addCard = useBoolean();
  const [totalValue, setTotalValue] = useState(0);

  const { settings } = block;
  const { showPhoneNumber } = settings;

  const onClose = () => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: null });
  };

  const FormSchema = Yup.object().shape({
    total: Yup.number(),
    ticketNumber: Yup.string(),
    month: Yup.string(),
    year: Yup.string(),
    CVV: Yup.number(),
    name: Yup.string(),
    id: Yup.string(),
    radio: Yup.boolean(),
  });

  const defaultValues = {
    total: 0,
    ticketNumber: '',
    month: '',
    year: '',
    CVV: 0,
    name: '',
    id: '',
    radio: true,
  };

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      reset();
      addBalance.onFalse();
      console.info('DATA', data);
    } catch (error) {
      console.error(error);
    }
  });

  const addCardModal = () => {
    setValue('total', totalValue);
    addCard.onTrue();
  };

  const retrieveUrl = () => {
    switch (PROJECT_NAME.toLowerCase()) {
      case 'xtra':
        return '/assets/images/xtra/logo/logo-single.svg';
      case 'ksharim':
        return '/assets/images/happy-gift/logo_happygift.svg';
      case 'raayonit':
        return '/assets/images/raayonit/logo/raayonit_horizontal.png';
      case 'boombuy':
        return '/logo/boom-buy/boom-logo-colored.png';
      case 'citypeople':
        return '/logo/city-people/logo.svg';
      case 'hashavshevet':
        return '/logo/hashavshevet/logo.svg';
      default:
        return '';
    }
  };

  return (
    <>
      {addCard.value ? (
        <Stack gap={2}>
          <Stack alignItems="center" mt={2}>
            <Image src={retrieveUrl()} width="30%" />
          </Stack>
          <Typography id="modal-modal-title" variant="h6" component="h2" color="secondary.main">
            בחר את אמצעי התשלום
          </Typography>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack gap={2}>
              <Paper elevation={2} sx={{ p: 2 }}>
                <Stack direction="row" width={1} alignItems="center" justifyContent="space-between">
                  <Stack direction="row" gap={3}>
                    <Radio name="radio" checked />
                    <Typography
                      sx={{
                        color: 'secondary.main',
                        fontSize: '20px',
                        fontWeight: 800,
                      }}
                    >
                      שלם בכרטיס אשראי
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Image
                      src="/assets/images/happy-gift/wallet/ic_payment_visa.png"
                      width="50px"
                      height="30px"
                    />
                    <Image
                      src="/assets/images/happy-gift/wallet/ic_payment_mastercard.png"
                      width="40px"
                      height="25px"
                    />
                  </Stack>
                </Stack>
              </Paper>
              <RHFTextField name="ticketNumber" label="מספר כרטיס" />
              <RHFTextField name="month" label="חודש" />
              <RHFTextField name="year" label="שנה" />
              <RHFTextField name="CVV" label="CVC/CVV" type="number" />
              <RHFTextField name="name" label="שם בעל הכרטיס" />
              <RHFTextField name="id" label="ת.ז" />
              <Stack direction="row" gap={1}>
                <LoadingButton
                  color="secondary"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  תשלום
                </LoadingButton>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    addBalance.onFalse();
                    addCard.onFalse();
                    setTotalValue(0);
                  }}
                >
                  ביטול
                </Button>
              </Stack>
            </Stack>
          </FormProvider>
        </Stack>
      ) : (
        <Stack gap={2}>
          <Stack alignItems="center" mt={2}>
            <Image src={retrieveUrl()} width="30%" />
          </Stack>
          <Typography id="modal-modal-title" variant="h6" component="h2" color="secondary.main">
            טעינת כרטיס
          </Typography>
          <Stack gap={3}>
            <Stack>
              <TextField
                name="total"
                label="סה`כ"
                defaultValue={totalValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Iconify icon="icons8:shekel" />
                    </InputAdornment>
                  ),
                }}
                type="number"
                onChange={(e) => setTotalValue(parseFloat(e.target.value))}
                helperText="כאשר אתה מפקיד את הסכום, אתה חוסך 15%"
              />
              <Typography id="modal-modal-title" variant="h6" component="h2" color="secondary.main">
                לתשלום: 850 שח
              </Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <LoadingButton color="secondary" variant="contained" onClick={addCardModal}>
                שלם
              </LoadingButton>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  addBalance.onFalse();
                  addCard.onFalse();
                  setTotalValue(0);
                }}
              >
                ביטול
              </Button>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
}
