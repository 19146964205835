'use client';

import { useMemo } from 'react';

import { getCurrentSection } from 'src/utils/sections';

import MainLayout from 'src/layouts/main';
// import { useGetActivitiesByMerchant } from 'src/api/merchant';

import { useContentContext } from 'src/components/content/context/content-context';

import { BlockTypes, SectionTypes } from 'src/types/generator';

import GeneratePageBlock from '../generate-page-section';
import { CartProvider } from '../../../contexts/CartContext';

// ----------------------------------------------------------------------

type Props = {
  title?: string | null;
  id?: string;
};

export default function GeneratePageView(params: Props) {
  // const { activity } = useGetActivitiesByMerchant();
  const { title } = params;

  const { sections } = useContentContext();

  const section = useMemo(
    () => getCurrentSection(sections, title ?? SectionTypes.home),
    [sections, title]
  );

  return (
    <MainLayout>
      <CartProvider>
        {section?.data.main
          .filter((block) => ![BlockTypes.footer, BlockTypes.header].includes(block.type))
          .filter((block) => !block.isServiceBlock)
          .map((blockItem, idx) => (
            <GeneratePageBlock key={`page-section-${idx}`} block={blockItem} />
          ))}
      </CartProvider>
    </MainLayout>
  );
}
