import { FC, useRef } from 'react';
import { useRouter } from 'next/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';

import Box from '@mui/material/Box';
import { Paper, Stack, Typography } from '@mui/material';

import Iconify from '../../../../components/iconify';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';
import Image from '../../../../components/image';

type IMethod = {
  icon: string;
  title: string;
  variant: string;
  link: string;
  image: string;
};
export const PaymentMethods: FC<ComponentTypeProps> = ({ block }) => {
  const methods: IMethod[] | any = block?.methods || [];
  const swiperRef = useRef<SwiperCore | null>(null);
  const router = useRouter();
  const { dispatch } = useAppContext();
  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };
  const paymentsClick = (method: IMethod) => {
    if (method?.variant === 'modal') {
      setActiveDialog(method?.link);
    }
    if (method?.variant === 'page' && method?.link !== '' && method?.link !== undefined) {
      router.push(`/${method?.link}`);
    }
  };
  const content = (name: string) => {
    switch (name) {
      case 'center':
        return 'center';
      case 'start':
        return 'flex-start';
      case 'end':
        return 'flex-end';
      default:
        return '';
    }
  };
  return (
    <Box
      sx={{
        '& .swiper-slide': { width: '140px', height: 'auto', margin: 1 },
        '& .swiper-wrapper': { justifyContent: `${content(block?.settings?.content)}` },
      }}
    >
      <Swiper
        speed={500}
        slidesPerView="auto"
        loop
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {methods.map((method: IMethod) => (
          <SwiperSlide>
            <Paper
              elevation={4}
              sx={{
                cursor: 'pointer',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Stack sx={{ p: 1 }} alignItems="center" onClick={() => paymentsClick(method)}>
                {block?.settings?.type === 'icon' && <Iconify icon={method.icon} width="30px" />}
                {block?.settings?.type === 'image' && (
                  <Image src={method.image} sx={{ height: '70px' }} />
                )}
                <Typography sx={{ textAlign: 'center', whiteSpace: 'balance' }}>
                  {method.title}
                </Typography>
              </Stack>
            </Paper>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
