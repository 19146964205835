import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC, useMemo } from 'react';
import { CustomSlider } from './custom-slider';
import { getContentValueFromProps } from '../../utils';
import { ComponentTypeProps } from '../../../../types/page-generator';

export const SpecialCategoriesHappyGift: FC<ComponentTypeProps> = ({ block }) => {
  const theme = useTheme();
  const getFieldValue = getContentValueFromProps(block);

  const mainTitle = useMemo(() => getFieldValue('mainTitle'), [block?.mainTitle]);

  const headingClasses = useMemo(
    () => getFieldValue('settings.headingClasses'),
    [block?.settings.headingClasses]
  );

  return (
    <Stack alignItems="center" my={4} width={1}>
      <Typography
        className={headingClasses}
        sx={{
          width: '100%',
          direction: block?.settings.forceRtl ? 'rtl' : undefined,
          color: block?.settings.headingTitleTextColor,
          fontSize: block?.settings.headingTitleFontSize,
          fontStyle: block?.settings.headingTitleFontStyle,
          fontWeight: block?.settings.headingTitleFontWeight,
          textDecoration: block?.settings.headingTitleTextDecoration,
          textAlign: block?.settings.headingTextAlignItems,
        }}
      >
        {mainTitle}
      </Typography>
      <CustomSlider block={block} />
    </Stack>
  );
};
