import { useRef, useState } from 'react';
// eslint-disable-next-line
import ReactCodeInput from 'react-verification-code-input';

import { Box, Stack, Button, TextField, Typography } from '@mui/material';
import Image from '../../../../../../components/image';
import ModalAuthRegisterForm from './AuthRegisterForm';
import { ComponentTypeProps } from '../../../../../../types/page-generator';

interface Props {
  onClose: () => void;
  closingForbidden?: boolean;
}

export const DefaultRegistration = ({ block }: ComponentTypeProps) => {
  const mainImage: string = block?.mainImage as string;
  const presentText: string = block?.presentText as string;
  const presentSubText: string = block?.presentSubText as string;

  return (
    <Box>
      <Stack alignItems="center" my={1}>
        <Image src={mainImage || ''} sx={{ width: '20%' }} />
      </Stack>
      <Stack spacing={2} alignItems="center" sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>
          {presentText}
        </Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
            {presentSubText}
          </Typography>
        </Stack>
      </Stack>
      <ModalAuthRegisterForm block={block} />
    </Box>
  );
};

export default DefaultRegistration;
