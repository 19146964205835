import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import Slider from 'react-slick';
import Box from '@mui/material/Box';
import { ComponentTypeProps } from '../../../../types/page-generator';
import Iconify from '../../../../components/iconify';
import SvgColor from '../../../../components/svg-color';
import { useResponsive } from '../../../../hooks/use-responsive';

const CategoriesBoomBuy: FC<ComponentTypeProps> = ({ block }) => {
  let sliderRef: Slider | any;
  const isMobile = useResponsive('down', 'sm');

  const CustomPrevArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        right: isMobile ? '-20px' : '-40px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
        color: block?.settings.colorButtons,
      }}
    >
      <Iconify icon="ep:arrow-left-bold" color="primary.main" />
    </Box>
  );

  const CustomNextArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        left: isMobile ? '-20px' : '-40px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
        color: block?.settings.colorButtons,
      }}
    >
      <Iconify icon="ep:arrow-right-bold" color="primary.main" />
    </Box>
  );

  const handlePrevClick = () => {
    sliderRef.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.slickNext();
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 4 : 8,
    slidesToScroll: 1,
    nextArrow: <CustomPrevArrow onClick={handlePrevClick} />,
    prevArrow: <CustomNextArrow onClick={handleNextClick} />,
  };

  const allCategories = [
    {
      id: 12,
      img: '/assets/images/boom-buy/categories/superst_store_icon.svg',
      title: 'המכולת הסודית',
    },
    {
      id: 11,
      img: '/assets/images/boom-buy/categories/star_icon.svg',
      title: 'הממולצים',
    },
    {
      id: 10,
      img: '/assets/images/boom-buy/categories/electro_icon.svg',
      title: 'חשמל ואלקטרוניקה',
    },
    {
      id: 9,
      img: '/assets/images/boom-buy/categories/brands_icon.svg',
      title: 'מותגים  חמים במחירים מטורפים',
    },
    {
      id: 8,
      img: '/assets/images/boom-buy/categories/handbag_icon.svg',
      title: 'תיקים ומזוודות',
    },
    {
      id: 7,
      img: '/assets/images/boom-buy/categories/alko_icon.svg',
      title: 'משקאות אלכוהול',
    },
    {
      id: 6,
      img: '/assets/images/boom-buy/categories/every_icon.svg',
      title: 'כללי',
    },
    {
      id: 5,
      img: '/assets/images/boom-buy/categories/every2_icon.svg',
      title: 'כל הדילים',
    },
    {
      id: 4,
      img: '/assets/images/boom-buy/categories/kitchen_icon.svg',
      title: 'לבית ולמטבח',
    },
    {
      id: 3,
      img: '/assets/images/boom-buy/categories/toys_icon.svg',
      title: 'צעצועים',
    },
    {
      id: 2,
      img: '/assets/images/boom-buy/categories/clothes_icon.svg',
      title: 'הלבשה והנעלה',
    },
    {
      id: 1,
      img: '/assets/images/boom-buy/categories/perfume_icon.svg',
      title: 'בשמים',
    },
  ];

  return (
    <Box sx={{ width: '90%', height: '100%', m: 'auto' }}>
      <Slider
        ref={function (slider) {
          sliderRef = slider;
        }}
        {...settings}
      >
        {allCategories.map((category, index) => (
          <Box key={index} sx={{ cursor: 'pointer' }}>
            <Stack key={index} alignItems="center">
              <SvgColor
                src={category.img}
                sx={{ width: '30%', color: block?.settings.colorIcons }}
              />
              <Typography
                sx={{
                  color: block?.settings.colorText,
                  fontWeight: 400,
                  fontSize: '12px',
                  textAlign: 'center',
                }}
              >
                {category.title}
              </Typography>
            </Stack>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default CategoriesBoomBuy;
