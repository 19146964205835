import { useRouter } from 'next/navigation';
// eslint-disable-next-line
import ReactCodeInput from 'react-verification-code-input';
import { useRef, useMemo, useState, useEffect } from 'react';

import { Box, Stack, Button, TextField, Typography } from '@mui/material';

import { useAuthContext } from 'src/auth/hooks';
import { getProducts, GetProductProps } from 'src/api/product';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify/iconify';

import { ComponentTypeProps } from 'src/types/page-generator';

import { getContentValueFromProps } from '../../../utils';
import AuthLockIcon from '../../../../../../public/assets/icons/city-people/auth-lock';

const PRODUCTS_LIMIT = 10;
const GRADIENT = 'linear-gradient(62deg, rgba(255, 86, 48, 0.12) 0%, rgba(0, 184, 217, 0.12) 100%)';
const SHADOW = '0px 2px 4px 0px rgba(0, 0, 0, 0.25)';

export const LoginCityPeople = ({ block }: ComponentTypeProps) => {
  const [phone, setPhone] = useState('');
  const [isCheckingOtp, setCheckingOtp] = useState<boolean | null>(null);
  const [isFetchingProducts, setFetchingProducts] = useState<boolean | null>(null);
  const [invalidCodeMsg, setInvalidCodeMsg] = useState('');
  const [isCodeView, setIsCodeView] = useState(false);
  const { dispatch, state } = useAppContext();
  const { login, checkCode, user } = useAuthContext();
  const getFieldValue = getContentValueFromProps(block);
  const ref = useRef<any>();
  const router = useRouter();

  const redirectLink: string = useMemo(
    () => getFieldValue('redirectLink') ?? '/wallet',
    [block?.redirectLink]
  );

  const getCode = async () => {
    try {
      await login(phone);
      setIsCodeView(true);
    } catch (e) {
      console.error(e);
    }
  };
  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };
  const sendCode = async (code: string) => {
    try {
      setCheckingOtp(true);
      await checkCode(phone, code);
      setCheckingOtp(false);
    } catch (e) {
      console.error(e);
      setCheckingOtp(false);
    }
  };

  const fetchProducts = async (options: GetProductProps) => {
    try {
      setFetchingProducts(true);
      await getProducts(state.smbAccount.id, options);
      setFetchingProducts(false);
    } catch (e) {
      console.error(e);
      setFetchingProducts(false);
      setInvalidCodeMsg('Error during receiving apartments!');
      setTimeout(() => {
        setActiveDialog(null);
        setInvalidCodeMsg('');
      }, 4000);
    }
  };

  useEffect(() => {
    if (isCheckingOtp === false) {
      if (user) {
        fetchProducts({
          limit: PRODUCTS_LIMIT,
          offset: 0,
          maxPrice: 200000,
          dispatch,
        });
      } else {
        setInvalidCodeMsg('Invalid code OTP!');
        setTimeout(() => {
          setActiveDialog(null);
          setInvalidCodeMsg('');
        }, 4000);
      }
    }
  }, [isCheckingOtp]);

  useEffect(() => {
    if (isFetchingProducts === false) {
      switch (state.products.length) {
        case 0:
          break;
        case 1:
          dispatch({
            type: ActionTypes.SET_SELECTED_APARTMENT,
            payload: state.apartments[0],
          });
          router.push('/coupons');
          break;
        default:
          router.push(redirectLink);
          break;
      }
      setActiveDialog(null);
    }
  }, [isFetchingProducts, state.products]);

  const isMaybeNext = !(phone.length > 9);
  const mainImage: string = (block?.mainImage || '') as string;
  const backgroundImage = mainImage ? `url(${mainImage})` : 'transparent';
  const hasBackground = block?.settings.hasBackground ?? true;
  const fontSizeTitle = block?.settings.fontSizeTitle ?? '18px';
  const fontWeightTitle = block?.settings.fontWeightTitle ?? 600;
  const iconSize = parseInt(block?.settings.iconSize, 10) ?? 90;

  if (user) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        alignItems="center"
        justifyContent="center"
      >
        <Typography my={5}>
          You are logged in by phone <b>{user.phone}</b>
        </Typography>
      </Box>
    );
  }

  return (
    <Box pt={1}>
      <Box
        mb={3}
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
        onClick={() => setActiveDialog('')}
      >
        <Iconify icon="bitcoin-icons:cross-outline" width={32} />
      </Box>
      <Stack alignItems="center" justifyContent="center">
        <Box
          width={iconSize}
          height={iconSize}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            p: 2.5,
            borderRadius: 2.5,
            backdropFilter: 'blur(20px)',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            backgroundImage: hasBackground ? GRADIENT : backgroundImage,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: '50%',
            boxShadow: hasBackground ? SHADOW : '',
          }}
        >
          {hasBackground && (
            <>
              {mainImage ? (
                <Image
                  src={mainImage}
                  sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
                />
              ) : (
                <AuthLockIcon />
              )}
            </>
          )}
        </Box>
      </Stack>
      {!isCodeView && (
        <Stack p={3} gap={2} alignItems="center" sx={{ m: '0 auto' }} width="90%" maxWidth="460px">
          <Typography
            color="primary"
            sx={{
              fontSize: fontSizeTitle,
              fontWeight: fontWeightTitle,
              textAlign: 'center',
              lineHeight: fontSizeTitle,
            }}
          >
            אימות חשבון
          </Typography>
          <TextField
            fullWidth
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            id="outlined-basic"
            label="מספר הטפון"
            variant="outlined"
            helperText="הקלד 10 ספרות"
          />
          <Stack direction="row" gap={2} width="100%">
            <Button
              sx={{
                borderRadius: 10,
                fontWeight: 300,
                width: '100%',
                color: 'rgb(0, 68, 84)',
              }}
              onClick={getCode}
              size="large"
              variant="contained"
              color="secondary"
              disabled={isMaybeNext}
            >
              כניסה
            </Button>
          </Stack>
        </Stack>
      )}

      {isCodeView && (
        <Stack alignItems="center">
          <Typography>Enter code from sms</Typography>
          {invalidCodeMsg && (
            <Typography mt={1} color="red">
              {invalidCodeMsg}
            </Typography>
          )}
          {isCheckingOtp === true && (
            <Typography mb={1} color="text.secondary">
              checking...
            </Typography>
          )}
          <Box my={5} sx={{ direction: 'rtl' }}>
            <ReactCodeInput ref={ref} autoFocus fields={6} onComplete={(code) => sendCode(code)} />
          </Box>
        </Stack>
      )}
    </Box>
  );
};
