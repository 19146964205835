import React, { useMemo, useState, ReactNode } from 'react';

import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { BOT_ID } from 'src/config-global';

import { useTranslate } from '../../locales';
import { selectedGift } from '../../api/gifts';
import { IProduct } from '../../types/product';
import SelectGiftItem from './select-gift-item';
import ConfirmModal from '../modals/confirm-modal';
import { IGift, GiftType } from '../../types/gift';
import { ICardType } from '../../types/club-cards';
import { useAppContext } from '../../contexts/AppContext';
import Carousel, { useCarousel, CarouselDots, CarouselArrows } from '../carousel';

type Props = {
  gift: IGift;
  onClose?: () => void;
  sx?: SxProps;
};

type SelectedGiftType = {
  productId: string;
  isGiftCardType: boolean;
};

type GiftContent = Array<IProduct | ICardType>;

const SelectGift = ({ sx, gift, onClose }: Props) => {
  const [confirmModalVisibility, setConfirmModalVisibility] = useState(false);
  const { state, dispatch } = useAppContext();
  const { t } = useTranslate();
  const [_selectedGift, _setSelectedGift] = useState<SelectedGiftType | null>(null);

  const handleSelectGift = (productId: string, isGiftCardType: boolean) => {
    setConfirmModalVisibility(true);
    _setSelectedGift({ productId, isGiftCardType });
  };

  const handleCloseConfirmModal = () => {
    setConfirmModalVisibility(false);
  };

  const handleConfirmSelectGift = () => {
    setConfirmModalVisibility(false);

    const selectGift = async () => {
      await selectedGift({
        giftId: gift.id,
        giftType: _selectedGift?.isGiftCardType ? GiftType.Card : GiftType.Product,
        selectedId: _selectedGift?.productId || '',
        botId: Number(BOT_ID),
        dispatch,
      });
    };
    selectGift();
  };

  // const gifts = useMemo(() => ([ ...state.gifts.products, ...state.gifts.virtualCardId ]), [state.gifts]);
  const giftContent: GiftContent = useMemo(
    () => [...gift.products, ...gift.virtualCardTypes],
    [gift]
  );

  return (
    <Box sx={sx || {}}>
      <Stack sx={{ position: 'relative' }} alignItems="center" gap={2}>
        <Typography>{t('Select one gift')}</Typography>
        <GiftsWrapper giftsCount={giftContent.length}>
          {/* TODO: set correct type of _gift */}
          {giftContent.map((_gift: any) => (
            <SelectGiftItem
              key={_gift.id}
              // giftId={gift.id}
              productId={_gift.id}
              name={_gift.title || _gift.name}
              coverUrl={_gift.image || _gift.mainImageURL}
              isGiftCardType={!!_gift.mainImageURL}
              onClick={handleSelectGift}
            />
          ))}
        </GiftsWrapper>
      </Stack>

      <ConfirmModal
        open={confirmModalVisibility}
        onClose={handleCloseConfirmModal}
        onConfirm={handleConfirmSelectGift}
      />
    </Box>
  );
};

type GiftsWrapperProps = {
  giftsCount: number;
  children: ReactNode;
};

const screenWidth = typeof window !== 'undefined' ? window.screen.width : 0;
const GiftsWrapper = ({ children, giftsCount }: any) => {
  const breakpoints = {
    xxl: 1400,
    md: 768,
    sm: 576,
  };

  const carousel = useCarousel({
    slidesToShow: 4,
    slidesToScroll: 1,
    rtl: document.dir === 'rtl',
    centerMode: true,
    centerPadding: '0px',
    ...CarouselDots({
      sx: { mt: 3 },
    }),
    responsive: [
      {
        breakpoint: breakpoints.xxl,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: breakpoints.md,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: breakpoints.sm,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  });

  return (screenWidth > breakpoints.xxl && giftsCount > 4) ||
    (screenWidth > breakpoints.md && screenWidth < breakpoints.xxl && giftsCount > 3) ||
    (screenWidth > breakpoints.sm && screenWidth < breakpoints.md && giftsCount > 2) ||
    (screenWidth < breakpoints.sm && giftsCount) ? (
    <CarouselArrows
      filled
      onNext={carousel.onNext}
      onPrev={carousel.onPrev}
      leftButtonProps={{
        size: 'small',
        sx: { top: 'calc(50% + 35px)', left: '-5px' },
      }}
      rightButtonProps={{
        size: 'small',
        sx: { top: 'calc(50% + 35px)', right: '-5px' },
      }}
      sx={{ maxWidth: '100%' }}
    >
      <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
        {children}
      </Carousel>
    </CarouselArrows>
  ) : (
    <Stack direction="row" gap={5} /* wrap */>
      {children}
    </Stack>
  );
};

export default SelectGift;
