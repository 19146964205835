'use client';

// types
import { styled } from '@mui/system';
// @mui
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

// components
import Iconify from 'src/components/iconify';

import { useTranslate } from '../../../../locales';
import { fDate } from '../../../../utils/format-time';
import { useBoolean } from '../../../../hooks/use-boolean';

// utils
// hooks

// ----------------------------------------------------------------------

type Props = {
  discounts: {
    id: string;
    discount: string;
    status: string;
    startDate: Date | string;
    endDate: Date | string;
  }[];
};

export default function AccountStatusHistory({ discounts }: Props) {
  const showMore = useBoolean();
  const { t } = useTranslate();

  const ScrollbarContainer = styled('div')({
    display: 'flex',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  });

  return (
    <Card>
      <CardHeader title={`${t('Discount status history')}`} />

      <Stack spacing={1.5} sx={{ px: 3, pt: 3 }}>
        <ScrollbarContainer>
          <Stack gap={2} width={1}>
            {(showMore.value ? discounts : discounts.slice(0, 3)).map((discount) => (
              <Stack key={discount.id} direction="row" alignItems="center">
                <Typography variant="body2" sx={{ textAlign: 'right', mr: 5 }}>
                  {discount.discount}%
                </Typography>

                <Typography sx={{ textAlign: 'right', mr: 5 }}>
                  {fDate(discount.startDate)}
                </Typography>
                <Typography sx={{ textAlign: 'right', mr: 5 }}>
                  {fDate(discount.endDate)}
                </Typography>

                <Typography
                  color={discount.status === 'Accepted' ? 'green' : 'red'}
                  sx={{ textAlign: 'right', mr: 5 }}
                >
                  {discount.status}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </ScrollbarContainer>
        <Divider sx={{ borderStyle: 'dashed' }} />
      </Stack>

      <Stack alignItems="flex-start" sx={{ p: 2 }}>
        <Button
          size="small"
          color="inherit"
          startIcon={
            <Iconify
              icon={showMore.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
            />
          }
          onClick={showMore.onToggle}
        >
          {showMore.value ? `${t(`Show Less`)}` : `${t(`Show More`)}`}
        </Button>
      </Stack>
    </Card>
  );
}
