import { FC } from 'react';
import { useParams } from 'next/navigation';

import { Grid, Typography } from '@mui/material';

import Image from '../../../../components/image';
import { useGetMerchant } from '../../../../api/shop';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { uploadProductsImage } from '../../../../helpers/uploadProductsImage';

export const Merchant: FC<ComponentTypeProps> = ({ block }) => {
  const { id }: { id: string } = useParams();
  const { merchant }: any = useGetMerchant({ id });

  const image = merchant?.mainImageURL || '';
  const { title } = merchant || { title: '' };
  return (
    <Grid container spacing={1}>
      <Grid item md={6} xs={12}>
        <Typography
          sx={{
            fontSize: 34,
            pt: 2,
            color: 'primary.main',
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <Image src={`${uploadProductsImage(image)}`} />
      </Grid>
    </Grid>
  );
};
