// types

// @mui
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

// hooks

// locales

// components

import { IWallet } from '../../../types/wallet';

// ----------------------------------------------------------------------

type Props = {
  row: IWallet;
  cellStyle?: any;
};

export default function WalletTableRow({ row, cellStyle }: Props) {
  const { id, coupon, code, dateOfUse, placeOfUse, eventType } = row;

  return (
    <TableRow hover>
      <TableCell style={cellStyle} sx={{ whiteSpace: 'nowrap' }}>
        {coupon}
      </TableCell>

      <TableCell style={cellStyle} sx={{ whiteSpace: 'nowrap' }}>
        {code}
      </TableCell>

      <TableCell style={cellStyle} sx={{ whiteSpace: 'nowrap' }}>
        {dateOfUse}
      </TableCell>

      <TableCell style={cellStyle} sx={{ whiteSpace: 'nowrap' }}>
        {placeOfUse}
      </TableCell>

      <TableCell style={cellStyle} sx={{ whiteSpace: 'nowrap' }}>
        {eventType}
      </TableCell>
    </TableRow>
  );
}
