import Typography from '@mui/material/Typography';
import Paper, { PaperProps } from '@mui/material/Paper';
import { useTranslate } from '../../locales';

// ----------------------------------------------------------------------

interface Props extends PaperProps {
  query?: string;
}

export default function SearchNotFound({ query, sx, ...other }: Props) {
  const { t } = useTranslate();
  return query ? (
    <Paper
      sx={{
        bgcolor: 'unset',
        textAlign: 'center',
        ...sx,
      }}
      {...other}
    >
      <Typography variant="h6" gutterBottom>
        {`${t('Not Found')}`}
      </Typography>

      <Typography variant="body2">
        {`${t('No results found for')}`} &nbsp;
        <strong>&quot;{query}&quot;</strong>.
        <br /> {`${t('Try checking for typos or using complete words.')}`}
      </Typography>
    </Paper>
  ) : (
    <Typography variant="body2" sx={sx}>
      {`${t('Please enter keywords')}`}
    </Typography>
  );
}
