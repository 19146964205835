import { endpoints } from 'src/utils/axios';

import { API } from 'src/helpers/api';

export const getVirtualCardTypeById = async (id: string) => {
  try {
    const url = endpoints.virtualCardType.getById;
    const { data } = await API({
      url,
      method: 'GET',
      params: {
        id,
      },
    });
    return data.payload;
  } catch (e) {
    return e;
  }
};
