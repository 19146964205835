import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import FormProvider, { RHFCheckbox, RHFTextField, RHFRadioGroup } from 'src/components/hook-form';

import { BillingAddress } from 'src/types/address';

import { useTranslate } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onCreate: (address: BillingAddress) => void;
};

export default function AddressNewForm({ open, onClose, onCreate }: Props) {
  const { t } = useTranslate();
  const NewAddressSchema = Yup.object().shape({
    name: Yup.string().required(`${t('First name is required')}`),
    lastName: Yup.string().required(`${t('Last name is required')}`),
    area: Yup.string().required(`${t('Area name is required')}`),
    phoneNumber: Yup.string().required(`${t('Phone number is required')}`),
    address: Yup.string().required(`${t('Address is required')}`),
    city: Yup.string().required(`${t('City is required')}`),
    street: Yup.string().required(`${t('Street is required')}`),
    more: Yup.string(),
    home: Yup.string().required(`${t('Home is required')}`),
    country: Yup.string().required(`${t('Country is required')}`),
    zipCode: Yup.string().required(`${t('Zip code is required')}`),
    // not required
    addressType: Yup.string(),
    primary: Yup.boolean(),
  });

  const defaultValues = {
    name: '',
    lastName: '',
    area: '',
    city: '',
    street: '',
    more: '',
    home: '',
    address: '',
    zipCode: '',
    primary: true,
    phoneNumber: '',
    addressType: 'Home',
    country: '',
  };

  const methods = useForm({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      onCreate({
        name: data.name,
        lastName: data.lastName,
        area: data.area,
        phoneNumber: data.phoneNumber,
        fullAddress: `${data.address}, ${data.city}, ${data.street}, ${data.country}, ${data.zipCode}`,
        addressType: data.addressType || '',
        primary: data.primary || true,
      });
      onClose();
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle sx={{ textDecoration: 'underline' }}>{`${t('New Address')}`}</DialogTitle>

        <DialogContent dividers>
          <Stack spacing={3}>
            <RHFRadioGroup
              row
              name="addressType"
              options={[
                { label: `${t('Home')}`, value: 'Home' },
                { label: `${t('Office')}`, value: 'Office' },
              ]}
            />

            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="name" label={`*${t('Full Name')}`} />

              <RHFTextField name="lastName" label={`*${t('Last Name')}`} />

              <RHFTextField name="phoneNumber" label={`*${t('Phone Number')}`} />

              <RHFTextField name="area" label={`${t('Area')}`} />
            </Box>

            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="city" label={`${t('Town / City')}`} />

              <RHFTextField name="zipCode" label={`${t('Zip/Code')}`} />

              <RHFTextField name="street" label={`${t('Street')}`} />

              <RHFTextField name="home" label={`${t('Home')}`} />
            </Box>

            <RHFTextField rows={4} multiline name="more" label={`${t('More details')}`} />

            {/* <RHFAutocomplete */}
            {/*  name="country" */}
            {/*  label={`${t('Country')}`} */}
            {/*  options={countries.map((country) => country.label)} */}
            {/*  getOptionLabel={(option) => option} */}
            {/*  renderOption={(props, option) => { */}
            {/*    const { code, label, phone } = countries.filter( */}
            {/*      (country) => country.label === option */}
            {/*    )[0]; */}

            {/*    if (!label) { */}
            {/*      return null; */}
            {/*    } */}

            {/*    return ( */}
            {/*      <li {...props} key={label}> */}
            {/*        <Iconify */}
            {/*          key={label} */}
            {/*          icon={`circle-flags:${code.toLowerCase()}`} */}
            {/*          width={28} */}
            {/*          sx={{ mr: 1 }} */}
            {/*        /> */}
            {/*        {label} ({code}) +{phone} */}
            {/*      </li> */}
            {/*    ); */}
            {/*  }} */}
            {/* /> */}

            <RHFCheckbox name="primary" label={`${t('Use this address as default.')}`} />
          </Stack>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {`${t('Deliver to this Address')}`}
          </LoadingButton>

          <Button color="inherit" variant="outlined" onClick={onClose}>
            {`${t('Cancel')}`}
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
