import { FC } from 'react';

import Stack from '@mui/material/Stack';
import { Box, Container } from '@mui/material';

import Image from 'src/components/image';

import Carousel from '../../../../components/carousel';
import { ComponentTypeProps } from '../../../../types/page-generator';

const BrandsCarousel2: FC<ComponentTypeProps> = ({ block }) => {
  const cards = [
    {
      id: 5,
      img: '/assets/images/xtra/banners/brands/5.png',
    },
    {
      id: 4,
      img: '/assets/images/xtra/banners/brands/4.png',
    },
    {
      id: 3,
      img: '/assets/images/xtra/banners/brands/3.png',
    },
    {
      id: 2,
      img: '/assets/images/xtra/banners/brands/2.png',
    },
    {
      id: 1,
      img: '/assets/images/xtra/banners/brands/1.png',
    },
    {
      id: 6,
      img: '/assets/images/xtra/banners/brands/5.png',
    },
    {
      id: 7,
      img: '/assets/images/xtra/banners/brands/4.png',
    },
    {
      id: 8,
      img: '/assets/images/xtra/banners/brands/3.png',
    },
    {
      id: 9,
      img: '/assets/images/xtra/banners/brands/2.png',
    },
    {
      id: 10,
      img: '/assets/images/xtra/banners/brands/1.png',
    },
  ];
  const { brandsCarouselImages }: any = block;

  const responsive = [
    { breakpoint: 1024, settings: { slidesToShow: 3 } },
    { breakpoint: 650, settings: { slidesToShow: 2 } },
    { breakpoint: 426, settings: { slidesToShow: 1 } },
  ];

  return (
    <Stack alignItems="center" width={1}>
      <Container maxWidth="xl" sx={{ mt: 8 }}>
        <Carousel autoplay arrows={false} slidesToShow={5} responsive={responsive}>
          {brandsCarouselImages?.map((item: { image: string }, ind: number) => (
            <Box key={ind} sx={{ px: 1 }}>
              <Image src={item.image} sx={{ borderRadius: '15px' }} />
            </Box>
          ))}
        </Carousel>
      </Container>
    </Stack>
  );
};

export default BrandsCarousel2;
