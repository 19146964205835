import { FC, useMemo } from 'react';

import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { getContentValueFromProps } from '../../utils';
import { ComponentTypeProps } from '../../../../types/page-generator';
import BrandListCarouselArea from '../bazaar/fashion-2/brand-list-carousel-area';

const BrandsCarousel: FC<ComponentTypeProps> = ({ block }) => {
  const theme = useTheme();
  const getFieldValue = getContentValueFromProps(block);
  const direction = useMemo(() => getFieldValue('settings.direction'), [block]);

  const { brandsCarouselImages }: any = block;

  return (
    <Stack alignItems="center" width={1}>
      <BrandListCarouselArea
        brands={brandsCarouselImages || []}
        divider={false}
        direction={direction}
        block={block}
      />
    </Stack>
  );
};

export default BrandsCarousel;
