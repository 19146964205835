import useSWR from 'swr';
import { useMemo } from 'react';

import { fetcher, endpoints } from 'src/utils/axios';

import { API } from 'src/helpers/api';
import { BOT_ID } from 'src/config-global';
import { ActionType, ActionTypes } from 'src/contexts/AppContext';
// import { LOCAL_STORAGE_KEYS } from 'src/constants/localStorageKeys';
// import { getDataFromLocaleStorage } from 'src/helpers/localStorage';

import { IProductItem } from 'src/types/product';

export type GetProductProps = {
  limit: number;
  offset: number;
  minPrice?: number;
  maxPrice?: number;
  dispatch?: React.Dispatch<ActionType>;
  search?: string;
  categoriesIds?: string[];
  tagsIds?: string[];
  priceType?: string;
  merchantTagsIds?: string[];
};
export type SearchProductsProps = GetProductProps & {
  categoryIds?: string[];
  merchantIds?: string[];
  productTagIds?: string[];
  merchantTagIds?: string[];
};
export type GetProductByIdProps = {
  limit: number;
  offset: number;
  minPrice?: number;
  maxPrice?: number;
  dispatch?: React.Dispatch<ActionType>;
  search?: string;
  id: string | string[];
  categoriesIds: string[];
};

type GetCategoriesProps = {
  limit: number;
  offset: number;
  categoryIds: string[];
  dispatch?: React.Dispatch<ActionType>;
  minPrice?: number;
  maxPrice?: number;
  search?: string;
};

export const getLocalProducts = async () => {
  try {
    const local: any = localStorage.getItem('account');
    const account = JSON.parse(local);
    const { data } = await API({
      url: `product/getAll?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        active: true,
        relations: ['merchants', 'categories'],
        limit: 999,
        offset: 0,
        minPrice: 0,
        maxPrice: 3000,
        smbAccountId: account?.id,
      },
    });
    return data.payload;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getProducts = async (
  smbAccountId: string,
  {
    limit,
    offset,
    minPrice = 0,
    maxPrice = 10000,
    dispatch,
    search,
    categoriesIds,
    priceType,
    tagsIds,
    merchantTagsIds,
  }: GetProductProps,
  isSearch?: boolean
) => {
  try {
    const categories = !categoriesIds ? [] : categoriesIds;

    const tags = !tagsIds ? [] : tagsIds;
    const { data } = await API({
      url: `product/getAll?botId=${BOT_ID}`,
      // url: 'product/getProductsByMerchantId',
      method: 'GET',
      params: {
        // merchantId: 3,
        // botId: BOT_ID,
        active: true,
        relations: ['merchants', 'categories'],
        limit,
        offset,
        minPrice,
        maxPrice,
        search,
        categoryIds: categories,
        tagsIds: tags,
        merchantTagsIds,
        priceType,
        // smbAccountId,
      },
    });
    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_PRODUCTS,
        payload: { products: data.payload, count: data.count },
      });
    }
    // if (!isSearch) {
    //   dispatch({
    //     type: ActionTypes.SET_RESERV_PRODUCTS,
    //     payload: { products: data.payload },
    //   });
    // }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
};

export const searchProducts = async (
  smbAccountId: string,
  {
    dispatch,
    limit,
    offset,
    // minPrice = 0,
    // maxPrice = 3000,
    search,
    categoryIds,
    merchantIds,
    productTagIds,
    merchantTagIds,
  }: SearchProductsProps
) => {
  try {
    let delayInMilliseconds;
    if (search) {
      delayInMilliseconds = 5000;
    } else if (merchantIds && merchantIds.length > 0) {
      delayInMilliseconds = 7000;
    } else {
      delayInMilliseconds = 0;
    }

    if (dispatch) {
      // dispatch({ type: ActionTypes.LOADING_SEARCH_PRODUCTS, payload: true });
    }
    // merchants.network,merchants.asterisks,merchants.network.asterisks
    const relationsQuery = '&relations=asterisks';
    const categoryIdsQuery =
      Array.isArray(categoryIds) && categoryIds.length
        ? `&categoryIds=${categoryIds.join(',')}`
        : '';
    const merchantIdsQuery =
      Array.isArray(merchantIds) && merchantIds.length
        ? `&merchantIds=${merchantIds.join(',')}`
        : '';
    const productTagIdsQuery =
      Array.isArray(productTagIds) && productTagIds.length
        ? `&productTagIds=${productTagIds.join(',')}`
        : '';
    const merchantTagIdsQuery =
      Array.isArray(merchantTagIds) && merchantTagIds.length
        ? `&merchantTagIds=${merchantTagIds.join(',')}`
        : '';

    const fetchProducts = async () => {
      const { data } = await API({
        url: `search/products?botId=${BOT_ID}${relationsQuery}${categoryIdsQuery}${merchantIdsQuery}${productTagIdsQuery}${merchantTagIdsQuery}`,
        method: 'GET',
        params: {
          active: true,
          limit,
          offset,
          // minPrice,
          // maxPrice,
          search,
          smbAccountId,
        },
      });
      // console.log('data.payload', data.payload);
      if (dispatch) {
        dispatch({
          type: ActionTypes.SEARCH_PRODUCTS,
          payload: {
            products: data.payload.products,
            merchants: data.payload.merchants,
            networks: data.payload.networks,
            categories: data.payload.categories,
            tags: data.payload.tags,
            count: data.count,
          },
        });
        // dispatch({ type: ActionTypes.LOADING_SEARCH_PRODUCTS, payload: false });
      }
    };
    setTimeout(fetchProducts, delayInMilliseconds);
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
};

export const getBannerProducts = async (
  smbAccountId: string,
  {
    limit,
    offset,
    minPrice = 0,
    maxPrice = 1400,
    dispatch,
    search,
    categoriesIds,
    priceType,
    tagsIds,
  }: GetProductProps,
  isSearch?: boolean
) => {
  try {
    const { data } = await API({
      url: `product/getAll?botId=${BOT_ID}`,
      // url: 'product/getProductsByMerchantId',
      method: 'GET',
      params: {
        // merchantId: 3,
        // botId: BOT_ID,
        active: true,
        relations: ['merchants', 'categories'],
        limit,
        offset,
        minPrice,
        maxPrice,
        search,
        categoryIds: categoriesIds,
        tagsIds,
        priceType,
        smbAccountId,
      },
    });
    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_BANNER_PRODUCTS,
        payload: { products: data.payload, count: data.count },
      });
    }

    return data.payload;

    // if (!isSearch) {
    //   dispatch({
    //     type: ActionTypes.SET_RESERV_PRODUCTS,
    //     payload: { products: data.payload },
    //   });
    // }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
  return null;
};

export const getBannerProductsForMainProducts = async (
  smbAccountId: string,
  {
    limit,
    offset,
    minPrice = 0,
    maxPrice = 1400,
    dispatch,
    search,
    categoriesIds,
    priceType,
  }: GetProductProps,
  isSearch?: boolean
) => {
  try {
    const { data } = await API({
      url: `product/getAll?botId=${BOT_ID}`,
      // url: 'product/getProductsByMerchantId',
      method: 'GET',
      params: {
        // merchantId: 3,
        // botId: BOT_ID,
        active: true,
        relations: ['categories'],
        limit,
        offset,
        minPrice,
        maxPrice,
        search,
        categoryIds: categoriesIds,
        priceType,
        smbAccountId,
      },
    });
    return data.payload;
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
  return null;
};

export const getProductsByMerchantId = async (
  {
    limit,
    offset,
    minPrice = 0,
    maxPrice = 1400,
    dispatch,
    search,
    id,
    categoriesIds = [],
  }: GetProductByIdProps,
  isSearch?: boolean
) => {
  try {
    const relations = BOT_ID === '502' ? [] : ['merchants', 'categories'];
    const { data } = await API({
      url: `/product/getProductsByMerchantId?botId=${BOT_ID}`,
      // url: 'product/getProductsByMerchantId',
      method: 'GET',
      params: {
        // botId: BOT_ID,
        merchantId: id,
        active: true,
        relations,
        limit,
        offset,
        minPrice,
        maxPrice,
        search,
        categoryIds: categoriesIds,
      },
    });
    if (dispatch) {
      if (BOT_ID !== '502') {
        dispatch({
          type: ActionTypes.GET_PRODUCTS,
          payload: { products: data.payload, count: data.count },
        });
      } else {
        dispatch({
          type: ActionTypes.GET_BANNER_PRODUCTS,
          payload: { products: data.payload, count: data.count },
        });
      }
    }

    // if (!isSearch) {
    //   dispatch({
    //     type: ActionTypes.SET_RESERV_PRODUCTS,
    //     payload: { products: data.payload },
    //   });
    // }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
};

export const getProductsByPrice = async (
  limit: number,
  offset: number,
  minPrice: number,
  maxPrice: number,
  dispatch?: React.Dispatch<ActionType>
) => {
  try {
    const { data } = await API({
      url: 'product/getAll',
      method: 'GET',
      params: {
        botId: BOT_ID,
        active: true,
        relations: ['merchants', 'categories'],
        limit,
        offset,
        minPrice,
        maxPrice,
      },
    });

    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_PRODUCTS,
        payload: { products: data.payload, count: data.count },
      });
    }
    if (dispatch) {
      dispatch({
        type: ActionTypes.SET_RESERV_PRODUCTS,
        payload: { products: data.payload },
      });
    }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
};

export const getProduct = async (id?: string | string[], dispatch?: any) => {
  try {
    const { data } = await API({
      url: 'product',
      method: 'GET',
      params: {
        id,
        relations: ['merchants', 'categories', 'asterisks'],
      },
    });
    return data.payload;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getProductByCategoryIds = async ({
  limit,
  offset,
  categoryIds,
  dispatch,
  minPrice = 0,
  maxPrice = 1400,
  search,
}: GetCategoriesProps) => {
  const local: any = localStorage.getItem('account');
  const account = JSON.parse(local);
  try {
    const { data } = await API({
      url: 'product/getProductsByCategory',
      method: 'GET',
      params: {
        categoryIds,
        relations: ['categories', 'prices', 'merchants'],
        type: 'FIXED_PRICE',
        limit,
        offset,
        botId: BOT_ID,
        minPrice,
        maxPrice,
        search,
        smbAccountId: account?.id,
      },
    });

    // -- temporary
    // data.payload.length = 12;

    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_PRODUCTS,
        payload: { products: data.payload, count: data.count },
      });
    }
    if (dispatch) {
      dispatch({
        type: ActionTypes.SET_RESERV_PRODUCTS,
        payload: { products: data.payload },
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export const getProductByCategory = async (
  smbAccountId: string,
  categoryIds: string[],
  dispatch?: React.Dispatch<ActionType>
) => {
  if (categoryIds.length) {
    try {
      const { data } = await API({
        // url: 'product/getProductsByCategory',
        url: `product/getAll?botId=${BOT_ID}`,
        method: 'GET',
        params: {
          categoryIds,
          limit: 10,
          offset: 0,
          minPrice: 0,
          maxPrice: 3000,
          // botId: BOT_ID,
          relations: ['categories', 'merchants'],
          smbAccountId,
        },
      });
      // console.log('data', data);
      // -- temporary
      data.payload.length = 10;

      if (dispatch) {
        dispatch({
          type: ActionTypes.GET_PRODUCTS_BY_CATEGORY,
          payload: { productsByCategory: data.payload },
        });
      }
    } catch (e) {
      console.error(e);
    }
  } else if (dispatch) {
    dispatch({
      type: ActionTypes.GET_PRODUCTS_BY_CATEGORY,
      payload: { productsByCategory: [] },
    });
  } else {
    console.log('');
  }
};

export const getCategories = async (
  smbAccountId: string,
  dispatch?: React.Dispatch<ActionType>
) => {
  try {
    const { data } = await API({
      url: 'product-category/getAll',
      method: 'GET',
      params: {
        limit: 100,
        offset: 0,
        smbAccountId,
      },
    });
    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_CATEGORIES,
        payload: data.payload,
      });
    }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
};

export const getProductsTags = async (
  smbAccountId: string,
  dispatch?: React.Dispatch<ActionType>
) => {
  try {
    const { data } = await API({
      url: 'product/getTags',
      method: 'GET',
      params: {
        limit: 100,
        offset: 0,
        smbAccountId,
      },
    });
    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_PRODUCTS_TAGS,
        payload: data.payload,
      });
    }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
};

export function useGetProduct(productId: string) {
  const URL = productId
    ? [endpoints.product.byId, { params: { id: productId, relations: ['merchants'] } }]
    : '';
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      product: data?.payload as IProductItem | any,
      productLoading: isLoading,
      productError: error,
      productValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useSearchProducts(query: string) {
  const URL = query ? [endpoints.product.search, { params: { query } }] : '';

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      searchResults: (data?.results as IProductItem[]) || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
      searchEmpty: !isLoading && !data?.results.length,
    }),
    [data?.results, error, isLoading, isValidating]
  );

  return memoizedValue;
}
