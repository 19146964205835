import { FC } from 'react';

import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';

import { ComponentTypeProps } from '../../../../types/page-generator';

const ProductPagination: FC<ComponentTypeProps> = ({ block }) => (
  <Stack width={1} justifyContent="center">
    <Pagination count={10} color="primary" sx={{ margin: '0 auto' }} />
  </Stack>
);

export default ProductPagination;
